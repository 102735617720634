export function Hotelapitoken(){
    return 'dT313RaUGMUrpDVXsgRmsJTZKCWzxhU4jkCnuHpl-GnyX3mq489CVkr1h4SNUfyBEBGeiTRJ0Mvimvh9Uyasa0Fa035D0QAj5jSWY-2p5acPid1jsLp2reVPlTYHcmO-wmDFC6qGP932vlJjUceETsypMc1JYYnnTg5NQD3dxSf126G0laOd4BRB9ye8Wt5bcgpddGjSUVM-cm1MXXNLZpRYs6jDt6krSZgNxVBc6aMb1weiYVrLRSbClpHRcXhL4T7yBuipXuJ0ynMmjWTk8Ptan1MsAT9ycOPGW2uB5N3qk86y-8OPTV6WlZZH9JOkNLWg4cwFAFmX0QV6B93dMucxLrkunbCKRqc0rOhHdWSsRMxTFoy09lPZQAAW-RicBUDkoidS2lQBukdYMqWjR2-9oglnD6Ec7Ru4ejnq1XbvGmRCdaidyFJunUAW74XWTHO2JcU8o-RrvTz0vzOOyFkUvouGf37VvqDBORcelqpi0ABtOkHcVlxMs1wM'
};

export function CustomerDomainName(){
    return 'https://system.alhijaztours.net/'
};
export function FlightSearchToken(){
    return 'dT313RaUGMUrpDVXsgRmsJTZKCWzxhU4jkCnuHpl-GnyX3mq489CVkr1h4SNUfyBEBGeiTRJ0Mvimvh9Uyasa0Fa035D0QAj5jSWY-2p5acPid1jsLp2reVPlTYHcmO-wmDFC6qGP932vlJjUceETsypMc1JYYnnTg5NQD3dxSf126G0laOd4BRB9ye8Wt5bcgpddGjSUVM-cm1MXXNLZpRYs6jDt6krSZgNxVBc6aMb1weiYVrLRSbClpHRcXhL4T7yBuipXuJ0ynMmjWTk8Ptan1MsAT9ycOPGW2uB5N3qk86y-8OPTV6WlZZH9JOkNLWg4cwFAFmX0QV6B93dMucxLrkunbCKRqc0rOhHdWSsRMxTFoy09lPZQAAW-RicBUDkoidS2lQBukdYMqWjR2-9oglnD6Ec7Ru4ejnq1XbvGmRCdaidyFJunUAW74XWTHO2JcU8o-RrvTz0vzOOyFkUvouGf37VvqDBORcelqpi0ABtOkHcVlxMs1wM'
};

export function ApiEndPoint(){
    return 'https://api.synchtravel.com'
};
export function TransferApiToken(){
    return 'dT313RaUGMUrpDVXsgRmsJTZKCWzxhU4jkCnuHpl-GnyX3mq489CVkr1h4SNUfyBEBGeiTRJ0Mvimvh9Uyasa0Fa035D0QAj5jSWY-2p5acPid1jsLp2reVPlTYHcmO-wmDFC6qGP932vlJjUceETsypMc1JYYnnTg5NQD3dxSf126G0laOd4BRB9ye8Wt5bcgpddGjSUVM-cm1MXXNLZpRYs6jDt6krSZgNxVBc6aMb1weiYVrLRSbClpHRcXhL4T7yBuipXuJ0ynMmjWTk8Ptan1MsAT9ycOPGW2uB5N3qk86y-8OPTV6WlZZH9JOkNLWg4cwFAFmX0QV6B93dMucxLrkunbCKRqc0rOhHdWSsRMxTFoy09lPZQAAW-RicBUDkoidS2lQBukdYMqWjR2-9oglnD6Ec7Ru4ejnq1XbvGmRCdaidyFJunUAW74XWTHO2JcU8o-RrvTz0vzOOyFkUvouGf37VvqDBORcelqpi0ABtOkHcVlxMs1wM'
};
export function TransferCheckoutToken(){
    return 'dT313RaUGMUrpDVXsgRmsJTZKCWzxhU4jkCnuHpl-GnyX3mq489CVkr1h4SNUfyBEBGeiTRJ0Mvimvh9Uyasa0Fa035D0QAj5jSWY-2p5acPid1jsLp2reVPlTYHcmO-wmDFC6qGP932vlJjUceETsypMc1JYYnnTg5NQD3dxSf126G0laOd4BRB9ye8Wt5bcgpddGjSUVM-cm1MXXNLZpRYs6jDt6krSZgNxVBc6aMb1weiYVrLRSbClpHRcXhL4T7yBuipXuJ0ynMmjWTk8Ptan1MsAT9ycOPGW2uB5N3qk86y-8OPTV6WlZZH9JOkNLWg4cwFAFmX0QV6B93dMucxLrkunbCKRqc0rOhHdWSsRMxTFoy09lPZQAAW-RicBUDkoidS2lQBukdYMqWjR2-9oglnD6Ec7Ru4ejnq1XbvGmRCdaidyFJunUAW74XWTHO2JcU8o-RrvTz0vzOOyFkUvouGf37VvqDBORcelqpi0ABtOkHcVlxMs1wM'
};

export function CurrencyConverter(){
    return 'd9c44ce6f7a3ac2c11e79b9f'
};
export function StripeClientSecret(){
    return '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'
    // return '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'
};
export function ActivityToken(){
    return 'dT313RaUGMUrpDVXsgRmsJTZKCWzxhU4jkCnuHpl-GnyX3mq489CVkr1h4SNUfyBEBGeiTRJ0Mvimvh9Uyasa0Fa035D0QAj5jSWY-2p5acPid1jsLp2reVPlTYHcmO-wmDFC6qGP932vlJjUceETsypMc1JYYnnTg5NQD3dxSf126G0laOd4BRB9ye8Wt5bcgpddGjSUVM-cm1MXXNLZpRYs6jDt6krSZgNxVBc6aMb1weiYVrLRSbClpHRcXhL4T7yBuipXuJ0ynMmjWTk8Ptan1MsAT9ycOPGW2uB5N3qk86y-8OPTV6WlZZH9JOkNLWg4cwFAFmX0QV6B93dMucxLrkunbCKRqc0rOhHdWSsRMxTFoy09lPZQAAW-RicBUDkoidS2lQBukdYMqWjR2-9oglnD6Ec7Ru4ejnq1XbvGmRCdaidyFJunUAW74XWTHO2JcU8o-RrvTz0vzOOyFkUvouGf37VvqDBORcelqpi0ABtOkHcVlxMs1wM'
};
export function HajjApiToken(){
    return 'dT313RaUGMUrpDVXsgRmsJTZKCWzxhU4jkCnuHpl-GnyX3mq489CVkr1h4SNUfyBEBGeiTRJ0Mvimvh9Uyasa0Fa035D0QAj5jSWY-2p5acPid1jsLp2reVPlTYHcmO-wmDFC6qGP932vlJjUceETsypMc1JYYnnTg5NQD3dxSf126G0laOd4BRB9ye8Wt5bcgpddGjSUVM-cm1MXXNLZpRYs6jDt6krSZgNxVBc6aMb1weiYVrLRSbClpHRcXhL4T7yBuipXuJ0ynMmjWTk8Ptan1MsAT9ycOPGW2uB5N3qk86y-8OPTV6WlZZH9JOkNLWg4cwFAFmX0QV6B93dMucxLrkunbCKRqc0rOhHdWSsRMxTFoy09lPZQAAW-RicBUDkoidS2lQBukdYMqWjR2-9oglnD6Ec7Ru4ejnq1XbvGmRCdaidyFJunUAW74XWTHO2JcU8o-RrvTz0vzOOyFkUvouGf37VvqDBORcelqpi0ABtOkHcVlxMs1wM'
};


// d452152ef318121614eccb23
// d9c44ce6f7a3ac2c11e79b9f