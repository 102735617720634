import React from "react";
import Layout from "../../Components/Layout/Layout";
import img from './faq.jpg'
function TermsConditions(){
    return(
        <>
        <Layout>
        <div>
                <img src={img}/>
            </div>
        <div className="container">
            <div className="row mt-3 text-center">
                <h3>Terms & Conditions</h3>
            </div>
            <div className="row mt-4 ">
            <p className="FontSize15">
                The following terms and conditions set  the rules and regulations for the use of 365 Tours  Travel and Tours's website. By accessing this website or using any part of it, you are agreeing to be bound by the following terms of use. If you do not wish to consent, you may not and should not access nor use 365 Tours Webite.  
                 These conditions may undergo change or alteration at any time without any given prior notice to you. If you continue to access and use 365 Tours you agree to be bound by the most current version of the conditions of use. Please check these conditions from time to time to see if any changes have been made.
               </p>           
                </div>
            <div className="row mt-4 ">
                <h4>
                    1-General Terms & Conditions
                </h4>
                <ul className="FontSize15" style={{listStyle:'disc'}}>
                      <li>
                          <p>
                              The Customer is prohibited from copying, transferring, reusing without modifications, reusing with modifications any of the mentioned above content published in the website.
                          </p>
                      </li>
                      
                      <li>
                          <p>
                          365 Tours Tours reserves all the rights of the content published on Alhjiaz Tours platform including but not limited to: Texts, Images, icons, logos, trademarks , video/audio media , links published in the platform.
                          </p>
                      </li>
                      
                      <li>
                          <p>
                          365 Tours grants the customer a limited, non-transferable license to use this site in line with the terms and conditions. The User may only use this site to create authentic reservations or purchases and shall not use this site for any other purposes.
                          </p>
                      </li>
                      
                      <li>
                          <p>
                              You  hereby agree that, as The Customer, shall not at any time distribute, resell, cross-sell, or permit access to the Services to any third party, permit multiple end users to access the Services using shared login credentials (i.e., a shared email address and password) and the terms contained in this Agreement.
                           </p>
                      </li>
                      
                      <li>
                          <p>
                          365 Tours reserves the right, at its sole discretion, to suspend The Customer ability to use or access the Services fully or partially at any time while The Platform inspects complaints about violating this Agreement, or for any other reason. Further, it will also have the right to terminate or restrict The Customer use of  the Services if the platform, learns  that The Customer is misusing the Services in any manner whatsoever.
                          </p>
                      </li>
                      
                       <li>
                          <p>
                              The Customer holds responsibility for the accuracy of any data, requested by the website online forms or support team, and submitted via different communications channels of online website forms, email messages, social media or direct phone calls.
                          </p>
                      </li>
                      
                      <li>
                          <p>
                          365 Tours also retains full rights to change/ add or edit any part or whole of Terms and Conditions at its discretion.
                          </p>
                      </li>
                  </ul>            </div>
        </div>
        </Layout>
        </>
    )
}

export default TermsConditions;