import React, { useEffect, useState ,useRef} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGlobe,
  faMinus,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'
import Axios from 'axios'
import Select from 'react-select';
import Loader from '../Loading/Loader'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import { fetchHotels,fetchHotelsSearh,SearchToursDetail } from '../../Redux/Actions/actions'
import Autocomplete from 'react-google-autocomplete'
import { DateRangePicker } from 'rsuite'
import {Hotelapitoken,ApiEndPoint} from '../GlobalData/GlobalData';
import { useDispatch } from 'react-redux';
import moment from 'moment'
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
const { beforeToday} =DateRangePicker;
function HotelSearch(){
  const dispatch = useDispatch();
  const navigate = useNavigate();
  var endpoint=ApiEndPoint();
  const [places, setPlace] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // You can adjust the breakpoint as needed
  const [placeDetail, setPlaceDetail] = useState({
      city: '',
      country: '',
      countrycode: '',
      lat: 0,
      long: 0,
      checkindate: '',
      Checkoutdate: ''
    });

    const [initialRender, setInitialRender] = useState(true);
    const [selectedNationality, setSelectedNationality] = useState('');
    const [loading, setLoading] = useState(false);
    const [adultModal, setAdultModal] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [dateRange, setDateRange] = useState({ checkindate: null,Checkoutdate: null});
    const [personData, setPersonData] = useState({
      adult: 2,
      children: 0,
      room: 1
    });
    const [rooms, setRooms] = useState([
      // Initial room with default values
      {
        adults: 2,
        children: 0,
        childrenAges: [],
      },
    ]);
  
    const [countryList, setCountryList] = useState([]);
    useEffect(() => {
      fetchData();
    }, [dispatch]);

    const Searchhotels = async () => {

      if(places.length ===0){
        toast.info('Please Select Destination.', {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      } if(dateRange.Checkoutdate===null){
        toast.info('Please Select Hotel Stay.', {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }else if(selectedNationality ===''){
        toast.info('Please Select Nationality.', {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      };
       setLoading(true)
       const currentURL = window.location.href;
       const parts = currentURL.split('/');
      const pagename = parts[parts.length - 1];
       // Define your API URL, authToken, and dataToSend as shown in the previous example
       const FormData = {
          "token":'r9fdvwRyF35JUnD6xXdRiDELANYjtfASzPmyGol4-1PN461EY50LbXcqkfEfISsOJDrnFDJbuMzPuxTz37zFWGWBVemQGhi2SYLrr-MZ75vJSAiV73z94UOVrDz5P6R-0KjFqr9XR6P2857snQbcKTUn9YNqjBOQQIkXENeO7tmjxdTJs2KUVoXqo6fFyT9TTq99eKe288N-wyanZXxOsfABWPjtSom2oKLVz6vJnn1WeQwHSp7VnzPUqq53rn80eFXNBSMIiEXBdDmlsokRYSa0evDrQKluhnIzMYkRiazxtnkb-z5Xj0tQReTTHsLz1sgnit2mRGGzP4EIdBK8TiLuEN7GD1kmOT3CMreL7ELrI4yxmEbnYyflICtG-ySk3aZkk8iM9mRZlA7CS10Zuj-C0HEBOFW8vMzy4Eq2CET5WN62S1xe0HPAOrDVwO6jDvVpKEMwm-NiyyjkU8oTTlgYpN77pXtfFjKPTF0julnAMC6cPzxZOGBIkRv0',
         "currency_slc": 'AFN',
         "currency_slc_iso": 'AF',
         "destination_name": placeDetail.city,
         "country": placeDetail.country,
         "lat": placeDetail.lat,
         "long": placeDetail.long,
         "pin": placeDetail.countrycode,
         "cityd": placeDetail.city,
         "country_code": placeDetail.countrycode,
         // "daterange_date": placeDetail.checkindate+'-'+placeDetail.Checkoutdate,
         "check_in":placeDetail.checkindate,
         "check_out":placeDetail.Checkoutdate,
         "slc_nationality": String(selectedNationality),
         ...rooms.reduce((acc, item,index) => {
           acc.adult += item.adults;
           acc.child += item.children;
           acc.room += 1;
           acc.Adults.push(item.adults);
           acc.children.push(item.children);
           acc[`child_ages${index + 1}`] = item.childrenAges.map((age) =>
       age ? parseInt(age) : 2
     );
           acc.rooms_counter.push(acc.room);
           return acc;
         }, {
           "adult": 0,
           "child": 0,
           "room": 0,
           "Adults": [],
           "children": [],
           "child_ages1": [],
           "rooms_counter": [],
           "child_ages2": [],
         }),
        
       };
       // navigate('/hotels',{state:{FormData}});
       try {
       
        dispatch(fetchHotelsSearh(FormData));

         const response = await Axios.post(endpoint+'/api/search/hotels/new',FormData, {
           headers: {
             "Access-Control-Allow-Origin": "*",
             // Required for CORS support to work
             //  "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
             // "Access-Control-Allow-Headers":
             // "Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale",
            
           } ,
           
         });
         dispatch(fetchHotels(response.data));
         setLoading(false);
         sessionStorage.removeItem('FlightCheckOut');
        
         if(pagename==='hotels'){
         
         }else{
          navigate('/hotels');
         }
        
       } catch (error) {
         console.error('Error:', error);
         setLoading(false);
       }
     };
     useEffect(() => {
      if (initialRender) {
        setInitialRender(false) // Update the initialRender state to false.
        return // Exit the effect early to avoid running the code below.
      }
      
      if (places.length !== 0) {
        // debugger
        if (
          places.address_components.filter(
            f =>
              JSON.stringify(f.types) ===
              JSON.stringify(['locality', 'political'])
          )[0]
        ) {
          const city1 = places.address_components.filter(
            f =>
              JSON.stringify(f.types) ===
              JSON.stringify(['locality', 'political'])
          )[0]?.short_name
          // console.log('city1=' + city1)
          setPlaceDetail(prevPlaceDetail => ({
            ...prevPlaceDetail,
            city: city1
          }))
        } else {
          const city1 = places.address_components.filter(
            f =>
              JSON.stringify(f.types) ===
              JSON.stringify(['administrative_area_level_2', 'political'])
          )[0]?.short_name
          // console.log('city1=' + city1)
          setPlaceDetail(prevPlaceDetail => ({
            ...prevPlaceDetail,
            city: city1
          }))
        }
  
        if (
          places.address_components.filter(
            f =>
              JSON.stringify(f.types) ===
              JSON.stringify(['locality', 'political'])
          )[0]
        ) {
          const displayCity1 = places.address_components.filter(
            f =>
              JSON.stringify(f.types) ===
              JSON.stringify(['locality', 'political'])
          )[0]?.long_name
          // console.log('displayCity1=' + displayCity1)
        } else {
          const displayCity1 = places.address_components.filter(
            f =>
              JSON.stringify(f.types) ===
              JSON.stringify(['administrative_area_level_2', 'political'])
          )[0]?.long_name
          // console.log('displayCity1=' + displayCity1)
        }
  
        if (
          places.address_components.filter(
            f =>
              JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
          )[0]
        ) {
          const country_code = places.address_components.filter(
            f =>
              JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
          )[0]?.short_name
          // console.log('country_code=' + country_code)
          setPlaceDetail(prevPlaceDetail => ({
            ...prevPlaceDetail,
            countrycode: country_code
          }))
        }
        if (
          places.address_components.filter(
            f =>
              JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
          )[0]
        ) {
          const country = places.address_components.filter(
            f =>
              JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
          )[0]?.long_name
          setPlaceDetail(prevPlaceDetail => ({
            ...prevPlaceDetail,
            country: country
          }))
        }
  
        var address = places.formatted_address
        var latitude = places.geometry.location.lat()
        setPlaceDetail(prevPlaceDetail => ({
          ...prevPlaceDetail,
          lat: latitude
        }))
        var longitude = places.geometry.location.lng()
        setPlaceDetail(prevPlaceDetail => ({
          ...prevPlaceDetail,
          long: longitude
        }))
        const latlng = new window.google.maps.LatLng(latitude, longitude)
        const geocoder = new window.google.maps.Geocoder()
        geocoder.geocode({ latLng: latlng }, function (results, status) {
          if (status === window.google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              const addressComponents = results[0].address_components
              const lastAddressComponent =
                addressComponents[addressComponents.length - 1]
              const country = lastAddressComponent.short_name
              const state =
                addressComponents[addressComponents.length - 3].long_name
              const city =
                addressComponents[addressComponents.length - 4].long_name
  
            }
          }
        })
      }
    }, [places, initialRender]);

    const handleDeleteRoomByIndex=(roomIndex)=>{
      const updatedRooms = rooms.filter((room, index) => index !== roomIndex);
      setRooms(updatedRooms)
    };
    const handleAdultDecrement = (roomIndex) => {
      // Decrement the number of adults for a specific room
      const updatedRooms = [...rooms];
      if (updatedRooms[roomIndex].adults > 1) {
        updatedRooms[roomIndex].adults -= 1;
        setRooms(updatedRooms);
      }
    };
    const handleChildrenDecrement = (roomIndex) => {
      // Decrement the number of children for a specific room
      const updatedRooms = [...rooms];
      if (updatedRooms[roomIndex].children > 0) {
        updatedRooms[roomIndex].children -= 1;
        setRooms(updatedRooms);
      }
    };
    const childAgearray = [
      '1 Year',
      '2 Year',
      '3 Year',
      '4 Year',
      '5 Year',
      '6 Year',
      '7 Year',
      '8 Year',
      '9 Year',
      '10 Year',
      '11 Year',
      '12 Year',
      '13 Year',
      '14 Year',
      '15 Year',
      '16 Year',
      '17 Year'
    ];
    const addRoom = () => {
      // Add a new room with default values
      setRooms([...rooms, { adults: 2, children: 0, childrenAges: [] }]);
    };
  
    const removeLastRoom = () => {
      if (rooms.length > 1) {
        // Remove the last room by slicing the array
        setRooms(rooms.slice(0, -1));
      }
    };
    const handleChildrenIncrement = (roomIndex) => {
      // Increment the number of children for a specific room, if less than 4
      const updatedRooms = [...rooms];
      if (updatedRooms[roomIndex].children < 4) {
        updatedRooms[roomIndex].children += 1;
        setRooms(updatedRooms);
      }
    };
    const handleChildAgeChange = (roomIndex, childIndex, value) => {
      // Update the age of a specific child for a specific room
      const updatedRooms = [...rooms];
      updatedRooms[roomIndex].childrenAges[childIndex] = value;
      setRooms(updatedRooms);
    };
    const handleAdultIncrement = (roomIndex) => {
      // Increment the number of adults for a specific room
      const updatedRooms = [...rooms];
      updatedRooms[roomIndex].adults += 1;
      setRooms(updatedRooms);
    };
    const handleDateRangeChange = (value) => {
      if (value === null) {
        // Dates have been removed, reset the date range state
        setPlaceDetail((prevPlaceDetail) => ({
          ...prevPlaceDetail,
          checkindate: '',
          Checkoutdate: '',
        }));
    
        // Reset the dateRange state
        setDateRange(null);
      } else {
        // Dates are selected, format and update the state
        setPlaceDetail((prevPlaceDetail) => ({
          ...prevPlaceDetail,
          checkindate: moment(value[0]).format('YYYY-MM-DD'),
          Checkoutdate: moment(value[1]).format('YYYY-MM-DD'),
        }));
    
        // Update the dateRange state
        setDateRange(value);
      }
    };
    const toggleAdultModal = () => {
      setAdultModal(!adultModal)
    };
    async function fetchData () {
      Axios.get('https://restcountries.com/v3.1/all?fields=name,flags,cca2,cca3,idd')
      .then(response => {
        const countryOptions = response?.data.map((country) => ({
            value:country.name.common,
            label: country.name.common,
            flag: country.flags.png,
            phoneCode: country.idd.root+country.idd.suffixes[0],
          }));
        setCountryList(countryOptions)
      })
      .catch(error => {
        console.error('Error:', error)
      })
    };
    useEffect(() => {
      // Calculate the total counts from the inputArray
      const adults = rooms.reduce((acc, item) => acc + item.adults, 0);
      const children = rooms.reduce((acc, item) => acc + item.children, 0);
      const roomss = rooms.length;
      setPersonData(prevPersonData => ({
        ...prevPersonData,
       adult:adults,
       children:children,
       room:roomss
        // Use square brackets to set the property dynamically based on the 'name' attribute
      }))
      // Update the state with the calculated totals
      
    }, [rooms]);

    const handleCountryChange = (selectedOption) => {
      setSelectedCountry(selectedOption);
      setSelectedNationality(selectedOption.value) // Update the selected nationality when the user makes a selection
    };
    
    return(
        <>
          {loading && (
          <Loader />
            )}
        <ToastContainer/> 
      <Modal isOpen={adultModal}   centered={true}  toggle={toggleAdultModal}>
        <ModalHeader toggle={toggleAdultModal}>Select Detail</ModalHeader>
        <ModalBody>
          <div className='p-3'>
          {rooms.map((room, roomIndex) => (
            <div key={roomIndex}>
             <div className='hotel-detail-border d-flex text-center fw-bold mb-2' style={{justifyContent:'space-between',alignItems:'center'}}>
             <p className='fw-bold'>Room {roomIndex + 1}</p>
             {roomIndex > 0 && ( // Check if roomIndex is greater than 0
        <button className='delete-room-button m-1' onClick={() => handleDeleteRoomByIndex(roomIndex)}>Delete</button>
      )}
           </div>
           <div>
           <div className='select-child'>
             <div class='f4878764f1'>
               <label class='a984a491d9 fw-bold' for='group_adults'>
                 Adults
               </label>
             </div>
             <div class='d-flex fff'>
               <button
                 className='adult-modal-btn'
                 name={`adults-${roomIndex}`}
                 onClick={() => handleAdultDecrement(roomIndex)}
                 
               >
                 <i class='fas fa-minus'>
                   <FontAwesomeIcon size='sm' icon={faMinus} />
                 </i>
               </button>
               <span className='d723d73d5f fw-bold' id='input'>
               {room.adults}
               </span>
               <button
                 className='adult-modal-btn'
                 name={`adults-${roomIndex}`}
                 onClick={() => handleAdultIncrement(roomIndex)}
                 id='increment'
               >
                 <i class='fas fa-plus'>
                   <FontAwesomeIcon size='sm' icon={faPlus} />
                 </i>
               </button>
             </div>
           </div>
           <div className='select-child'>
             <div class='f4878764f1'>
               <label class='a984a491d9 fw-bold' for='group_adults'>
                 Children
               </label>
             </div>
             <div class='d-flex fff'>
               <button
                 className='adult-modal-btn'
                 onClick={() => handleChildrenDecrement(roomIndex)}
                 id='decrement'
               >
                 <i class='fas fa-minus'>
                   <FontAwesomeIcon size='sm' icon={faMinus} />
                 </i>
               </button>
               <span className='d723d73d5f fw-bold' id='input'>
                 {room.children}
               </span>
               <button
                 className='adult-modal-btn'
                 onClick={() => handleChildrenIncrement(roomIndex)}
                 id='increment'
               >
                 <i class='fas fa-plus'>
                   <FontAwesomeIcon size='sm' icon={faPlus} />
                 </i>
               </button>
             </div>
           </div>
           <div className='select-child' style={{ justifyContent: 'left' }}>
           {room.children > 0 && (
             <div className='row w-100 '>
             {Array.from({ length: room.children }, (_, childIndex) => (
              
               <div  key={childIndex} className='m-1 ' style={{width:'45%',alignItems:'center'}}>
               <label>Child {childIndex+1} (Age)</label>
               <select
                 class='form-select child-age-select'
                 name='child1'
                 onChange={(e) =>handleChildAgeChange(roomIndex, childIndex, e.target.value)}
                 aria-label='Default select example'
               >
                 <option selected>Age needed</option>
                 {childAgearray.map((item, index) => (
                   <option key={index} value={index + 1}>
                     {item}
                   </option>
                 ))}
               </select>
             </div>
             ))}
           </div>
           )}
           </div>
           
         </div>
         </div>
          ))}
           
            
          </div>
        </ModalBody>
        <ModalFooter>
        <div className='select-child'>
                <div class='f4878764f1 pe-4'>
                  <label class='a984a491d9 fw-bold' for='group_adults'>
                    Room
                  </label>
                </div>
                <div class='d-flex fff'>
                  <button
                    className='adult-modal-btn'
                    onClick={removeLastRoom}
                    id='decrement'
                  >
                    <i class='fas fa-minus'>
                      <FontAwesomeIcon size='sm' icon={faMinus} />
                    </i>
                  </button>
                  <span className='d723d73d5f fw-bold' id='input'>
                    {rooms.length}
                  </span>
                  <button
                    className='adult-modal-btn'
                    onClick={addRoom}
                    id='increment'
                  >
                    <i class='fas fa-plus'>
                      <FontAwesomeIcon size='sm' icon={faPlus} />
                    </i>
                  </button>
                </div>
              </div>
          <Button color='secondary' onClick={toggleAdultModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <div id="panel-hotels" class="sc-aXZVg py-2 px-3">
       
          <div class="sticky-outer-wrapper sc-7e08f4d0-0 ktsBCw" >
            <div class="sticky-inner-wrapper" style={{position: 'relative', top:'0px', zIndex: '1'}}>
              <div class="sc-gEvEer PJepm">
                <div class="sc-gEvEer sc-iHGNWf kAxPQI gDaPee">
                  <div class="sc-gEvEer sc-dAlyuH fgRulP bnGnEl">
                    <div class="sc-gEvEer sc-eqUAAy dETXCd gBtaQQ">
                        <div width="1,,0.5,,0.3333333333333333" data-testid="DASH_TAB_HOTELS_ENDLOCATION" class="Box-pcln-design-system__sc-n9h3nv-0 jXiFwQ" style={{width:'25%'}}>
                          <div width="1" class="sc-gEvEer dsJkWl">
                            <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                              <div class="sc-gEvEer iTwuwh">
                                <div width="1" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="endLocation-typeahead-downshift-container-label" aria-controls="typeahead-dropdown" class="sc-gEvEer dsJkWl" style={{position: 'relative'}}>
                                  <div width="1" class="sc-gEvEer sc-20db91c2-0 dsJkWl gSCCYN">
                                    <div color="background.lightest" class="sc-gEvEer hILmdS">
                                        <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe">
                                          <div width="1" class="sc-gEvEer SjeQr">
                                            <div class="sc-gEvEer PJepm">
                                              <div class="sc-gEvEer sc-uVWWZ kPRUfN jGcugE">
                                                  <label width="auto" for="endLocation-typeahead-downshift-container-input" id="endLocation-typeahead-downshift-container-label" color="text.light" font-size="10" font-weight="700" letter-spacing="0.2px" class="sc-fXSgeo cAIQgh"
                                                        style={{height: '20px', transitionProperty: 'opacity', transitionDuration: '0.1s', opacity: '1', pointerEvents: 'none', position: 'relative', width: 'calc(100% - 40px)'}}>Where to?
                                                  </label>

                                                  <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe">
                                                      <div data-testid="icon-field-prefix-icons" class="sc-gEvEer sc-eqUAAy fQyDwd hIDAJO">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 aLkqs Search__SvgSearch-sc-1osw7jr-0 kHgnEk">
                                                          <path d="M15.5 14h-.8l-.3-.3c1-1.1 1.6-2.6 1.6-4.2C16 5.9 13.1 3 9.5 3S3 5.9 3 9.5 5.9 16 9.5 16c1.6 0 3.1-.6 4.2-1.6l.3.3v.8l5 5 1.5-1.5-5-5zm-6 0C7 14 5 12 5 9.5S7 5 9.5 5 14 7 14 9.5 12 14 9.5 14z"></path>
                                                        </svg>
                                                      </div>
                                                      <Autocomplete style={{ paddingTop: '20px', paddingBottom: '8px', transitionProperty: 'paddingTop, padding-bottom' , transitionDuration: '0.1s'}} class="sc-hCPjZK hmtJkv text-start" type='text'
                                                          apiKey='AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY'  
                                                          onPlaceSelected={place => { console.log('Selected Place:', place); setPlace(place);}} 
                                                          options={{types: ['(regions)'],  componentRestrictions: null }}
                                                         >
                                                      </Autocomplete>
                                                    </div>
                                                </div>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div width="1,,0.5,,0.3333333333333333" data-testid="DASH_TAB_HOTELS_DATES" id="hotel-date-range-box" class="Box-pcln-design-system__sc-n9h3nv-0 jXiFwQ" style={{width:'25%'}}>
                          <div width="1" class="sc-gEvEer dsJkWl">
                            <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                              <div width="1" class="sc-gEvEer sc-20db91c2-0 dsJkWl gSCCYN">
                                  <div color="background.lightest" class="sc-gEvEer hILmdS">
                                    <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe">
                                      <div width="1" class="sc-gEvEer SjeQr">
                                        <div class="sc-gEvEer PJepm">
                                          <div class="sc-gEvEer sc-uVWWZ kPRUfN jGcugE">
                                            <label for="hotelDates" width="auto" color="text.light" font-size="10" font-weight="700" letter-spacing="0.2px" class="sc-fXSgeo cAIQgh" 
                                                  style={{height: '20px', transitionProperty: 'opacity', transitionDuration: '0.1s', opacity: '1', pointerEvents: 'none', position: 'relative', width: 'calc(100% - 40px)'}}>Check-in - Check-out
                                            </label>
                                            <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe kpaNAY1">
                                              <div data-testid="icon-field-prefix-icons" class="sc-gEvEer sc-eqUAAy fQyDwd hIDAJO">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 aLkqs Calendar__SvgCalendar-sc-12aq2xi-0 DXxDd">
                                                  <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"></path>
                                                </svg>
                                              </div>
                                              <DateRangePicker
                                                style={{border:'0 !important', marginLeft:'30px', paddingTop: '20px', paddingBottom: '8px', transitionProperty: 'paddingTop', transitionDuration: '0.1s'}} 
                                                role="combobox" placeholder="Check-in - Check-out" value={dateRange} showOneCalendar={isMobile}  disabledDate={beforeToday()} onChange={handleDateRangeChange}
                                                data-datepicker="true" id="hotelDates" data-testid="hotel-date-range" aria-controls="hotel-calander-wrapper" aria-expanded="false" 
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div width="1,,0.5,,0.3333333333333333" data-testid="DASH_TAB_HOTELS_DATES" id="hotel-date-range-box" class="Box-pcln-design-system__sc-n9h3nv-0 jXiFwQ" style={{width:'25%'}}>
                          <div width="1" class="sc-gEvEer dsJkWl">
                            <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                              <div width="1" class="sc-gEvEer sc-20db91c2-0 dsJkWl gSCCYN">
                                  <div color="background.lightest" class="sc-gEvEer hILmdS">
                                    <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe">
                                      <div width="1" class="sc-gEvEer SjeQr">
                                        <div class="sc-gEvEer PJepm">
                                          <div class="sc-gEvEer sc-uVWWZ kPRUfN jGcugE">
                                            <label for="hotelDates" width="auto" color="text.light" font-size="10" font-weight="700" letter-spacing="0.2px" class="sc-fXSgeo cAIQgh" 
                                                  style={{height: '20px', transitionProperty: 'opacity', transitionDuration: '0.1s', opacity: '1', pointerEvents: 'none', position: 'relative', width: 'calc(100% - 40px)'}}>Rooms & Guests
                                            </label>
                                            <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe kpaNAY1" style={{height:'50px'}}>
                                              <div data-testid="icon-field-prefix-icons" class="sc-gEvEer sc-eqUAAy fQyDwd hIDAJO" style={{marginLeft:'10px'}}>
                                                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 fKFmLo User__SvgUser-sc-tdftug-0 jIfocp">
                                                  <path d="M12 12c2.2 0 4-1.8 4-4s-1.8-4-4-4-4 1.8-4 4 1.8 4 4 4zm0 2c-2.7 0-8 1.3-8 4v2h16v-2c0-2.7-5.3-4-8-4z"></path>
                                                </svg>
                                              </div>
                                                <div class="sc-gEvEer sc-eqUAAy PJepm cgaCzu" style={{marginLeft:'35px', marginTop:'10px'}}>
                                                  <div font-size="1" font-weight="500" class="sc-kAyceB ctauFf"> 
                                                     {personData.room} Room, {personData.adult} Adults, {personData.children} Children
                                                  </div>
                                                  <input  type="button"  onClick={toggleAdultModal}  class="sc-hCPjZK jzjDHJ sc-9cd2429f-1 ioEqsK" style={{textAlign:'left', marginLeft:'25px'}}
                                                          // value={`${hotelsSearchData.room} Room, ${hotelsSearchData.adult} Adults, ${hotelsSearchData.child} Child`}
                                                  />
                                                </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div width="1,,0.5,,0.3333333333333333" data-testid="DASH_TAB_HOTELS_DATES" id="hotel-date-range-box" class="Box-pcln-design-system__sc-n9h3nv-0 jXiFwQ" style={{width:'25%'}}>
                          <div width="1" class="sc-gEvEer dsJkWl">
                            <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                              <div width="1" class="sc-gEvEer sc-20db91c2-0 dsJkWl gSCCYN">
                                  <div  class="sc-gEvEer ">
                                    <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe modify">
                                      
                                    <Select
                                                  options={countryList}
                                                  isSearchable={true}
                                                  className=""
                                                  onChange={handleCountryChange}
                                                  value={selectedCountry}
                                                  
                                                  getOptionLabel={(option) => (
                                                      <div>
                                                      <img
                                                          src={option.flag}
                                                          alt={option.label}
                                                          style={{ width: '20px', marginRight: '8px' }}
                                                      />
                                                      {option.label}
                                                      </div>
                                                  )}
                                                  getOptionValue={(option) => option.value}
                                                  />
                                    </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div width="1,,0.5,,0.3333333333333333" class="Box-pcln-design-system__sc-n9h3nv-0 jXiFwQ" style={{overflowY: 'visible', width:'15%'}}>
                          <button width="1" type="submit" style={{cursor:'pointer', height:'40px'}} data-testid="HOTELS_SUBMIT_BUTTON" scale="1.02"
                              class="sc-fqkvVR kNhhGi sc-266892e-0 kULWIA" color="primary" onClick={Searchhotels}>
                            <div class="sc-gEvEer sc-eqUAAy PJepm cgaCzu" style={{cursor:'pointer'}}>Update Search</div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
       
      </div>
        </>
    )
};

export default HotelSearch;