import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMinus,
  faPlus,
  faPlane,
  faSliders
} from '@fortawesome/free-solid-svg-icons'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import ActivitySearch from './ActivitySearch'
import { ToastContainer, toast } from 'react-toastify';
import { NavLink,Link,useNavigate } from 'react-router-dom'
import Loader from '../Loading/Loader'
import { DateRangePicker } from 'rsuite'
import 'rsuite/dist/rsuite.min.css'
import {Hotelapitoken,ApiEndPoint} from '../GlobalData/GlobalData';
import Autocomplete from 'react-google-autocomplete'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import moment from 'moment'
import Axios from 'axios'
import TransferSearch from './Transfers'
import 'react-datepicker/dist/react-datepicker.css'
import HotelSearch from './HotelSearch';
import PackageSearch from './PackageSearch'
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FlightSearch from './FlightSearch'
import { fetchHotels,fetchHotelsSearh,SearchToursDetail } from '../../Redux/Actions/actions'
const { beforeToday} =DateRangePicker;
function SearchBar () {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  var endpoint=ApiEndPoint();
  const [selectedDate, setSelectedDate] = useState(null);
  const [places, setPlace] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedNationality, setSelectedNationality] = useState('');
  const [loading, setLoading] = useState(false);
  const [personData, setPersonData] = useState({
    adult: 2,
    children: 0,
    room: 1
  });
  const [placeDetail, setPlaceDetail] = useState({
    city: '',
    country: '',
    countrycode: '',
    lat: 0,
    long: 0,
    checkindate: '',
    Checkoutdate: ''
  });
  const [rooms, setRooms] = useState([
    // Initial room with default values
    {
      adults: 2,
      children: 0,
      childrenAges: [],
    },
  ]);
 
  const [showtours, setShowtours] = useState(false);
  const [Showhotels, setShowhotels] = useState(true);
  const [Showflights, setFlights] = useState(false);
  const [Showtransfers, setShowtransfers] = useState(false);
  const [ShowActivities, setShowActivities] = useState(false);
  const [activeItem, setActiveItem] = useState(2);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // You can adjust the breakpoint as needed
  const [adultModal, setAdultModal] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState({
    payment: false,
    booking: false,
    trackid: false
  });
  const [dateRange, setDateRange] = useState({ checkindate: null,Checkoutdate: null});
  // State for input value
 
  useEffect(() => {
    fetchData();
    GetPackages();
  }, [dispatch]);
 

  const Searchhotels = async () => {
    var token=Hotelapitoken();
    if(places.length ===0){
      toast.info('Please Select Destination.', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    } if(dateRange.Checkoutdate===null){
      toast.info('Please Select Hotel Stay.', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }else if(selectedNationality ===''){
      toast.info('Please Select Nationality.', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    };
     setLoading(true)
     // Define your API URL, authToken, and dataToSend as shown in the previous example
     const FormData = {
        "token":token,
       "currency_slc": 'AFN',
       "currency_slc_iso": 'AF',
       "destination_name": placeDetail.city,
       "country": placeDetail.country,
       "lat": placeDetail.lat,
       "long": placeDetail.long,
       "pin": placeDetail.countrycode,
       "cityd": placeDetail.city,
       "country_code": placeDetail.countrycode,
       // "daterange_date": placeDetail.checkindate+'-'+placeDetail.Checkoutdate,
       "check_in":placeDetail.checkindate,
       "check_out":placeDetail.Checkoutdate,
       "slc_nationality": String(selectedNationality),
       ...rooms.reduce((acc, item,index) => {
         acc.adult += item.adults;
         acc.child += item.children;
         acc.room += 1;
         acc.Adults.push(item.adults);
         acc.children.push(item.children);
         acc[`child_ages${index + 1}`] = item.childrenAges.map((age) =>
     age ? parseInt(age) : 2
   );
         acc.rooms_counter.push(acc.room);
         return acc;
       }, {
         "adult": 0,
         "child": 0,
         "room": 0,
         "Adults": [],
         "children": [],
         "child_ages1": [],
         "rooms_counter": [],
         "child_ages2": [],
       }),
      
     };
   
     // navigate('/hotels',{state:{FormData}});
     try {
     
      dispatch(fetchHotelsSearh(FormData));
      // _Live
       const response = await Axios.post(endpoint+'/api/search/hotels/new_Live',FormData, {
         headers: {
           "Access-Control-Allow-Origin": "*",
         } ,
         
       });
       sessionStorage.removeItem('FlightCheckOut');
       navigate('/hotels');
       dispatch(fetchHotels(response.data));
       // Handle the API response here
       setLoading(false)
     } catch (error) {
       // Handle errors here
       console.error('Error:', error);
     }
   };
   const [startDate, setStartDate] = useState(null);

   const handleDateFieldClick = () => {
     // Toggle visibility of the date picker on click
     setStartDate(startDate => (startDate ? null : new Date()));
   };
   useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Update isMobile based on the screen width
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Calculate the total counts from the inputArray
    const adults = rooms.reduce((acc, item) => acc + item.adults, 0);
    const children = rooms.reduce((acc, item) => acc + item.children, 0);
    const roomss = rooms.length;
    setPersonData(prevPersonData => ({
      ...prevPersonData,
     adult:adults,
     children:children,
     room:roomss
      // Use square brackets to set the property dynamically based on the 'name' attribute
    }))
    // Update the state with the calculated totals
    
  }, [rooms]);

  async function fetchData () {
    Axios.get('https://restcountries.com/v3.1/all?fields=name,flags,cca2,cca3,idd')
          .then(response => {
            const countryOptions = response?.data.map((country) => ({
                value:country.name.common,
                label: country.name.common,
                flag: country.flags.png,
                phoneCode: country.idd.root+country.idd.suffixes[0],
              }));
            setCountryList(countryOptions)
          })
          .catch(error => {
            console.error('Error:', error)
          })
  };


 
  
  // Callback function to handle date range changes
  

  const print = () => {
    // console.log(placeDetail)
    // console.log(selectedNationality)
    // console.log(personData)
    // console.log(rooms)
  };


  const GetPackages= async()=>{
    var token=Hotelapitoken();
    const fullURL = window.location.href;
    var data={
      'token':token,
      'currentURL':fullURL,
      'limit':6
    };

   };
  
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false) // Update the initialRender state to false.
      return // Exit the effect early to avoid running the code below.
    }
    
    if (places.length !== 0) {
      // debugger
      if (
        places.address_components.filter(
          f =>
            JSON.stringify(f.types) ===
            JSON.stringify(['locality', 'political'])
        )[0]
      ) {
        const city1 = places.address_components.filter(
          f =>
            JSON.stringify(f.types) ===
            JSON.stringify(['locality', 'political'])
        )[0]?.short_name
        // console.log('city1=' + city1)
        setPlaceDetail(prevPlaceDetail => ({
          ...prevPlaceDetail,
          city: city1
        }))
      } else {
        const city1 = places.address_components.filter(
          f =>
            JSON.stringify(f.types) ===
            JSON.stringify(['administrative_area_level_2', 'political'])
        )[0]?.short_name
        // console.log('city1=' + city1)
        setPlaceDetail(prevPlaceDetail => ({
          ...prevPlaceDetail,
          city: city1
        }))
      }

      if (
        places.address_components.filter(
          f =>
            JSON.stringify(f.types) ===
            JSON.stringify(['locality', 'political'])
        )[0]
      ) {
        const displayCity1 = places.address_components.filter(
          f =>
            JSON.stringify(f.types) ===
            JSON.stringify(['locality', 'political'])
        )[0]?.long_name
        // console.log('displayCity1=' + displayCity1)
      } else {
        const displayCity1 = places.address_components.filter(
          f =>
            JSON.stringify(f.types) ===
            JSON.stringify(['administrative_area_level_2', 'political'])
        )[0]?.long_name
        // console.log('displayCity1=' + displayCity1)
      }

      if (
        places.address_components.filter(
          f =>
            JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
        )[0]
      ) {
        const country_code = places.address_components.filter(
          f =>
            JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
        )[0]?.short_name
        // console.log('country_code=' + country_code)
        setPlaceDetail(prevPlaceDetail => ({
          ...prevPlaceDetail,
          countrycode: country_code
        }))
      }
      if (
        places.address_components.filter(
          f =>
            JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
        )[0]
      ) {
        const country = places.address_components.filter(
          f =>
            JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
        )[0]?.long_name
        setPlaceDetail(prevPlaceDetail => ({
          ...prevPlaceDetail,
          country: country
        }))
      }

      var address = places.formatted_address
      var latitude = places.geometry.location.lat()
      setPlaceDetail(prevPlaceDetail => ({
        ...prevPlaceDetail,
        lat: latitude
      }))
      var longitude = places.geometry.location.lng()
      setPlaceDetail(prevPlaceDetail => ({
        ...prevPlaceDetail,
        long: longitude
      }))
      const latlng = new window.google.maps.LatLng(latitude, longitude)
      const geocoder = new window.google.maps.Geocoder()
      geocoder.geocode({ latLng: latlng }, function (results, status) {
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            const addressComponents = results[0].address_components
            const lastAddressComponent =
              addressComponents[addressComponents.length - 1]
            const country = lastAddressComponent.short_name
            const state =
              addressComponents[addressComponents.length - 3].long_name
            const city =
              addressComponents[addressComponents.length - 4].long_name

          }
        }
      })
    }
  }, [places, initialRender]);

  const toggleModal = divNumber => {
    if (divNumber === 1) {
      setModalIsOpen({ payment: !modalIsOpen.payment })
    } else if (divNumber === 2) {
      setModalIsOpen({ booking: !modalIsOpen.booking })
    } else if (divNumber === 3) {
      setModalIsOpen({ trackid: !modalIsOpen.trackid })
    }
  };
  const handleDateChange = date => {
    setSelectedDate(date)
  };
  
  const handleCountryChange = (selectedOption) => {
    // debugger
    setSelectedCountry(selectedOption);
    setSelectedNationality(selectedOption.value) // Update the selected nationality when the user makes a selection
  };
  

  const toggleDiv = divNumber => {
    if (divNumber === 1) {
      setActiveItem(divNumber)
      setShowtransfers(false)
      setFlights(false)
      setShowtours(true)
      setShowActivities(false);
      setShowhotels(false) // Hide div2 when showing div1
    } else if (divNumber === 2) {
      setActiveItem(divNumber)
      setShowtransfers(false)
      setShowhotels(true)
      setFlights(false)
      setShowActivities(false)
      setShowtours(false) // Hide div1 when showing div2
    } else if (divNumber === 3) {
      setActiveItem(divNumber)
      setShowtransfers(true)
      setShowhotels(false)
      setShowActivities(false)
      setFlights(false)
      setShowtours(false) // Hide div1 when showing div2
    }else if (divNumber === 4) {
      setActiveItem(divNumber)
      setShowtransfers(false)
      setShowhotels(false)
      setShowActivities(false)
      setFlights(true)
      setShowtours(false) // Hide div1 when showing div2
    }
    else if (divNumber === 5) {
      setActiveItem(divNumber)
      setShowtransfers(false)
      setShowhotels(false)
      setShowActivities(true)
      setFlights(false)
      setShowtours(false) // Hide div1 when showing div2
    }
  };
  const toggleAdultModal = () => {
    setAdultModal(!adultModal)
  };
  const childAgearray = [
    '1 Year',
    '2 Year',
    '3 Year',
    '4 Year',
    '5 Year',
    '6 Year',
    '7 Year',
    '8 Year',
    '9 Year',
    '10 Year',
    '11 Year',
    '12 Year',
    '13 Year',
    '14 Year',
    '15 Year',
    '16 Year',
    '17 Year'
  ];
  const handleDateRangeChange = (value) => {
    if (value === null) {
      // Dates have been removed, reset the date range state
      setPlaceDetail((prevPlaceDetail) => ({
        ...prevPlaceDetail,
        checkindate: '',
        Checkoutdate: '',
      }));
  
      // Reset the dateRange state
      setDateRange(null);
    } else {
      // Dates are selected, format and update the state
      setPlaceDetail((prevPlaceDetail) => ({
        ...prevPlaceDetail,
        checkindate: moment(value[0]).format('YYYY-MM-DD'),
        Checkoutdate: moment(value[1]).format('YYYY-MM-DD'),
      }));
  
      // Update the dateRange state
      setDateRange(value);
    }
  };
  
  const handleAdultIncrement = (roomIndex) => {
    // Increment the number of adults for a specific room
    const updatedRooms = [...rooms];
    updatedRooms[roomIndex].adults += 1;
    setRooms(updatedRooms);
  };

  const handleAdultDecrement = (roomIndex) => {
    // Decrement the number of adults for a specific room
    const updatedRooms = [...rooms];
    if (updatedRooms[roomIndex].adults > 1) {
      updatedRooms[roomIndex].adults -= 1;
      setRooms(updatedRooms);
    }
  };

  const handleChildrenIncrement = (roomIndex) => {
    // Increment the number of children for a specific room, if less than 4
    const updatedRooms = [...rooms];
    if (updatedRooms[roomIndex].children < 4) {
      updatedRooms[roomIndex].children += 1;
      setRooms(updatedRooms);
    }
  };

  const handleChildrenDecrement = (roomIndex) => {
    // Decrement the number of children for a specific room
    const updatedRooms = [...rooms];
    if (updatedRooms[roomIndex].children > 0) {
      updatedRooms[roomIndex].children -= 1;
      setRooms(updatedRooms);
    }
  };

  const handleChildAgeChange = (roomIndex, childIndex, value) => {
    // Update the age of a specific child for a specific room
    const updatedRooms = [...rooms];
    updatedRooms[roomIndex].childrenAges[childIndex] = value;
    setRooms(updatedRooms);
  };
  const addRoom = () => {
    // Add a new room with default values
    setRooms([...rooms, { adults: 2, children: 0, childrenAges: [] }]);
  };

  const removeLastRoom = () => {
    if (rooms.length > 1) {
      // Remove the last room by slicing the array
      setRooms(rooms.slice(0, -1));
    }
  };
const handleDeleteRoomByIndex=(roomIndex)=>{
  const updatedRooms = rooms.filter((room, index) => index !== roomIndex);
  setRooms(updatedRooms)
};
  return (
    <>
    <ToastContainer/>
      <Modal isOpen={adultModal} centered={true} toggle={toggleAdultModal}>
        <ModalHeader toggle={toggleAdultModal}>Select Detail</ModalHeader>
        <ModalBody>
          <div className='p-3'>
          {rooms.map((room, roomIndex) => (
            <div key={roomIndex}>
             <div className='hotel-detail-border d-flex text-center fw-bold mb-2' style={{justifyContent:'space-between',alignItems:'center'}}>
             <p className='fw-bold'>Room {roomIndex + 1}</p>
             {roomIndex > 0 && ( // Check if roomIndex is greater than 0
        <button className='delete-room-button m-1' onClick={() => handleDeleteRoomByIndex(roomIndex)}>Delete</button>
      )}
           </div>
           <div>
           <div className='select-child'>
             <div class='f4878764f1'>
               <label class='a984a491d9 fw-bold' for='group_adults'>
                 Adults
               </label>
             </div>
             <div class='d-flex fff'>
               <button
                 className='adult-modal-btn'
                 name={`adults-${roomIndex}`}
                 onClick={() => handleAdultDecrement(roomIndex)}
                 
               >
                 <i class='fas fa-minus'>
                   <FontAwesomeIcon size='sm' icon={faMinus} />
                 </i>
               </button>
               <span className='d723d73d5f fw-bold' id='input'>
               {room.adults}
               </span>
               <button
                 className='adult-modal-btn'
                 name={`adults-${roomIndex}`}
                 onClick={() => handleAdultIncrement(roomIndex)}
                 id='increment'
               >
                 <i class='fas fa-plus'>
                   <FontAwesomeIcon size='sm' icon={faPlus} />
                 </i>
               </button>
             </div>
           </div>
           <div className='select-child'>
             <div class='f4878764f1'>
               <label class='a984a491d9 fw-bold' for='group_adults'>
                 Children
               </label>
             </div>
             <div class='d-flex fff'>
               <button
                 className='adult-modal-btn'
                 onClick={() => handleChildrenDecrement(roomIndex)}
                 id='decrement'
               >
                 <i class='fas fa-minus'>
                   <FontAwesomeIcon size='sm' icon={faMinus} />
                 </i>
               </button>
               <span className='d723d73d5f fw-bold' id='input'>
                 {room.children}
               </span>
               <button
                 className='adult-modal-btn'
                 onClick={() => handleChildrenIncrement(roomIndex)}
                 id='increment'
               >
                 <i class='fas fa-plus'>
                   <FontAwesomeIcon size='sm' icon={faPlus} />
                 </i>
               </button>
             </div>
           </div>
           <div className='select-child' style={{ justifyContent: 'left' }}>
           {room.children > 0 && (
             <div className='row w-100 '>
             {Array.from({ length: room.children }, (_, childIndex) => (
              
               <div  key={childIndex} className='m-1 ' style={{width:'45%',alignItems:'center'}}>
               <label>Child {childIndex+1} (Age)</label>
               <select
                 class='form-select child-age-select'
                 name='child1'
                 onChange={(e) =>handleChildAgeChange(roomIndex, childIndex, e.target.value)}
                 aria-label='Default select example'
               >
                 <option selected>Age needed</option>
                 {childAgearray.map((item, index) => (
                   <option key={index} value={index + 1}>
                     {item}
                   </option>
                 ))}
               </select>
             </div>
             ))}
           </div>
           )}
           </div>
           
         </div>
         </div>
          ))}
          </div>
        </ModalBody>
        <ModalFooter>
        <div className='select-child'>
                <div class='f4878764f1 pe-4'>
                  <label class='a984a491d9 fw-bold' for='group_adults'>
                    Room
                  </label>
                </div>
                <div class='d-flex fff'>
                  <button
                    className='adult-modal-btn'
                    onClick={removeLastRoom}
                    id='decrement'
                  >
                    <i class='fas fa-minus'>
                      <FontAwesomeIcon size='sm' icon={faMinus} />
                    </i>
                  </button>
                  <span className='d723d73d5f fw-bold' id='input'>
                    {rooms.length}
                  </span>
                  <button
                    className='adult-modal-btn'
                    onClick={addRoom}
                    id='increment'
                  >
                    <i class='fas fa-plus'>
                      <FontAwesomeIcon size='sm' icon={faPlus} />
                    </i>
                  </button>
                </div>
              </div>
          <Button color='secondary' onClick={toggleAdultModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      {/***********  Model Windows ****************/}
      <Modal isOpen={modalIsOpen.payment} toggle={() => toggleModal(1)}>
        <ModalHeader toggle={() => toggleModal(1)}>Make Payment</ModalHeader>
        <ModalBody>
          <div className='form-group'>
            <label htmlFor=''>Enter Invoice No.</label>
            <input
              type='text'
              className='form-control select-styling mt-2'
              id=''
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={() => toggleModal(1)}>
            Close
          </Button>
          <button
            className=' btn btn-warning modal-btn1'
            onClick={() => toggleModal(1)}
          >
            Submit
          </button>
        </ModalFooter>
      </Modal>
      {/***********  Model Windows ****************/}
      <Modal isOpen={modalIsOpen.booking} toggle={() => toggleModal(2)}>
        <ModalHeader toggle={() => toggleModal(2)}>View Booking</ModalHeader>
        <ModalBody>
          <div className='form-group'>
            <label htmlFor=''>Enter Email.</label>
            <input
              type='email'
              className='form-control mt-2'
              id='exampleInputEmail1'
              aria-describedby='emailHelp'
              placeholder=''
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={() => toggleModal(2)}>
            Close
          </Button>
          <button
            className=' btn btn-warning modal-btn1'
            onClick={() => toggleModal(2)}
          >
            Submit
          </button>
        </ModalFooter>
      </Modal>
      {/***********  Model Windows ****************/}
      <Modal isOpen={modalIsOpen.trackid} toggle={() => toggleModal(3)}>
        <ModalHeader toggle={() => toggleModal(3)}>Track ID</ModalHeader>
        <ModalBody>
          <div className='form-group'>
            <label htmlFor=''>Enter Track ID.</label>
            <input
              type='text'
              className='form-control select-styling mt-2'
              id=''
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={() => toggleModal(3)}>
            Close
          </Button>
          <button className=' btn btn-warning modal-btn1' onClick={print}>
            Submit
          </button>
        </ModalFooter>
      </Modal>
      {loading ? (
        <Loader />
      ) : (
      <>
        <div className='sc-aXZVg ldwMXy'>
          <div className='sc-aXZVg ldwMXy' style={{overflow:'hidden'}}>
            <div height="100%" data-testid="hero-outer-box" class="sc-aXZVg jaPzIU">
              <div class="sc-aXZVg sc-feUZmu eLJNWg cxiIfM">
                <div width="1" height="100%" class="sc-aXZVg sc-kOHTFB hCnDID fDkWMd">
                  <div class="sc-aXZVg sc-hmdomO ldwMXy hKHkrd">
                    <div width="1" height="100%" class="sc-aXZVg sc-gEvEer hCnDID edsbtf">
                      <div data-testid="hero-silhouette" class="sc-aXZVg sc-eqUAAy ldwMXy fvVanw">
                        <div height="100%" width="1,,,,0.5" class="sc-aXZVg sc-kOHTFB gCSJQb fDkWMd">
                          <div class="sc-aXZVg sc-kOHTFB sc-fThUAz gOjHMg fDkWMd dAZNoa">
                            <div width="340px,,458px,,576px" class="sc-aXZVg sc-gEvEer sc-eoVZPG eTNbOc fNLzQu jhsxhN">
                              <div data-testid="lighter-svg-large-circle" class="sc-aXZVg ldwMXy">
                                <div class="sc-fqkvVR sc-iapWAC czdWUD krWSDp sc-lizKOf gubUFG">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 300 300" focusable="false" aria-hidden="true" role="img">
                                    <circle cx="198" cy="425" r="150" fill="#e8f2ff" fill-rule="evenodd" transform="translate(-48 -275)"></circle>
                                    </svg>
                                </div>
                              </div>
                            </div>
                            <div width="412px,,,640px,115%" class="sc-aXZVg sc-gEvEer sc-dWZqqJ cENklz cngUVh ibItNb">
                              <div data-testid="lighter-svg-priceline-p" class="sc-aXZVg ldwMXy">
                                <div class="sc-fqkvVR sc-dNsVcS czdWUD bFaeAz sc-czkgLR ioLaXq">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 412 293" focusable="false" aria-hidden="true" role="img">
                                    <path fill="#0068ef" fill-rule="evenodd" d="M348.674 149.182c0-16.118-6.943-32.753-24.252-45.37-16.708-12.663-42.704-18.354-70.939-18.354-38.073 0-61.748 9.775-74.426 21.212-10.974 10.34-17.309 25.28-17.309 39.646 0 43.03 46.728 61.989 94.055 61.989 39.355 0 81.015-10.672 90.855-46.002l.288-1.079c1.054-3.808 1.662-7.608 1.723-11.409l.005-.633zm55.32-70.465h-28.236l1.382 1.142c2.986 2.474 5.906 4.958 8.406 7.436C399.99 102.243 412 129.234 412 162.58 412 236.104 356.16 293 255.888 293 194.263 293 98.53 265.954 98.53 163.121c0-25.633 6.49-41.752 16.059-58.58l.752-1.313.764-1.317.775-1.321.392-.664.793-1.331 1.21-2.012 1.647-2.716L123.897 89H0V0h403.994v78.717z"></path>
                                  </svg>
                                </div>
                              </div>
                            </div>
                            <a target="blank" data-testid="silhouette-img" color="primary" rel="noopener" class="sc-jlZhew jhFlZt">
                              <div class="sc-aXZVg sc-hmdomO ldwMXy rSqYC">
                                <div width="72%" height="212px,,,240px,420px" class="sc-aXZVg sc-kOHTFB tSWaN fjzJdU">
                                  <div width="100%" height="100%" class="sc-aXZVg sc-eqUAAy sc-bdOgaJ bwaFpX jAekbq cbSskZ">
                                    <div width="100%" height="100%" class="sc-imWYAI sc-empnci bSISgq jpIeBg hero-image"></div>
                                  </div>
                                </div>
                              </div>
                              <div class="sc-aXZVg sc-hmdomO ldwMXy hKHkrd">
                                <div width="97%" height="212px,,,240px,420px" class="sc-aXZVg sc-gEvEer sc-kMribo bmTETO ixHBMT kcHLYw">
                                  <div width="100%" height="100%" class="sc-aXZVg sc-eqUAAy sc-bdOgaJ bwaFpX jAekbq cbSskZ">
                                    <div width="100%" height="100%" class="sc-imWYAI sc-empnci bSISgq jpIeBg hero-image"></div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div data-testid="layout-flex" class="sc-aXZVg sc-eqUAAy hYVpLi eCdPAh">
                    <div width="1,,,,0.66" data-testid="box-0" class="sc-aXZVg sc-JrDLc dUAERr dRlhbD">
                      <div class="sc-aXZVg ldwMXy">
                        <div width="1,,,,0.6666666666666666" style={{gap:'24px'}} class="sc-aXZVg sc-eqUAAy eVekry heAqDO">
                          <div class="sc-aXZVg ldwMXy">
                            <div class="sc-aXZVg sc-hmdomO ldwMXy rSqYC"></div>
                            <div class="sc-aXZVg sc-hmdomO ldwMXy hKHkrd"></div>
                          </div>
                        </div>
                        <div class="sc-aXZVg fxJeHc"> 
                          <div class="sc-aXZVg ldwMXy">
                            <h1 font-weight="700,700,,700,,700" color="text.heading" class="sc-fqkvVR ikqVgf sc-6d72c82-0 jsuRSV" font-size="32px,26px,,32px,,40px" letter-spacing=",">
                              <div class="sc-fqkvVR czdWUD">Save big on your next hotel</div>
                            </h1>
                            <div color="background.lightest" class="sc-aXZVg sc-jEACwC jhIZHl hxzNjF">
                              <div class="sc-aXZVg sc-jEACwC sc-46714189-0 kmgYlY bXYFop kmoYRN">
                                <div class="sc-aXZVg ZLSPb">
                                  <div role="tablist" aria-label="Types of travel" class="sc-aXZVg sc-eqUAAy sc-e95ced8d-0 kjHRhB hIDAJO cNNZPq">
                                    <button width="1,,,auto" role="tab" aria-selected="false" aria-label="Search for hotels" data-testid="DASH_TAB_HOTELS" 
                                        class="sc-fqkvVR jixyDq sc-e95ced8d-2 bNDgsd"  onClick={() => toggleDiv(2)}
                                      >
                                      <div class="sc-aXZVg sc-eqUAAy ldwMXy cgaCzu">
                                        <div class="sc-gEvEer sc-e95ced8d-3"  
                                            className={`nav-item ${
                                              activeItem === 2 ? 'fUmNib' : ''
                                            }`} 
                                        style={{transform: 'none;', transformOrigin: '50% 50% 0px;', opacity: '1;'}}></div>
                                        <div style={{border:'2px solid transparent', marginLeft:'10px'}} class="sc-aXZVg sc-eqUAAy ldwMXy cgaCzu">
                                          <div display="none,,block" class="sc-aXZVg dVmLzM">
                                            <div width="40" height="40" class="sc-gEvEer sc-eqUAAy sc-e95ced8d-1 bLIJfH cgaCzu iTlgTb" >
                                              <div font-size="24px" class="sc-kAyceB sc-kOHTFB jUbjrh kDxMca">
                                                <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true" role="img">
                                                  <g fill="none" fill-rule="evenodd">
                                                    <path d="M21 1a.5.5 0 01.09.992L21 2h-1v11.39a1.91 1.91 0 013.126.205c.323.536.356 1.183.118 1.734l-.05.103.12.116c.475.498.729 1.178.68 
                                                      1.88l-.018.175a2.48 2.48 0 01-1.85 2.056l-.123.015-.028-.001V21h.525a.5.5 0 01.09.992L22.5 22h-21a.5.5 0 01-.09-.992L1.5 21h.525v-1.327H2l-.123-.015a2.483 
                                                      2.483 0 01-1.198-4.115l.112-.111-.038-.074a1.865 1.865 0 01-.164-.623l-.006-.164a1.904 1.904 0 011.4-1.828l.156-.036a1.911 1.911 0 011.863.707L4 2H3a.5.5 0 
                                                      01-.09-.992L3 1h18zm-1 18.24V21h1.025v-1.327H21l-.123-.015A2.494 2.494 0 0120 19.24zm-15.999.003a2.484 2.484 0 01-.876.416l-.122.015-.028-.001V21H4z" fill="#0068ef">
                                                    </path>
                                                    <path d="M11.5 16v5H8v-4.5a.5.5 0 01.5-.5h3zm4 0a.5.5 0 01.5.5V21h-3.5v-5h3zm6.77-1.888c.209.346.16.79-.118 1.084a.5.5 0 00-.128.249l-.009.105a.5.5 0 
                                                      00.249.433c.52.302.805.887.721 1.48l-.025.135a1.48 1.48 0 01-.947 1.051l-.08.024h-.863l-.079-.024a1.485 1.485 0 01-.26-2.68.5.5 0 00.102-.78.868.868 0 
                                                      01-.25-.614.91.91 0 011.687-.463zm-19 0c.209.346.16.79-.118 1.084a.5.5 0 00-.128.249l-.009.105a.5.5 0 00.249.433c.52.302.805.887.721 1.48l-.025.135a1.48 
                                                      1.48 0 01-.947 1.051l-.08.024H2.07l-.079-.024a1.485 1.485 0 01-.26-2.68.5.5 0 00.102-.78.868.868 0 01-.25-.614.91.91 0 011.687-.463z" fill="#e8f2ff">
                                                    </path>
                                                    <path d="M8.5 12a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1zm4 0a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 
                                                      0 01.5-.5h1zm4 0a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1zm-8-4a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 
                                                      0 01.5-.5h1zm4 0a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1zm4 0a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 
                                                      01.5-.5h1zm-8-4a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1zm4 0a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 
                                                      01.5-.5h1zm4 0a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1z" fill="#fff">
                                                    </path>
                                                  </g>
                                                </svg>
                                              </div>
                                            </div>
                                          </div>
                                          <div font-size="1" font-weight="bold" class="sc-kAyceB eUlJqB mx-2"> {t('Hotels')}</div>
                                        </div>
                                      </div>
                                    </button>
                                    <button width="1,,,auto" role="tab" aria-selected="false" aria-label="Search for flights" data-testid="DASH_TAB_FLIGHTS" class="sc-fqkvVR jixyDq sc-e95ced8d-2 bNDgsd"  
                                        onClick={() => toggleDiv(4)}>
                                      <div class="sc-aXZVg sc-eqUAAy ldwMXy cgaCzu">
                                        <div class="sc-gEvEer sc-e95ced8d-3"   className={`nav-item ${
                                              activeItem === 4 ? 'fUmNib' : ''
                                            }`} style={{transform: 'none;', transformOrigin: '50% 50% 0px;', opacity: '1;'}}></div>
                                        <div style={{border:'2px solid transparent'}} class="sc-aXZVg sc-eqUAAy ldwMXy cgaCzu">
                                          <div display="none,,block" class="sc-aXZVg dVmLzM">
                                            <div width="40" height="40" class="sc-gEvEer sc-eqUAAy sc-e95ced8d-1 bLIJfH cgaCzu iTlgTb">
                                              <div font-size="24px" class="sc-kAyceB sc-dtInlm jUbjrh kDxMca">
                                                <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true" role="img">
                                                  <g fill="none" fill-rule="evenodd">
                                                    <path d="M22.15.5c.31.004.617.07.902.197a.5.5 0 01.254.254c.126.285.193.591.198.881l-.012.418c-.077 1.384-.803 2.627-2.142 4.081l-.321.341-.68.69-2.084 
                                                      2.041.428 1.41c.45-.157.968-.103 1.412.186l.127.092 1.397 1.397a1.5 1.5 0 01.386 1.123c-.027.36-.184.696-.411.925l-.102.092-1.269 1.268 1.335 
                                                      4.404c.124.415.037.861-.23 1.2l-.096.108-.543.543a1.12 1.12 0 01-1.734-.146l-.065-.112-4.641-8.528-.625.625-1.263 1.249a20.596 20.596 0 01-2.67 
                                                      2.359l.348 4.502a.836.836 0 01-.162.517l-.075.09-.54.54a.87.87 0 01-1.327-.115l-.063-.103-1.734-3.45a1.66 1.66 0 01-1.125-.208l-.142-.095-.084-.078a1.666 
                                                      1.666 0 01-.374-1.344L.967 16.117a.87.87 0 01-.29-1.298l.078-.088.555-.554a.835.835 0 01.499-.218l.12-.003 4.478.355a20.52 20.52 0 011.94-2.245l.426-.413 
                                                      1.887-1.898L2.123 5.11a1.12 1.12 0 01-.574-.7l-.028-.133a1.12 1.12 0 01.24-.874l.091-.1.547-.546a1.32 1.32 0 011.165-.355l.14.034 4.402 1.333L9.34 2.54a1.5 1.5 0 
                                                      012.068-.251l.124.103 1.422 1.426a1.57 1.57 0 01.238 1.494l1.419.43 2.157-2.214.599-.59.45-.423C19.273 1.19 20.38.574 21.726.511L22.15.5z" fill="#0068ef">
                                                    </path>
                                                    <path d="M8.75 18.262l.293 3.8-.352.352-1.6-3.184c.096-.045.193-.092.29-.142.311-.173.618-.352.92-.54l.449-.286zm8.709-8.07L20.61 20.59l.013.067a.32.32 0 
                                                      01-.048.194l-.043.054-.547.547-.035.027-.037.014-.039.001-.036-.012-.03-.022-.013-.014-4.798-8.816 1.42-1.413 1.042-1.024zM1.941 
                                                      14.958l3.798.304c-.198.299-.388.603-.57.912l-.268.468-.13.267-3.182-1.599.352-.352zM3.347 3.38l.068.013 10.411 3.154-1.236 1.27-1.195 
                                                      1.2-8.807-4.792-.04-.027a.115.115 0 01-.038-.07.12.12 0 01.013-.073l.022-.03.557-.557.053-.041a.32.32 0 01.192-.047z" fill="#e8f2ff">
                                                    </path>
                                                    <path d="M22.141 1.501l.136.01.128.021.051.014.027.111.014.103.006.105-.01.316c-.066 1.051-.618 2.053-1.664 3.236l-.216.239-.41.433-.558.562-4.126 4.046-3.257 
                                                      3.246-.603.594-.386.394a19.638 19.638 0 01-4.366 3.275l-.225.111-.234.104-.356.14-.113.027a.67.67 0 01-.318-.02l-.096-.04-.039-.025-.028-.038a.66.66 0 
                                                      01-.04-.602c.102-.274.208-.517.327-.753a19.59 19.59 0 013.292-4.359l.395-.383 4.005-4.026.509-.518 3.371-3.471.236-.237.227-.226.436-.417.244-.225c1.203-1.09 
                                                      2.117-1.621 3.122-1.725l.202-.015.317-.007zm-2.66 10.28l.091.058 1.333 1.339.042.056a.511.511 0 01-.054.595l-.059.057-.93.926-.921-3.04a.57.57 0 01.498.01zm-8.704-8.717l.065.05 
                                                      1.363 1.364.042.07c.051.108.06.245.022.376l-.036.096-3.042-.922.891-.89.074-.078a.504.504 0 01.312-.145.5.5 0 01.31.08zm10.862-.737a1.186 1.186 0 00-1.679 0 .275.275 0 00-.001.388.27.27 
                                                      0 00.384.001.645.645 0 01.745-.12l.088.052.077.065.033.044a.644.644 0 010 .914.275.275 0 00-.002.387.27.27 0 00.384.003 1.194 1.194 0 00.168-1.484l-.078-.11-.088-.098-.031-.042z" fill="#fff">
                                                    </path>
                                                  </g>
                                                </svg>
                                              </div>
                                            </div>
                                          </div>
                                          <div font-size="1" font-weight="100" class="sc-kAyceB eUlJqB">{t('Flights')}</div>
                                        </div>
                                      </div>
                                    </button>
                                    <button width="1,,,auto" role="tab" aria-selected="false" aria-label="Search for vacations" data-testid="DASH_TAB_HOTEL + FLIGHT" class="sc-fqkvVR jixyDq sc-e95ced8d-2 bNDgsd" onClick={() => toggleDiv(1)}>
                                      <div class="sc-aXZVg sc-eqUAAy ldwMXy cgaCzu">
                                        <div class="sc-gEvEer sc-e95ced8d-3" className={`nav-item ${
                                              activeItem === 1 ? 'fUmNib' : ''
                                            }`}  style={{transform: 'none;', transformOrigin: '50% 50% 0px;', opacity: '1;'}}></div>
                                        <div style={{border:'2px solid transparent'}} class="sc-aXZVg sc-eqUAAy ldwMXy cgaCzu">
                                          <div display="none,,block" class="sc-aXZVg dVmLzM">
                                            <div width="40" height="40" class="sc-gEvEer sc-eqUAAy sc-e95ced8d-1 bLIJfH cgaCzu iTlgTb">
                                              <div font-size="24px" class="sc-kAyceB sc-cWSHoV jUbjrh gcqUcG">
                                                <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true" role="img">
                                                  <g fill="none" fill-rule="evenodd">
                                                    <path d="M1.005 19.176L1 19V9a3 3 0 012.824-2.995L4 6h1V2h-.5a.5.5 0 01-.09-.992L4.5 1h7a.5.5 0 01.09.992L11.5 2H11v4h1a3 3 0 012.995 2.824L15 9V4h-.5a.5.5 0 
                                                      01-.09-.992L14.5 3h6a.5.5 0 01.09.992L20.5 4H20v6a3 3 0 012.995 2.824L23 13v6a3 3 0 01-2.824 2.995L20 22c0 .552-.336 1-.75 1s-.75-.448-.75-1h-2c0 .552-.336 1-.75 
                                                      1s-.75-.448-.75-1c-.547 0-1.06-.146-1.5-.402-.379.22-.809.358-1.268.393L12 22c0 .566-.448 1.025-1 1.025s-1-.459-1-1.025H6a1 1 0 01-2 0 3 3 0 01-2.995-2.824zM19 10V4h-3v6h3zm-9-8H6v4h4V2z" fill="#0068ef">
                                                    </path>
                                                    <path d="M20 11a2 2 0 012 2v6l-.005.15A2 2 0 0120 21h-5l-.174-.007a1.967 1.967 0 01-.493-.107A2.987 2.987 0 0015 19v-8zm-3 1.5a.5.5 0 00-.5.5v6l.008.09A.5.5 0 0017.5 
                                                      19v-6l-.008-.09A.5.5 0 0017 12.5zm2.5 0a.5.5 0 00-.5.5v6l.008.09A.5.5 0 0020 19v-6l-.008-.09a.5.5 0 00-.492-.41z" fill-opacity="0.9" fill="#fff">
                                                    </path>
                                                    <path d="M12 7a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2V9a2 2 0 012-2h8zM4.5 9a.5.5 0 00-.492.41L4 9.5V19l.008.09a.5.5 0 00.984 0L5 19V9.5l-.008-.09A.5.5 0 004.5 
                                                      9zM8 9a.5.5 0 00-.492.41L7.5 9.5V19l.008.09a.5.5 0 00.984 0L8.5 19V9.5l-.008-.09A.5.5 0 008 9zm3.5 0a.5.5 0 00-.492.41L11 9.5V19l.008.09a.5.5 0 00.984 0L12 
                                                      19V9.5l-.008-.09A.5.5 0 0011.5 9z" fill="#fff">
                                                    </path>
                                                  </g>
                                                </svg>
                                              </div>
                                            </div>
                                          </div>
                                          <div font-size="1" font-weight="100" class="sc-kAyceB eUlJqB">{t('Packages')}
                                            {/* Bundle &amp; Save */}
                                            </div>
                                        </div>
                                      </div>
                                    </button>
                                    <button width="1,,,auto" role="tab" aria-selected="false" aria-label="Search for cars" data-testid="DASH_TAB_CARS" class="sc-fqkvVR jixyDq sc-e95ced8d-2 bNDgsd" onClick={() => toggleDiv(3)}>
                                      <div class="sc-aXZVg sc-eqUAAy ldwMXy cgaCzu">
                                        <div class="sc-gEvEer sc-e95ced8d-3"   className={`nav-item ${
                                              activeItem === 3 ? 'fUmNib' : ''
                                            }`}  style={{transform: 'none;', transformOrigin: '50% 50% 0px;', opacity: '1;'}}></div>
                                        <div  style={{border:'2px solid transparent'}} class="sc-aXZVg sc-eqUAAy ldwMXy cgaCzu">
                                          <div display="none,,block" class="sc-aXZVg dVmLzM">
                                            <div width="40" height="40" class="sc-gEvEer sc-eqUAAy sc-e95ced8d-1 bLIJfH cgaCzu iTlgTb">
                                              <div font-size="24px" class="sc-kAyceB sc-dCFHLb jUbjrh ctYaUb">
                                                <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true" role="img">
                                                <g fill="none" fill-rule="evenodd">
                                                    <path d="M8 20.064c0 1.051-.971 1.863-2.158 1.931L5.679 22H5.32c-1.189 0-2.204-.746-2.312-1.77L3 20.09V18h-.502a1.499 1.499 0 01-.978-.407l-.11-.114a1.664 1.664 0 01-.406-.979l-.003-.153v-3.938c.026-.939.4-1.792.994-2.41H1.86a1.396 1.396 0 01-.941-.344 1.23 1.23 0 01-.412-.784L.5 8.741v-.35c0-.735.601-1.319 1.35-1.385L1.992 7h1.387l.178-.595.411-1.271c.077-.309.195-.606.358-.895.252-.421.622-.76 1.085-.981a3.227 3.227 0 011.235-.259l.203.007h10.312a3.253 3.253 0 011.491.261c.442.213.812.55 1.071.984.117.208.212.427.283.645l.442 1.52.174.583L22.008 7c.765 0 1.412.54 1.485 1.255l.007.136v.35c0 .349-.153.678-.419.914a1.386 1.386 0 01-.79.339l-.145.006h-.139c.541.564.9 1.327.979 2.178l.014.234v3.922c.012.422-.134.834-.408 1.145-.245.28-.576.46-.943.508l-.16.013H21v2.064c0 1.049-.958 1.863-2.13 1.931l-.162.005h-.416c-1.19 0-2.2-.768-2.286-1.794L16 20.064V18H8v2.065z" fill="#0068ef"></path>
                                                    <path d="M19.774 10l.155.01c1.081.116 1.95 1.055 2.06 2.246l.011.172v3.919l-.004.109a.66.66 0 01-.155.364c-.08.09-.18.149-.286.17l-.08.01H2.512l-.074-.01a.529.529 0 01-.278-.17.662.662 0 01-.156-.358l-.004-.102v-3.771l.001-.174c.05-1.266.941-2.284 2.07-2.404L4.229 10h15.546zM18.5 11.5a2 2 0 100 4 2 2 0 000-4zm-13 0a2 2 0 100 4 2 2 0 000-4zM15 13H9l-.09.008a.5.5 0 000 .984L9 14h6l.09-.008a.5.5 0 000-.984L15 13zm7.008-5l.092.007c.206.03.358.163.392.317l.008.067v.35l-.01.058a.247.247 0 01-.073.108.38.38 0 01-.19.087L22.15 9h-.938l-.294-1h1.09zM3.081 8l-.293 1h-.934l-.079-.006a.379.379 0 01-.192-.087.247.247 0 01-.074-.108L1.5 8.742V8.39l.008-.067c.034-.154.186-.286.392-.317L1.992 8H3.08z" fill="#e8f2ff"></path>
                                                    <path d="M20 18v2.064l-.007.097c-.063.418-.526.783-1.14.833l-.145.006h-.416l-.144-.006-.14-.017c-.544-.09-.943-.43-1-.816L17 20.064V18h3zM7 18v2.064l-.007.097c-.065.416-.537.783-1.166.833L5.679 21H5.32l-.146-.006-.14-.016c-.553-.088-.959-.424-1.026-.804L4 20.078 4 18h3zm11.5-5.5a1 1 0 110 2 1 1 0 010-2zm-13 0a1 1 0 110 2 1 1 0 010-2zm11.916-8.498c.294.007.567.067.824.176.244.118.466.321.619.575.078.14.145.295.197.457l.047.165.386 1.324.492 1.65.202.686-.187-.023-.19-.012H4.212l-.199.013-.195.024.203-.69.49-1.646.419-1.293.052-.184c.056-.177.125-.334.208-.483.146-.242.368-.445.633-.573.24-.1.51-.16.786-.167l.208.003 10.376.002.223-.004z" fill="#fff"></path>
                                                  </g>
                                                </svg>
                                              </div>
                                            </div>
                                          </div>
                                          <div font-size="1" font-weight="100" class="sc-kAyceB eUlJqB">{t('Transfers')}</div>
                                        </div>
                                      </div>
                                    </button>
                                    <button width="1,,,auto" role="tab" aria-selected="false" aria-label="Search for cruises" data-testid="DASH_TAB_CRUISES" class="sc-dhKdcB fbmvGi sc-e95ced8d-2 bNDgsd"  onClick={() => toggleDiv(5)}>
                                      <div class="sc-aXZVg sc-eqUAAy ldwMXy cgaCzu">
                                        <div class="sc-gEvEer sc-e95ced8d-3"   className={`nav-item ${
                                              activeItem === 5 ? 'fUmNib' : ''
                                            }`} style={{transform: 'none;', transformOrigin: '50% 50% 0px;', opacity: '1;'}}></div>
                                        <div style={{border:'2px solid transparent'}} class="sc-aXZVg sc-eqUAAy ldwMXy cgaCzu">
                                          <div display="none,,block" class="sc-aXZVg dVmLzM">
                                            <div width="40" height="40" class="sc-aXZVg sc-eqUAAy sc-e95ced8d-1 eoQCaH cgaCzu iTlgTb">
                                              <div font-size="24px" class="sc-kAyceB sc-jxOSlx jUbjrh kDyrQm">
                                              <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true" role="img">
                                                <g fill="none" fill-rule="evenodd">
                                                  <path d="M12.02 1.5a.5.5 0 01.492.41l.008.09v1.44h2.25a.5.5 0 01.35.143l.062.074L16.786 6 19.21 6a.5.5 0 01.497.446l.002.084-.254 4.167 2.736.803a.5.5 0 01.35.58l-.025.082-3.437 8.809c.137.097.265.129.441.129.3 
                                                    0 .457-.092.762-.443l.11-.13c.552-.665.916-.927 1.628-.927a.5.5 0 110 1c-.294 0-.449.09-.751.439l-.11.13c-.554.667-.921.931-1.639.931-.662 0-1.026-.224-1.517-.783l-.129-.15c-.38-.454-.537-.567-.864-.567-.295 
                                                    0-.452.091-.758.443l-.108.127c-.556.668-.92.93-1.634.93-.659 0-1.02-.223-1.509-.783l-.125-.147c-.381-.457-.538-.57-.866-.57-.295 0-.452.091-.758.443l-.108.127c-.556.668-.92.93-1.634.93-.658 
                                                    0-1.021-.224-1.512-.783l-.125-.146c-.383-.458-.54-.571-.863-.571-.29 0-.444.09-.746.439l-.11.13c-.554.667-.92.931-1.634.931-.659 0-1.02-.223-1.509-.783l-.125-.147c-.381-.457-.538-.57-.866-.57a.5.5 0 110-1c.659 
                                                    0 1.02.223 1.509.783l.125.147c.381.457.538.57.866.57a.68.68 0 00.455-.143l-3.44-8.795a.5.5 0 01.244-.63l.08-.032 2.727-.8-.245-4.17a.5.5 0 01.415-.523L4.84 6l2.423-.001 1.605-2.342a.5.5 0 01.315-.207l.097-.01h2.24V2a.5.5 
                                                    0 01.5-.5z" fill="#0068ef">
                                                  </path>
                                                  <path d="M12.528 9.708l8.858 2.598-3.068 7.859c-.397-.4-.743-.565-1.308-.565-.659 0-1.02.223-1.509.783l-.233.274c-.306.352-.463.443-.758.443s-.452-.091-.758-.443l-.233-.274c-.347-.396-.629-.624-.998-.723l.007-9.952zm-.999-.003l-.01 
                                                    9.95c-.379.096-.665.324-1.018.728l-.233.274c-.306.352-.463.443-.758.443-.296 0-.455-.092-.762-.444l-.237-.277c-.447-.51-.787-.74-1.344-.774l-.115-.005-.065-.01c-.334-.034-.67.074-1.014.31l-.148.109-.111.09-3.05-7.794 
                                                    8.865-2.6zm2.977-5.265l1.068 1.559h-7.1L9.544 4.44h4.963z" fill="#e8f2ff"></path>
                                                  <path fill="#fff" d="M18.678 7l-.208 3.408-6.3-1.848-.14-.02-.15.02-6.309 1.851L5.37 7z"></path>
                                                </g>
                                                {/* <g fill="none" fill-rule="evenodd"> <FontAwesomeIcon icon={faSliders} fill="#0068ef" /> </g> */}
                                              </svg>
                                              </div>
                                            </div>
                                          </div>
                                          <div font-size="1" font-weight="100" class="sc-fqkvVR cnQMIQ">{t('Activities')}</div>
                                        </div>
                                      </div>
                                    </button>
                                  </div>
                                </div>
                              </div>
                                {showtours && (
                                <PackageSearch/>
                                )}
                                {Showflights && (
                                <FlightSearch/>
                                )}
                                {Showhotels && (
                                <div id="panel-hotels" class="sc-aXZVg dUQmGt">
                                  <form data-testid="form" aria-label="hotel-search-form">
                                    <div class="sticky-outer-wrapper sc-7e08f4d0-0 ktsBCw" >
                                      <div class="sticky-inner-wrapper" style={{position: 'relative', top:'0px', zIndex: '1'}}>
                                        <div class="sc-gEvEer PJepm">
                                          <div class="sc-gEvEer sc-iHGNWf kAxPQI gDaPee">
                                            <div class="sc-gEvEer sc-dAlyuH fgRulP bnGnEl">
                                              <div class="sc-gEvEer sc-eqUAAy dETXCd gBtaQQ">
                                                  <div width="1,,1,,1" data-testid="DASH_TAB_HOTELS_ENDLOCATION" class="sc-gEvEer dQFMGm">
                                                    <div width="1" class="sc-gEvEer dsJkWl">
                                                      <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                                                        <div class="sc-gEvEer iTwuwh">
                                                          <div width="1" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="endLocation-typeahead-downshift-container-label" aria-controls="typeahead-dropdown" class="sc-gEvEer dsJkWl" style={{position: 'relative'}}>
                                                            <div width="1" class="sc-gEvEer sc-20db91c2-0 dsJkWl gSCCYN">
                                                              <div color="background.lightest" class="sc-gEvEer hILmdS">
                                                                  <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe">
                                                                    <div width="1" class="sc-gEvEer SjeQr">
                                                                      <div class="sc-gEvEer PJepm">
                                                                        <div class="sc-gEvEer sc-uVWWZ kPRUfN jGcugE">
                                                                            <label width="auto" for="endLocation-typeahead-downshift-container-input" id="endLocation-typeahead-downshift-container-label" color="text.light" font-size="10" font-weight="700" letter-spacing="0.2px" class="sc-fXSgeo jcqFHr"
                                                                                  style={{height: '20px', transitionProperty: 'opacity', transitionDuration: '0.1s', opacity: '0', pointerEvents: 'none', position: 'relative', width: 'calc(100% - 40px)'}}>Where to?
                                                                            </label>
                                                                            <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe">
                                                                                <div data-testid="icon-field-prefix-icons" class="sc-gEvEer sc-eqUAAy fQyDwd hIDAJO">
                                                                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 aLkqs Search__SvgSearch-sc-1osw7jr-0 kHgnEk">
                                                                                    <path d="M15.5 14h-.8l-.3-.3c1-1.1 1.6-2.6 1.6-4.2C16 5.9 13.1 3 9.5 3S3 5.9 3 9.5 5.9 16 9.5 16c1.6 0 3.1-.6 4.2-1.6l.3.3v.8l5 5 1.5-1.5-5-5zm-6 0C7 14 5 12 5 9.5S7 5 9.5 5 14 7 14 9.5 12 14 9.5 14z"></path>
                                                                                  </svg>
                                                                                </div>
                                                                                <Autocomplete id="endLocation-typeahead-downshift-container-input" data-testid="endLocation-typeahead-input" aria-autocomplete="list" 
                                                                                    aria-labelledby="endLocation-typeahead-downshift-container-label" class="sc-hCPjZK hmtJkv text-start" type='text'
                                                                                    apiKey='AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY' onPlaceSelected={place => { setPlace(place);}}
                                                                                    options={{types: ['(regions)'],  componentRestrictions: null }} placeholder="Where to?" name="endLocation" font-size="2,,1" 
                                                                                    style={{height: '56px', transitionProperty: 'paddingTop, padding-bottom' , transitionDuration: '0.1s'}}>
                                                                                </Autocomplete>
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div width="1,,0.5,,0.5" data-testid="DASH_TAB_HOTELS_DATES" id="hotel-date-range-box" class="sc-gEvEer dtZXam">
                                                    <div width="1" class="sc-gEvEer dsJkWl">
                                                      <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                                                        <div width="1" class="sc-gEvEer sc-20db91c2-0 dsJkWl gSCCYN">
                                                            <div color="background.lightest" class="sc-gEvEer hILmdS">
                                                              <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe">
                                                                <div width="1" class="sc-gEvEer SjeQr">
                                                                  <div class="sc-gEvEer PJepm">
                                                                    <div class="sc-gEvEer sc-uVWWZ kPRUfN jGcugE">
                                                                      <label for="hotelDates" width="auto" color="text.light" font-size="10" font-weight="700" letter-spacing="0.2px" class="sc-fXSgeo cAIQgh" 
                                                                            style={{height: '20px', transitionProperty: 'opacity', transitionDuration: '0.1s', opacity: '1', pointerEvents: 'none', position: 'relative', width: 'calc(100% - 40px)'}}>Check-in - Check-out
                                                                      </label>
                                                                      <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe kpaNAY1">
                                                                        <div data-testid="icon-field-prefix-icons" class="sc-gEvEer sc-eqUAAy fQyDwd hIDAJO">
                                                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 aLkqs Calendar__SvgCalendar-sc-12aq2xi-0 DXxDd">
                                                                            <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"></path>
                                                                          </svg>
                                                                        </div>
                                                                        <DateRangePicker
                                                                          style={{border:'0 !important', marginLeft:'30px', height: '56px', paddingTop: '20px', paddingBottom: '8px', transitionProperty: 'paddingTop', transitionDuration: '0.1s'}} 
                                                                          role="combobox" placeholder="Check-in - Check-out" value={dateRange} showOneCalendar={isMobile}  disabledDate={beforeToday()} onChange={handleDateRangeChange}
                                                                          data-datepicker="true" id="hotelDates" data-testid="hotel-date-range" aria-controls="hotel-calander-wrapper" aria-expanded="false" 
                                                                        />
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div width="1,,0.5,,0.5" data-testid="DASH_TAB_HOTELS_NUMROOMS" class="sc-gEvEer eghPaz">
                                                    <div width="1" class="sc-gEvEer dsJkWl">
                                                      <div style={{width: '100%', position: 'relative', zIndex:'auto'}}>
                                                        <div>
                                                          <div data-testid="traveler-selection" class="sc-gEvEer sc-dAlyuH ccdnux bnGnEl">
                                                            <div color="background.lightest" data-traveler-selection="true" title="Traveler Selection" width="1" class="sc-gEvEer sc-20db91c2-0 gindoq gSCCYN sc-36fcef75-2 brdBgp">
                                                              <button type="button" color="text.lightest" width="1" aria-label="traveler-selection-control-panel" aria-expanded="false" aria-controls="traveler-selection-control-panel"
                                                                  class="sc-fqkvVR pXlTP sc-9cd2429f-0 euugUZ" onClick={toggleAdultModal}>
                                                                <div class="sc-gEvEer sc-eqUAAy PJepm cgaCzu">
                                                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 fKFmLo User__SvgUser-sc-tdftug-0 jIfocp">
                                                                    <path d="M12 12c2.2 0 4-1.8 4-4s-1.8-4-4-4-4 1.8-4 4 1.8 4 4 4zm0 2c-2.7 0-8 1.3-8 4v2h16v-2c0-2.7-5.3-4-8-4z"></path>
                                                                  </svg>
                                                                  <div font-size="1" font-weight="500" class="sc-kAyceB ctauFf"> {personData.adult} Adults . {personData.children} Children . {personData.room} Room</div>
                                                                  <input readonly="" type="text" tabindex="-1" aria-live="polite" aria-atomic="true" aria-label="traveler selection" class="sc-hCPjZK jzjDHJ sc-9cd2429f-1 ioEqsK" font-size="2,,1" />
                                                                </div>
                                                              </button>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {/* <div width="1,,0.5,,0.5" height="56px" class="sc-gEvEer jKftlo">
                                                    <div height="100%" color="highlight.light" class="sc-gEvEer sc-eqUAAy kRohZa evpJHe">
                                                      <div class="sc-gEvEer sc-eqUAAy PJepm hIDAJO">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="highlight.shade" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 bkdMKt Discount__SvgDiscount-sc-1s5abed-0 fkoOZw">
                                                          <path d="M23 12l-2.4 2.7.3 3.5-3.6.8-1.9 3-3.4-1.4L8.6 22l-1.9-3-3.6-.8.3-3.5L1 12l2.4-2.7-.3-3.5L6.7 5l1.9-3L12 3.4 15.4 2l1.9 3 3.6.8-.3 3.5L23 12zm-10.8-.6c-1.3-.3-1.8-.7-1.8-1.3 0-.6.6-1.1 1.6-1.1s1.4.5 1.5 1.2h1.3c0-1-.7-1.9-1.9-2.2V6.7h-1.8V8c-1.1.2-2 1-2 2.1 0 1.3 1.1 2 2.8 2.4 1.5.4 1.8.9 1.8 1.4 0 .4-.3 1-1.6 1-1.2 0-1.7-.5-1.8-1.2H9c.1 1.3 1 2 2.2 2.2v1.3H13V16c1.1-.2 2.1-.9 2.1-2.1-.1-1.6-1.5-2.2-2.9-2.5z"></path>
                                                        </svg>
                                                        <div color="highlight.shade" font-size="0,,,,1" class="sc-kAyceB stlTT">Bundle + Save</div>
                                                      </div>
                                                      <div class="sc-gEvEer sc-eqUAAy PJepm ctTVKt" style={{flex: '1 0 auto'}}>
                                                        <div class="sc-gEvEer jpUEwj">
                                                            <label font-size="0" for="add-a-car-checkbox" color="text" font-weight="normal" letter-spacing="0.2px" class="sc-fXSgeo jYPJRh">
                                                              <div color="primary" class="sc-cfxfcM gQifjs">
                                                                <input type="checkbox" aria-label="Add a car" id="add-a-car-checkbox" data-testid="add-a-car-checkbox" name="car" size="20" color="primary" role="checkbox" aria-checked="false" data-indeterminate="false" class="sc-gFAWRd geBRaV" value="DRIVE"></input>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" data-name="checked" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt BoxChecked__SvgBoxChecked-sc-xar6qz-0 fQHOkP">
                                                                  <path d="M6 3h12c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3H6c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3zm4 14l9-8.6L17.6 7 10 14.3l-3.6-3.5L5 12.2l5 4.8z"></path>
                                                                </svg>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" data-name="indeterminate" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt BoxMinus__SvgBoxMinus-sc-m2y8sx-0 fWKCvU">
                                                                  <path d="M6 5c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V6c0-.6-.4-1-1-1H6zm0-2h12c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3H6c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3zm1 8v2h10v-2H7z"></path>
                                                                </svg>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" data-name="empty" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt BoxEmpty__SvgBoxEmpty-sc-tc3bem-0 ehVqsH">
                                                                  <path d="M6 5c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V6c0-.6-.4-1-1-1H6zm0-2h12c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3H6c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3z"></path>
                                                                </svg>
                                                              </div> Add a car 
                                                            </label>
                                                          </div>
                                                          <div class="sc-gEvEer jpUEwj">
                                                            <label font-size="0" for="add-a-flight-checkbox" color="text" font-weight="normal" letter-spacing="0.2px" class="sc-fXSgeo jYPJRh">
                                                              <div color="primary" class="sc-cfxfcM gQifjs">
                                                                <input type="checkbox" aria-label="Add a flight" id="add-a-flight-checkbox" data-testid="add-a-flight-checkbox" name="flight" size="20" color="primary" role="checkbox" aria-checked="false" data-indeterminate="false" class="sc-gFAWRd geBRaV" value="FLY"></input>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" data-name="checked" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt BoxChecked__SvgBoxChecked-sc-xar6qz-0 fQHOkP">
                                                                  <path d="M6 3h12c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3H6c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3zm4 14l9-8.6L17.6 7 10 14.3l-3.6-3.5L5 12.2l5 4.8z"></path>
                                                                </svg>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" data-name="indeterminate" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt BoxMinus__SvgBoxMinus-sc-m2y8sx-0 fWKCvU">
                                                                  <path d="M6 5c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V6c0-.6-.4-1-1-1H6zm0-2h12c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3H6c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3zm1 8v2h10v-2H7z"></path>
                                                                </svg>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" data-name="empty" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt BoxEmpty__SvgBoxEmpty-sc-tc3bem-0 ehVqsH">
                                                                  <path d="M6 5c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V6c0-.6-.4-1-1-1H6zm0-2h12c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3H6c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3z"></path>
                                                                </svg>
                                                              </div> Add a flight 
                                                            </label>
                                                          </div>
                                                      </div>
                                                    </div>
                                                  </div> */}
                                                  <div width="1,,0.5,,0.5" height="56px" class="sc-gEvEer jKftlo">
                                                    <div width="1" class="sc-gEvEer dsJkWl">
                                                      <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                                                        <div class="sc-gEvEer iTwuwh">
                                                          <div width="1" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="endLocation-typeahead-downshift-container-label" aria-controls="typeahead-dropdown" class="sc-gEvEer dsJkWl" style={{position: 'relative'}}>
                                                            <div width="1" class="sc-gEvEer sc-20db91c2-0 dsJkWl gSCCYN">
                                                              <div color="background.lightest" class="sc-gEvEer hILmdS">
                                                                  <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe">
                                                                    <div width="1" class="sc-gEvEer SjeQr">
                                                                      <div class="sc-gEvEer PJepm">
                                                                        <div class="sc-gEvEer sc-uVWWZ kPRUfN jGcugE">
                                                                            <label width="auto" for="endSelection-typeahead-downshift-container-input" id="endSelection-typeahead-downshift-container-label" color="text.light" font-size="10" font-weight="700" letter-spacing="0.2px" class="sc-fXSgeo jcqFHr"
                                                                                  style={{height: '20px', transitionProperty: 'opacity', transitionDuration: '0.1s', opacity: '0', pointerEvents: 'none', position: 'relative', width: 'calc(100% - 40px)'}}>Nationality
                                                                            </label>
                                                                            <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe" style={{border:'1px solid rgb(192, 202, 213)', borderRadius:'12px'}}>
                                                                                <div data-testid="icon-field-prefix-icons" class="sc-gEvEer sc-eqUAAy fQyDwd hIDAJO">
                                                                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 aLkqs Search__SvgSearch-sc-1osw7jr-0 kHgnEk">
                                                                                    <path d="M15.5 14h-.8l-.3-.3c1-1.1 1.6-2.6 1.6-4.2C16 5.9 13.1 3 9.5 3S3 5.9 3 9.5 5.9 16 9.5 16c1.6 0 3.1-.6 4.2-1.6l.3.3v.8l5 5 1.5-1.5-5-5zm-6 0C7 14 5 12 5 9.5S7 5 9.5 5 14 7 14 9.5 12 14 9.5 14z"></path>
                                                                                  </svg>
                                                                                </div>
                                                                                <Select options={countryList}
                                                                                    isSearchable={true}
                                                                                    onChange={handleCountryChange} value={selectedCountry}
                                                                                      getOptionLabel={(option) => (
                                                                                      <div>
                                                                                        <img
                                                                                            src={option.flag}
                                                                                            alt={option.label}
                                                                                            style={{ width: '20px', marginRight: '8px' }}
                                                                                        />
                                                                                        {option.label}
                                                                                      </div>
                                                                                    )}
                                                                                    getOptionValue={(option) => option.value}
                                                                                    aria-labelledby="endSelection-typeahead-downshift-container-label"  type='text'
                                                                                    placeholder="Nationality" name="endSelection" font-size="2,,1" 
                                                                                    style={{height: '56px', transitionProperty: 'paddingTop, padding-bottom' , transitionDuration: '0.1s'}} 
                                                                                 />
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div width="1,,0.5,,0.5" class="sc-gEvEer ffVUga" style={{overflowY: 'visible'}}>
                                                    <button width="1" type="submit" data-testid="HOTELS_SUBMIT_BUTTON" scale="1.02" 
                                                        class="sc-fqkvVR kNhhGi sc-266892e-0 kULWIA" color="primary" onClick={Searchhotels}>
                                                      <div class="sc-gEvEer sc-eqUAAy PJepm cgaCzu">Find Your Hotel</div>
                                                    </button>
                                                    <div class="sc-gEvEer ezgmJW">
                                                      <div font-size="0" class="sc-kAyceB eUJElH">Book a hotel with free cancellation for flexibility</div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  </form>
                                </div>
                                )}
                                {Showtransfers && (
                                <TransferSearch/>
                                )}
                                {ShowActivities && (
                                  <ActivitySearch/>
                                )}
                              {/* <div class="sc-aXZVg ldwMXy">
                                <div class="sc-gEvEer sc-534a0e0e-0 gGkbt fhWJzE">
                                  <div font-size="1" class="sc-kAyceB VdGuR">
                                    <div class="sc-gEvEer sc-eqUAAy PJepm eGMgXl">
                                      <a href="/shop/search/trips/?adults=2&amp;ages=%5B%5D&amp;children=0&amp;end=20240131&amp;flight-code=RT&amp;hotel-end=20240131&amp;hotel-start=20240130&amp;openMultiHotel=true&amp;rooms=1&amp;start=20240130&amp;trip-type=STAY" target="_blank" color="primary" rel="noopener" class="sc-dcJsrY eewULi">
                                          Book all of your hotels at once and save up to $625
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div width="1,,,,0.33" data-testid="box-1" class="sc-aXZVg sc-JrDLc cZVRZb csbhqS">
                      <div class="sc-aXZVg sc-kOHTFB fnUlhM fDkWMd">
                        <div class="sc-aXZVg sc-hmdomO ldwMXy rSqYC">
                          <div width="1" height="100%" class="sc-aXZVg HalfHeroModuleWithForm__HeroContainer-sc-c380ui-0 hCnDID dbIoGf">
                            <div data-testid="hero-silhouette" class="sc-aXZVg sc-eqUAAy ldwMXy fvVanw">
                              <div height="100%" width="1,,,,0.5" class="sc-aXZVg sc-kOHTFB gCSJQb fDkWMd">
                                <div class="sc-aXZVg sc-kOHTFB sc-fThUAz gOjHMg fDkWMd dAZNoa">
                                    <div width="340px,,458px,,576px" class="sc-aXZVg sc-gEvEer sc-eoVZPG eTNbOc fNLzQu jhsxhN">
                                      <div data-testid="lighter-svg-large-circle" class="sc-aXZVg ldwMXy">
                                        <div class="sc-fqkvVR sc-iapWAC czdWUD krWSDp sc-lizKOf gubUFG">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 300 300" focusable="false" aria-hidden="true" role="img">
                                            <circle cx="198" cy="425" r="150" fill="#e8f2ff" fill-rule="evenodd" transform="translate(-48 -275)"></circle>
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                    <div width="412px,,,640px,115%" class="sc-aXZVg sc-gEvEer sc-dWZqqJ cENklz cngUVh ibItNb">
                                      <div data-testid="lighter-svg-priceline-p" class="sc-aXZVg ldwMXy">
                                        <div class="sc-fqkvVR sc-dNsVcS czdWUD bFaeAz sc-czkgLR ioLaXq">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 412 293" focusable="false" aria-hidden="true" role="img">
                                            <path fill="#0068ef" fill-rule="evenodd" d="M348.674 149.182c0-16.118-6.943-32.753-24.252-45.37-16.708-12.663-42.704-18.354-70.939-18.354-38.073 
                                              0-61.748 9.775-74.426 21.212-10.974 10.34-17.309 25.28-17.309 39.646 0 43.03 46.728 61.989 94.055 61.989 39.355 0 81.015-10.672 90.855-46.002l.288-1.079c1.054-3.808 
                                              1.662-7.608 1.723-11.409l.005-.633zm55.32-70.465h-28.236l1.382 1.142c2.986 2.474 5.906 4.958 8.406 7.436C399.99 102.243 412 129.234 412 162.58 412 236.104 356.16 293 
                                              255.888 293 194.263 293 98.53 265.954 98.53 163.121c0-25.633 6.49-41.752 16.059-58.58l.752-1.313.764-1.317.775-1.321.392-.664.793-1.331 1.21-2.012 1.647-2.716L123.897 89H0V0h403.994v78.717z">
                                            </path>
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                    <a target="_blank" data-testid="silhouette-img" aria-label="Go to your Happy Price" color="primary" rel="noopener" class="sc-jlZhew jhFlZt">
                                      <div class="sc-aXZVg sc-hmdomO ldwMXy rSqYC">
                                        <div width="72%" height="212px,,,240px,420px" class="sc-aXZVg sc-kOHTFB tSWaN fjzJdU">
                                          <div width="100%" height="100%" class="sc-aXZVg sc-eqUAAy sc-bdOgaJ bwaFpX jAekbq cbSskZ">
                                            <div width="100%" height="100%" class="sc-imWYAI sc-empnci bSISgq jpIeBg hero-image"></div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="sc-aXZVg sc-hmdomO ldwMXy hKHkrd">
                                        <div width="97%" height="212px,,,240px,420px" class="sc-aXZVg sc-gEvEer sc-kMribo bmTETO ixHBMT kcHLYw">
                                          <div width="100%" height="100%" class="sc-aXZVg sc-eqUAAy sc-bdOgaJ bwaFpX jAekbq cbSskZ">
                                            <div width="100%" height="100%" class="sc-imWYAI sc-empnci bSISgq jpIeBg hero-image"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="sc-aXZVg ldwMXy"></div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          </div>
        </div>
     </>
      )}
    </>
  )
}

export default SearchBar
