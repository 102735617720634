import React, { useState,useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CustomerDomainName } from '../GlobalData/GlobalData';
import { Carousel, Button, Modal , Container, Row, Col} from 'react-bootstrap';
import {
  faSearch,
 
} from '@fortawesome/free-solid-svg-icons'
function Images (props) {
    const [selectedImage, setSelectedImage] = useState(null);
    const [showImage, setShowImage] = useState([]);
    const [showOneimage, setShowOneimage] = useState(true);
    const [showCarousel, setShowCarousel] = useState(false);
    const imageurl=CustomerDomainName();
    var imgaray=[];
    const imageData = props.data;
    const setting = props.setting;
    useEffect(()=>{
      setimages();
    },[]);
    const setimages=()=>{
      if(setting===1){
        var length=imageData.length;
        for(var i=0;i<length;i++){
          var url=imageurl+'public/uploads/package_imgs/'+imageData[i];
          imgaray.push(url);
        }
        setShowImage(imgaray);
      }else{
        setShowImage(imageData);
      }
    };
      const closeImage = () => {
        setSelectedImage(null)
      };
      const handleShowCarousel = () => {
        setShowCarousel(true);
      };
    
      const handleCloseCarousel = () => {
        setShowCarousel(false);
      };
      useEffect(() => {
        const checkWindowSize = () => {
          if(window.innerWidth <= 500){
            setShowOneimage(!showOneimage);
          }
        };
    
        // Initial check
        checkWindowSize();
    
        // Add event listener to check window size on resize
        window.addEventListener('resize', checkWindowSize);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('resize', checkWindowSize);
        };
      }, []);
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const itemsPerPage = 3;
  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex + itemsPerPage < showImage.length ? prevIndex + 1 : 0));
  };

  const handlePrev = () => {
    setIndex((prevIndex) => (prevIndex - 1 >= 0 ? prevIndex - 1 : showImage.length - itemsPerPage));
  };
  return (
    <>
    <div className='imgpreview'>
      <Modal show={showCarousel} onHide={handleCloseCarousel} dialogClassName='modal-90w'>
        <Modal.Body>
          <Carousel>
          {showImage.map((item,index)=>(
            <Carousel.Item key={index}>
              <img className='d-block w-100 rounded-4' src={item} alt='First slide' />
            </Carousel.Item>
          ))}
          </Carousel>
        </Modal.Body>
      </Modal>
      </div>
       {selectedImage && (
          <div className='image-lightbox' onClick={closeImage}>
          <Carousel>
            {showImage.map((item,index)=>(
              <Carousel.Item key={index}>
                <img className='d-block w-100' src={item} alt='First slide' />
              </Carousel.Item>
            ))}
          </Carousel>
          </div>
        )}
        <Carousel activeIndex={index} onSelect={handleSelect} interval={null} className='indicater' style={{height:'50%'}}>
          {showImage.map((item, idx) => (
            <Carousel.Item key={idx}>
              <Container fluid style={{height:'50%'}}>
                <Row>
                  {showImage.slice(idx, idx + itemsPerPage).map((img, subIdx) => (
                    <Col key={subIdx} className='p-1'>
                      <img className='d-block w-100 rounded-4 hrTKQS' src={img} alt={`Slide ${idx + subIdx + 1}`} style={{ cursor: 'pointer', height: '100%' }} onClick={handleShowCarousel} />
                    </Col>
                  ))}
                </Row>
              </Container>
            </Carousel.Item>
          ))}
          <div class="Box-pcln-design-system__sc-n9h3nv-0 ArrowControl__ArrowWrapper-sc-hsu3od-0 djhuzv lpcNPO">
            <button  type="button" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 kmfzg ArrowControl__ArrowButton-sc-hsu3od-1 hKlqBb fade-enter-done" aria-label="Previous" direction="LEFT" width="48px" height="48px" color="primary" onClick={handlePrev} >
              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="primary" data-testid="STRIP_ARROW_LEFT" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 gHvJpT ChevronLeft__SvgChevronLeft-sc-1pih41b-0 dvOXuG">
                  <path d="M15.4 16.6L10.8 12l4.6-4.6L14 6l-6 6 6 6 1.4-1.4z"></path>
                </svg>
              </div>
            </button>
            <button  type="button" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 kmfzg ArrowControl__ArrowButton-sc-hsu3od-1 hyYIBn fade-enter-done" aria-label="Next" direction="RIGHT" width="48px" height="48px" color="primary" onClick={handleNext} >
              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="primary" data-testid="STRIP_ARROW_RIGHT" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 gHvJpT ChevronRight__SvgChevronRight-sc-ec4b4o-0 ktEMtW">
                  <path d="M8 16.6l4.6-4.6L8 7.4 9.4 6l6 6-6 6L8 16.6z"></path>
                </svg>
              </div>
            </button>
          </div>
        </Carousel>
        {/* {showOneimage ?(
      <div className='container-fluid'>
        <div class='row mt-3 mb-3'>
          <div class='col-md-4 item-from'>
            <div className='image-container hellll'>
              <img
                src={showImage[0]}
                className='inner-img view-detail-img'
                alt='Hotel Image'
              />
              <div class='middle' onClick={handleShowCarousel}>
                <div class='text'>
                  <FontAwesomeIcon
                    className='search-icon-viewdetail'
                    color='#cb9f1c'
                    icon={faSearch}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-8'>
          <div className='row'>
          <div className='column img-grid'>
            <div className='image-container set-detail-img hellll m-1'>
              <img src={showImage[0]} className='inner-img view-detail-img' alt='Hotel Image' />
              <div class='middle'  onClick={handleShowCarousel}>
                <div class='text'>
                  <FontAwesomeIcon
                    className='search-icon-viewdetail'
                    color='#cb9f1c'
                    icon={faSearch}
                  />
                </div>
              </div>
            </div> 
            <div className='image-container set-detail-img hellll m-1'>
              <img src={showImage[1]} className='inner-img view-detail-img' alt='Hotel Image' />
              <div class='middle'  onClick={handleShowCarousel}>
                <div class='text'>
                  <FontAwesomeIcon
                    className='search-icon-viewdetail'
                    color='#cb9f1c'
                    icon={faSearch}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='column img-grid'>
          <div className='image-container set-detail-img hellll m-1'>
              <img src={showImage[2]} className='inner-img view-detail-img' alt='Hotel Image' />
              <div class='middle'  onClick={handleShowCarousel}>
                <div class='text'>
                  <FontAwesomeIcon
                    className='search-icon-viewdetail'
                    color='#cb9f1c'
                    icon={faSearch}
                  />
                </div>
              </div>
            </div>
            <div className='image-container set-detail-img hellll m-1'>
              <img src={showImage[3]} className='inner-img view-detail-img' alt='Hotel Image' />
              <div class='middle'  onClick={handleShowCarousel}>
                <div class='text'>
                  <FontAwesomeIcon
                    className='search-icon-viewdetail'
                    color='#cb9f1c'
                    icon={faSearch}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='column img-grid'>
          <div className='image-container set-detail-img hellll m-1' >
              <img src={showImage[4]} className='inner-img view-detail-img' alt='Hotel Image' />
              <div class='middle'  onClick={handleShowCarousel}>
                <div class='text'>
                  <FontAwesomeIcon
                    className='search-icon-viewdetail'
                    color='#cb9f1c'
                    icon={faSearch}
                  />
                </div>
              </div>
            </div>
            <div className='image-container set-detail-img hellll m-1' >
              <img src={showImage[5]} className='inner-img view-detail-img' alt='Hotel Image' />
              <div class='middle'  onClick={handleShowCarousel}>
                <div class='text'>
                  <FontAwesomeIcon
                    className='search-icon-viewdetail'
                    color='#cb9f1c'
                    icon={faSearch}
                  />
                </div>
              </div>
            </div>
          </div>
          {showImage[6] &&( 
          <div className='column'>
            <div className='image-container set-detail-img hellll m-1' >
              <img src={showImage[6]} className='inner-img view-detail-img' alt='Hotel Image' />
              <div class='middle'  onClick={handleShowCarousel}>
                <div class='text'>
                  <FontAwesomeIcon
                    className='search-icon-viewdetail'
                    color='#cb9f1c'
                    icon={faSearch}
                  />
                </div>
              </div>
            </div>
          {showImage[7] &&(
            <div className='image-container set-detail-img hellll m-1' >
              <img src={showImage[7]} className='inner-img view-detail-img' alt='Hotel Image' />
              <div class='middle'  onClick={handleShowCarousel}>
                <div class='text'>
                  <FontAwesomeIcon
                    className='search-icon-viewdetail'
                    color='#cb9f1c'
                    icon={faSearch}
                  />
                </div>
              </div>
            </div>
          )}
          </div>
          )}
        </div>
          </div>
        </div>
      </div>
        ):(
          <div className='image-container hellll'>
              <img
                src={showImage[0]}
                className='inner-img view-detail-img'
                alt='Hotel Image'
              />
              <div class='middle' onClick={handleShowCarousel}>
                <div class='text'>
                  <FontAwesomeIcon
                    className='search-icon-viewdetail'
                    color='#cb9f1c'
                    icon={faSearch}
                  />
                </div>
              </div>
          </div>
        )} */}
      
    </>
  )
}

export default Images
