import React,{useState} from "react";
import Layout from "../../Components/Layout/Layout";
import bgimage from '../../Images/Activity/cover.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faAngleDown
} from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ActivityCard from "../../Components/Activity/ActivityCard";
import { useSelector } from "react-redux";
import ActivitySearch from "../../Components/SearchBar/ActivitySearch";
import ModifyActivitySearch from "../../Components/SearchBar/ModifyActivitySearch";
function Activities (){
  const [ShowRatingFilter, setShowRatingFilter] = useState(false);
  const [starRating, setStarRating] = useState({rating5:'0',rating4:'0',rating3:'0',rating2:'0',rating1:'0',type:'Stars'});
  const ActivityListing=useSelector((state) => state.hotels.ActivityListing);
const[newActivityListing,setNewActivityListing]=useState(ActivityListing.tours);
const[FilterActivityListing,setFilterActivityListing]=useState(ActivityListing.tours);
const [showfilter, setShowfilter] = useState({rating:true});
const handleCheckboxChange = (event) => {
  const { name, value } = event.target;

        setStarRating((prevDetails) => {
          const updatedStarRating = { ...prevDetails };
          if (updatedStarRating[name] !=="0") {
            updatedStarRating[name]="0";
          } else {
            updatedStarRating[name] =value;
          }

          return updatedStarRating;
        });
      };
      
      const filterbyStars=()=>{
        setShowRatingFilter(false);
        if(starRating.rating1==='0' && starRating.rating2==='0' && starRating.rating3==='0' && starRating.rating4==='0' && starRating.rating5==='0'){
          setFilterActivityListing(newActivityListing);
          
        }else{
          console.log(newActivityListing);
        const filteredTours = newActivityListing.filter((tour) => {
          const hotelRating = tour.starts_rating;
          if(hotelRating !=='')
          {
            return Object.keys(starRating).some((ratingKey) => Number(hotelRating) === Number(starRating[ratingKey]));

          }
        });
        setFilterActivityListing(filteredTours);
        
      }

      };

      const ToggleRatingFilter=()=>{
        setShowRatingFilter(!ShowRatingFilter);
      };
      const Showfilter=(num)=>{
        if(num===1){
          setShowfilter(prevData => ({ ...prevData, rating: !prevData.rating }));
        }
      };

    return(
        <>
         <Modal isOpen={ShowRatingFilter} toggle={ToggleRatingFilter}>
          <ModalHeader toggle={ToggleRatingFilter}>Star Rating Filter</ModalHeader>
          <ModalBody>
          <div>
                <ul>
                  <li>
                    <label>
                      <input type='checkbox' 
                      className='custom-textbox' 
                      onChange={handleCheckboxChange} name='rating5' value='5' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating4' value='4' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating3' value='3' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox'className='custom-textbox' onChange={handleCheckboxChange} name='rating2' value='2' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox'className='custom-textbox'  onChange={handleCheckboxChange} name='rating1' value='1' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                </ul>
                <button onClick={filterbyStars} className='btn btn-warning m-2'>Apply</button>
                </div>
                  
          </ModalBody>
        </Modal>
        <Layout>
      <div className='container-fluid px-0'>
        <div className="modify-flight-search" style={{marginTop:'1px'}}><ModifyActivitySearch /></div>
      </div>
      <div className='container-fluid'>
        <div className='row mt-2 pb-5'>
         <div className='col-md-3 col-md-pull-9 px-4'>
           <div class="mobile-Filter-info">
              <ul>
                  <li><p onClick={ToggleRatingFilter}><FontAwesomeIcon icon={faStar}/><span > Rating</span></p> </li>
              </ul>
            </div>
            <div className='page-sidebar hide-page_filter'>
              {/* <div
                  id='map-container-google-2'
                  className='z-depth-1-half map-container rounded-5 mt-2'
                  style={{ height: '500' }}
                >
                  <iframe
                    src='https://www.google.com/maps/embed/v1/place?key=AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY
                  &q=Mecca+Saudi+Arabia'
                    frameborder='0'
                    allowfullscreen
                    className='rounded-4'
                  ></iframe>
              </div> */}
              <div className='widget widget_has_radio_checkbox'>
                <div className='filter-show-hide bwCBvh kuQtDu mt-2' onClick={()=>Showfilter(1)}>                
                  <h3 className='fMYYEI'>Filter by Rating</h3>
                  <i className='gVYMMq kcalFF ljjAAP'><FontAwesomeIcon icon={faAngleDown}/></i>
                </div>
                {showfilter.rating && (
                <div className='edRKIj gCLia-d'>
                  <ul>
                    <li>
                      <label>
                        <input type='checkbox' 
                        className='custom-textbox' 
                        onChange={handleCheckboxChange} name='rating5' value='5' />
                        <i className='awe-icon awe-icon-check'></i>
                        <span className='rating'>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                        </span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating4' value='4' />
                        <i className='awe-icon awe-icon-check'></i>
                        <span className='rating'>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                        </span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating3' value='3' />
                        <i className='awe-icon awe-icon-check'></i>
                        <span className='rating'>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                        </span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type='checkbox'className='custom-textbox' onChange={handleCheckboxChange} name='rating2' value='2' />
                        <i className='awe-icon awe-icon-check'></i>
                        <span className='rating'>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                        </span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type='checkbox'className='custom-textbox'  onChange={handleCheckboxChange} name='rating1' value='1' />
                        <i className='awe-icon awe-icon-check'></i>
                        <span className='rating'>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                        </span>
                      </label>
                    </li>
                  </ul>
                  <button style={{width:'96%'}} onClick={filterbyStars} className='HWMyp w-100'>Apply</button>
                </div>
                )}
              </div>
            </div>
          </div>
          <div className='col-md-9 col-md-push-3'>
            <div className='my-3'>
              <h6 className='title' style={{fontSize:'18px', color:'black'}} id='tours_result'>
                {ActivityListing.tours.length} Result found
              </h6>
            </div>
            <ActivityCard newActivityListing={FilterActivityListing}/>
          </div>
        </div>
      </div>
        </Layout>
        </>
    );
}

export default Activities;