import React, { useEffect, useState } from 'react'
import { Hotelapitoken,ApiEndPoint,CustomerDomainName } from '../GlobalData/GlobalData';
import Axios from 'axios';
import Loading from '../Loading/Loader';
import { ViewTourDetail, fetchHotels,fetchHotelsSearh, OneWayFlightIndexSearchData,OneWayFlightSearchData } from '../../Redux/Actions/actions';
import { useNavigate } from 'react-router-dom';
import {Modal, ModalBody } from 'reactstrap'
import { useDispatch } from 'react-redux';
import moment from 'moment';
import bed from '../../Images/bed.jpg'
import span from '../../Images/Hotels/italy.jpeg'
import { ToastContainer,toast } from 'react-toastify';
import { airportcode } from '../Data/AirportCodes';
import parisimg from '../../Images/Hotels/paris.jpeg'
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Sofia"></link>
function OtherComponents () {

  var token = Hotelapitoken();
  var apiendpoint=ApiEndPoint();
  const url=CustomerDomainName();
  const navigation=useNavigate();
  const Dispatch=useDispatch();
  const [userLocation,setUserLocation]=useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [latestPackages,setLatestPackages]=useState([]);
  const [activityOne,setActivityOne]=useState([]);
  const [activitySecond,setActivitySecond]=useState([]);
  const [isOpen,setIsOpen]=useState(false);
  const [arivalAirpotrs,setArivalAirpotrs]=useState([]);

  const Airportcode=airportcode.items;
  useEffect(() => {
    const GetPackages = async () => {
      var data = {
        token: token
      }
      try {
        const response = await Axios.post(
          apiendpoint + '/api/latest_packages_Live',
          data,
          {
            headers: {
              'Access-Control-Allow-Origin': '*'
            }
          }
        )
       setLatestPackages(response.data.latest_packages[0]);
      } catch (error) {
        console.error('Error:', error)
      }
    };
    GetPackages();
    LastestActivities();
    GetFlighMarkup();
    getUserLocation();
  },[]);

  const OpenModal=(data)=>{
    if(data !==''){
      const UserAirport = Airportcode.filter(option =>
        option.cityName.toLowerCase().includes(data.toLowerCase())
      );
      setArivalAirpotrs(UserAirport);
    }
   
    setIsOpen(!isOpen);
  };
  // **********Get User Location*************

  function getUserLocation() {
    // Replace 'YOUR_API_KEY' with your actual API key from GeoIPfy
    const apiKey = '0708571ae58c4688824a7d01ca397041';
    const apiUrl = `https://api.geoipfy.com/v1`;
  
    // Fetch user's location data
    fetch("https://api.geoapify.com/v1/ipinfo?&apiKey=0708571ae58c4688824a7d01ca397041")
      .then(response => response.json())
      .then(data => {
        setUserLocation(data);
        // Handle location data as needed
      })
      .catch(error => {
        console.error('Error fetching user location:', error);
      });
  }


// **********Latest Umrah  Packages Section*************

  const OpenPackageDetail=async(id,name)=>{
    setIsLoading(true);
    var data = {
      token: token,
      id: id,
      type: 'tour',
      provider_token: ''
    };
    try {
      const response = await Axios.post(
        apiendpoint + '/api/get_tour_details',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      Dispatch(ViewTourDetail(response.data));
      const result = name.split(' ').join('-');
      navigation(`/umrah-package/${result}`)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error('Error:', error)
    }
  };

// **********Latest Activities Section*************

  const LastestActivities=async()=>{
    var data={
      'token':token,
      'location':'',
      'start_dates':'',
    }
    setIsLoading(true);
    try {
      
      const response = await Axios.post(apiendpoint+'/api/search_activities_react',data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        } ,
        
      });
      setIsLoading(false);
      if(response.data.tours.length ===1){
        setActivityOne(response.data.tours[0]);
      }else  if(response.data.tours.length >=2){
        setActivityOne(response.data.tours[0]);
        setActivitySecond(response.data.tours[1]);
      };
    } catch (error) {
      // Handle errors here
      setIsLoading(false);
      console.error('Error:', error);
    }
    
  };
  
  const ActivityDetail=(id)=>{
    navigation(`/activity_details/${id}`);
  };

  // **********Flight Section*************

  const GetFlighMarkup=async()=>{
      var data={
        'token':token,
      };
    try{
      const response=await Axios.post(apiendpoint+'/api/get_markup_flight_new_Live',data,{
        headers:{
          "Access-Control-Allow-Origin": "*",

        }
      });
      localStorage.setItem('FlightMarkup',JSON.stringify(response.data));
    } catch (error){
        console.error('Error',error);
    }

  };

  const OneWayapicall = async (arrival) => {
    var Location=userLocation.city.name;
    const UserAirport = Airportcode.filter(option =>
      option.cityName.toLowerCase().includes(Location.toLowerCase())
    );
    if(UserAirport.length===0){
      return;
    }
    const random15DigitNumber = generateRandomNumber();
    const currentDate = new Date();
     const twoDaysLater = new Date(currentDate);
     twoDaysLater.setDate(currentDate.getDate() + 2);
    const data = {
      token_authorization: token,
      case: 'search_flights',
      MaxStopsQuantity:'All',
      DepartureDate: moment(twoDaysLater).format('YYYY-MM-DD'),
      DepartureCode: UserAirport[0].airportCode,
      ArrivalCode: arrival,
      AirTripType:'OneWay',
      AirlinesCode: 'EK',
      adult: 1,
      child: 0,
      infant: 0,
      ConversationId: random15DigitNumber,
      CabinType: 'no'
    }
    Dispatch(OneWayFlightIndexSearchData(data))
    setIsLoading(true)
    try {
      const response = await Axios.post(
        apiendpoint + '/api/flight_search_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setIsLoading(false)
      if (response.data.Success === false) {
        toast.warning(response.data.Message, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;

      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        Dispatch(OneWayFlightSearchData(response.data.Data))
        sessionStorage.setItem('15digitnumber', random15DigitNumber.toString())
        navigation('/Flight_search')
      }
    } catch (error) {
      
      setIsLoading(false)
      console.error('Error:', error)
    }
  };

  function generateRandomNumber () {
    const min = Math.pow(10, 14) 
    const max = Math.pow(10, 15) - 1 
    return Math.floor(Math.random() * (max - min + 1) + min)
  };


 // **********Hotel Section*************


  const Searchhotels = async (name) => {
    setIsLoading(true);
    const currentDate = new Date();
    const twoDaysLater = new Date(currentDate);
    twoDaysLater.setDate(currentDate.getDate() + 2);
    const threeDaysLater = new Date(currentDate);
    threeDaysLater.setDate(currentDate.getDate() + 3);
    var destination_name='';
     var country='';
     var lat='';
     var lon='';
     var pin='';
    if(name==='New York'){
     destination_name="New York";
     country="United States";
     lat=40.7127753;
     lon=-74.0059728;
     pin="US";
    }else if(name==='Los Angeles'){
     destination_name="Los Angeles";
     country="United States";
     lat=34.0549076;
     lon=-118.242643;
     pin="US";
    }else if(name==='Orlando'){
     destination_name="Orlando";
     country="United States";
     lat=36.1489309;
     lon=-97.378098;
     pin="US";
    }else if(name==='Paris'){
      destination_name="Paris";
      country="France";
      lat=48.856614;
      lon=2.3522219;
      pin="FR";
     }else if(name==='Dallas'){
      destination_name="Dallas";
      country="United States";
      lat=32.7766642;
      lon=-96.79698789999999;
      pin="US";
     }else if(name==='San Francisco'){
      destination_name="SF";
      country="United States";
      lat=37.7749295;
      lon=-122.4194155;
      pin="US";
     }else if(name==='Miami'){
      destination_name="Miami";
      country="United States";
      lat=25.7616798;
      lon=-80.1917902;
      pin="US";
     }else if(name==='Jeddah'){
      destination_name="Jeddah";
      country="Saudi Arabia";
      lat=21.5291545;
      lon=39.1610863;
      pin="SA";
     }else if(name==='Dubai'){
      destination_name="Dubai";
      country="United Arab Emirates";
      lat=25.2048493;
      lon=55.2707828;
      pin="AE";
     }else if(name==='Riyadh'){
      destination_name="Riyadh";
      country="Saudi Arabia";
      lat=24.7135517;
      lon=46.6752957;
      pin="SA";
     }
     
    // Define your API URL, authToken, and dataToSend as shown in the previous example
    const FormData = {
       "token":token,
      "currency_slc": 'AFN',
      "currency_slc_iso": 'AF',
      "destination_name":destination_name,
      "country":country,
      "lat":  lat,
      "long": lon,
      "pin": pin,
      "cityd":destination_name,
      "country_code": pin,
      "check_in":moment(twoDaysLater).format('YYYY-MM-DD'),
      "check_out":moment(threeDaysLater).format('YYYY-MM-DD'),
      "slc_nationality": "",
      "adult": 2,
      "child": 0,
      "room": 1,
      "Adults": [2],
      "children": [0],
      "child_ages1": [],
      "rooms_counter": [1],
      "child_ages2": [],
     
    };
    try {
    
     Dispatch(fetchHotelsSearh(FormData));
      const response = await Axios.post(apiendpoint+'/api/search/hotels/new_Live',FormData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        
        } ,
        
      });
      sessionStorage.removeItem('FlightCheckOut');
      navigation('/hotels');
      Dispatch(fetchHotels(response.data));
      // Handle the API response here
      setIsLoading(false);
    } catch (error) {
      // Handle errors here
      setIsLoading(false);
      console.error('Error:', error);
    }
  };

  
  return (
    <>
    <ToastContainer/>
    {isLoading && ( <Loading/>)}
    <Modal isOpen={isOpen} className='Airport-Modal' toggle={()=>OpenModal('')}>
      <ModalBody>
        <h4 className='p-3'>Where you want to go?</h4>
          <div className='row'>
            {arivalAirpotrs.map((item,index)=>(
              <div key={index}  data-testid="box-2" class="sc-aXZVg p-2 col-md-4 col-lg-4 sc-JrDLc  csbhqS">
              <div onClick={()=>OneWayapicall(item.airportCode)} class="sc-aXZVg sc-jEACwC sc-cMa-dbN kqMasH jjRHoq Pgbli sc-c30427c5-0 gBuBCo" color="background.lightest" data-testid="action-card" role="link" tabindex="0">
                <div class="sc-aXZVg ldwMXy">
                  <div color="primary.light" height="48" width="48" class="sc-aXZVg sc-eqUAAy TZuCs cgaCzu">
                  <svg width="1.5em" height="1.5em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true" role="img">
                                                  <g fill="none" fill-rule="evenodd">
                                                    <path d="M22.15.5c.31.004.617.07.902.197a.5.5 0 01.254.254c.126.285.193.591.198.881l-.012.418c-.077 1.384-.803 2.627-2.142 4.081l-.321.341-.68.69-2.084 
                                                      2.041.428 1.41c.45-.157.968-.103 1.412.186l.127.092 1.397 1.397a1.5 1.5 0 01.386 1.123c-.027.36-.184.696-.411.925l-.102.092-1.269 1.268 1.335 
                                                      4.404c.124.415.037.861-.23 1.2l-.096.108-.543.543a1.12 1.12 0 01-1.734-.146l-.065-.112-4.641-8.528-.625.625-1.263 1.249a20.596 20.596 0 01-2.67 
                                                      2.359l.348 4.502a.836.836 0 01-.162.517l-.075.09-.54.54a.87.87 0 01-1.327-.115l-.063-.103-1.734-3.45a1.66 1.66 0 01-1.125-.208l-.142-.095-.084-.078a1.666 
                                                      1.666 0 01-.374-1.344L.967 16.117a.87.87 0 01-.29-1.298l.078-.088.555-.554a.835.835 0 01.499-.218l.12-.003 4.478.355a20.52 20.52 0 011.94-2.245l.426-.413 
                                                      1.887-1.898L2.123 5.11a1.12 1.12 0 01-.574-.7l-.028-.133a1.12 1.12 0 01.24-.874l.091-.1.547-.546a1.32 1.32 0 011.165-.355l.14.034 4.402 1.333L9.34 2.54a1.5 1.5 0 
                                                      012.068-.251l.124.103 1.422 1.426a1.57 1.57 0 01.238 1.494l1.419.43 2.157-2.214.599-.59.45-.423C19.273 1.19 20.38.574 21.726.511L22.15.5z" fill="#0068ef">
                                                    </path>
                                                    <path d="M8.75 18.262l.293 3.8-.352.352-1.6-3.184c.096-.045.193-.092.29-.142.311-.173.618-.352.92-.54l.449-.286zm8.709-8.07L20.61 20.59l.013.067a.32.32 0 
                                                      01-.048.194l-.043.054-.547.547-.035.027-.037.014-.039.001-.036-.012-.03-.022-.013-.014-4.798-8.816 1.42-1.413 1.042-1.024zM1.941 
                                                      14.958l3.798.304c-.198.299-.388.603-.57.912l-.268.468-.13.267-3.182-1.599.352-.352zM3.347 3.38l.068.013 10.411 3.154-1.236 1.27-1.195 
                                                      1.2-8.807-4.792-.04-.027a.115.115 0 01-.038-.07.12.12 0 01.013-.073l.022-.03.557-.557.053-.041a.32.32 0 01.192-.047z" fill="#e8f2ff">
                                                    </path>
                                                    <path d="M22.141 1.501l.136.01.128.021.051.014.027.111.014.103.006.105-.01.316c-.066 1.051-.618 2.053-1.664 3.236l-.216.239-.41.433-.558.562-4.126 4.046-3.257 
                                                      3.246-.603.594-.386.394a19.638 19.638 0 01-4.366 3.275l-.225.111-.234.104-.356.14-.113.027a.67.67 0 01-.318-.02l-.096-.04-.039-.025-.028-.038a.66.66 0 
                                                      01-.04-.602c.102-.274.208-.517.327-.753a19.59 19.59 0 013.292-4.359l.395-.383 4.005-4.026.509-.518 3.371-3.471.236-.237.227-.226.436-.417.244-.225c1.203-1.09 
                                                      2.117-1.621 3.122-1.725l.202-.015.317-.007zm-2.66 10.28l.091.058 1.333 1.339.042.056a.511.511 0 01-.054.595l-.059.057-.93.926-.921-3.04a.57.57 0 01.498.01zm-8.704-8.717l.065.05 
                                                      1.363 1.364.042.07c.051.108.06.245.022.376l-.036.096-3.042-.922.891-.89.074-.078a.504.504 0 01.312-.145.5.5 0 01.31.08zm10.862-.737a1.186 1.186 0 00-1.679 0 .275.275 0 00-.001.388.27.27 
                                                      0 00.384.001.645.645 0 01.745-.12l.088.052.077.065.033.044a.644.644 0 010 .914.275.275 0 00-.002.387.27.27 0 00.384.003 1.194 1.194 0 00.168-1.484l-.078-.11-.088-.098-.031-.042z" fill="#fff">
                                                    </path>
                                                  </g>
                  </svg>
                  </div>
                </div>
                <div width="100%" class="sc-aXZVg sc-eqUAAy feckNt dFCDBu">
                  <div class="sc-aXZVg sc-eqUAAy gCbQdB heAqDO">
                    <div font-weight="700" font-size="14px" class="sc-fqkvVR sc-fUBkdm dxpPZm btsJdq">{item.airportCode}-{item.airportName}, {item.cityName}</div>
                  </div>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20" title="ArrowRight" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 iJgYGn ArrowRight__SvgArrowRight-sc-i9is6p-0 sc-hBtRBD cpzuBV kOTrwD">
                  <title>ArrowRight</title>
                  <path d="M12 4l-1.4 1.4 5.6 5.6H4v2h12.2l-5.6 5.6L12 20l8-8-8-8z"></path>
                </svg>
              </div>
              </div>
            ))}
         
            <button className='btn btn-primary' onClick={()=>OpenModal('')}>Close</button>
          </div>
      </ModalBody>
    </Modal>
    <div class="sc-jIGnZt jVAcwv">
      <div class="sc-aXZVg ldwMXy">
        <div style={{overflow:'hidden'}} class="sc-aXZVg ldwMXy">
          <div class="sc-aXZVg sc-feUZmu gjRupo cxiIfM">
            <div class="sc-aXZVg sc-hmdomO ldwMXy grHEeX">
              <div data-testid="layout-flex" class="sc-aXZVg sc-eqUAAy hvmlrp eCdPAh">
                <div width="1" data-testid="box-0" class="sc-aXZVg sc-JrDLc jaGgOB csbhqS">
                  <div class="sc-aXZVg sc-eqUAAy iqMbjt jAekbq">
                    <div class="sc-aXZVg sc-eqUAAy sc-gdyeKB llivTk oYJda Ptooe sc-cf2824f7-0 bcXIyJ sc-cf2824f7-0 bcXIyJ">
                     {latestPackages.slice(0, 2).map((item,index)=>(
                      <div key={index} width="1,,0.5" class="sc-aXZVg sc-jEACwC sc-719b1c37-0 iCdfOp bXYFop dEdkZD">
                        <div height="100%" class="sc-aXZVg sc-eqUAAy MediaCard__ContainerFlex-sc-1dwmsnf-1 hwxHjM hsOoFy kdSzFt">
                          <div class="sc-aXZVg ldwMXy">
                            <div class="sc-aXZVg sc-kOHTFB ldwMXy fDkWMd">
                              <div style={{backgroundImage:`url(${url}public/uploads/package_imgs/${item.tour_banner_image})`}} class="sc-imWYAI sc-dkmUuB doJyA-D fVGdFZ MediaCard__BackgroundImage-sc-1dwmsnf-0 kLGKll" height="200px" data-testid="backgroundImage" loading="eager"></div>
                            </div>
                            <div class="sc-aXZVg kgMHOD">
                              <div color="text.dark" font-weight="700" font-size="20px" class="sc-fqkvVR elhLKq">{item.title}</div>
                              <div color="text.base" font-weight="500" font-size="14px" letter-spacing="-0.003rem" class="sc-fqkvVR eqPnni">{item.content}</div>
                            </div>
                          </div>
                          <div>
                            <ul className="list-items list-items-2">
                              <li class="fw-bold mt-2"><span>Start Date : </span>{item.start_date}</li>
                              <li class="fw-bold mt-2"><span>Price : </span>{item.currency_symbol} {item.quad_grand_total_amount}</li>
                            </ul>
                          </div>
                          <div class="sc-aXZVg daarIs">
                            <div font-weight="500" font-size="14px" class="sc-fqkvVR cnrZBo">
                              <a aria-label="Mardi Gras 2024! Save 15% or more in New Orleans " color="primary" onClick={()=>OpenPackageDetail(item.id,item.title)}  rel="noopener" class="sc-jlZhew jhFlZt">
                                <div class="sc-fqkvVR czdWUD">Explore Package </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                       ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div data-testid="layout-flex" class="sc-aXZVg sc-eqUAAy hvmlrp eCdPAh">
              <div width="1" data-testid="box-0" class="sc-aXZVg sc-JrDLc jaGgOB csbhqS">
                <div class="sc-aXZVg sc-eqUAAy ldwMXy hIDAJO">
                  <div class="sc-aXZVg cPFeQP">
                    <div class="sc-aXZVg sc-jEACwC OptInCard__OptInFormCard-sc-ji1nfw-0 fVrJIT jjRHoq dzwvKv">
                      <div class="sc-aXZVg sc-hmdomO ldwMXy lkjwMz">
                        <div class="sc-aXZVg sc-eqUAAy dUQmGt hIDAJO">
                          <div width="160px" class="sc-aXZVg dcGJoN">
                            <img alt="app screenshot" height="100%" src={bed} data-testid="desktop-image" loading="lazy" class="sc-kdBSHD OptInCard__OptInImage-sc-ji1nfw-1 jcWEqU evIdBZ" />
                          </div>
                          <div width="1" class="sc-aXZVg sc-eqUAAy feckNt fiYCwu">
                            <div class="sc-aXZVg ldwMXy">
                              <div color="text" font-weight="700" font-size="20px" class="sc-fqkvVR cSOqYT">365 Tours:  book now with the best price guarantee!</div>
                              <div color="text" font-weight="500" font-size="14px" letter-spacing="-0.003rem" class="sc-fqkvVR eqPnni">We’ve got you covered with amazing deals at thousands upon thousands of top hotels in cities & countries worldwide.</div>
                            </div>
                            {/* <div class="sc-aXZVg ldwMXy">
                              <div width="1" class="sc-aXZVg sc-eqUAAy eGwsri kmNFHk">
                                <div width="1" class="sc-aXZVg iyjFLz">
                                  <div class="sc-aXZVg sc-eqUAAy ldwMXy evpJHe">
                                    <div data-testid="icon-field-prefix-icons" class="sc-aXZVg sc-eqUAAy czUyOF hIDAJO">
                                      <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" fill="currentcolor" data-testid="phone-icon" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 aLkqs Smartphone__SvgSmartphone-sc-2bgefj-0 kdWtyj">
                                        <path d="M17 1.01L7 1c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-1.99-2-1.99zM17 19H7V5h10v14z" fill-rule="evenodd"></path>
                                      </svg>
                                    </div>
                                    <input data-testid="form-input" id="form-input" name="form-input" height="48px" placeholder="+1 (###) ###-####" font-size="2,,1" class="sc-tagGq fHqGUr" value="" />
                                  </div>
                                </div>
                                <div class="sc-aXZVg OptInForm__ButtonBox-sc-8bbmi7-0 leRkyE bKbHxa">
                                  <button data-testid="form-button" class="sc-dhKdcB lmgKyB OptInForm__SignUpButton-sc-8bbmi7-2 hkUFKU" color="primary">
                                    <div class="sc-aXZVg sc-eqUAAy ldwMXy cgaCzu">Send Link</div>
                                  </button>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div class="sc-aXZVg sc-hmdomO ldwMXy jgwuHU">
                        <div class="sc-aXZVg sc-eqUAAy dUQmGt heAqDO">
                          <div class="sc-aXZVg sc-eqUAAy ldwMXy hIDAJO">
                            <div width="104px" class="sc-aXZVg eWQxRd">
                              <img alt="app screenshot" height="100%" src="https://s1.pclncdn.com/design-assets/next-landing/assets/images/app-download/opt-in-app-download-square.png?opto&amp;auto=avif,webp" data-testid="mobile-image" width="104px" loading="lazy" class="sc-kdBSHD OptInCard__OptInImage-sc-ji1nfw-1 pSaee evIfdX" />
                            </div>
                            <div class="sc-aXZVg sc-eqUAAy ldwMXy evpJHe">
                              <div class="sc-aXZVg ldwMXy">
                              <div color="text" font-weight="700" font-size="20px" class="sc-fqkvVR cSOqYT">Our Best Deals are in the App</div>
                              <div color="text" font-weight="500" font-size="14px" letter-spacing="-0.003rem" class="sc-fqkvVR eqPnni">Discover hotel, flight and rental car deals exclusively in the app. Download today to stay connected with important trip details.</div>
                              </div>
                            </div>
                          </div>
                          <div width="1" class="sc-aXZVg feckNt">
                            <div class="sc-aXZVg ldwMXy">
                              <div width="1" class="sc-aXZVg sc-eqUAAy eGwsri kmNFHk">
                                <div width="1" class="sc-aXZVg iyjFLz">
                                  <div class="sc-aXZVg sc-eqUAAy ldwMXy evpJHe">
                                    <div data-testid="icon-field-prefix-icons" class="sc-aXZVg sc-eqUAAy czUyOF hIDAJO">
                                      <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" fill="currentcolor" data-testid="phone-icon" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 aLkqs Smartphone__SvgSmartphone-sc-2bgefj-0 kdWtyj">
                                        <path d="M17 1.01L7 1c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-1.99-2-1.99zM17 19H7V5h10v14z" fill-rule="evenodd"></path>
                                      </svg>
                                    </div>
                                    <input data-testid="form-input" id="form-input" name="form-input" height="48px" placeholder="+1 (###) ###-####" font-size="2,,1" class="sc-tagGq fHqGUr" value="" />
                                  </div>
                                </div>
                                <div class="sc-aXZVg OptInForm__ButtonBox-sc-8bbmi7-0 leRkyE bKbHxa">
                                  <button data-testid="form-button" class="sc-dhKdcB lmgKyB OptInForm__SignUpButton-sc-8bbmi7-2 hkUFKU" color="primary">
                                    <div class="sc-aXZVg sc-eqUAAy ldwMXy cgaCzu">Send Link</div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{gap:'16px'}} class="sc-aXZVg sc-eqUAAy ldwMXy heAqDO">
                    <div class="sc-aXZVg sc-jEACwC sc-cMa-dbN kqMasH jjRHoq Pgbli" color="background.lightest" data-testid="action-card" role="link" tabindex="0">
                      <div class="sc-aXZVg ldwMXy">
                        <div color="primary.light" height="72" width="72" class="sc-aXZVg sc-eqUAAy hyoFtz cgaCzu">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="32" width="32" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 jHlNZc Bag__SvgBag-sc-1hqc6cc-0 egaGcs">
                            <path d="M20 6.5h-3v-2c0-1.1-.9-2-2-2H9c-1.1 0-2 .9-2 2v2H4c-1.1 0-2 .9-2 2v11c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-11c0-1.1-.9-2-2-2zm-11-2h6v2H9v-2zm11 15H4v-2h16v2zm0-5H4v-6h3v2h2v-2h6v2h2v-2h3v6z"></path>
                          </svg>
                        </div>
                      </div>
                      <div width="100%" class="sc-aXZVg sc-eqUAAy feckNt dFCDBu">
                        <div class="sc-aXZVg sc-eqUAAy gCbQdB heAqDO">
                          <div font-weight="700" width="300px" font-size="14px" class="sc-fqkvVR sc-fUBkdm krFFQm ccmNyz">My Trips</div>
                          <div font-weight="500" font-size="12px" letter-spacing="0.006rem" class="sc-fqkvVR kLCEhS">Quickly find your booking reservations and redeem flight credits</div>
                        </div>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20" title="ArrowRight" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 iJgYGn ArrowRight__SvgArrowRight-sc-i9is6p-0 sc-hBtRBD cpzuBV kOTrwD">
                        <title>ArrowRight</title>
                        <path d="M12 4l-1.4 1.4 5.6 5.6H4v2h12.2l-5.6 5.6L12 20l8-8-8-8z"></path>
                      </svg>
                    </div>
                    <div class="sc-aXZVg sc-jEACwC sc-cMa-dbN kqMasH jjRHoq Pgbli" color="background.lightest" data-testid="action-card" role="link" tabindex="0">
                      <div class="sc-aXZVg ldwMXy">
                        <div color="primary.light" height="72" width="72" class="sc-aXZVg sc-eqUAAy hyoFtz cgaCzu">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="32" width="32" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" color="primary" class="Svg-sc-12lgb6u-0 jHlNZc Help__SvgHelp-sc-saxfvn-0 brWoWZ">
                            <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm1 17h-2v-2h2v2zm2.1-7.8l-.9.9c-.8.8-1.2 1.4-1.2 2.9h-2v-.5c0-1.1.4-2.1 1.2-2.8l1.2-1.3c.4-.3.6-.8.6-1.4 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.2 1.8-4 4-4s4 1.8 4 4c0 .9-.4 1.7-.9 2.2z"></path>
                          </svg>
                        </div>
                      </div>
                      <div width="100%" class="sc-aXZVg sc-eqUAAy feckNt dFCDBu">
                        <div class="sc-aXZVg sc-eqUAAy gCbQdB heAqDO">
                          <div font-weight="700" width="300px" font-size="14px" class="sc-fqkvVR sc-fUBkdm krFFQm ccmNyz">Help Center</div>
                          <div font-weight="500" font-size="12px" letter-spacing="0.006rem" class="sc-fqkvVR kLCEhS">We are always Here for you - reach us 24hrs a day, 7 days a week</div>
                        </div>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20" title="ArrowRight" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 iJgYGn ArrowRight__SvgArrowRight-sc-i9is6p-0 sc-hBtRBD cpzuBV kOTrwD">
                        <title>ArrowRight</title>
                        <path d="M12 4l-1.4 1.4 5.6 5.6H4v2h12.2l-5.6 5.6L12 20l8-8-8-8z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {activityOne.length !==0 && (
              <div data-testid="layout-flex" class="sc-aXZVg sc-eqUAAy hvmlrp eCdPAh">
                <div width="1" data-testid="box-0" class="sc-aXZVg sc-JrDLc jaGgOB csbhqS">
                  <div class="sc-aXZVg sc-feUZmu dVjqBv cxiIfM">
                    <div data-testid="merch-module-box" color="background.light" class="sc-aXZVg jYjIPn">
                      <div data-testid="merch-module-wrapper" width="100%" class="sc-gEvEer sc-eqUAAy MerchModule__ModuleWrapper-sc-14j1nf9-0 fLxUHz BmYQI dHoagx">
                        <div data-testid="layout-flex" width="100%" class="sc-gEvEer sc-eqUAAy cdpMj bSzpSI">
                          <div width="1,,0.5" data-testid="box-0" class="sc-gEvEer sc-jlZhew guNkHI goPNoH">
                            <div data-testid="merch-module-text-wrapper" height="100%" class="sc-gEvEer sc-eqUAAy eQsLnH fiYCwu">
                              <div color="text.base" font-weight="700" font-size="40px" class="sc-kAyceB dYbpGs">{activityOne.title}</div>
                              <div color="text.light" font-weight="700" font-size="12px" letter-spacing="0.063rem" class="sc-kAyceB isqCVK">{activityOne.location}</div>
                              <p color="text.dark" font-size="16px" letter-spacing="-0.006rem" font-weight="500" class="sc-jXbUNg hPTigh">{activityOne.activity_content}</p>
                              <div class="sc-gEvEer sc-eqUAAy kqfIgw cabrXW">
                                <a onClick={()=>ActivityDetail(activityOne.id)}  rel="noopener"  color="primary" class="sc-dcJsrY OjPuL">Read More</a>
                              </div>
                            </div>
                          </div>
                          <div width="1,,0.5" data-testid="box-1" class="sc-gEvEer sc-jlZhew guNkHI goPNoH">
                            <div data-testid="image-wrapper" height="100%" class="sc-gEvEer sc-eqUAAy MerchModule__ImageWrapper-sc-14j1nf9-2 ljuAJw cgaCzu bcqRBO">
                                <img alt="" width="100%" loading="lazy" src={url+'public/images/activites/'+activityOne.featured_image} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activitySecond.length !==0 && (
              <div data-testid="layout-flex" class="sc-aXZVg sc-eqUAAy hvmlrp eCdPAh">
                <div width="1" data-testid="box-0" class="sc-aXZVg sc-JrDLc jaGgOB csbhqS">
                  <div class="sc-aXZVg sc-feUZmu dVjqBv cxiIfM">
                    <div data-testid="merch-module-box" color="background.light" class="sc-aXZVg jYjIPn">
                      <div data-testid="merch-module-wrapper" width="100%" class="sc-gEvEer sc-eqUAAy MerchModule__ModuleWrapper-sc-14j1nf9-0 fLxUHz BmYQI dHoagx">
                        <div data-testid="layout-flex" width="100%" class="sc-gEvEer sc-eqUAAy cdpMj bSzpSI">
                        <div width="1,,0.5" data-testid="box-1" class="sc-gEvEer sc-jlZhew guNkHI goPNoH">
                            <div data-testid="image-wrapper" height="100%" class="sc-gEvEer sc-eqUAAy MerchModule__ImageWrapper-sc-14j1nf9-2 ljuAJw cgaCzu bcqRBO">
                                <img alt="" width="100%" loading="lazy" src={url+'public/images/activites/'+activitySecond.featured_image} />
                            </div>
                          </div>
                          <div width="1,,0.5" data-testid="box-0" class="sc-gEvEer sc-jlZhew guNkHI goPNoH">
                            <div data-testid="merch-module-text-wrapper" height="100%" class="sc-gEvEer sc-eqUAAy eQsLnH fiYCwu">
                              <div color="text.base" font-weight="700" font-size="40px" class="sc-kAyceB dYbpGs">{activitySecond.title}</div>
                              <div color="text.light" font-weight="700" font-size="12px" letter-spacing="0.063rem" class="sc-kAyceB isqCVK">{activitySecond.location}</div>
                              <p color="text.dark" font-size="16px" letter-spacing="-0.006rem" font-weight="500" class="sc-jXbUNg hPTigh">{activitySecond.activity_content}</p>
                              <div class="sc-gEvEer sc-eqUAAy kqfIgw cabrXW">
                                <a onClick={()=>ActivityDetail(activitySecond.id)}  color="primary" class="sc-dcJsrY OjPuL">Read More</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             )}
            <div data-testid="layout-flex" class="sc-aXZVg sc-eqUAAy hvmlrp eCdPAh">
              <div width="1" data-testid="box-0" class="sc-aXZVg sc-JrDLc jaGgOB csbhqS">
                <div class="sc-aXZVg sc-eqUAAy ldwMXy NtmuT">
                  <div width="1,,0.6" class="sc-aXZVg WYJJs">
                    <div class="sc-aXZVg sc-kOHTFB sc-eNSrOW izsYur fDkWMd bjPSPu">
                      <div class="sc-aXZVg sc-gEvEer sc-kFWlue fleGre lrsvS ioVxDK">
                        <div class="sc-fqkvVR sc-dxcDKg czdWUD bdbgij sc-jOnpCo iwztGw">
                          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 168 168" focusable="false" aria-hidden="true" role="img">
                            <circle cx="1100" cy="359" r="84" fill="#EDFF7A" fill-rule="evenodd" transform="translate(-1016 -275)"></circle>
                          </svg>
                        </div>
                      </div>
                      <div width="291.04px,,,400px,450px,551.25px" transform="scaleX(-1)" class="sc-aXZVg sc-gEvEer sc-bkEOxz ewbuYT kHlnRS ePnAMN">
                        <div class="sc-fqkvVR sc-dNsVcS czdWUD bFaeAz sc-fsvrbR jheWmd">
                          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 412 293" focusable="false" aria-hidden="true" role="img">
                            <path fill="#0068ef" fill-rule="evenodd" d="M348.674 149.182c0-16.118-6.943-32.753-24.252-45.37-16.708-12.663-42.704-18.354-70.939-18.354-38.073 0-61.748 9.775-74.426 21.212-10.974 10.34-17.309 25.28-17.309 39.646 0 43.03 46.728 61.989 94.055 61.989 39.355 0 81.015-10.672 90.855-46.002l.288-1.079c1.054-3.808 1.662-7.608 1.723-11.409l.005-.633zm55.32-70.465h-28.236l1.382 1.142c2.986 2.474 5.906 4.958 8.406 7.436C399.99 102.243 412 129.234 412 162.58 412 236.104 356.16 293 255.888 293 194.263 293 98.53 265.954 98.53 163.121c0-25.633 6.49-41.752 16.059-58.58l.752-1.313.764-1.317.775-1.321.392-.664.793-1.331 1.21-2.012 1.647-2.716L123.897 89H0V0h403.994v78.717z"></path>
                          </svg>
                        </div>
                      </div>
                      <div width="90%,,,,100%,748px" height="175px,,374px" class="sc-aXZVg sc-gEvEer sc-dhFUGM hTpUvt iYRbFM csXEeD">
                        <div width="100%" height="100%" class="sc-aXZVg sc-eqUAAy sc-isRoRg kjpQAf jAekbq isVdPO">
                          <div width="100%" height="100%" class="sc-imWYAI sc-satoz dKRUzS froqZr"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div width="1,,0.4" class="sc-aXZVg eEdKXU">
                    <div color="text.heading" font-size="26px,,,32px,40px" font-weight="700,,,700,700" letter-spacing="," class="sc-fqkvVR fwXoUo">365 Flights and Tours</div>
                    <div color="text.light" font-size="16px,,,20px" font-weight="500,,,500" letter-spacing="," class="sc-fqkvVR gjNkko">Access the most memorable experiences across the globe! Book over 80,000 travel activities in more than 100 countries worldwide!</div>
                    <a  color="primary" rel="noopener" class="sc-jlZhew fTsEh">Explore Now</a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div data-testid="layout-flex" class="sc-aXZVg sc-eqUAAy hvmlrp eCdPAh">
              <div width="1" data-testid="box-0" class="sc-aXZVg sc-JrDLc jaGgOB csbhqS">
                <div class="sc-aXZVg ldwMXy">
                  <div color="background.lightest" width="1" class="sc-aXZVg heCIPL">
                    <form aria-label="email-sign-up-form"><div width="1" class="sc-aXZVg sc-eqUAAy jaGgOB goTqVw">
                      <div class="sc-aXZVg sc-kgOKUu kYzkho fzKYiA">
                        <div color="text.base" font-weight="bold" font-size="3" class="sc-fqkvVR sc-bfhvDw eGtAPT iysLZV">
                          <p class="sc-iGgWBj ghQOGT sc-ce62b8ce-0 iMWoqA">Sign up for Exclusive Email-only Coupons</p>
                        </div>
                        <div color="text.light" font-size="2" class="sc-fqkvVR sc-bfhvDw hFMhwq iysLZV">
                          <p class="sc-iGgWBj ghQOGT sc-ce62b8ce-0 iMWoqA">Exclusive access to coupons, special offers and promotions.</p>
                        </div>
                      </div>
                      <div width="1,,,auto" class="sc-aXZVg sc-eqUAAy bOvDWU pvirm">
                        <div width="1" class="sc-aXZVg sc-eqUAAy feckNt juUPFK">
                          <div width="1" class="sc-aXZVg sc-eqUAAy feckNt laIosI">
                            <div width="296,,,200,400" class="sc-aXZVg dSdBAN">
                              <div class="sc-aXZVg sc-dtBdUo fPsAVt hHvdph">
                                <div class="sc-aXZVg sc-eqUAAy ldwMXy evpJHe">
                                  <input aria-label="Promotion email input" id="emailAddress-1706865475041" name="emailAddress" placeholder="example@example.com" 
                                    style={{transitionProperty:'padding-top, padding-bottom', transitionDuration:'1s'}} class="sc-tagGq hJSqeZ sc-iLsKjm bIiIar" font-size="2,,1" />
                                </div>
                              </div>
                            </div>
                            <div width="1,,,,148" class="sc-aXZVg kfShAY">
                              <button color="primary"  width="100%" class="sc-dhKdcB gzPrHe sc-eifrsQ hVaSbQ">
                                <div class="sc-aXZVg sc-eqUAAy ldwMXy cgaCzu">send me deals</div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            </div> */}
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    ...
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                  </div>
                </div>
              </div>
            </div>
            <div data-testid="layout-flex" class="sc-aXZVg sc-eqUAAy hvmlrp eCdPAh">
              <div width="1" data-testid="box-0" class="sc-aXZVg sc-JrDLc jaGgOB csbhqS">
                <div class="sc-aXZVg ldwMXy">
                  <h3 color="text.heading" font-weight="700" font-size="32px" class="sc-fqkvVR jUVzqw">Discover deals in every city</h3>
                  <div class="sc-aXZVg sc-eqUAAy fZIqsM eCdPAh">
                  <div width="1,1,0.5,0.5,0.3333333333333333" class="sc-aXZVg kkpsLh">
                      <div height="301px" width="1" color="background.darkest" class="sc-aXZVg sc-eqUAAy ImageTile__ImageTileFlex-sc-j5s7d5-1 esxpss jSGePS kRgeSZ">
                        <div src="https://s1.pclncdn.com/design-assets/next-landing/assets/images/destinations/popular/denver.jpg?opto&amp;auto=avif,webp" height="301px" class="sc-imWYAI gWSbkB ImageTile__ImageTileBackgroundImage-sc-j5s7d5-0 kQkKdz"></div>
                        <div color="secondary" class="sc-jXbUNg ImageTile__StyledBadge-sc-j5s7d5-2 eUlGHJ eMiQUR">MILE-HIGH MAGIC</div>
                        <div class="sc-aXZVg ImageTile__PropsChildrenBox-sc-j5s7d5-3 ldwMXy jiydOm">
                          <div width="295" height="38">
                              <div fill="#FFF" transform="translate(3 -36)" fill-rule="evenodd" >
                                 <b style={{fontFamily:'Times-new-roman', fontSize:'46px'}}>Jeddah</b>
                              </div>
                           </div>
                        </div>
                        <nav style={{zIndex:'2'}} class="sc-aXZVg sc-eqUAAy ldwMXy hIDAJO">
                          <div class="sc-aXZVg ldwMXy">
                            <a onClick={()=>Searchhotels('Jeddah')} id="nav-text-0" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Hotels</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          {/* <div class="sc-aXZVg ldwMXy">
                            <a href="#"  data-testid="imageTile-nav-link" id="nav-text-1" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Transfers</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div> */}
                          <div class="sc-aXZVg ldwMXy">
                            <a  onClick={()=>OpenModal('Jeddah')} id="nav-text-2" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Flights</a>
                          </div>
                        </nav>
                      </div>
                    </div>
                    <div width="1,1,0.5,0.5,0.3333333333333333" class="sc-aXZVg kkpsLh">
                      <div height="301px" width="1" color="background.darkest" class="sc-aXZVg sc-eqUAAy ImageTile__ImageTileFlex-sc-j5s7d5-1 esxpss jSGePS kRgeSZ">
                        <div src="https://s1.pclncdn.com/design-assets/next-landing/assets/images/destinations/popular/seattle.jpg?opto&amp;auto=avif,webp" height="301px" class="sc-imWYAI iKguPp ImageTile__ImageTileBackgroundImage-sc-j5s7d5-0 kQkKdz"></div>
                        <div color="secondary" class="sc-jXbUNg ImageTile__StyledBadge-sc-j5s7d5-2 eUlGHJ eMiQUR">PACIFIC NORTHWEST BEAUTY</div>
                        <div class="sc-aXZVg ImageTile__PropsChildrenBox-sc-j5s7d5-3 ldwMXy jiydOm">
                            <div width="295" height="38">
                              <div fill="#FFF" transform="translate(3 -36)" fill-rule="evenodd" >
                                 <b style={{fontFamily:'monospace', fontSize:'42px'}}>Dubai</b>
                              </div>
                             </div>
                        </div>
                        <nav style={{zIndex:'2'}} class="sc-aXZVg sc-eqUAAy ldwMXy hIDAJO">
                          <div class="sc-aXZVg ldwMXy">
                            <a  onClick={()=>Searchhotels('Dubai')} id="nav-text-0" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Hotels</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          {/* <div class="sc-aXZVg ldwMXy">
                            <a href="#"  data-testid="imageTile-nav-link" id="nav-text-1" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Transfers</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div> */}
                          <div class="sc-aXZVg ldwMXy">
                            <a onClick={()=>OpenModal('Dubai')} id="nav-text-2" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Flights</a>
                          </div>
                        </nav>
                      </div>
                    </div>
                    <div width="1,1,0.5,0.5,0.3333333333333333" class="sc-aXZVg kkpsLh">
                      <div height="301px" width="1" color="background.darkest" class="sc-aXZVg sc-eqUAAy ImageTile__ImageTileFlex-sc-j5s7d5-1 esxpss jSGePS kRgeSZ">
                        <div src="https://s1.pclncdn.com/design-assets/next-landing/assets/images/destinations/popular/riyadh.jpg?opto&amp;auto=avif,webp" height="301px" class="sc-imWYAI iwlNAu ImageTile__ImageTileBackgroundImage-sc-j5s7d5-0 kQkKdz"></div>
                        <div color="secondary" class="sc-jXbUNg ImageTile__StyledBadge-sc-j5s7d5-2 eUlGHJ eMiQUR">RIDE THE WAVES</div>
                        <div class="sc-aXZVg ImageTile__PropsChildrenBox-sc-j5s7d5-3 ldwMXy jiydOm">
                            <div width="295" height="38">
                              <div fill="#FFF" transform="translate(3 -36)" fill-rule="evenodd" >
                                 <b style={{fontFamily:'monospace', fontSize:'42px'}}>Riyadh</b>
                              </div>
                             </div>
                        </div>
                        <nav style={{zIndex:'2'}} class="sc-aXZVg sc-eqUAAy ldwMXy hIDAJO">
                          <div class="sc-aXZVg ldwMXy">
                            <a onClick={()=>Searchhotels('Riyadh')} id="nav-text-0" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Hotels</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          {/* <div class="sc-aXZVg ldwMXy">
                            <a href="#" data-testid="imageTile-nav-link" id="nav-text-1" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Transfers</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div> */}
                          <div class="sc-aXZVg ldwMXy">
                            <a  onClick={()=>OpenModal('Riyadh')} id="nav-text-2" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Flights</a>
                          </div>
                        </nav>
                      </div>
                    </div>
                    <div width="1,1,0.5,0.5,0.6666666666666666" class="sc-aXZVg kSKTWB">
                      <div height="301px" width="1" color="background.darkest" class="sc-aXZVg sc-eqUAAy ImageTile__ImageTileFlex-sc-j5s7d5-1 esxpss jSGePS kRgeSZ">
                        <div src="https://s1.pclncdn.com/design-assets/next-landing/assets/images/destinations/popular/new-york-city.jpg?opto&amp;auto=avif,webp" height="301px" class="sc-imWYAI bnQmMX ImageTile__ImageTileBackgroundImage-sc-j5s7d5-0 kQkKdz"></div>
                        <div color="secondary" class="sc-jXbUNg ImageTile__StyledBadge-sc-j5s7d5-2 eUlGHJ eMiQUR">24-HOUR EXCITEMENT</div>
                        <div class="sc-aXZVg ImageTile__PropsChildrenBox-sc-j5s7d5-3 ldwMXy jiydOm">
                          <div width="295" height="38">
                              <div fill="#FFF" transform="translate(3 -36)" fill-rule="evenodd" className='my-0'>
                              <p className='my-0' style={{fontFamily:'"French Script MT"', fontSize:'52px'}}>New York</p></div>
                          </div>
                        </div>
                        <nav style={{zIndex:'2'}} class="sc-aXZVg sc-eqUAAy ldwMXy hIDAJO">
                          <div class="sc-aXZVg ldwMXy">
                            <a  onClick={()=>Searchhotels('New York')} id="nav-text-0" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Hotels</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          {/* <div class="sc-aXZVg ldwMXy">
                            <a href="#"  data-testid="imageTile-nav-link" id="nav-text-1" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Transfers</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div> */}
                          <div class="sc-aXZVg ldwMXy">
                            <a onClick={()=>OpenModal('New York')} data-toggle="modal" data-target="#exampleModal"  font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Flights</a>
                          </div>
                        </nav>
                      </div>
                    </div>
                    <div width="1,1,0.5,0.5,0.3333333333333333" class="sc-aXZVg kkpsLh">
                      <div height="301px" width="1" color="background.darkest" class="sc-aXZVg sc-eqUAAy ImageTile__ImageTileFlex-sc-j5s7d5-1 esxpss jSGePS kRgeSZ">
                        <div src="https://s1.pclncdn.com/design-assets/next-landing/assets/images/destinations/popular/los-angeles.jpg?opto&amp;auto=avif,webp" height="301px" class="sc-imWYAI NHEcp ImageTile__ImageTileBackgroundImage-sc-j5s7d5-0 kQkKdz"></div>
                        <div color="secondary" class="sc-jXbUNg ImageTile__StyledBadge-sc-j5s7d5-2 eUlGHJ eMiQUR">CUTTING-EDGE COOL</div>
                        <div class="sc-aXZVg ImageTile__PropsChildrenBox-sc-j5s7d5-3 ldwMXy jiydOm">
                          <div class="la-svg-wrapper">
                            <div width="295" height="38">
                              <div fill="#FFF" transform="translate(3 -36)" fill-rule="evenodd" style={{fontFamily:'"Imprint MT Shadow"', fontSize:'36px'}}>LOS ANGELES</div>
                            </div>
                          </div>
                        </div>
                        <nav style={{zIndex:'2'}} class="sc-aXZVg sc-eqUAAy ldwMXy hIDAJO">
                          <div class="sc-aXZVg ldwMXy">
                            <a onClick={()=>Searchhotels('Los Angeles')} id="nav-text-0" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Hotels</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          {/* <div class="sc-aXZVg ldwMXy">
                            <a href="#"  data-testid="imageTile-nav-link" id="nav-text-1" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Transfers</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div> */}
                          <div class="sc-aXZVg ldwMXy">
                            <a onClick={()=>OpenModal('LOS ANGELES')}   font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Flights</a>
                          </div>
                        </nav>
                      </div>
                    </div>
                    <div width="1,1,0.5,0.5,0.3333333333333333" class="sc-aXZVg kkpsLh">
                      <div height="301px" width="1" color="background.darkest" class="sc-aXZVg sc-eqUAAy ImageTile__ImageTileFlex-sc-j5s7d5-1 esxpss jSGePS kRgeSZ">
                        <div src="https://s1.pclncdn.com/design-assets/next-landing/assets/images/destinations/popular/orlando.jpg?opto&amp;auto=avif,webp" height="301px" class="sc-imWYAI cZOIYG ImageTile__ImageTileBackgroundImage-sc-j5s7d5-0 kQkKdz"></div>
                        <div color="secondary" class="sc-jXbUNg ImageTile__StyledBadge-sc-j5s7d5-2 eUlGHJ eMiQUR">THEME PARK PARADISE</div><div class="sc-aXZVg ImageTile__PropsChildrenBox-sc-j5s7d5-3 ldwMXy jiydOm">
                          <div width="295" height="38">
                              <div fill="#FFF" transform="translate(3 -36)" fill-rule="evenodd" >
                              <b style={{fontFamily:'cursive', fontSize:'32px'}}>ORLANDO</b>
                              </div>
                          </div>
                        </div>
                        <nav style={{zIndex:'2'}} class="sc-aXZVg sc-eqUAAy ldwMXy hIDAJO">
                          <div class="sc-aXZVg ldwMXy">
                            <a onClick={()=>Searchhotels('Orlando')} id="nav-text-0" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Hotels</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          {/* <div class="sc-aXZVg ldwMXy">
                            <a href="#"  data-testid="imageTile-nav-link" id="nav-text-1" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Transfers</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div> */}
                          <div class="sc-aXZVg ldwMXy">
                            <a onClick={()=>OpenModal('ORLANDO')}   id="nav-text-2" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Flights</a>
                          </div>
                        </nav>
                      </div>
                    </div>
                    <div width="1,1,0.5,0.5,0.3333333333333333" class="sc-aXZVg kkpsLh">
                      <div height="301px" width="1" color="background.darkest" class="sc-aXZVg sc-eqUAAy ImageTile__ImageTileFlex-sc-j5s7d5-1 esxpss jSGePS kRgeSZ">
                        <div src={parisimg} height="301px" class="sc-imWYAI sykmU ImageTile__ImageTileBackgroundImage-sc-j5s7d5-0 kQkKdz"></div>
                        <div color="secondary" class="sc-jXbUNg ImageTile__StyledBadge-sc-j5s7d5-2 eUlGHJ eMiQUR">SWEET SOUTHERN CHARM</div>
                        <div class="sc-aXZVg ImageTile__PropsChildrenBox-sc-j5s7d5-3 ldwMXy jiydOm">
                          <div width="295" height="38">
                              <div fill="#FFF" transform="translate(3 -36)" fill-rule="evenodd" >
                                 <b style={{fontFamily:'fantasy', fontSize:'42px', fontWeight:'200'}}>Paris</b>
                              </div>
                          </div>
                        </div>
                        <nav style={{zIndex:'2'}} class="sc-aXZVg sc-eqUAAy ldwMXy hIDAJO">
                          <div class="sc-aXZVg ldwMXy">
                            <a onClick={()=>Searchhotels('Paris')} id="nav-text-0" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Hotels</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          {/* <div class="sc-aXZVg ldwMXy">
                            <a href="#"  data-testid="imageTile-nav-link" id="nav-text-1" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Transfers</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div> */}
                          <div class="sc-aXZVg ldwMXy">
                            <a  onClick={()=>OpenModal('Paris')}  id="nav-text-2" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Flights</a>
                          </div>
                        </nav>
                      </div>
                    </div>
                    <div width="1,1,0.5,0.5,0.3333333333333333" class="sc-aXZVg kkpsLh">
                      <div height="301px" width="1" color="background.darkest" class="sc-aXZVg sc-eqUAAy ImageTile__ImageTileFlex-sc-j5s7d5-1 esxpss jSGePS kRgeSZ">
                        <div src="https://s1.pclncdn.com/design-assets/next-landing/assets/images/destinations/popular/dallas.jpg?opto&amp;auto=avif,webp" height="301px" class="sc-imWYAI lncQFE ImageTile__ImageTileBackgroundImage-sc-j5s7d5-0 kQkKdz"></div>
                        <div color="secondary" class="sc-jXbUNg ImageTile__StyledBadge-sc-j5s7d5-2 eUlGHJ eMiQUR">FOOD CAPITAL</div>
                        <div class="sc-aXZVg ImageTile__PropsChildrenBox-sc-j5s7d5-3 ldwMXy jiydOm">
                          <div width="295" height="38">
                              <div fill="#FFF" transform="translate(3 -36)" fill-rule="evenodd" >
                                 <p className='my-0' style={{fontFamily:'"Colonna MT"', fontSize:'38px'}}>DALLAS</p>
                              </div>
                          </div>
                        </div>
                        <nav style={{zIndex:'2'}} class="sc-aXZVg sc-eqUAAy ldwMXy hIDAJO">
                          <div class="sc-aXZVg ldwMXy">
                            <a  onClick={()=>Searchhotels('Dallas')} id="nav-text-0" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Hotels</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          {/* <div class="sc-aXZVg ldwMXy">
                            <a href="#"  data-testid="imageTile-nav-link" id="nav-text-1" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Transfers</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div> */}
                          <div class="sc-aXZVg ldwMXy">
                            <a  onClick={()=>OpenModal('DALLAS')} id="nav-text-2" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Flights</a>
                          </div>
                        </nav>
                      </div>
                    </div>
                    <div width="1,1,0.5,0.5,0.3333333333333333" class="sc-aXZVg kkpsLh">
                      <div height="301px" width="1" color="background.darkest" class="sc-aXZVg sc-eqUAAy ImageTile__ImageTileFlex-sc-j5s7d5-1 esxpss jSGePS kRgeSZ">
                        <div src="https://s1.pclncdn.com/design-assets/next-landing/assets/images/destinations/popular/san-francisco.jpg?opto&amp;auto=avif,webp" height="301px" class="sc-imWYAI cXDWsA ImageTile__ImageTileBackgroundImage-sc-j5s7d5-0 kQkKdz"></div>
                        <div color="secondary" class="sc-jXbUNg ImageTile__StyledBadge-sc-j5s7d5-2 eUlGHJ eMiQUR">SOAK UP THE SCENES</div><div class="sc-aXZVg ImageTile__PropsChildrenBox-sc-j5s7d5-3 ldwMXy jiydOm">
                          <div class="sf-svg-wrapper">
                            <div width="295" height="38">
                              <div fill="#FFF" transform="translate(3 -36)" fill-rule="evenodd" >
                                 <p style={{fontFamily:'"Bodoni MT Black"', fontSize:'38px'}}>San Francisco</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <nav style={{zIndex:'2'}} class="sc-aXZVg sc-eqUAAy ldwMXy hIDAJO">
                          <div class="sc-aXZVg ldwMXy">
                            <a  onClick={()=>Searchhotels('San Francisco')} id="nav-text-0" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Hotels</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          {/* <div class="sc-aXZVg ldwMXy">
                            <a href="#"  data-testid="imageTile-nav-link" id="nav-text-1" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Transfers</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div> */}
                          <div class="sc-aXZVg ldwMXy">
                            <a  onClick={()=>OpenModal('San Francisco')} id="nav-text-2" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Flights</a>
                          </div>
                        </nav>
                      </div>
                    </div>
                    <div width="1,1,0.5,0.5,0.6666666666666666" class="sc-aXZVg kSKTWB">
                      <div height="301px" width="1" color="background.darkest" class="sc-aXZVg sc-eqUAAy ImageTile__ImageTileFlex-sc-j5s7d5-1 esxpss jSGePS kRgeSZ">
                        <div src="https://s1.pclncdn.com/design-assets/next-landing/assets/images/destinations/popular/miami.jpg?opto&amp;auto=avif,webp" height="301px" class="sc-imWYAI ldSBtq ImageTile__ImageTileBackgroundImage-sc-j5s7d5-0 kQkKdz"></div>
                        <div color="secondary" class="sc-jXbUNg ImageTile__StyledBadge-sc-j5s7d5-2 eUlGHJ eMiQUR">IT'S BEACH TIME</div>
                        <div class="sc-aXZVg ImageTile__PropsChildrenBox-sc-j5s7d5-3 ldwMXy jiydOm">
                          <div width="295" height="38">
                              <div fill="#FFF" transform="translate(3 -36)" fill-rule="evenodd" >
                                 <b style={{fontFamily:'Copperplate, Papyrus, fantasy', fontSize:'46px'}}>Miami</b>
                              </div>
                          </div>
                        </div>
                        <nav style={{zIndex:'2'}} class="sc-aXZVg sc-eqUAAy ldwMXy hIDAJO">
                          <div class="sc-aXZVg ldwMXy">
                            <a  onClick={()=>Searchhotels('Miami')} id="nav-text-0" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Hotels</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div>
                          {/* <div class="sc-aXZVg ldwMXy">
                            <a href="#"  data-testid="imageTile-nav-link" id="nav-text-1" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Transfers</a>
                            <span color="background.lightest" class="sc-dcJsrY bvFqQO">·</span>
                          </div> */}
                          <div class="sc-aXZVg ldwMXy">
                            <a  onClick={()=>OpenModal('Miami')} id="nav-text-2" font-size="3" font-weight="regular" color="background.lightest" rel="noopener" class="sc-jlZhew ImageTileNav__NavLink-sc-k4p6jl-1 eGtkfR lahrll">Flights</a>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="sc-aXZVg ldwMXy">
              <div data-testid="layout-flex" class="sc-aXZVg sc-eqUAAy hvmlrp eCdPAh">
                <div width="1" data-testid="box-0" class="sc-aXZVg sc-JrDLc jaGgOB csbhqS">
                  <div class="sc-aXZVg sc-feUZmu dVjqBv cxiIfM">
                    <div data-testid="merch-module-box" color="background.light" class="sc-aXZVg jYjIPn">
                      <div data-testid="merch-module-wrapper" width="100%" class="sc-gEvEer sc-eqUAAy MerchModule__ModuleWrapper-sc-14j1nf9-0 fLxUHz BmYQI dHoagx">
                        <div data-testid="layout-flex" width="100%" class="sc-gEvEer sc-eqUAAy cdpMj bSzpSI">
                          <div width="1,,0.5" data-testid="box-0" class="sc-gEvEer sc-jlZhew guNkHI goPNoH">
                            <div data-testid="merch-module-text-wrapper" height="100%" class="sc-gEvEer sc-eqUAAy eQsLnH fiYCwu">
                              <div color="text.light" font-weight="700" font-size="12px" letter-spacing="0.063rem" class="sc-kAyceB isqCVK">SPECIAL NEW YEAR'S OFFER</div>
                              <div color="text.base" font-weight="700" font-size="40px" class="sc-kAyceB dYbpGs">The All-In-One Travel Card</div>
                              <p color="text.dark" font-size="16px" letter-spacing="-0.006rem" font-weight="500" class="sc-jXbUNg hPTigh">
                                Earn a $100 statement credit + 5K bonus points after qualifying purchases with the 365 Flights and Tours VIP Rewards™ Visa® Card. Plus, earn 5X on 365 bookings, 2X on restaurant and gas purchases, and automatic VIP Gold Status! Terms apply.
                              </p>
                              <div class="sc-gEvEer sc-eqUAAy kqfIgw cabrXW">
                                <a  rel="noopener" aria-label="homepage credit card merch module " color="primary" class="sc-dcJsrY OjPuL">Learn More</a>
                              </div>
                            </div>
                          </div>
                          <div width="1,,0.5" data-testid="box-1" class="sc-gEvEer sc-jlZhew guNkHI goPNoH">
                            <div data-testid="image-wrapper" height="100%" class="sc-gEvEer sc-eqUAAy MerchModule__ImageWrapper-sc-14j1nf9-2 ljuAJw cgaCzu bcqRBO">
                              <a aria-label="homepage credit card merch module " data-testid="image-link-id" href="https://cards.barclaycardus.com/banking/credit-card/priceline/visa-signature/banner/36c96d65-79d0-426a-85f3-9d295d7af24f?referrerid=HomepageSOS23" rel="noopener" target="_blank" color="primary" class="sc-dcJsrY eewULi">
                                <img alt="" width="100%" loading="lazy" src={span} class="sc-iGgWBj MerchImage__ResponsiveImage-sc-16ti2t2-0 bkRuHn eimqwJ" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div data-testid="layout-flex" class="sc-aXZVg sc-eqUAAy hvmlrp eCdPAh">
              <div width="1" data-testid="box-0" class="sc-aXZVg sc-JrDLc jaGgOB csbhqS">
                <div class="sc-aXZVg sc-eqUAAy ldwMXy hIDAJO">
                  <div data-testid="layout-flex" class="sc-aXZVg sc-eqUAAy frsMYR eCdPAh">
                    <div width="0.5,,,,0.25" data-testid="box-0" class="sc-aXZVg sc-JrDLc knvObg csbhqS">
                      <div class="sc-aXZVg sc-jEACwC sc-cMa-dbN kqMasH jFwuXl cZAEdd sc-c30427c5-0 gBuBCo" color="background.lightest" data-testid="action-card" role="" tabindex="-1">
                        <div class="sc-aXZVg ldwMXy">
                          <div color="primary.light" height="48" width="48" class="sc-aXZVg sc-eqUAAy TZuCs cgaCzu">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" color="primary" class="Svg-sc-12lgb6u-0 gHvJpT Verified__SvgVerified-sc-a72ym8-0 eHnFep">
                              <path d="M12 2L4 5.6v5.5c0 5 3.4 9.8 8 10.9 4.6-1.1 8-5.9 8-10.9V5.6L12 2zm-1.8 14.5l-3.6-3.6 1.3-1.3 2.3 2.3 5.9-6 1.3 1.3-7.2 7.3z"></path>
                            </svg>
                          </div>
                        </div>
                        <div width="100%" class="sc-aXZVg sc-eqUAAy feckNt dFCDBu">
                          <div class="sc-aXZVg sc-eqUAAy gCbQdB heAqDO">
                            <div font-weight="700" font-size="14px" class="sc-fqkvVR sc-fUBkdm dxpPZm btsJdq">Flexible Bookings</div>
                            <div font-weight="500" font-size="12px" letter-spacing="0.006rem" class="sc-fqkvVR kLCEhS">Plans change. That’s why we offer free cancellation on most hotels &amp; rental cars.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div width="0.5,,,,0.25" data-testid="box-1" class="sc-aXZVg sc-JrDLc knvObg csbhqS">
                      <div class="sc-aXZVg sc-jEACwC sc-cMa-dbN kqMasH jFwuXl cZAEdd sc-c30427c5-0 gBuBCo" color="background.lightest" data-testid="action-card" role="" tabindex="-1">
                        <div class="sc-aXZVg ldwMXy">
                          <div color="primary.light" height="48" width="48" class="sc-aXZVg sc-eqUAAy TZuCs cgaCzu">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" color="primary" class="Svg-sc-12lgb6u-0 gHvJpT Cars__SvgCars-sc-1ky1id6-0 bucAKE">
                              <path d="M18.9 5c-.2-.6-.8-1-1.4-1h-11c-.7 0-1.2.4-1.4 1L3 11v8c0 .6.4 1 1 1h1c.5 0 1-.4 1-1v-1h12v1c0 .6.4 1 1 1h1c.5 0 1-.4 1-1v-8l-2.1-6zM6.5 15c-.8 0-1.5-.7-1.5-1.5S5.7 12 6.5 12s1.5.7 1.5 1.5S7.3 15 6.5 15zm11 0c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5zM5 10l1.5-4.5h11L19 10H5z"></path>
                            </svg>
                          </div>
                        </div>
                        <div width="100%" class="sc-aXZVg sc-eqUAAy feckNt dFCDBu">
                          <div class="sc-aXZVg sc-eqUAAy gCbQdB heAqDO">
                            <div font-weight="700" font-size="14px" class="sc-fqkvVR sc-fUBkdm dxpPZm btsJdq">No Flight? No Problem</div>
                            <div font-weight="500" font-size="12px" letter-spacing="0.006rem" class="sc-fqkvVR kLCEhS">Bundle hotel &amp; rental car deals to build your perfect getaway. No airtime required.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div width="0.5,,,,0.25" data-testid="box-2" class="sc-aXZVg sc-JrDLc knvObg csbhqS">
                      <div class="sc-aXZVg sc-jEACwC sc-cMa-dbN kqMasH jjRHoq Pgbli sc-c30427c5-0 gBuBCo" color="background.lightest" data-testid="action-card" role="link" tabindex="0">
                        <div class="sc-aXZVg ldwMXy">
                          <div color="primary.light" height="48" width="48" class="sc-aXZVg sc-eqUAAy TZuCs cgaCzu">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" color="primary" class="Svg-sc-12lgb6u-0 gHvJpT Discount__SvgDiscount-sc-1s5abed-0 fkoOZw">
                              <path d="M23 12l-2.4 2.7.3 3.5-3.6.8-1.9 3-3.4-1.4L8.6 22l-1.9-3-3.6-.8.3-3.5L1 12l2.4-2.7-.3-3.5L6.7 5l1.9-3L12 3.4 15.4 2l1.9 3 3.6.8-.3 3.5L23 12zm-10.8-.6c-1.3-.3-1.8-.7-1.8-1.3 0-.6.6-1.1 1.6-1.1s1.4.5 1.5 1.2h1.3c0-1-.7-1.9-1.9-2.2V6.7h-1.8V8c-1.1.2-2 1-2 2.1 0 1.3 1.1 2 2.8 2.4 1.5.4 1.8.9 1.8 1.4 0 .4-.3 1-1.6 1-1.2 0-1.7-.5-1.8-1.2H9c.1 1.3 1 2 2.2 2.2v1.3H13V16c1.1-.2 2.1-.9 2.1-2.1-.1-1.6-1.5-2.2-2.9-2.5z"></path>
                            </svg>
                          </div>
                        </div>
                        <div width="100%" class="sc-aXZVg sc-eqUAAy feckNt dFCDBu">
                          <div class="sc-aXZVg sc-eqUAAy gCbQdB heAqDO">
                            <div font-weight="700" font-size="14px" class="sc-fqkvVR sc-fUBkdm dxpPZm btsJdq">Incredible Deals</div>
                            <div font-weight="500" font-size="12px" letter-spacing="0.006rem" class="sc-fqkvVR kLCEhS">Check out with confidence. Priceline members always get our best price.</div>
                          </div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20" title="ArrowRight" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 iJgYGn ArrowRight__SvgArrowRight-sc-i9is6p-0 sc-hBtRBD cpzuBV kOTrwD">
                          <title>ArrowRight</title>
                          <path d="M12 4l-1.4 1.4 5.6 5.6H4v2h12.2l-5.6 5.6L12 20l8-8-8-8z"></path>
                        </svg>
                      </div>
                    </div>
                    <div width="0.5,,,,0.25" data-testid="box-3" class="sc-aXZVg sc-JrDLc knvObg csbhqS">
                      <div class="sc-aXZVg sc-jEACwC sc-cMa-dbN kqMasH jjRHoq Pgbli sc-c30427c5-0 gBuBCo" color="background.lightest" data-testid="action-card" role="link" tabindex="0">
                        <div class="sc-aXZVg ldwMXy">
                          <div color="primary.light" height="48" width="48" class="sc-aXZVg sc-eqUAAy TZuCs cgaCzu">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" color="primary" class="Svg-sc-12lgb6u-0 gHvJpT Help__SvgHelp-sc-saxfvn-0 brWoWZ">
                              <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm1 17h-2v-2h2v2zm2.1-7.8l-.9.9c-.8.8-1.2 1.4-1.2 2.9h-2v-.5c0-1.1.4-2.1 1.2-2.8l1.2-1.3c.4-.3.6-.8.6-1.4 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.2 1.8-4 4-4s4 1.8 4 4c0 .9-.4 1.7-.9 2.2z"></path>
                            </svg>
                          </div>
                        </div>
                        <div width="100%" class="sc-aXZVg sc-eqUAAy feckNt dFCDBu">
                          <div class="sc-aXZVg sc-eqUAAy gCbQdB heAqDO">
                            <div font-weight="700" font-size="14px" class="sc-fqkvVR sc-fUBkdm dxpPZm btsJdq">Help 24/7</div>
                            <div font-weight="500" font-size="12px" letter-spacing="0.006rem" class="sc-fqkvVR kLCEhS">We’re always here for you – reach us 24 hours a day, 7 days a week.</div>
                          </div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20" title="ArrowRight" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 iJgYGn ArrowRight__SvgArrowRight-sc-i9is6p-0 sc-hBtRBD cpzuBV kOTrwD">
                          <title>ArrowRight</title>
                          <path d="M12 4l-1.4 1.4 5.6 5.6H4v2h12.2l-5.6 5.6L12 20l8-8-8-8z"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    {/* <div data-testid="layout-flex" class="sc-aXZVg sc-eqUAAy hvmlrp eCdPAh">
      <div width="1" data-testid="box-0" class="sc-aXZVg sc-JrDLc jaGgOB csbhqS">
        <div class="sc-aXZVg sc-eqUAAy ldwMXy cgaCzu">
          <div class="sc-aXZVg sc-feUZmu iHVsUp cxiIfM">
            <div id="div-gpt-ad-1614716188275-0" data-google-query-id="CJWP2PanjIQDFUNBHQkdIdAOtg">
              <div id="google_ads_iframe_/3102/priceline.dart/main_homepage_728x90_0__container__" style={{border: '0pt none'}}>
                <iframe id="google_ads_iframe_/3102/priceline.dart/main_homepage_728x90_0" name="google_ads_iframe_/3102/priceline.dart/main_homepage_728x90_0" title="3rd party ad content" width="728" height="90" scrolling="no" marginwidth="0" marginheight="0" frameborder="0" aria-label="Advertisement" tabindex="0" allow="attribution-reporting" style={{border: '0px', verticalAlign: 'bottom'}} data-load-complete="true" data-google-container-id="1" data-integralas-id-6ec9b453-23c0-102d-fe20-765938faf54d="">www.priceline.com</iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
    </div>
    </>
  )
}

export default OtherComponents
