import React from "react";
import { NavLink } from 'react-router-dom'
import logo from '../../Images/Logo/footer1.png';
import footer2 from '../../Images/Logo/footer2.png';
import payment from '../../Images/Logo/payment-icon2.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay, faDove, faF, faPlay } from "@fortawesome/free-solid-svg-icons";

function Footer(){
    return(
    <>
        <div id="expr-ftr" class="sc-83b3ffc4-0 hqGofM experimental-footer jVAcwv">
          <div id="pcln-global-footer" style={{visibility:'hidden'}}>
            <div>
              <div class="page--responsive light-footer">  
                <footer id="footer-root" class="global-footer global-header-footer global-footer--home global-footer--customized ">
                  <div class="global-footer__outer">
                    <div class="global-footer__inner">  
                      <nav class="global-footer__top">
                      <div className="row">
                        <div className="col-md-4 card-price-section" >
                           <strong class="">365 Flights and Tours </strong>
                           <p>Your one-stop travel agency for all your travel needs. We offer a wide range of travel services, including tours to Islamic lands and other world-renowned destinations, flights, hotels, and other excursions.</p>
                        </div>
                        <div className="col-md-8">
                          <div className="row">
                          <div class="col-md-6">
                           <strong class="global-footer__top-column__headline">About Priceline</strong>
                           <ul class="global-footer__top-column__list"> 
                              <li class="global-footer__top-column__item">
                                <p class="global-footer__top-column__item--anchor"  data-ga-category="footer" data-ga-action="about_priceline" data-ga-label="our_story">Our Story</p>
                              </li>
                              <li class="global-footer__top-column__item">
                                <p class="global-footer__top-column__item--anchor"  data-ga-category="footer" data-ga-action="about_priceline" data-ga-label="careers"> Careers </p>
                              </li>
                              <li class="global-footer__top-column__item">
                                <p class="global-footer__top-column__item--anchor"  data-ga-category="footer" data-ga-action="about_priceline" data-ga-label="press_center">Press Center</p>
                              </li>
                              <li class="global-footer__top-column__item">
                                <p class="global-footer__top-column__item--anchor"  data-ga-category="footer" data-ga-action="about_priceline" data-ga-label="new_features">Learn About New Features</p>
                              </li>
                           </ul>
                        </div>
                       
                        <div class="col-md-6">
                           <strong class="global-footer__top-column__headline">Helpful Links</strong>
                           <ul class="global-footer__top-column__list">
                              <li class="global-footer__top-column__item">
                                <NavLink class="global-footer__top-column__item--anchor" to="/terms_and_conditions" data-ga-category="footer" data-ga-action="partner links" data-ga-label="add_your_hotel">Terms & Conditions </NavLink>
                              </li>
                              <li class="global-footer__top-column__item">
                                <NavLink class="global-footer__top-column__item--anchor" to="/complaint_policy" data-ga-category="footer" data-ga-action="partner links" data-ga-label="priceline_partner_network">Complaint policy</NavLink>
                              </li>
                              <li class="global-footer__top-column__item">
                                 <NavLink class="global-footer__top-column__item--anchor" to="/privacy_policy" data-ga-category="footer" data-ga-action="partner links" data-ga-label="advertise">Privacy policy </NavLink>
                              </li>
                              <li class="global-footer__top-column__item">
                                 <NavLink class="global-footer__top-column__item--anchor" to="/faqs" data-ga-category="footer" data-ga-action="partner links" data-ga-label="advertise">
                                  Faqs </NavLink>
                              </li>
                           </ul>
                        </div>
                          </div>
                        </div>
                      </div>
                        
                        
                      </nav>  
                      <p class="global-footer__footnote">
                        <span class="global-footer__footnote__line global-footer__footnote--marks">365 Flights LTD is acting agent on behalf of Flight 380 limited: ATOL 11870.</span>
                      </p>
                      <br />
                      <p class="global-footer__footnote global-footer__footnote--disclaimer">
                        <span>Your one-stop travel agency for all your travel needs. We offer a wide range of travel services, including tours to Islamic lands and other world-renowned destinations, flights, hotels, and other excursions.</span>
                      </p> 
                    </div>
                  </div>  
                  <aside class="global-footer__pcln-group-logos">
                    <div class="global-footer__pcln-group-logos__inner">
                        <div className="d-flex">
                          <div className="col-md-3">
                            <div class="mt-4">
                                <i class="fab fa-facebook-f" style={{backgroundColor:'white', borderRadius:'20px', padding:'10px 13px', color:'#001832'}}><FontAwesomeIcon icon={faF} /></i>
                                <i class="fab fa-twitter mx-2"  style={{backgroundColor:'white', borderRadius:'20px', padding:'10px 10px', color:'#001832'}}><FontAwesomeIcon icon={faDove} /></i>				
                                <i class="fab fa-youtube" style={{backgroundColor:'white', borderRadius:'20px', padding:'8px 8px', color:'#001832', fontSize:'16px'}}><FontAwesomeIcon icon={faCirclePlay} /></i>					
                            </div>
                          </div>
                          <div className="col-md-6 d-flex justify-content-center align-items-center">
                          <p class="global-footer__pcln-group-logos__headline"> © 2024 365 Flights All Rights Reserved.</p>

                          </div>
                          <div className="col-md-3">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-widget-container">
                                    <h6 class=" mb-3" style={{color:'white', textAlign:'left'}}>PAY SECURELY WITH</h6>		
                                </div>
                                <div style={{textAlign:'left'}}>
                                    <img src={payment} width={213} height={26} alt="Priceline" loading="lazy" />
                                </div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </aside> 
                </footer>  
              </div>
            </div>
          </div>
        </div>
    </>        
    );
}

export default Footer;