import React, { useState, useEffect, useRef } from 'react'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays, faCheck, faLocation, faLocationDot, faRotate } from '@fortawesome/free-solid-svg-icons'
import noImage from '../../Images/Hotels/no-img.jpg'
import moment from 'moment'
import Loader from '../../Components/Loading/Loader'
import bgimage from '../../Images/Hotels/bg.jpg'
import image from '../../Images/View Detail/1.jpeg'
import { useSelector } from 'react-redux'
import Carousel from 'react-bootstrap/Carousel'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HotelCard4 from '../../Components/HotelCard/HotelCard4';
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ApiEndPoint,Hotelapitoken } from '../../Components/GlobalData/GlobalData'
import { fetchCheckoutDetail } from '../../Redux/Actions/actions'
import Images from '../../Components/Images/images'
import Layout from '../../Components/Layout/Layout'
import RoomFacilities from '../../Components/Hotel/RoomFacility'
import Axios from 'axios'
import HotelSearch from '../../Components/SearchBar/HotelSearch'
import InnerHotelSearch from '../../Components/SearchBar/InnerHotelSearch'

function HotelDetail () {
  const location = useLocation();
  const dispatch = useDispatch();
  const todayDate = new Date();
  const [hotelid, setHotelid] = useState("");
  var endpoint=ApiEndPoint();
  const { index } = location.state || {};
  const [selectedDate, setSelectedDate] = useState(null);
  const [activeButton, setActiveButton] = useState(null);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [result, setResult] = useState([]);
  const [activeIndex, setActiveIndex] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showPrice, setShowPrice] = useState(true);
  const [baseCName, setBaseCName] = useState('GBP');
  const [counter, setCounter] = useState(0);

  const [hotelSelectedRooms, setHotelSelectedRooms] = useState([]);

  const navigate = useNavigate();
  const hoteldetail = useSelector(state => state.hotels.hoteldetail);
  
  const seleectedhotelfromlist = useSelector(
    state => state.hotels.hotels.hotels_list[index]
  );
  let { id } = useParams();
  const CurrencyRates = useSelector(state => state.hotels.Currency);
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency);
  const ReduxSearchData = useSelector(state => state.hotels.hsearch);
  
  let Checkin = moment(ReduxSearchData.check_in);
  let checkout = moment(ReduxSearchData.check_out);
  let daysBetween = Math.abs(checkout.diff(Checkin, 'days'));
  const latitude = hoteldetail.latitude;
  const longitude = hoteldetail.longitude;
  var mapUrl =
    'https://maps.google.com/maps?width=50%25&height=600&hl=en&q=' +
    latitude +
    ',' +
    longitude +
    '&t=&z=19&ie=UTF8&iwloc=B&output=embed';
 
  useEffect(() => {
    // Function to handle scroll events
    const handleScroll = () => {
      const sectionIds = [
        'section-1',
        'section-2',
        'section-3',
        'section-4',
        'section-5',
        'section-6',
        'section-7'
      ]

      // Find the section that is currently in view
      for (const id of sectionIds) {
        const section = document.getElementById(id)
        if (section) {
          const rect = section.getBoundingClientRect()
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            setActiveButton(id)
            break
          }
        }
      }
    }

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll)

    // Clean up the listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, []);
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName)
  };
  const handleButtonClick2 = (buttonName) => {
    setActiveButton(buttonName)
  };

  useEffect(() => {
    let NewArray = [];
    let occurrences = {};
    // Iterate over the adults and childs arrays
  for (let i = 0; i < ReduxSearchData.Adults.length; i++) {
    let adult = ReduxSearchData.Adults[i];
    let child = ReduxSearchData.children[i];
 // Create a unique identifier for the pair
 let pairKey = `${adult}-${child}`;

 // Update the occurrences object
 occurrences[pairKey] = (occurrences[pairKey] || 0) + 1;

 // Only add to the result if the current qty is the maximum
 if (occurrences[pairKey] === 1) {
  NewArray.push({ adult, child, qty: occurrences[pairKey],status:false });
 } else {
     // Update the qty for the existing entry
     NewArray.find(entry => entry.adult === adult && entry.child === child).qty = occurrences[pairKey];
 }
}
    setResult(NewArray);
  }, [ReduxSearchData]);

  const selectedRoomData=(index, event)=>{
    event.preventDefault();
    event.stopPropagation();
    const selectedroom = hoteldetail.rooms_options[index];
      for (let i = 0; i < result.length; i++) {
        if(result[i].adult===selectedroom.adults &&  result[i].child===selectedroom.childs  &&  result[i].qty===selectedroom.rooms_qty)
        {
          if( result[i].status===false){
            const key = { rateKey: selectedroom.booking_req_id, index: index }
            const newSelectedRoom = {
              room_rate_key: JSON.stringify(key),
              rooms_qty: selectedroom.rooms_qty,
              index: index,
            }
            result[i].status=true;
            setSelectedRooms([...selectedRooms, newSelectedRoom])
            setHotelSelectedRooms([...hotelSelectedRooms, key])
          }else{
            if (selectedRooms.some(room => room.index === index)) {
              const updatedSelectedRooms = selectedRooms.filter(  room => room.index !== index )
                    const newroom = hotelSelectedRooms.filter(item => item.index !== index);
                    setSelectedRooms(updatedSelectedRooms);
                    setHotelSelectedRooms(newroom);
                    result[i].status=false;
            }else{
              toast.error('Select the room according to the search criteria.', {
                        position: toast.POSITION.TOP_RIGHT
                    });
            }
          }
         
        }
          
      }

  };
  const BookRoom = async () => {
    if(hotelSelectedRooms.length !==0){ 
      // debugger
      if(hotelSelectedRooms.length !== result.length)
      {
        toast.error('Please Select the room according to the search criteria.', {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }

    const roomdata = {
      rooms_select_data: JSON.stringify(selectedRooms),
      hotel_index: index,
      hotelbeds_select_room: hotelSelectedRooms
    }
    const apitokennew=Hotelapitoken();
    const finaldata = {
      token:apitokennew ,
      request_data: JSON.stringify(roomdata),
      selected_hotel: JSON.stringify(seleectedhotelfromlist),
      selected_hotel_details: JSON.stringify({
        check_in: ReduxSearchData.check_in,
        check_out: ReduxSearchData.check_out,
        hotel_address: hoteldetail.hotel_address,
        hotel_country: hoteldetail.hotel_country,
        hotel_city: hoteldetail.hotel_city,
        atitude: hoteldetail.latitude,
        longitude: hoteldetail.longitude
      }),
      req_index: index
    }
    setLoading(true)
    try {
      const response = await Axios.post(
        endpoint+'/api/hotels/checkavailability_Live',
        finaldata,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            // Required for CORS support to work
            'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
            // 'Access-Control-Allow-Headers':
            //   'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          }
        }
      )
      // Handle the API response here
      if(response.data.status==='error'){
        setLoading(false);
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
      });
      return;
      };
      if(response.data !==''){
        dispatch(fetchCheckoutDetail(response.data.hotels_data))
        navigate('/hotel_checkout')
      }
      setLoading(false)
    } catch (error) {
      // Handle errors here
      setLoading(false);
      console.error('Error:', error)
    }
  }else{
    toast.error('Please Select Room First.', {
      position: toast.POSITION.TOP_RIGHT
  });
  }
  };

  const renderPrice = (price) =>{
    const userData = localStorage.getItem('HotelCurrency');
    const Rates = JSON.parse(userData);
      if(userData !==null){
          if(CurrencyRates===undefined){
            const gbpprice = Rates.conversion_rates[baseCName]; // Use square brackets to access the property
            var baseprice = (Number(gbpprice) * Number(price)).toFixed(0);
        
          }else{
            var select123 = CurrencyRates.selectedcurrency;
            const gbpprice = Rates.conversion_rates[baseCName];
            var baseprice1 = (Number(gbpprice) * Number(price)).toFixed(0);
            const gbpprice2 = GBPCurrencyRates.conversion_rates[select123]; // Use square brackets to access the property
            var baseprice = (Number(gbpprice2) * Number(baseprice1)).toFixed(0);
          }
          return baseprice
       }else{
        setShowPrice(false);
       }
  };
  const calculateMarkup = (price) => {
    
    if(Object.keys(hoteldetail).length !==0){
    let markupprice=0;
    let adminmarkupprice=0;
    let clientmarkupprice=0;
    let finalpricemarkup=Number(price);
    if(Number(hoteldetail.admin_markup) !== 0)
   {
    if(hoteldetail.admin_markup_type === "Percentage")
    {
      markupprice=( price * Number(hoteldetail.admin_markup))/100;
    }else{
      markupprice= Number(hoteldetail.admin_markup);
     }
      adminmarkupprice=markupprice;
      finalpricemarkup +=markupprice
      
   }
   if(Number(hoteldetail.customer_markup) !== 0)
   {
    if(hoteldetail.customer_markup_type=== "Percentage")
    {
      markupprice= (finalpricemarkup * Number(hoteldetail.customer_markup))/100;
    }else{
      markupprice= Number(hoteldetail.customer_markup);
     }
     clientmarkupprice=markupprice;
      finalpricemarkup +=markupprice;
   }
   return finalpricemarkup.toFixed(2);
  }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
        <div>
          <ToastContainer/>
          <div className='container-fluid modify-flight-search p-0'>
            <div className="gQSzOC" style={{marginTop:'1px'}}>
              <div class="Box-pcln-design-system__sc-n9h3nv-0 BackHeader__HeaderWrap-sc-1mcpnyd-0 djhuzv cHWgzb styles__StyledBackHeader-sc-uqxtca-2 juJooJ" data-testid="HEADER_WRAP">
                <div class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 BackHeader__HeaderCard-sc-1mcpnyd-1 gMoPDK giDoGG crjFKm">
                  <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv cIFUSm">
                    <a href="/hotels" target="_self" data-autobot-element-id="HTL_D_DETAIL_BACK_HOTEL_RESULTS" color="primary" class="Link-pcln-design-system__sc-1eidvea-0 BlockLink-pcln-design-system__sc-mu1rzn-0 BackHeader__BackLink-sc-1mcpnyd-2 eDEWbr fFvqRs dMZHbl">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="16" width="16" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 egJvso ChevronLeft__SvgChevronLeft-sc-1pih41b-0 dvOXuG">
                        <path d="M15.4 16.6L10.8 12l4.6-4.6L14 6l-6 6 6 6 1.4-1.4z"></path>
                      </svg>
                      <span color="primary" font-size="1" font-weight="bold" class="Text__Span-pcln-design-system__sc-1xtb652-1 iyYUkm">Back to Listings</span>
                    </a>
                  </div>
                </div>
              </div>
              <HotelSearch/>
            </div>
          </div>
            <Images data={hoteldetail.hotel_gallery} />
          <nav className='navbar view-detail-navbar biEWAU py-0'>
            <div className='container-fluid'>
              <ul>
                <li className='dFjWGa dxUoaI fkfxJA'>
                  {/* <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">
                    <a font-size="0,,,,1" color="primary" font-weight="regular" class="Text-pcln-design-system__sc-1xtb652-0 fJfOGH" href='#section-1' onClick={() => handleButtonClick2('section-1')}>Overview</a>
                  </div> */}
                  <a
                    href='#section-1'
                    onClick={() => handleButtonClick2('section-1')}
                    className={activeButton === 'section-1' ? 'current' : ''}
                  >
                    Overview
                    <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 Absolute-pcln-design-system__sc-1sjf63y-0 bXyETw ckrUgW">
                      <div width="calc(100% - 32px),,,,calc(100% - 48px)" height="3" color="primary" aria-hidden="true" data-testid="active-bar" class="Box-pcln-design-system__sc-n9h3nv-0 NavBarTab__ActiveUnderline-sc-etif2q-1 ixfzHX dPpUjs tab--right-enter-done"></div>
                    </div>
                  </a>
                </li>
                <li className='dFjWGa dxUoaI fkfxJA'>
                  <a
                    href='#section-2'
                    onClick={() => handleButtonClick2('section-2')}
                    className={activeButton === 'section-2' ? 'current' : ''}
                  >
                    Rooms
                    <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 Absolute-pcln-design-system__sc-1sjf63y-0 bXyETw ckrUgW">
                      <div width="calc(100% - 32px),,,,calc(100% - 48px)" height="3" color="primary" aria-hidden="true" data-testid="active-bar" class="Box-pcln-design-system__sc-n9h3nv-0 NavBarTab__ActiveUnderline-sc-etif2q-1 ixfzHX dPpUjs tab--right-enter-done"></div>
                    </div>
                  </a>
                </li>
                <li className='dFjWGa dxUoaI fkfxJA'>
                  <a
                    href='#section-3'
                    onClick={() => handleButtonClick('section-3')}
                    className={activeButton === 'section-3' ? 'current' : ''}
                  >
                    Facilities
                    <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 Absolute-pcln-design-system__sc-1sjf63y-0 bXyETw ckrUgW">
                      <div width="calc(100% - 32px),,,,calc(100% - 48px)" height="3" color="primary" aria-hidden="true" data-testid="active-bar" class="Box-pcln-design-system__sc-n9h3nv-0 NavBarTab__ActiveUnderline-sc-etif2q-1 ixfzHX dPpUjs tab--right-enter-done"></div>
                    </div>
                  </a>
                </li>
                <li className='dFjWGa dxUoaI fkfxJA'>
                  <a
                    href='#section-4'
                    onClick={() => handleButtonClick('section-4')}
                    className={activeButton === 'section-4' ? 'current' : ''}
                  >
                    About
                    <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 Absolute-pcln-design-system__sc-1sjf63y-0 bXyETw ckrUgW">
                      <div width="calc(100% - 32px),,,,calc(100% - 48px)" height="3" color="primary" aria-hidden="true" data-testid="active-bar" class="Box-pcln-design-system__sc-n9h3nv-0 NavBarTab__ActiveUnderline-sc-etif2q-1 ixfzHX dPpUjs tab--right-enter-done"></div>
                    </div>
                  </a>
                </li>
                <li className='dFjWGa dxUoaI fkfxJA'>
                  <a
                    href='#section-6'
                    onClick={() => handleButtonClick('section-6')}
                    className={activeButton === 'section-6' ? 'current' : ''}
                  >
                    Review & Rating
                    <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 Absolute-pcln-design-system__sc-1sjf63y-0 bXyETw ckrUgW">
                      <div width="calc(100% - 32px),,,,calc(100% - 48px)" height="3" color="primary" aria-hidden="true" data-testid="active-bar" class="Box-pcln-design-system__sc-n9h3nv-0 NavBarTab__ActiveUnderline-sc-etif2q-1 ixfzHX dPpUjs tab--right-enter-done"></div>
                    </div>
                  </a>
                </li>
                <li className='dFjWGa dxUoaI fkfxJA'>
                  <a
                    href='#section-5'
                    onClick={() => handleButtonClick('section-5')}
                    className={activeButton === 'section-5' ? 'current' : ''}
                  >
                    Location
                    <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 Absolute-pcln-design-system__sc-1sjf63y-0 bXyETw ckrUgW">
                      <div width="calc(100% - 32px),,,,calc(100% - 48px)" height="3" color="primary" aria-hidden="true" data-testid="active-bar" class="Box-pcln-design-system__sc-n9h3nv-0 NavBarTab__ActiveUnderline-sc-etif2q-1 ixfzHX dPpUjs tab--right-enter-done"></div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          <div className='container-fluid'>
            <section id='section-1' className=''>
              <div className='cMhMXY dliJJP'>
                <div className='fFOfCi ktTcSV brHOWu'>
                  <div>
                    <h1 className='eGeDlK'>{hoteldetail.hotel_name}</h1>
                    <div className='bjQiHv'>
                      <div className='gnNNl bZYGh'> {hoteldetail.hotel_address}</div>
                      <div class="Box-pcln-design-system__sc-n9h3nv-0 Hide-pcln-design-system__sc-i482tf-0 djhuzv juLRVt">
                        <button color="primary" aria-label="View Map" data-testid="View-map-button" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 khFTaF Address__ViewMapButton-sc-toi3id-1 iuEGkU">
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 duGimm brHOWu">
                              <i><FontAwesomeIcon icon={faLocationDot} /></i>
                              <div font-size="0" font-weight="bold" class="Text-pcln-design-system__sc-1xtb652-0 klMbWH">View Map</div>
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                    <p className='mt-2 text-justify'>{hoteldetail.description} </p>
                  </div>
                  {/* <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 HotelInfoArea__PriceAreaWrapper-sc-1iu0w09-0 hZcIUX jrYywt">
                    <div class="Box-pcln-design-system__sc-n9h3nv-0 cCKpKv">
                      <div class="Box-pcln-design-system__sc-n9h3nv-0 cCKpKv">
                        <div class="Box-pcln-design-system__sc-n9h3nv-0 PriceArea__SpacedBox-sc-zl61gi-0 eYAKUl fKAeiP"></div>
                        <span class="GenericPrice__StyledWrapper-sc-ehy7jm-2 rLAaP">
                          <span data-testid="PRICE" font-weight="bold" color="primary.dark" font-size="40px" class="Text__Span-pcln-design-system__sc-1xtb652-1 ctfuwv">
                            <sup font-size="20" class="GenericPrice__Sup-sc-ehy7jm-0 GenericPrice__CurrencySup-sc-ehy7jm-1 hYllkW bQDYAi"><span>$</span></sup>82
                          </span>
                        </span>
                        <div color="text.light" font-size="1" class="Text-pcln-design-system__sc-1xtb652-0 PriceArea__StyledText-sc-zl61gi-1 gIaSZe cqBiqL">price per night</div>
                      </div>
                    </div>
                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv lfvHNo">
                      <button color="secondary" data-autobot-element-id="HTL_DETAIL_SUMMARY_CHOOSE_ROOM_BUTTON_TOP" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 bMBaBy HotelInfoArea__ChooseRoomButton-sc-1iu0w09-3 eGowrA"  href='#section-2'
                              onClick={() => handleButtonClick2('section-2')}>
                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">
                          <div font-weight="bold" font-size="1" class="Text-pcln-design-system__sc-1xtb652-0 HotelInfoArea__NoWrapText-sc-1iu0w09-4 JMjyN kAsriY">Choose Your Room</div>
                        </div>
                      </button>
                    </div>
                  </div> */}
                </div>
                <hr color="border" class="Divider-pcln-design-system__sc-10weutl-0 kwmkzy"></hr>
              </div>
              <div class="Box-pcln-design-system__sc-n9h3nv-0 styles__ResponsiveWrapper-sc-33r0uq-9 cMhMXY gtTPFM">
                <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                  <div class="Box-pcln-design-system__sc-n9h3nv-0 gWmWRB">
                    <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 bXyETw koPElt">
                      <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 InfoPanels__BoxOne-sc-ejiqif-0 iZSTjk kfGON">
                        <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 bXyETw">
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 kjAZcI">
                              <h2 font-size="2,2,3,3" color="text.base" font-weight="bold" class="Text-pcln-design-system__sc-1xtb652-0 uXFCt">Top Reasons to Book</h2>
                            </div>
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 bQNZnh">
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv brHOWu">
                                  <div color="secondary.light" data-testid="CircleFlex" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 ReasonToBook__CircleFlex-sc-hfnhe5-3 ddScBT gayMAW bsUJZA">
                                    <i className='hOuIer Calendar__SvgCalendar-sc-12aq2xi-0 DXxDd'><FontAwesomeIcon icon={faCalendarDays} /></i>
                                  </div>
                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 brbcua">
                                    <div color="#001833" font-weight="bold" font-size="0" class="Text-pcln-design-system__sc-1xtb652-0 ixiPcI">PAY LATER AVAILABLE</div>
                                    <div font-size="1" color="text.light" class="Text-pcln-design-system__sc-1xtb652-0 gIaSZe">Prepayment isn't required when you pick a Pay Later room&nbsp;</div>
                                  </div>
                                </div>
                              </div>
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 bQNZnh">
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv brHOWu">
                                  <div color="secondary.light" data-testid="CircleFlex" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 ReasonToBook__CircleFlex-sc-hfnhe5-3 ddScBT gayMAW bsUJZA">
                                    <i className='hOuIer ApXvv'><FontAwesomeIcon icon={faRotate}/></i>
                                  </div>
                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 brbcua">
                                    <div color="#001833" font-weight="bold" font-size="0" class="Text-pcln-design-system__sc-1xtb652-0 ixiPcI">FULLY REFUNDABLE RATES</div>
                                    <div font-size="1" color="text.light" class="Text-pcln-design-system__sc-1xtb652-0 gIaSZe">Choose a room with a Refundable Rate for maximum flexibility&nbsp;</div>
                                  </div>
                                </div>
                              </div>
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 bQNZnh">
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv brHOWu">
                                  <div color="secondary.light" data-testid="CircleFlex" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 ReasonToBook__CircleFlex-sc-hfnhe5-3 ddScBT gayMAW bsUJZA">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" color="secondary" class="Svg-sc-12lgb6u-0 hOuIer Carriage__SvgCarriage-sc-1cm5gdd-0 cTfkbw">
                                      <path d="M13.5 2v8h8c0-4.4-3.6-8-8-8zm6.3 13.9c1.1-1.4 1.7-3.1 1.7-4.9H6.9L6 9H2.5v2h2.2s1.9 4.1 2.1 4.4C5.7 16 5 17.2 5 18.5 5 20.4 6.6 22 8.5 22c1.8 0 3.2-1.3 3.5-3h2c.2 1.7 1.7 3 3.5 3 1.9 0 3.5-1.6 3.5-3.5 0-1-.5-2-1.2-2.6zM8.5 20c-.8 0-1.5-.7-1.5-1.5S7.7 17 8.5 17s1.5.7 1.5 1.5S9.3 20 8.5 20zm9 0c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5z"></path>
                                    </svg>
                                  </div>
                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 brbcua">
                                    <div color="#001833" font-weight="bold" font-size="0" class="Text-pcln-design-system__sc-1xtb652-0 ixiPcI">KIDS STAY FREE</div>
                                    <div font-size="1" color="text.light" class="Text-pcln-design-system__sc-1xtb652-0 gIaSZe">Children 2 and under stay for free when using an available crib&nbsp;
                                      <button class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 drkNgV ReasonToBook__LinkButton-sc-hfnhe5-1 fRkZGD" color="primary">
                                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">(details)</div>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 bQNZnh">
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv brHOWu">
                                  <div color="alert.light" data-testid="CircleFlex" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 ReasonToBook__CircleFlex-sc-hfnhe5-3 cWIBJX gayMAW bsUJZA">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" color="alert" class="Svg-sc-12lgb6u-0 zDJak Ribbon__SvgRibbon-sc-qn6qiu-0 fxqxlx">
                                      <path d="M11 17.5c.1 0 .1 0 .2-.1L9 22l-1.5-2-2.5.2 2.1-4.4h.1c.2.2.4.4.6.7.4.5.8 1 1.4 1.2.2.1.4.1.5.1.5 0 .9-.1 1.3-.3zm5.8-1.7c-.2.2-.4.4-.6.7-.4.5-.8 1-1.4 1.2-.6.2-1.2 0-1.8-.2-.1 0-.1 0-.2-.1L15 22l1.5-2 2.5.2-2.2-4.4zM20 9.4c0 .3-.3.7-.6 1.1-.2.3-.5.7-.6 1-.1.4-.1.8-.1 1.2 0 .5 0 .9-.2 1.2s-.6.4-1.1.6c-.4.1-.8.3-1.2.5-.3.2-.6.6-.8.9-.3.4-.6.7-.9.8-.3.1-.7 0-1.2-.2-.4-.1-.8-.2-1.3-.2-.4 0-.9.1-1.3.2-.5.1-.9.3-1.2.2-.3-.1-.6-.5-.9-.8-.3-.3-.5-.7-.8-.9s-.8-.4-1.2-.5c-.5-.2-.9-.3-1.1-.6s-.2-.7-.2-1.2c0-.4 0-.8-.1-1.2s-.4-.7-.6-1c-.3-.4-.6-.7-.6-1.1s.3-.7.6-1.1c.2-.3.4-.6.6-1 .1-.4.1-.9.1-1.3 0-.5 0-.9.2-1.2.2-.3.6-.4 1.1-.6.4 0 .8-.2 1.2-.4.3-.2.6-.6.8-.9.3-.4.6-.8.9-.9h.2c.3 0 .6.1.9.2.4.1.8.2 1.3.2.4 0 .9-.1 1.3-.2.5-.1.9-.3 1.2-.2s.6.5.9.8c.3.3.5.7.8.9.5.3.9.5 1.3.6.5.2.9.3 1.1.6.2.3.2.7.2 1.2 0 .4 0 .8.1 1.2.1.4.4.7.6 1 .3.4.6.8.6 1.1zm-3.5-1.8l-.9-.9-4.9 4.9-2.3-2.3-.9.9 3.2 3.2 5.8-5.8z"></path>
                                    </svg>
                                  </div>
                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 brbcua">
                                    <div color="#001833" font-weight="bold" font-size="0" class="Text-pcln-design-system__sc-1xtb652-0 ixiPcI">GUESTS LOVE IT</div>
                                    <div font-size="1" color="text.light" class="Text-pcln-design-system__sc-1xtb652-0 gIaSZe">Guests give this property a very good rating of 8.4/10&nbsp;</div>
                                  </div>
                                </div>
                              </div>
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 bQNZnh">
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv brHOWu">
                                  <div color="alert.light" data-testid="CircleFlex" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 ReasonToBook__CircleFlex-sc-hfnhe5-3 cWIBJX gayMAW bsUJZA">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" color="alert" class="Svg-sc-12lgb6u-0 zDJak Trophy__SvgTrophy-sc-qs1eyc-0 kgiFhp">
                                      <path d="M19.4 3h-2c-.8 0-1.8.9-1.8 1.8H8.4c0-.9-1-1.8-1.8-1.8H3v8.1c0 .9.9 1.8 1.8 1.8h2c.4 1.8 1.5 3.3 4.3 3.6v1.9c-2 .2-2.7 1.2-2.7 2.3v.3h7.2v-.3c0-1.2-.7-2.2-2.7-2.3v-1.9c2.8-.3 4-1.8 4.3-3.6h2c.9 0 1.8-.9 1.8-1.8V3h-1.6zM4.8 11.1V4.8h1.8v6.3H4.8zm14.4 0h-1.8V4.8h1.8v6.3z"></path>
                                    </svg>
                                  </div>
                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 brbcua">
                                    <div color="#001833" font-weight="bold" font-size="0" class="Text-pcln-design-system__sc-1xtb652-0 ixiPcI">TOP RATED LOCATION</div>
                                    <div font-size="1" color="text.light" class="Text-pcln-design-system__sc-1xtb652-0 gIaSZe">Guests rate the location 8.9/10&nbsp;</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                      <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 InfoPanels__BoxTwo-sc-ejiqif-1 iVgiTb jzTiZs">
                        <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 bXyETw">
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 kjAZcI">
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 iDeVNi fctwCy">
                              <div width="48px" color="text.lightest" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 GuestRatingHeading__Score-sc-1kv9q6r-0 efJUkR clKdln kkwANn">
                                <div font-weight="bold" font-size="20px" data-autobot-element-id="HTL_DETAIL_REVIEWS_SCORES_TOP" class="Text-pcln-design-system__sc-1xtb652-0 bnszOG">8.4</div>
                              </div>
                              <h2 font-size="2,2,3,3" color="text.base" font-weight="bold" class="Text-pcln-design-system__sc-1xtb652-0 uXFCt">Guest Rating</h2>
                            </div>
                          </div>
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 bQNZnh">
                              <div data-testid="PROGRESS_BAR_CONTAINER" class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 dgSpnW dJKBvC">
                                  <span font-size="12px" title="CLEANLINESS" font-weight="bold" class="Text__Span-pcln-design-system__sc-1xtb652-1 ProgressBar__Title-sc-1cvkpw2-2 bmECKS hyzRiB">CLEANLINESS</span>
                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv brHOWu">
                                    <div data-testid="progress-bar-icons-left" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv brHOWu">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 bUvHvR Trophy__SvgTrophy-sc-qs1eyc-0 kgiFhp">
                                        <path d="M19.4 3h-2c-.8 0-1.8.9-1.8 1.8H8.4c0-.9-1-1.8-1.8-1.8H3v8.1c0 .9.9 1.8 1.8 1.8h2c.4 1.8 1.5 3.3 4.3 3.6v1.9c-2 .2-2.7 1.2-2.7 2.3v.3h7.2v-.3c0-1.2-.7-2.2-2.7-2.3v-1.9c2.8-.3 4-1.8 4.3-3.6h2c.9 0 1.8-.9 1.8-1.8V3h-1.6zM4.8 11.1V4.8h1.8v6.3H4.8zm14.4 0h-1.8V4.8h1.8v6.3z"></path>
                                      </svg>
                                    </div>
                                    <span font-size="1" font-weight="bold" class="Text__Span-pcln-design-system__sc-1xtb652-1 hfkzjs">8.8</span>
                                  </div>
                                </div>
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK">
                                  <div height="4px" width="1" class="Box-pcln-design-system__sc-n9h3nv-0 ProgressBar__HideOverflow-sc-1cvkpw2-0 ga-doeC faiveK">
                                    <div height="4px" class="Box-pcln-design-system__sc-n9h3nv-0 ProgressBar__AnimationBox-sc-1cvkpw2-1 kBoBML llmARQ"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 bQNZnh">
                              <div data-testid="PROGRESS_BAR_CONTAINER" class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 dgSpnW dJKBvC">
                                  <span font-size="12px" title="STAFF" font-weight="bold" class="Text__Span-pcln-design-system__sc-1xtb652-1 ProgressBar__Title-sc-1cvkpw2-2 bmECKS hyzRiB">STAFF</span>
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv brHOWu">
                                  <span font-size="1" font-weight="bold" class="Text__Span-pcln-design-system__sc-1xtb652-1 bcOhvn">8.6</span>
                                </div>
                              </div>
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK">
                                <div height="4px" width="1" class="Box-pcln-design-system__sc-n9h3nv-0 ProgressBar__HideOverflow-sc-1cvkpw2-0 ga-doeC faiveK">
                                  <div height="4px" class="Box-pcln-design-system__sc-n9h3nv-0 ProgressBar__AnimationBox-sc-1cvkpw2-1 kBoBML exklS"></div>
                                </div>
                              </div>
                              </div>
                            </div>
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 bQNZnh">
                              <div data-testid="PROGRESS_BAR_CONTAINER" class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 dgSpnW dJKBvC">
                                  <span font-size="12px" title="LOCATION" font-weight="bold" class="Text__Span-pcln-design-system__sc-1xtb652-1 ProgressBar__Title-sc-1cvkpw2-2 bmECKS hyzRiB">LOCATION</span>
                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv brHOWu">
                                    <span font-size="1" font-weight="bold" class="Text__Span-pcln-design-system__sc-1xtb652-1 bcOhvn">8.9</span>
                                  </div>
                                </div>
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK">
                                  <div height="4px" width="1" class="Box-pcln-design-system__sc-n9h3nv-0 ProgressBar__HideOverflow-sc-1cvkpw2-0 ga-doeC faiveK">
                                    <div height="4px" class="Box-pcln-design-system__sc-n9h3nv-0 ProgressBar__AnimationBox-sc-1cvkpw2-1 kBoBML ewMNXd"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <button class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 drkNgV GuestScoresPanel__ReviewsButton-sc-nk4ur4-1 ljUrvP" color="primary">
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">Verified ratings from 1550 guests</div>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 InfoPanels__BoxThree-sc-ejiqif-2 jNuJI bgXeoc">
                        <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 bXyETw">
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 kjAZcI">
                            <h2 font-size="2,2,3,3" color="text.base" font-weight="bold" class="Text-pcln-design-system__sc-1xtb652-0 uXFCt">Top Amenities</h2>
                          </div>
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 AmenitiesSection__OverallContainer-sc-ei6p42-9 djhuzv ggTnOm izTftl">
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 AmenitiesSection__AmenityIconsCarousel-sc-ei6p42-7 djhuzv brHOWu dYqbsU">
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 AmenitiesSection__AmenitiesContainer-sc-ei6p42-8 djhuzv brHOWu bgmTtJ">
                                  <div width="25%,,,33%,50%" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 AmenitiesSection__AmenityContainer-sc-ei6p42-1 gPxwGI brHOWu jHESzD">
                                    <button data-testid="ACTIONABLE_AMENITY" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 drkNgV AmenitiesSection__ActionableContainer-sc-ei6p42-3 dxvzAU" color="primary">
                                      <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">
                                        <div size="40" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 gWrpZK brHOWu">
                                          <div size="40" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 Disk__CircularFlex-sc-7y7vvd-0 etIvbM kAXPUW jUnDuc AmenitiesSection__CircleContainer-sc-ei6p42-0 kbxCrI AmenitiesSection__CircleContainer-sc-ei6p42-0 kbxCrI">
                                            <div size="36" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 Disk__CircularFlex-sc-7y7vvd-0 jkVzhs kAXPUW jVjUOc">
                                              <img src="https://pix8.agoda.net/hotelImages/23670965/0/c94a3815a1d3624cab4a1433baba42b6.jpg?ce=0&amp;s=1024x768" alt="Outdoor pool Amenity" data-testid="AMENITY_ICON_IMAGE" class="AmenitiesSection__ImageIconContainer-sc-ei6p42-10 fTpksA" />
                                              <div data-testid="AMENITY_BADGE_CONTAINER" class="Box-pcln-design-system__sc-n9h3nv-0 AmenitiesSection__BadgeContainer-sc-ei6p42-11 fXVldm fAUQAu">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="16" width="16" aria-hidden="true" fill="currentcolor" color="text.lightest" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 fAXPed Gallery__SvgGallery-sc-1cx3qfz-0 kCsBzV">
                                                  <path d="M22 16V4c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2zm-11-4l2 2.7 3-3.7 4 5H8l3-4zM2 6v14c0 1.1.9 2 2 2h14v-2H4V6H2z"></path>
                                                </svg>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div font-size="0" class="Text-pcln-design-system__sc-1xtb652-0 jsqwxd">Swimming Pool</div>
                                      </div>
                                    </button>
                                  </div>
                                  <div width="25%,,,33%,50%" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 AmenitiesSection__AmenityContainer-sc-ei6p42-1 gPxwGI brHOWu jHESzD">
                                    <button data-testid="ACTIONABLE_AMENITY" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 drkNgV AmenitiesSection__ActionableContainer-sc-ei6p42-3 dxvzAU" color="primary">
                                      <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">
                                        <div size="40" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 gWrpZK brHOWu">
                                          <div size="40" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 Disk__CircularFlex-sc-7y7vvd-0 etIvbM kAXPUW jUnDuc AmenitiesSection__CircleContainer-sc-ei6p42-0 kbxCrI AmenitiesSection__CircleContainer-sc-ei6p42-0 kbxCrI">
                                            <div size="36" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 Disk__CircularFlex-sc-7y7vvd-0 jkVzhs kAXPUW jVjUOc">
                                              <img src="https://pix8.agoda.net/hotelImages/23670965/0/4a004f3454cf7912e968e5b5ee64229d.jpg?ca=22&amp;ce=0&amp;s=1024x768" alt="Restaurant(s) Amenity" data-testid="AMENITY_ICON_IMAGE" class="AmenitiesSection__ImageIconContainer-sc-ei6p42-10 fTpksA"/>
                                              <div data-testid="AMENITY_BADGE_CONTAINER" class="Box-pcln-design-system__sc-n9h3nv-0 AmenitiesSection__BadgeContainer-sc-ei6p42-11 fXVldm fAUQAu">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="16" width="16" aria-hidden="true" fill="currentcolor" color="text.lightest" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 fAXPed Gallery__SvgGallery-sc-1cx3qfz-0 kCsBzV">
                                                  <path d="M22 16V4c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2zm-11-4l2 2.7 3-3.7 4 5H8l3-4zM2 6v14c0 1.1.9 2 2 2h14v-2H4V6H2z"></path>
                                                </svg>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div font-size="0" class="Text-pcln-design-system__sc-1xtb652-0 jsqwxd">Restaurant</div>
                                      </div>
                                    </button>
                                  </div>
                                  <div width="25%,,,33%,50%" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 AmenitiesSection__AmenityContainer-sc-ei6p42-1 gPxwGI brHOWu jHESzD">
                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 AmenitiesSection__PaddedContainer-sc-ei6p42-2 djhuzv brHOWu iMBOxr">
                                      <div size="40" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 gWrpZK brHOWu">
                                        <div size="40" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 Disk__CircularFlex-sc-7y7vvd-0 etIvbM kAXPUW jUnDuc AmenitiesSection__CircleContainer-sc-ei6p42-0 kbxCrI AmenitiesSection__CircleContainer-sc-ei6p42-0 kbxCrI">
                                          <div size="36" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 Disk__CircularFlex-sc-7y7vvd-0 jkVzhs kAXPUW jVjUOc">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt Wifi__SvgWifi-sc-12pfcwg-0 cRKlDn">
                                              <path d="M2 9.1l1.8 1.8c4.5-4.5 11.8-4.5 16.4 0L22 9.1c-5.5-5.5-14.5-5.5-20 0zm7.3 7.2L12 19l2.7-2.7c-1.4-1.5-3.9-1.5-5.4 0zm-3.7-3.6l1.8 1.8c2.5-2.5 6.5-2.5 9.1 0l1.8-1.8c-3.5-3.5-9.1-3.5-12.7 0z"></path>
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                      <div font-size="0" class="Text-pcln-design-system__sc-1xtb652-0 jsqwxd">Free Internet Access</div>
                                    </div>
                                  </div>
                                  <div width="25%,,,33%,50%" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 AmenitiesSection__AmenityContainer-sc-ei6p42-1 gPxwGI brHOWu jHESzD">
                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 AmenitiesSection__PaddedContainer-sc-ei6p42-2 djhuzv brHOWu iMBOxr">
                                      <div size="40" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 gWrpZK brHOWu">
                                        <div size="40" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 Disk__CircularFlex-sc-7y7vvd-0 etIvbM kAXPUW jUnDuc AmenitiesSection__CircleContainer-sc-ei6p42-0 kbxCrI AmenitiesSection__CircleContainer-sc-ei6p42-0 kbxCrI">
                                          <div size="36" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 Disk__CircularFlex-sc-7y7vvd-0 jkVzhs kAXPUW jVjUOc">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt Breakfast__SvgBreakfast-sc-c158l0-0 Hnkbq">
                                              <path d="M19 3H3v10c0 2.2 1.8 4 4 4h6c2.2 0 4-1.8 4-4v-3h2c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 5h-2V5h2v3zM3 19h16v2H3v-2z"></path>
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                      <div font-size="0" class="Text-pcln-design-system__sc-1xtb652-0 jsqwxd mt-2">Free Breakfast</div>
                                    </div>
                                  </div>
                                  <div width="25%,,,33%,50%" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 AmenitiesSection__AmenityContainer-sc-ei6p42-1 gPxwGI brHOWu jHESzD">
                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 AmenitiesSection__PaddedContainer-sc-ei6p42-2 djhuzv brHOWu iMBOxr">
                                      <div size="40" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 gWrpZK brHOWu">
                                        <div size="40" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 Disk__CircularFlex-sc-7y7vvd-0 etIvbM kAXPUW jUnDuc AmenitiesSection__CircleContainer-sc-ei6p42-0 kbxCrI AmenitiesSection__CircleContainer-sc-ei6p42-0 kbxCrI">
                                          <div size="36" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 Disk__CircularFlex-sc-7y7vvd-0 jkVzhs kAXPUW jVjUOc">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt Parking__SvgParking-sc-i9x80m-0 cciCA">
                                              <path d="M12.5 3h-7v18h4v-6h3c3.3 0 6-2.7 6-6s-2.7-6-6-6zm.2 8H9.5V7h3.2c1.1 0 2 .9 2 2s-.9 2-2 2z"></path>
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                      <div font-size="0" class="mt-2 Text-pcln-design-system__sc-1xtb652-0 jsqwxd">Free Parking</div>
                                    </div>
                                  </div>
                                  <div width="25%,,,33%,50%" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 AmenitiesSection__AmenityContainer-sc-ei6p42-1 gPxwGI brHOWu jHESzD">
                                    <button data-testid="ACTIONABLE_AMENITY" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 drkNgV AmenitiesSection__ActionableContainer-sc-ei6p42-3 dxvzAU" color="primary">
                                      <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">
                                        <div size="40" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 gWrpZK brHOWu">
                                          <div size="40" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 Disk__CircularFlex-sc-7y7vvd-0 etIvbM kAXPUW jUnDuc AmenitiesSection__CircleContainer-sc-ei6p42-0 kbxCrI AmenitiesSection__CircleContainer-sc-ei6p42-0 kbxCrI">
                                            <div size="36" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 Disk__CircularFlex-sc-7y7vvd-0 jkVzhs kAXPUW jVjUOc">
                                              <img src="https://assets.pclncdn.com/bstatic/xdata/images/hotel/409294564.jpg?k=24c17ecdbc9dc91e1ea0f3c30f1a0dc0590f94afedce1e9b808331e9e41835c7&amp;o=&amp;width=50&amp;crop=1:1&amp;dpr=2&amp;format=jpg&amp;opto&amp;auto=avif,webp" alt="Fitness center Amenity" data-testid="AMENITY_ICON_IMAGE" class="AmenitiesSection__ImageIconContainer-sc-ei6p42-10 fTpksA" />
                                              <div data-testid="AMENITY_BADGE_CONTAINER" class="Box-pcln-design-system__sc-n9h3nv-0 AmenitiesSection__BadgeContainer-sc-ei6p42-11 fXVldm fAUQAu">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="16" width="16" aria-hidden="true" fill="currentcolor" color="text.lightest" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 fAXPed Gallery__SvgGallery-sc-1cx3qfz-0 kCsBzV">
                                                    <path d="M22 16V4c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2zm-11-4l2 2.7 3-3.7 4 5H8l3-4zM2 6v14c0 1.1.9 2 2 2h14v-2H4V6H2z"></path>
                                                </svg>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div font-size="0" class="Text-pcln-design-system__sc-1xtb652-0 jsqwxd">Fitness Center</div>
                                      </div>
                                    </button>
                                  </div>
                                  <div width="25%,,,33%,50%" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 AmenitiesSection__AmenityContainer-sc-ei6p42-1 gPxwGI brHOWu jHESzD">
                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 AmenitiesSection__PaddedContainer-sc-ei6p42-2 djhuzv brHOWu iMBOxr">
                                      <div size="40" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 gWrpZK brHOWu">
                                        <div size="40" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 Disk__CircularFlex-sc-7y7vvd-0 etIvbM kAXPUW jUnDuc AmenitiesSection__CircleContainer-sc-ei6p42-0 kbxCrI AmenitiesSection__CircleContainer-sc-ei6p42-0 kbxCrI">
                                          <div size="36" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 Disk__CircularFlex-sc-7y7vvd-0 jkVzhs kAXPUW jVjUOc">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt Shuttle__SvgShuttle-sc-1bpdk9s-0 jOHTuH">
                                              <path d="M16.5 5.5H3.8c-1 0-1.8.8-1.8 1.9v8.4h1.8c0 1.5 1.2 2.8 2.7 2.8s2.7-1.3 2.7-2.8h5c0 1.5 1.2 2.8 2.7 2.8s2.7-1.3 2.7-2.8H22v-4.6l-5.5-5.7zM3.8 11.1V7.4h3.6v3.7H3.8zm2.7 6c-.7 0-1.4-.6-1.4-1.4 0-.7.6-1.4 1.4-1.4s1.4.7 1.4 1.4c0 .8-.6 1.4-1.4 1.4zm6.4-6H9.3V7.4h3.6v3.7zm4.1 6c-.7 0-1.4-.6-1.4-1.4 0-.7.6-1.4 1.4-1.4s1.4.7 1.4 1.4c0 .8-.7 1.4-1.4 1.4zm-2.3-6V7.4h.9l3.6 3.7h-4.5z"></path>
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                      <div font-size="0" class="Text-pcln-design-system__sc-1xtb652-0 jsqwxd mt-2">Airport Shuttle</div>
                                    </div>
                                  </div>
                                  <div width="25%,,,33%,50%" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 AmenitiesSection__AmenityContainer-sc-ei6p42-1 gPxwGI brHOWu jHESzD">
                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 AmenitiesSection__PaddedContainer-sc-ei6p42-2 djhuzv brHOWu iMBOxr">
                                      <div size="40" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 gWrpZK brHOWu">
                                        <div size="40" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 Disk__CircularFlex-sc-7y7vvd-0 etIvbM kAXPUW jUnDuc AmenitiesSection__CircleContainer-sc-ei6p42-0 kbxCrI AmenitiesSection__CircleContainer-sc-ei6p42-0 kbxCrI">
                                          <div size="36" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 Disk__CircularFlex-sc-7y7vvd-0 jkVzhs kAXPUW jVjUOc">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt Accessible__SvgAccessible-sc-1qted1z-0 bVUCIX">
                                              <path d="M19 13v-2c-1.5 0-3.1-.8-4.1-1.8l-1.3-1.5c-.1-.1-.3-.3-.6-.4-.3-.2-.8-.3-1.2-.3-1 .1-1.8 1-1.8 2.1V15c0 1.1.9 2 2 2h5v5h2v-5.5c0-1.1-.9-2-2-2h-3v-3.4c1.3 1 3.2 1.9 5 1.9zm-6.2 5h2.1c-.5 2.3-2.5 4-4.9 4-2.8 0-5-2.2-5-5 0-2.4 1.7-4.4 4-4.9v2.1c-1.2.4-2 1.5-2 2.8 0 1.7 1.3 3 3 3 1.3 0 2.4-.8 2.8-2zM12 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path>
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                      <div font-size="0" class="Text-pcln-design-system__sc-1xtb652-0 jsqwxd">Accessible Rooms / Facilities</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <button data-testid="AMENITIES_CTA" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 jEzxeZ AmenitiesSection__CtaButton-sc-ei6p42-5 hVEfxv" color="primary">
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">Show All Amenities</div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </section>
            <section id='section-2' className='mt-2 cNXMnW'>
              <div id="RatesTableHeader" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 RatesTableHeader__HeaderWrap-sc-1yrde87-0 djhuzv jXalzF ktXtGG">
                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 fpmaAk jXYUns">
                  <div font-size="3,,,4" font-weight="bold" class="Text-pcln-design-system__sc-1xtb652-0 RatesTableHeader__RoomOptionsText-sc-1yrde87-1 dsyKEM icvVOM">Room Options</div>
                  <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 cYdLnE llNdfK">
                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 kRlXIw hZMDvQ RatesTableHeader__PriceGuidance-sc-1yrde87-8 lfyFtr RatesTableHeader__PriceGuidance-sc-1yrde87-8 lfyFtr" data-testid="PRICE_GUIDANCE_POPOVER">
                      <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv ggTnOm">
                        <button data-autobot-element-id="HTL_DETAIL_PRICE_GUIDANCE_POPOVER" aria-label="How do these prices compare?" type="button" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 drkNgV PriceGuidancePopover__PriceComparisonButton-sc-15ery3s-1 fegQzz" color="primary">
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">How do these prices compare?</div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 Hide-pcln-design-system__sc-i482tf-0 bXyETw bpEwQY">
                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 bQNZnh brHOWu">
                      <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv ggTnOm">
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv dzXNGz">
                            <span font-weight="bold" font-size="1" color="text" class="Text__Span-pcln-design-system__sc-1xtb652-1 iCsihk">Best Price.
                              <span class="Text__Span-pcln-design-system__sc-1xtb652-1 BestPriceGuaranteedHeader__Caps-sc-23td4r-2 bnZUwN hZbLaw">GUARANTEED.</span>
                            </span>
                            <span font-size="0" color="text" class="Text__Span-pcln-design-system__sc-1xtb652-1 jIhEIt">Exclusively for Priceline members
                              <a href="https://www.priceline.com/landing/best-price-guarantee.htm" target="_blank" data-unit-id="BEST_PRICE_GUARANTEED_LINK" data-testid="BEST_PRICE_GUARANTEED_LINK" color="primary" rel="noopener" class="Link-pcln-design-system__sc-1eidvea-0 BestPriceGuaranteedHeader__InlineLink-sc-23td4r-1 dNxNky kPJikv">Details</a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="Box-pcln-design-system__sc-n9h3nv-0 Hide-pcln-design-system__sc-i482tf-0 djhuzv hhrOLf">
                  <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv brHOWu">
                    <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                      <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv ggTnOm">
                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv dzXNGz">
                          <span font-weight="bold" font-size="1" color="text" class="Text__Span-pcln-design-system__sc-1xtb652-1 iCsihk">Best Price.
                            <span class="Text__Span-pcln-design-system__sc-1xtb652-1 BestPriceGuaranteedHeader__Caps-sc-23td4r-2 bnZUwN hZbLaw">GUARANTEED.</span>
                          </span>
                          <span font-size="0" color="text" class="Text__Span-pcln-design-system__sc-1xtb652-1 jIhEIt">Exclusively for Priceline members
                            <a href="https://www.priceline.com/landing/best-price-guarantee.htm" target="_blank" data-unit-id="BEST_PRICE_GUARANTEED_LINK" data-testid="BEST_PRICE_GUARANTEED_LINK" color="primary" rel="noopener" class="Link-pcln-design-system__sc-1eidvea-0 BestPriceGuaranteedHeader__InlineLink-sc-23td4r-1 dNxNky kPJikv">Details</a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv" id="GenericFilter">
                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 GenericFilter__FilterWrapper-sc-40mpry-6 djhuzv bzlJSN ipbsgI">
                  <div className='modify-flight-search rounded-4'><InnerHotelSearch/></div>
                 
                  <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                    <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                      <div data-testid="theme-provider" class="ThemeProvider__Base-pcln-design-system__sc-1502h3l-0 iDmCsd">
                        <div data-testid="GRID_WRAPPER" class="row col-gap-2 Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 RoomsList__FlexGridWrapper-sc-1uv58qc-0 djhuzv eIydDN kmJYQj">
                          {hoteldetail.rooms_options.map((item, index) => (
                           <div key={index} class="Box-pcln-design-system__sc-n9h3nv-0 jVIMBk col-md-4">
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 ComposableRoomRow__Container-sc-va24ob-0 ciJqJ HbHrI KoyBQ RoomsList__StyledComposableRoomRow-sc-1uv58qc-1 ehmsvz RoomsList__StyledComposableRoomRow-sc-1uv58qc-1 ehmsvz" data-testid="ROOM_ROW_VARIANT">
                              <div class="PhotoCarousel__ImageWrap-sc-engw3c-1 dwDXYb">
                                                <span aria-hidden="true" class="PhotoCarousel__CenterImageHelper-sc-engw3c-2 hLmSyt"></span>
                                                {item.rooms_images && item.rooms_images.length > 0 ? (
                                                  // <img
                                                  //   src={item.rooms_images[0]}
                                                  //   alt={item.room_name}
                                                  //   className='w-100'
                                                  // />
                                                  
                                                 <Carousel>
                                                 {item.rooms_images.map((item1,index)=>(
                                                    <Carousel.Item>
                                                    <img style={{height:'20em'}}
                                                      className="d-block w-100"
                                                      src={item1}
                                                      alt="First slide"
                                                    />
                                                  </Carousel.Item>
                                                  ))}
                                               </Carousel>
                                                ) : (
                                                  <img style={{height:'23em'}} src={noImage} alt='Default Image' />
                                                )}
                                              </div>
                                {/* <div width="1,1,50%,,24%" data-testid="ROOM_ROW_BOX_1" class="Box-pcln-design-system__sc-n9h3nv-0 ComposableRoomRow__Box1-sc-va24ob-1 kGhvdP qJjIJ">
                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 Relative-pcln-design-system__sc-us5x91-0 RoomRowImage__ContainerBox-sc-1bbpgk1-0 gYFXQx dgoPHI" width="1">
                                    <div width="1" data-testid="ROOM_ROW_SECTION_ONE" class="Box-pcln-design-system__sc-n9h3nv-0 Absolute-pcln-design-system__sc-1sjf63y-0 RoomRowImage__PhotoSwipeContainer-sc-1bbpgk1-1 bXyETw fUNrhW bhFHkk">
                                      <div width="1,1,1,360px,525px" class="Box-pcln-design-system__sc-n9h3nv-0 RoomPhotoSwipe__Container-sc-1np6xw9-0 bGJChp cnKDxd">
                                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Relative-pcln-design-system__sc-us5x91-0 PhotoSwipe__CarouselContainer-sc-1cx2tme-2 juFVep dgoPHI iVvcKc RoomPhotoSwipe__StyledPhotoSwipe-sc-1np6xw9-1 cNZjdr" data-testid="CAROUSEL_CONTAINER" width="1" role="region" aria-label="Photo carousel">
                                          <div data-testid="photo-indicator" class="Box-pcln-design-system__sc-n9h3nv-0 Absolute-pcln-design-system__sc-1sjf63y-0 PhotoSwipe__PhotoIndicator-sc-1cx2tme-7 jKgutU fUNrhW eVBySI">1 / 26
                                            <span class="SrOnly-pcln-design-system__sc-3hvdi4-0 jrVkbu">Images</span>
                                          </div>
                                          <button aria-label="Expand Photo Theater" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 drkNgV PhotoSwipe__ExpanderContainer-sc-1cx2tme-9 lkotus" color="primary">
                                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">
                                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 iJgYGn ZoomOut__SvgZoomOut-sc-1tv7fjb-0 ATCYL">
                                                <path d="M15 3l2.3 2.3-2.9 2.9 1.4 1.4 2.9-2.9L21 9V3h-6zM3 9l2.3-2.3 2.9 2.9 1.4-1.4-2.9-2.9L9 3H3v6zm6 12l-2.3-2.3 2.9-2.9-1.4-1.4-2.9 2.9L3 15v6h6zm12-6l-2.3 2.3-2.9-2.9-1.4 1.4 2.9 2.9L15 21h6v-6z"></path>
                                              </svg>
                                            </div>
                                          </button>
                                          <div class="ArrowController__InvisibleWrapper-sc-ybue07-0 jigjHb PhotoSwipe__PositionedArrowLeftController-sc-1cx2tme-0 hEjgWn" data-testid="LEFT_ARROW">
                                            <button aria-label="Scroll to Previous image" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 drkNgV ArrowController__StyledController-sc-ybue07-1 gWcIzk" color="primary">
                                              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" data-unit-id="ARROW_CONTROLLER_ChevronLeft" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt ChevronLeft__SvgChevronLeft-sc-1pih41b-0 dvOXuG">
                                                  <path d="M15.4 16.6L10.8 12l4.6-4.6L14 6l-6 6 6 6 1.4-1.4z"></path>
                                                </svg>
                                              </div>
                                            </button>
                                          </div>
                                          <div class="ArrowController__InvisibleWrapper-sc-ybue07-0 fkIzmh PhotoSwipe__PositionedArrowLeftController-sc-1cx2tme-0 PhotoSwipe__PositionedArrowRightController-sc-1cx2tme-1 hEjgWn bzJQRg" data-testid="RIGHT_ARROW">
                                            <button aria-label="Scroll to Next image" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 drkNgV ArrowController__StyledController-sc-ybue07-1 gWcIzk" color="primary">
                                              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" data-unit-id="ARROW_CONTROLLER_ChevronRight" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt ChevronRight__SvgChevronRight-sc-ec4b4o-0 ktEMtW">
                                                  <path d="M8 16.6l4.6-4.6L8 7.4 9.4 6l6 6-6 6L8 16.6z"></path>
                                                </svg>
                                              </div>
                                            </button>
                                          </div>
                                          <div>
                                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Absolute-pcln-design-system__sc-1sjf63y-0 PhotoCarousel__PhotoCarouselAbsolute-sc-engw3c-0 hLLYkz dpgIZ eNvHXq">
                                              <div class="PhotoCarousel__ImageWrap-sc-engw3c-1 dwDXYb">
                                                <span aria-hidden="true" class="PhotoCarousel__CenterImageHelper-sc-engw3c-2 hLmSyt"></span>
                                                {item.rooms_images && item.rooms_images.length > 0 ? (
                                                  // <img
                                                  //   src={item.rooms_images[0]}
                                                  //   alt={item.room_name}
                                                  //   className='w-100'
                                                  // />
                                                  <Carousel
                                                  className="custom-slider"
                                                  activeIndex={activeIndex}
                                                  onSelect={index => {
                                                    setActiveIndex(index);
                                                  }}
                                                >
                                                  {item.rooms_images.map((item,index)=>(
                                                    <img key={index} src={item} height="250" />

                                                  ))}
                                                </Carousel>
                                                ) : (
                                                  <img src={noImage} alt='Default Image' />
                                                )}
                                              </div>
                                              <div class="PhotoCarousel__ImageWrap-sc-engw3c-1 dwDXYb">
                                                <span aria-hidden="true" class="PhotoCarousel__CenterImageHelper-sc-engw3c-2 hLmSyt"></span>
                                                <img class="Image-pcln-design-system__sc-1x3ijma-0 MainImage__StyledMainImage-sc-1wwotm0-0 ebgvjd fhxWfg PhotoCarousel__PositionMainImage-sc-engw3c-3 gqbmxJ" src="https://assets.pclncdn.com/bstatic/xdata/images/hotel/403056548.jpg?k=0e6adb18f01d61f69beab57ff7d7852f18b9e64e59f0158a7b01196489054f98&amp;o=&amp;width=350&amp;crop=3:2&amp;dpr=2&amp;format=jpg&amp;opto&amp;auto=avif,webp" alt="Image #2" data-testid="MainImage"/>
                                              </div>
                                              <div class="PhotoCarousel__ImageWrap-sc-engw3c-1 dwDXYb">
                                                <span aria-hidden="true" class="PhotoCarousel__CenterImageHelper-sc-engw3c-2 hLmSyt"></span>
                                                <img class="Image-pcln-design-system__sc-1x3ijma-0 MainImage__StyledMainImage-sc-1wwotm0-0 ebgvjd fhxWfg PhotoCarousel__PositionMainImage-sc-engw3c-3 gqbmxJ" src="https://assets.pclncdn.com/bstatic/xdata/images/hotel/408695501.jpg?k=75adf0d2e7e927bbf6f6ff80235b9423b132be2944fa75baae9452505cbc9bde&amp;o=&amp;width=350&amp;crop=3:2&amp;dpr=2&amp;format=jpg&amp;opto&amp;auto=avif,webp" alt="Image #26" data-testid="MainImage" />
                                              </div>
                                            </div>
                                          </div>
                                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 CarouselPagination__CarouselContainer-sc-9e4onp-0 djhuzv brHOWu dRiUBG PhotoSwipe__CarouselPaginationGradient-sc-1cx2tme-10 esKvPa PhotoSwipe__CarouselPaginationGradient-sc-1cx2tme-10 esKvPa">
                                            <div data-unit-id="CAROUSEL_SLIDER" class="CarouselPagination__StyledCarousel-sc-9e4onp-1 gzvUFf">
                                              <form>
                                                <div data-unit-id="PAGINATION_DOTS_CONTAINER" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 CarouselPagination__StyledFlex-sc-9e4onp-2 djhuzv ipCoSW mDstq">
                                                  <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                                    <button aria-label="Go to Image #1" aria-current="true" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 gfMkOK">
                                                      <div color="text.lightest" width="8px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 cefYII hXamFu dlexSF"></div>
                                                    </button>
                                                  </div>
                                                  <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                                    <button aria-label="Go to Image #2" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                      <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                                    </button>
                                                  </div>
                                                  <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                                    <button aria-label="Go to Image #3" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                      <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                                    </button>
                                                  </div>
                                                  <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                                    <button aria-label="Go to Image #4" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                      <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                                    </button>
                                                  </div>
                                                  <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                                    <button aria-label="Go to Image #5" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggtQIC">
                                                      <div color="text.lightest" width="4px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 ipqAOM hXamFu gnxRRP"></div>
                                                    </button>
                                                  </div>
                                                  <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                                    <button aria-label="Go to Image #6" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                      <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                                    </button>
                                                  </div>
                                                  <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                                    <button aria-label="Go to Image #7" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                      <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                                    </button>
                                                  </div>
                                                  <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                                    <button aria-label="Go to Image #8" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                      <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                                    </button>
                                                  </div>
                                                  <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                                    <button aria-label="Go to Image #9" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                      <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                                    </button>
                                                  </div>
                                                  <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                                    <button aria-label="Go to Image #10" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                      <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                                    </button>
                                                  </div>
                                                  <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                                    <button aria-label="Go to Image #11" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                      <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                                    </button>
                                                  </div>
                                                  <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                                    <button aria-label="Go to Image #12" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                      <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                                    </button>
                                                  </div>
                                                  <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                                    <button aria-label="Go to Image #13" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                      <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                                    </button>
                                                  </div>
                                                  <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                                    <button aria-label="Go to Image #14" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                      <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                                    </button>
                                                  </div>
                                                  <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                                    <button aria-label="Go to Image #15" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                                      <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                                    </button>
                                                  </div>
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                                <div width="calc(100% - 16px),calc(100% - 24px),50%,,24%" class="Box-pcln-design-system__sc-n9h3nv-0 ComposableRoomRow__Box2-sc-va24ob-2 fWAacj kwfmwn">
                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 RoomRowContent__SectionTwoContainer-sc-1cf95i-2 eQUyTT gfbFZA">
                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 RoomRowContent__RoomTitleBox-sc-1cf95i-3 bxCHnZ csAxEc">
                                      <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv" data-testid="ROOM_TITLE_SECTION">
                                          <h3 font-size="2,2,3,3,2" font-weight="bold" class="Text-pcln-design-system__sc-1xtb652-0 cebHjO RoomTitleSection__HotelHeading-sc-14d7u8h-2 eoIQXF">{item.room_name}</h3>
                                          {/* <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 yPWXP brHOWu">
                                            <div font-size="0" color="text.light" class="Text-pcln-design-system__sc-1xtb652-0 RoomTitleSection__RoomSizeText-sc-14d7u8h-0 eYufAo">400 sq ft</div>
                                          </div> */}
                                          <div color="text.dark" font-weight="bold" font-size="0" class="Text-pcln-design-system__sc-1xtb652-0 RoomTitleSection__TitleSectionText-sc-14d7u8h-1 czFQKw jutuuU">{item.board_id}</div>
                                      </div>
                                      <div class="Box-pcln-design-system__sc-n9h3nv-0 jzYUts">
                                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 RoomAmenitiesWithIcons__AmenitiesFlexWrapper-sc-12on5te-0 bXyETw kcpoDZ gSoBiO" width="1">
                                          <div width="auto" class="Box-pcln-design-system__sc-n9h3nv-0 iFnCLT">
                                            <div data-testid="ICON_AND_TEXT" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 IconAndText__ListWrap-sc-1ulmzb0-1 ibEGsf bjQiHv dMAmjv" color="secondary.base">
                                              <div style={{display:'block'}} class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 IconAndText__PromoWrapper-sc-1ulmzb0-0 djhuzv brHOWu gQnuJA">
                                                  {item.rooms_facilities &&
                                                    item.rooms_facilities.length > 0 ? (
                                                      <RoomFacilities item={item}/>
                                                  
                                                    ) : (
                                                      <li>No facilities available</li>
                                                    )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div width="calc(1  00% - 16px),calc(100% - 24px),100%,,52%" class="Box-pcln-design-system__sc-n9h3nv-0 ComposableRoomRow__Box3-sc-va24ob-3 zcfXr kNZEEI">
                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 RoomRowRates__RoomRowRatesContainer-sc-sgzj44-1 fihmfN">
                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 RoomRowRates__RateBox-sc-sgzj44-0 egjWBu">
                                      <div class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 RateCard__WrapperCard-sc-vh5h8r-6 lkCWDC bBYTmp jorfE" data-testid="RATE_CARD">
                                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv gnbdky">
                                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 RateCard__WrapperFlex-sc-vh5h8r-7 gUaRrO bJbPHj ctFHh">
                                            <div width="40%,55%,160px" class="Box-pcln-design-system__sc-n9h3nv-0 RateCard__PolicySection-sc-vh5h8r-5 jBlZcv cVJMBb">
                                              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 RateCard__FullHeightFlex-sc-vh5h8r-11 djhuzv flBzdP fDGkOd">
                                                <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                                    {/* <button data-autobot-element-id="HTL_DETAIL_CANCELLATION_POLICY" aria-label="Fully Refundable" type="button" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 drkNgV CancellationPolicy__PolicyButton-sc-9419yk-1 bGQTjl" color="primary">
                                                      <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">Fully Refundable</div>
                                                    </button> */}
                                                    <div font-size="0" color="text.light" class="Text-pcln-design-system__sc-1xtb652-0 eYufAo">
                                                      <span font-weight="bold" class="Text__Span-pcln-design-system__sc-1xtb652-1 hIuQSD">{item.adults} Adults , {item.childs} children , {item.rooms_qty} Rooms </span>
                                                    </div>
                                                  </div>
                                                  {/* <div class="Box-pcln-design-system__sc-n9h3nv-0 dejomW">
                                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                                      <div class="Box-pcln-design-system__sc-n9h3nv-0 bCwlin">
                                                        <div color="text.dark" class="Text-pcln-design-system__sc-1xtb652-0 RateCard__PolicySectionText-sc-vh5h8r-13 dJIatV dhQkRP">
                                                          <div class="Text-pcln-design-system__sc-1xtb652-0 QULNm">
                                                            <span class="Text__Span-pcln-design-system__sc-1xtb652-1 ebyuif">Book now, pay later</span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div>
                                                      <span class="rtdbg" data-dbg="null-null|null:undefined|0"></span>
                                                    </div>
                                                  </div> */}
                                                </div>
                                              </div>
                                            </div>
                                            <div width="130px,45%,180px" class="Box-pcln-design-system__sc-n9h3nv-0 RateCard__PriceSection-sc-vh5h8r-10 kqTRsk fudRzz">
                                              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 RateCard__FullHeightFlex-sc-vh5h8r-11 djhuzv bvXQlp fDGkOd">
                                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 RateCard__StrikePriceFlex-sc-vh5h8r-4 djhuzv gxMehY gmaMFq"></div>
                                                <div class="Box-pcln-design-system__sc-n9h3nv-0 eEgiYO">
                                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv brHOWu">
                                                    <span class="GenericPrice__StyledWrapper-sc-ehy7jm-2 rLzYt">
                                                      <span data-testid="PRICE" font-weight="bold" color="primary.dark" font-size="24px" class="Text__Span-pcln-design-system__sc-1xtb652-1 fYqIWV">
                                                        {showPrice ?(
                                                          <span>
                                                            <sup font-size="12" class="GenericPrice__Sup-sc-ehy7jm-0 GenericPrice__CurrencySup-sc-ehy7jm-1 dURtmi bQDYAi">
                                                              <span data-autobot-element-id="RATE_CURRENCY_SYMBOL">{CurrencyRates===undefined ? (baseCName):(CurrencyRates.selectedcurrency)}</span>
                                                            </sup>{renderPrice(calculateMarkup(item.rooms_total_price))}
                                                          </span>
                                                          ):(
                                                            <span>
                                                              <sup font-size="12" class="GenericPrice__Sup-sc-ehy7jm-0 GenericPrice__CurrencySup-sc-ehy7jm-1 dURtmi bQDYAi">
                                                                <span data-autobot-element-id="RATE_CURRENCY_SYMBOL">{hoteldetail.hotel_curreny}</span>
                                                              </sup>{calculateMarkup(item.rooms_total_price)}
                                                            </span>
                                                        )}
                                                      </span>
                                                    </span>
                                                  </div>
                                                </div>
                                                <div data-unit-id="LOS_TOTAL_PRICE" font-size="0" color="text.light" class="Text-pcln-design-system__sc-1xtb652-0 gya-Dwn">Price for {daysBetween} nights</div>
                                                <div font-size="0" color="text.light" class="Text-pcln-design-system__sc-1xtb652-0 gya-Dwn">includes taxes &amp; fees</div>
                                              </div>
                                            </div>
                                            <div className=''>
                                              {item.cancliation_policy_arr &&
                                                item.cancliation_policy_arr.length > 0 ? (
                                                  new Date(
                                                    item.cancliation_policy_arr[0].from_date
                                                  ) > todayDate ? (
                                                    <p className='text-center' > <span style={{ color: 'green' }}>RISK FREE!</span> No cancellation fee before {moment(item.cancliation_policy_arr[0].from_date).format('DD-MM-YYYY')} (property local time)</p>
                                                  ) : (
                                                    <p style={{ color: 'red' }}>Non Refundable</p>
                                                  )
                                                ) : null}
                                            </div>
                                            <div width="1,1,120px" class="Box-pcln-design-system__sc-n9h3nv-0 fhgHhr d-flex">
                                            
                                              <div className='col-6'>
                                                  <label
                                                    onClick={event =>
                                                      selectedRoomData(index, event)
                                                    }
                                                    class='btn btn-default mt-2 select-room--checkbox primary px-2 py-1'
                                                  >
                                                    <i class='fa fa-fw'></i>
                                                    <input
                                                      id={index}
                                                      autoComplete='off'
                                                      className='room-check'
                                                      type='checkbox'
                                                      checked={selectedRooms.some(
                                                        room => room.index === index
                                                      )}
                                                      onChange={() => {}} // Add an empty onChange handler to prevent warnings
                                                    />
                                                    Select Room
                                                  </label>
                                              </div>
                                              <div class="col-6 Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 RateCard__FullHeightFlex-sc-vh5h8r-11 jJxSvm bkUQzO fDGkOd">
                                                <div width="100px,,1" class="Box-pcln-design-system__sc-n9h3nv-0 epKcre">
                                                  <button color="secondary" data-autobot-element-id="HTL_DETAIL_RATE_BOOK_BUTTON_ROOM" width="1" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 cesWFB RateCard__ResponsiveButton-sc-vh5h8r-9 kfOXqK" 
                                                          onClick={BookRoom}>
                                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">Book
                                                      <span class="SrOnly-pcln-design-system__sc-3hvdi4-0 jrVkbu">Deluxe King</span>
                                                    </div>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <span class="pclndbg" data-rateid="76C6371BB5E6EFE8084847ABF9CE60579E708FB611539EF50C3BC653366B1D82C5EA008B63F13A845CF9A12D98447C51BB18E74921DB093CBD96BF0D172B3CA5" aria-hidden="true"></span>
                                      </div>
                                    </div>
                                    {/* <div class="Box-pcln-design-system__sc-n9h3nv-0 RoomRowRates__RateBox-sc-sgzj44-0 jzYUts">
                                      <div class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 RateCard__WrapperCard-sc-vh5h8r-6 lkCWDC bBYTmp jorfE" data-testid="RATE_CARD">
                                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv gnbdky">
                                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 RateCard__WrapperFlex-sc-vh5h8r-7 gUaRrO bJbPHj ctFHh">
                                            <div width="40%,55%,160px" class="Box-pcln-design-system__sc-n9h3nv-0 RateCard__PolicySection-sc-vh5h8r-5 jBlZcv cVJMBb">
                                              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 RateCard__FullHeightFlex-sc-vh5h8r-11 djhuzv flBzdP fDGkOd">
                                                <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                                    <button data-autobot-element-id="HTL_DETAIL_CANCELLATION_POLICY" aria-label="Flexible Cancellation" type="button" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 drkNgV CancellationPolicy__PolicyButton-sc-9419yk-1 bGQTjl" color="primary">
                                                      <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">Flexible Cancellation</div>
                                                    </button>
                                                  </div>
                                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 dejomW">
                                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                                      <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                                        <div class="Box-pcln-design-system__sc-n9h3nv-0 bCwlin">
                                                          <div font-size="0" color="secondary.base" class="Text-pcln-design-system__sc-1xtb652-0 jwNHal">On Sale Now -&gt; Save 10% on this stay</div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div>
                                                      <span class="rtdbg" data-dbg="null-null|null:undefined|0"></span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div width="130px,45%,180px" class="Box-pcln-design-system__sc-n9h3nv-0 RateCard__PriceSection-sc-vh5h8r-10 kqTRsk fudRzz">
                                              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 RateCard__FullHeightFlex-sc-vh5h8r-11 djhuzv bvXQlp fDGkOd">
                                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 RateCard__StrikePriceFlex-sc-vh5h8r-4 djhuzv gxMehY gmaMFq">
                                                  <div font-size="1" color="pricePrimary.base" font-weight="bold" data-autobot-element-id="HTL_DETAIL_RATE_SPECIAL_DISCOUNT" class="Text-pcln-design-system__sc-1xtb652-0 bIgisn">10% Off</div>
                                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 StrikePriceWithDisclaimer__InlineFlex-sc-j8qwj9-1 djhuzv jsoPfu">
                                                    <span class="GenericPrice__StyledWrapper-sc-ehy7jm-2 rLAaV StrikePrice__StrikedPrice-sc-1fanxp3-0 iSfVyZ">
                                                      <span data-testid="PRICE" font-weight="regular" color="text.light" font-size="14px" class="Text__Span-pcln-design-system__sc-1xtb652-1 ghQdEF">
                                                        <span class="SrOnly-pcln-design-system__sc-3hvdi4-0 jrVkbu">Price marked down from original price of&nbsp;</span>
                                                        <sup font-size="8" class="GenericPrice__Sup-sc-ehy7jm-0 GenericPrice__CurrencySup-sc-ehy7jm-1 pZWSO jXcSIt">
                                                          <span data-autobot-element-id="RATE_CURRENCY_SYMBOL">$</span>
                                                        </sup>96
                                                      </span>
                                                    </span>
                                                  </div>
                                                </div>
                                                <div class="Box-pcln-design-system__sc-n9h3nv-0 qOvtC">
                                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv brHOWu">
                                                    <span class="GenericPrice__StyledWrapper-sc-ehy7jm-2 rLzYt">
                                                      <span data-testid="PRICE" font-weight="bold" color="pricePrimary.base" font-size="24px" class="Text__Span-pcln-design-system__sc-1xtb652-1 bYtFKZ">
                                                        <sup font-size="12" class="GenericPrice__Sup-sc-ehy7jm-0 GenericPrice__CurrencySup-sc-ehy7jm-1 dURtmi bQDYAi">
                                                          <span data-autobot-element-id="RATE_CURRENCY_SYMBOL">$</span>
                                                        </sup>87
                                                      </span>
                                                    </span>
                                                  </div>
                                                </div>
                                                <div data-unit-id="LOS_TOTAL_PRICE" font-size="0" color="text.light" class="Text-pcln-design-system__sc-1xtb652-0 gya-Dwn">$204.98 for 2 nights</div>
                                                <div font-size="0" color="text.light" class="Text-pcln-design-system__sc-1xtb652-0 gya-Dwn">includes taxes &amp; fees</div>
                                              </div>
                                            </div>
                                            <div className='d-flex justify-content-center'>
                                              {item.cancliation_policy_arr &&
                                                item.cancliation_policy_arr.length > 0 ? (
                                                  new Date(
                                                    item.cancliation_policy_arr[0].from_date
                                                  ) > todayDate ? (
                                                    <h6> <span style={{ color: 'green' }}>RISK FREE!</span> No cancellation fee before {moment(item.cancliation_policy_arr[0].from_date).format('DD-MM-YYYY')} (property local time)</h6>
                                                  ) : (
                                                    <h6 style={{ color: 'red' }}>Non Refundable</h6>
                                                  )
                                                ) : null}
                                            </div>
                                            <div width="1,1,120px" class="Box-pcln-design-system__sc-n9h3nv-0 fhgHhr">
                                              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 RateCard__FullHeightFlex-sc-vh5h8r-11 jJxSvm bkUQzO fDGkOd">
                                                <div width="100px,,1" class="Box-pcln-design-system__sc-n9h3nv-0 epKcre">
                                                  <button color="secondary" data-autobot-element-id="HTL_DETAIL_RATE_BOOK_BUTTON_ROOM" width="1" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 cesWFB RateCard__ResponsiveButton-sc-vh5h8r-9 kfOXqK"  onClick={BookRoom}>
                                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">Book
                                                      <span class="SrOnly-pcln-design-system__sc-3hvdi4-0 jrVkbu">Deluxe King</span>
                                                    </div>
                                                  </button>
                                                </div>
                                                <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 ichsfB">
                                                  <div font-size="0" color="text.light" class="Text-pcln-design-system__sc-1xtb652-0 bWSMKb">Limited time offer</div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <span class="pclndbg" data-rateid="576F6E757248D62978DA79AD1E8059841CEC3AE1EEF8207E4CB510565163E38D5ECB8A7C359DEAA636CA84EAD844EE5241AAA030AC36FEF45EDE865ACC41EF6854058C54A974DB05C7BBAECB666254F45D3C0611B905D493D775E75E259E0CFCF459FE3731340DC4" aria-hidden="true"></span>
                                      </div>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                           </div>
                           ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className='row'>
                <div className='col-md-2 col-sm-2'>
                  <div className='theiaStickySidebar'>
                    <div className='book-now button-4 cart-btn'>
                      <button
                        className='btn mt-1 btn-primary  btn-block select-styling search-btn1 '
                        onClick={BookRoom}
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
            </section>
            <section id='section-3'>
              <div class='property-highlights px-4'>
                <h3>Property highlights</h3>
                <div class='property-highlights__content'>
                  <div class='row'>
                    {hoteldetail.hotel_facilities.map((item, index) => (
                      <div key={index} class='col-md-3 col-6'>
                        <div class='item'>
                          <i
                            style={{ color: '#d39d00' }}
                            class='awe-icon fa fa-check'
                            aria-hidden='true'
                          >
                            <FontAwesomeIcon icon={faCheck} />
                          </i>{' '}
                          <span style={{marginLeft:'5px'}}>{item}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <hr color="border" class="Divider-pcln-design-system__sc-10weutl-0 kwmkzy"></hr>
              </div>
            </section>
            <section id='section-4'>
              <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                <div class="Box-pcln-design-system__sc-n9h3nv-0 styles__ResponsiveWrapper-sc-33r0uq-9 cMhMXY gtTPFM">
                  <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                    <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                      <h2 font-size="4" font-weight="bold" class="Text-pcln-design-system__sc-1xtb652-0 DUVRK">About the Hotel</h2>
                      <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 bXyETw QsWjo">
                        <div class="Box-pcln-design-system__sc-n9h3nv-0 DesktopInfoSection__HotelContentBox-sc-5de7n9-0 djhuzv cwMOhW">
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                            <div>
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 juJwPl">
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 DetailPanel__StyledDetailPanel-sc-xl3si0-0 kZUiTn giDoGG jgLiXo" data-testid="CARD_STYLE_TEST">
                                  <h3 font-size="2,2,2" data-unit-id="DETAILS_PANEL_TITLE" color="text.base" font-weight="bold" class="Text-pcln-design-system__sc-1xtb652-0 gJzErg">About Best Western</h3>
                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                    <div color="text.light" font-size="1" class="Text-pcln-design-system__sc-1xtb652-0 eaelwp">Best Western is a hotel brand that provides comfortable and convenient accommodations for travelers, 
                                      offering a range of options from budget-friendly to upscale. Its facilities include comfortable guest rooms, meeting and event spaces, fitness centers, and business centers. 
                                      Some hotels also have on-site restaurants or bars. Best Western's unique selling point is its loyalty program, BW Rewards, which allows members to earn points for every stay and redeem them for free nights, 
                                      gift cards, and other rewards. The brand has a broad geographical presence and its properties are often situated in convenient and accessible areas. Best Western is generally considered a mid-range hotel brand 
                                      and its target market includes business travelers, families, and leisure travelers seeking a comfortable and convenient stay at a reasonable price. Guests are generally received in a friendly and professional manner 
                                      and some hotels offer a digital check-in system and complimentary breakfast.
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 juJwPl">
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 DetailPanel__StyledDetailPanel-sc-xl3si0-0 kZUiTn giDoGG jgLiXo" data-testid="CARD_STYLE_TEST">
                                  <h3 font-size="2,2,2" data-unit-id="DETAILS_PANEL_TITLE" color="text.base" font-weight="bold" class="Text-pcln-design-system__sc-1xtb652-0 gJzErg">Sustainability</h3>
                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                    <div color="text.light" font-size="1" class="Text-pcln-design-system__sc-1xtb652-0 eaelwp">This hotel is committed to sustainability and participates in Booking.com’s Travel Sustainable program. 
                                        This means they make steps to a more sustainable world such as reducing water waste, reducing emissions, and providing locally sourced food. 
                                        <button font-size="1" color="primary" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 drkNgV">
                                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">Learn More</div>
                                        </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr color="border" class="Divider-pcln-design-system__sc-10weutl-0 CiomQ" />
                          </div>
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 DetailPanel__StyledDetailPanel-sc-xl3si0-0 kZUiTn giDoGG jgLiXo" id="hotel-policies" data-testid="CARD_STYLE_TEST">
                            <h3 font-size="2,2,2" data-unit-id="DETAILS_PANEL_TITLE" color="text.base" font-weight="bold" class="Text-pcln-design-system__sc-1xtb652-0 kpQSwr">Guest Policies</h3>
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                              <a name="HotelPolicies" aria-hidden="true"></a>
                              <table class="Tablestyles__Table-sc-heugld-2 dFUvld" data-testid="HTL_DTL_POLICY_TABLE" role="table">
                                <thead>
                                  <tr role="row">
                                    <th colspan="1" role="columnheader"></th>
                                    <th colspan="1" role="columnheader"></th>
                                  </tr>
                                </thead>
                                <tbody role="rowgroup">
                                  <tr role="row">
                                    <td role="cell" class="Tablestyles__Td-sc-heugld-1 kxYfiX">
                                      <div font-size="0" font-weight="bold" class="Text-pcln-design-system__sc-1xtb652-0 PolicyTable__LongTextLineWrap-sc-1v9tiky-0 jrtJHJ iFdUYL">Check-in</div>
                                    </td>
                                    <td role="cell" class="Tablestyles__Td-sc-heugld-1 kxYfiX">
                                      <div font-size="0" width="0.8" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 jgsqC ggTnOm">
                                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 foFPhq brHOWu">
                                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 TableRow__InlineFlex-sc-1fpjb52-0 foFPhq kAXPUW fjmVNW">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20" aria-hidden="true" fill="currentcolor" color="text" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 bEBSGl Clock__SvgClock-sc-nknuv6-0 iIVzwr">
                                              <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm.5-7.8l4.5 2.7-.8 1.2L11 13V7h1.5v5.2z"></path>
                                            </svg>
                                            <div font-size="1" font-weight="bold" class="Text-pcln-design-system__sc-1xtb652-0 iCfoxR" style={{WebkitFontSmoothing: 'antialiased'}}>{moment(ReduxSearchData.check_in).format('DD-MM-YYYY')}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr role="row">
                                    <td role="cell" class="Tablestyles__Td-sc-heugld-1 gNgLVF">
                                      <div font-size="0" font-weight="bold" class="Text-pcln-design-system__sc-1xtb652-0 PolicyTable__LongTextLineWrap-sc-1v9tiky-0 jrtJHJ iFdUYL">Check-out</div>
                                    </td>
                                    <td role="cell" class="Tablestyles__Td-sc-heugld-1 kxYfiX">
                                      <div font-size="0" width="0.8" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 jgsqC ggTnOm">
                                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 foFPhq brHOWu">
                                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 TableRow__InlineFlex-sc-1fpjb52-0 foFPhq kAXPUW fjmVNW">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20" aria-hidden="true" fill="currentcolor" color="text" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 bEBSGl Clock__SvgClock-sc-nknuv6-0 iIVzwr">
                                              <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm.5-7.8l4.5 2.7-.8 1.2L11 13V7h1.5v5.2z"></path>
                                            </svg>
                                            <div font-size="1" font-weight="bold" class="Text-pcln-design-system__sc-1xtb652-0 iCfoxR" style={{WebkitFontSmoothing: 'antialiased'}}>{moment(ReduxSearchData.check_out).format('DD-MM-YYYY')}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr role="row">
                                    <td role="cell" class="Tablestyles__Td-sc-heugld-1 kxYfiX">
                                      <div font-size="0" font-weight="bold" class="Text-pcln-design-system__sc-1xtb652-0 PolicyTable__LongTextLineWrap-sc-1v9tiky-0 jrtJHJ iFdUYL">Internet</div>
                                    </td>
                                    <td role="cell" class="Tablestyles__Td-sc-heugld-1 kxYfiX">
                                      <div font-size="0" width="0.8" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 jgsqC ggTnOm">
                                        <div font-size="1" color="text.base" class="Text-pcln-design-system__sc-1xtb652-0 fpxnDT">WiFi is available in public areas and is free of charge.</div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr role="row">
                                    <td role="cell" class="Tablestyles__Td-sc-heugld-1 gNgLVF">
                                      <div font-size="0" font-weight="bold" class="Text-pcln-design-system__sc-1xtb652-0 PolicyTable__LongTextLineWrap-sc-1v9tiky-0 jrtJHJ iFdUYL">Children &amp; Extra Beds</div>
                                    </td>
                                    <td role="cell" class="Tablestyles__Td-sc-heugld-1 kxYfiX">
                                      <div font-size="0" width="0.8" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 jgsqC ggTnOm">
                                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 bQNZnh brHOWu">
                                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 TableRow__InlineFlex-sc-1fpjb52-0 gXfUIb kAXPUW fjmVNW">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20" aria-hidden="true" fill="currentcolor" color="secondary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 cLsHZx Carriage__SvgCarriage-sc-1cm5gdd-0 cTfkbw">
                                              <path d="M13.5 2v8h8c0-4.4-3.6-8-8-8zm6.3 13.9c1.1-1.4 1.7-3.1 1.7-4.9H6.9L6 9H2.5v2h2.2s1.9 4.1 2.1 4.4C5.7 16 5 17.2 5 18.5 5 20.4 6.6 22 8.5 22c1.8 0 3.2-1.3 3.5-3h2c.2 1.7 1.7 3 3.5 3 1.9 0 3.5-1.6 3.5-3.5 0-1-.5-2-1.2-2.6zM8.5 20c-.8 0-1.5-.7-1.5-1.5S7.7 17 8.5 17s1.5.7 1.5 1.5S9.3 20 8.5 20zm9 0c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5z"></path>
                                            </svg>
                                            <div font-size="1" color="secondary" font-weight="bold" class="Text-pcln-design-system__sc-1xtb652-0 bIgisn" style={{webkitFontSmoothing: 'antialiased'}}>KIDS STAY FREE!</div>
                                          </div>
                                        </div>
                                        <div font-size="1" color="text.base" class="Text-pcln-design-system__sc-1xtb652-0 fpxnDT">Children of all ages are allowed.</div>
                                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 juJwPl ggTnOm">
                                          <div font-weight="bold" class="Text-pcln-design-system__sc-1xtb652-0 frGNoy">Extra Beds</div>
                                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 gWBVRI ggTnOm">
                                            <ul class="Tablestyles__Ul-sc-heugld-0 eJoEVm">
                                              <li class="Tablestyles__Li-sc-heugld-3 hkMLOd">Children 2 and under stay for free when using an available crib.</li>
                                              <li class="Tablestyles__Li-sc-heugld-3 hkMLOd">Children 5 and under stay for free when using an existing bed.</li>
                                              <li class="Tablestyles__Li-sc-heugld-3 hkMLOd">Children from 6 to 17 years old stay for PKR&nbsp;2,000 per person, per night when using an existing bed.</li>
                                              <li class="Tablestyles__Li-sc-heugld-3 hkMLOd">People no matter the age stay for PKR&nbsp;3,000 per person, per night when using an available extra bed.</li>
                                              <li class="Tablestyles__Li-sc-heugld-3 hkMLOd">Any type of extra bed or crib is upon request and needs to be confirmed by management.</li>
                                              <li class="Tablestyles__Li-sc-heugld-3 hkMLOd">Additional fees are not calculated automatically in the total cost and will have to be paid for separately during your stay.</li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr role="row">
                                    <td role="cell" class="Tablestyles__Td-sc-heugld-1 kxYfiX">
                                      <div font-size="0" font-weight="bold" class="Text-pcln-design-system__sc-1xtb652-0 PolicyTable__LongTextLineWrap-sc-1v9tiky-0 jrtJHJ iFdUYL">Parking</div>
                                    </td>
                                    <td role="cell" class="Tablestyles__Td-sc-heugld-1 kxYfiX">
                                      <div font-size="0" width="0.8" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 jgsqC ggTnOm">
                                        <div font-size="1" color="text.base" class="Text-pcln-design-system__sc-1xtb652-0 fpxnDT">Free private parking is available  on site (reservation is not needed).</div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr role="row">
                                    <td role="cell" class="Tablestyles__Td-sc-heugld-1 gNgLVF">
                                      <div font-size="0" font-weight="bold" class="Text-pcln-design-system__sc-1xtb652-0 PolicyTable__LongTextLineWrap-sc-1v9tiky-0 jrtJHJ iFdUYL">Pets</div>
                                    </td>
                                    <td role="cell" class="Tablestyles__Td-sc-heugld-1 kxYfiX">
                                      <div font-size="0" width="0.8" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 jgsqC ggTnOm">
                                        <div font-size="1" color="text.base" class="Text-pcln-design-system__sc-1xtb652-0 fpxnDT">Pets are not allowed.</div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr role="row">
                                    <td role="cell" class="Tablestyles__Td-sc-heugld-1 kxYfiX">
                                      <div font-size="0" font-weight="bold" class="Text-pcln-design-system__sc-1xtb652-0 PolicyTable__LongTextLineWrap-sc-1v9tiky-0 jrtJHJ iFdUYL">Room Rates</div>
                                    </td>
                                    <td role="cell" class="Tablestyles__Td-sc-heugld-1 kxYfiX">
                                      <div font-size="0" width="0.8" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 jgsqC ggTnOm">
                                        <div font-size="1" color="text.base" class="Text-pcln-design-system__sc-1xtb652-0 fpxnDT">All rates displayed are for double occupancy unless otherwise noted.</div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 fqVryd brHOWu">
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 DetailPanel__StyledDetailPanel-sc-xl3si0-0 kZUiTn giDoGG jgLiXo" data-testid="CARD_STYLE_TEST">
                              <h3 font-size="2,2,2" data-unit-id="DETAILS_PANEL_TITLE" color="text.base" font-weight="bold" class="Text-pcln-design-system__sc-1xtb652-0 fXZPlk">Important Info</h3>
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                <a name="ImportantInfo" aria-hidden="true"></a>
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                  <p font-size="1" color="text.light" class="Text__Paragraph-pcln-design-system__sc-1xtb652-2 gFoUce">A damage deposit of PKR 20000 is required on arrival. 
                                    This will be collected as a cash payment. You should be reimbursed within 7 days of check-out. Your deposit will be refunded in full by bank transfer, 
                                    subject to an inspection of the property
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id='section-6' className='px-4'>
              <hr color="border" class="Divider-pcln-design-system__sc-10weutl-0 kwmkzy"></hr>
              <h5 className='eSnpzm'>Reviews</h5>
              <div id='reviews' class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv cxaTTs">
                <div width="0.25" class="Box-pcln-design-system__sc-n9h3nv-0 bzJxON">
                  <div data-testid="PROGRESS_BAR_CONTAINER" class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 dgSpnW dJKBvC">
                      <span title="Overall Score" font-size="14px,,,16px" font-weight="700,,,700" letter-spacing="-0.003rem,," class="Text__Span-pcln-design-system__sc-1xtb652-1 ProgressBar__Title-sc-1cvkpw2-2 ervxCT hyzRiB">Overall Score</span>
                      <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv brHOWu">
                        <span font-size="1,,,2" font-weight="bold" class="Text__Span-pcln-design-system__sc-1xtb652-1 dVWxdi">
                          <div height="20,,,22" color="alert.dark" font-weight="bold" class="Box-pcln-design-system__sc-n9h3nv-0 RatingBadge-pcln-design-system__sc-1haimsy-0 ePpiEV blyaJX">8.4 </div>
                        </span>
                      </div>
                    </div>
                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK">
                      <div height="4px,,,8px" width="1" class="Box-pcln-design-system__sc-n9h3nv-0 ProgressBar__HideOverflow-sc-1cvkpw2-0 gcQYQw faiveK">
                        <div height="4px,,,8px" class="Box-pcln-design-system__sc-n9h3nv-0 ProgressBar__AnimationBox-sc-1cvkpw2-1 gRBngB esgyzg"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="Box-pcln-design-system__sc-n9h3nv-0 ReviewScales__LongBox-sc-1a8h6mt-0 djhuzv eGMzlJ">
                  <div font-weight="700" font-size="14px" class="Text-pcln-design-system__sc-1xtb652-0 jjJyTR">Other scores:</div><div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv brHOWu">
                    <div width="0.3333333333333333" class="Box-pcln-design-system__sc-n9h3nv-0 iEvZTr">
                      <div data-testid="PROGRESS_BAR_CONTAINER" class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 dgSpnW dJKBvC">
                          <span title="Staff" font-size="12px,,,14px" font-weight="700,,,700" letter-spacing="0.006rem,,,-0.003rem" class="Text__Span-pcln-design-system__sc-1xtb652-1 ProgressBar__Title-sc-1cvkpw2-2 ehOnXj hyzRiB">Facility</span>
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv brHOWu">
                            <span font-size="1" font-weight="bold" class="Text__Span-pcln-design-system__sc-1xtb652-1 bcOhvn">
                              <div font-size="12px,,,16px" font-weight="700,,,700" letter-spacing="0.006rem,," class="Text-pcln-design-system__sc-1xtb652-0 ksWPfx">8.6</div>
                            </span>
                          </div>
                        </div>
                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK">
                          <div height="4px,,,8px" width="1" class="Box-pcln-design-system__sc-n9h3nv-0 ProgressBar__HideOverflow-sc-1cvkpw2-0 gcQYQw faiveK">
                            <div height="4px,,,8px" class="Box-pcln-design-system__sc-n9h3nv-0 ProgressBar__AnimationBox-sc-1cvkpw2-1 gRBngB fbqiKi"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div width="0.3333333333333333" class="Box-pcln-design-system__sc-n9h3nv-0 kTqdvX">
                      <div data-testid="PROGRESS_BAR_CONTAINER" class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 dgSpnW dJKBvC">
                          <span title="Location" font-size="12px,,,14px" font-weight="700,,,700" letter-spacing="0.006rem,,,-0.003rem" class="Text__Span-pcln-design-system__sc-1xtb652-1 ProgressBar__Title-sc-1cvkpw2-2 ehOnXj hyzRiB">Location</span>
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv brHOWu">
                              <span font-size="1" font-weight="bold" class="Text__Span-pcln-design-system__sc-1xtb652-1 bcOhvn">
                                <div font-size="12px,,,16px" font-weight="700,,,700" letter-spacing="0.006rem,," class="Text-pcln-design-system__sc-1xtb652-0 ksWPfx">8.9</div>
                              </span>
                          </div>
                        </div>
                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK">
                          <div height="4px,,,8px" width="1" class="Box-pcln-design-system__sc-n9h3nv-0 ProgressBar__HideOverflow-sc-1cvkpw2-0 gcQYQw faiveK">
                            <div height="4px,,,8px" class="Box-pcln-design-system__sc-n9h3nv-0 ProgressBar__AnimationBox-sc-1cvkpw2-1 gRBngB hbVrgV"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div width="0.3333333333333333" class="Box-pcln-design-system__sc-n9h3nv-0 kTqdvX">
                      <div data-testid="PROGRESS_BAR_CONTAINER" class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 dgSpnW dJKBvC">
                          <span title="Cleanliness" font-size="12px,,,14px" font-weight="700,,,700" letter-spacing="0.006rem,,,-0.003rem" class="Text__Span-pcln-design-system__sc-1xtb652-1 ProgressBar__Title-sc-1cvkpw2-2 ehOnXj hyzRiB">Service</span>
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv brHOWu">
                            <span font-size="1" font-weight="bold" class="Text__Span-pcln-design-system__sc-1xtb652-1 bcOhvn">
                              <div font-size="12px,,,16px" font-weight="700,,,700" letter-spacing="0.006rem,," class="Text-pcln-design-system__sc-1xtb652-0 ksWPfx">8.8</div>
                            </span>
                          </div>
                        </div>
                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK">
                          <div height="4px,,,8px" width="1" class="Box-pcln-design-system__sc-n9h3nv-0 ProgressBar__HideOverflow-sc-1cvkpw2-0 gcQYQw faiveK">
                            <div height="4px,,,8px" class="Box-pcln-design-system__sc-n9h3nv-0 ProgressBar__AnimationBox-sc-1cvkpw2-1 gRBngB gGHvZQ"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr color="border" class="Divider-pcln-design-system__sc-10weutl-0 cwEuHu" />
              <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 ReviewsInfoSection__ReviewCardsFlex-sc-15kjpcx-1 bXyETw btBzhj homwJr">
                <div data-testid="Review_Container" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 Review__Container-sc-11g1wgz-0 cphENy ipCoSW ACSEa">
                  <div width=",,row,,0.15" class="Box-pcln-design-system__sc-n9h3nv-0 kVfdRt">
                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv ggTnOm">
                      <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 ReviewRatingBadge__NoWrapFlex-sc-13v4z94-2 djhuzv ggTnOm bEbGEP">
                        <div font-weight="bold" color="alert.base" class="Box-pcln-design-system__sc-n9h3nv-0 RatingBadge-pcln-design-system__sc-1haimsy-0 ReviewRatingBadge__GuestBadge-sc-13v4z94-0 hceaXX blyaJX diaqkO">1</div>
                      </div>
                      <span font-size="0" color="text.light" class="Text__Span-pcln-design-system__sc-1xtb652-1 ReviewRatingBadge__NoWrapText-sc-13v4z94-1 gTgcAr bIMWka">Oct 28, 2023</span>
                    </div>
                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 dejomW ggTnOm">
                      <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                        <div font-size="12px" font-weight="bold" class="Text-pcln-design-system__sc-1xtb652-0 klMbWH">Zeeshan</div>
                      </div>
                    </div>
                    <div font-size="12px" color="text.base" class="Text-pcln-design-system__sc-1xtb652-0 cyBYZx">Traveling as Couple</div>
                  </div>
                  <div width=",,row,,0.85" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 Review__Box2-sc-11g1wgz-1 lfrjsi brHOWu cAEcxZ">
                    <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 bXyETw ggTnOm">
                      <div data-testid="OUTLINE_WITH_EMOJI" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 WjkcY gSRZuX">
                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Relative-pcln-design-system__sc-us5x91-0 ReviewMessage__ReviewTextBox-sc-1gp5zn2-1 cfpzyC dgoPHI gPuaBj">
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv brHOWu">
                            <div color="success.light" width="32px" height="32px" class="Box-pcln-design-system__sc-n9h3nv-0 ReviewMessage__CircleDisk-sc-1gp5zn2-0 iYqLfI fTTUvj">
                              <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" fill="currentcolor" color="success.base" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 bnqrEj Smile__SvgSmile-sc-hlaya5-0 dxaJKp">
                                <path d="M15.65 10.675c.375 0 .694-.131.956-.394.263-.262.394-.581.394-.956s-.131-.694-.394-.956a1.302 1.302 0 00-.956-.394c-.375 0-.694.131-.956.394a1.302 1.302 0 00-.394.956c0 .375.131.694.394.956.262.263.581.394.956.394zm-7.3 0c.375 0 .694-.131.956-.394.263-.262.394-.581.394-.956s-.131-.694-.394-.956a1.302 1.302 0 00-.956-.394c-.375 0-.694.131-.956.394A1.302 1.302 0 007 9.325c0 .375.131.694.394.956.262.263.581.394.956.394zm3.65 6.8c1.1 0 2.113-.296 3.037-.888a5.09 5.09 0 002.013-2.412h-1.3a3.936 3.936 0 01-1.575 1.537 4.497 4.497 0 01-2.163.538c-.774 0-1.5-.175-2.175-.525a3.643 3.643 0 01-1.562-1.55H6.95a5.26 5.26 0 002.025 2.412 5.465 5.465 0 003.025.888zM12 22a9.738 9.738 0 01-3.9-.788 10.099 10.099 0 01-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 012 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 012.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0112 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 013.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0122 12a9.738 9.738 0 01-.788 3.9 10.098 10.098 0 01-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0112 22z"></path>
                              </svg>
                            </div>
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                              <div font-size="1" font-weight="500" color="text.light" class="Text-pcln-design-system__sc-1xtb652-0 cInCXq">What did you like most?</div>
                                <div data-testid="See_More_Content_Wrapper" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 SeeMoreContent__SeeMoreContentWrapper-sc-1m2mfs5-1 djhuzv ggTnOm kTfOYx">
                                  <div class="SeeMoreContent__TextWrapper-sc-1m2mfs5-3 cqogvA">
                                    <div font-size="1" color="text.base" data-testid="CONTEXT-TEXT-WRAPPER" class="Text-pcln-design-system__sc-1xtb652-0 SeeMoreContent__ContentText-sc-1m2mfs5-2 dBRFJj fHBNwk">
                                      <span class="Text__Span-pcln-design-system__sc-1xtb652-1 ebyuif">Very bad experience</span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </div>
                        </div>
                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Relative-pcln-design-system__sc-us5x91-0 ReviewMessage__ReviewTextBox-sc-1gp5zn2-1 kRClNC dgoPHI gPuaBj">
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv brHOWu">
                            <div color="background.light" width="32px" height="32px" class="Box-pcln-design-system__sc-n9h3nv-0 ReviewMessage__CircleDisk-sc-1gp5zn2-0 jAxqKl fTTUvj">
                              <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" fill="currentcolor" color="text.light" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 gIXjiC Frown__SvgFrown-sc-gl5ir8-0 hXarKA">
                                <path d="M15.65 10.675c.375 0 .694-.131.956-.394.263-.262.394-.581.394-.956s-.131-.694-.394-.956a1.302 1.302 0 00-.956-.394c-.375 0-.694.131-.956.394a1.302 1.302 0 00-.394.956c0 .375.131.694.394.956.262.263.581.394.956.394zm-7.3 0c.375 0 .694-.131.956-.394.263-.262.394-.581.394-.956s-.131-.694-.394-.956a1.302 1.302 0 00-.956-.394c-.375 0-.694.131-.956.394A1.302 1.302 0 007 9.325c0 .375.131.694.394.956.262.263.581.394.956.394zm3.654 2.9c-1.12 0-2.133.313-3.041.938A5.568 5.568 0 006.95 17h1.325c.367-.7.885-1.242 1.554-1.625a4.327 4.327 0 012.188-.575c.789 0 1.512.196 2.17.587.659.392 1.18.93 1.563 1.613h1.3a5.301 5.301 0 00-1.996-2.5 5.332 5.332 0 00-3.05-.925zM12 22a9.738 9.738 0 01-3.9-.788 10.099 10.099 0 01-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 012 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 012.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0112 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 013.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0122 12a9.738 9.738 0 01-.788 3.9 10.098 10.098 0 01-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0112 22z"></path>
                              </svg>
                            </div>
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                              <div font-size="1" font-weight="500" color="text.light" class="Text-pcln-design-system__sc-1xtb652-0 cInCXq">Was there anything you didn't like?</div>
                              <div data-testid="See_More_Content_Wrapper" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 SeeMoreContent__SeeMoreContentWrapper-sc-1m2mfs5-1 djhuzv ggTnOm kTfOYx">
                                <div class="SeeMoreContent__TextWrapper-sc-1m2mfs5-3 cqogvA">
                                  <div font-size="1" color="text.base" data-testid="CONTEXT-TEXT-WRAPPER" class="Text-pcln-design-system__sc-1xtb652-0 SeeMoreContent__ContentText-sc-1m2mfs5-2 dBRFJj fHBNwk">
                                    <span data-unit-id="ITALIC_CONTENT" class="Text__Span-pcln-design-system__sc-1xtb652-1 ebyuif">
                                     <i>Guest did not submit any negative comments.</i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="Box-pcln-design-system__sc-n9h3nv-0 fEdCeI">
                        <span font-size="0" color="text.base" class="Text__Span-pcln-design-system__sc-1xtb652-1 gsltyo">Verified  Guest</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-testid="Review_Container" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 Review__Container-sc-11g1wgz-0 cphENy ipCoSW ACSEa">
                  <div width=",,row,,0.15" class="Box-pcln-design-system__sc-n9h3nv-0 kVfdRt">
                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv ggTnOm">
                      <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 ReviewRatingBadge__NoWrapFlex-sc-13v4z94-2 djhuzv ggTnOm bEbGEP">
                        <div font-weight="bold" color="alert.base" class="Box-pcln-design-system__sc-n9h3nv-0 RatingBadge-pcln-design-system__sc-1haimsy-0 ReviewRatingBadge__GuestBadge-sc-13v4z94-0 hceaXX blyaJX diaqkO">8</div>
                        <span font-size="2" font-weight="bold" class="Text__Span-pcln-design-system__sc-1xtb652-1 ReviewRatingBadge__NoWrapText-sc-13v4z94-1 hFHtRf bIMWka">Very Good</span>
                      </div>
                      <span font-size="0" color="text.light" class="Text__Span-pcln-design-system__sc-1xtb652-1 ReviewRatingBadge__NoWrapText-sc-13v4z94-1 gTgcAr bIMWka">Sep 21, 2023</span>
                    </div>
                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 dejomW ggTnOm">
                      <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                        <div font-size="12px" font-weight="bold" class="Text-pcln-design-system__sc-1xtb652-0 klMbWH">Tahir</div>
                      </div>
                    </div>
                    <div font-size="12px" color="text.base" class="Text-pcln-design-system__sc-1xtb652-0 cyBYZx">Traveling as Couple</div>
                  </div>
                  <div width=",,row,,0.85" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 Review__Box2-sc-11g1wgz-1 lfrjsi brHOWu cAEcxZ">
                      <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 bXyETw ggTnOm">
                        <span font-size="2" class="Text__Span-pcln-design-system__sc-1xtb652-1 kOtbwR">This guest satisfied with their stay at the property. They rated it as very good.</span>
                        <div data-testid="OUTLINE_WITH_EMOJI" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 WjkcY gSRZuX">
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Relative-pcln-design-system__sc-us5x91-0 ReviewMessage__ReviewTextBox-sc-1gp5zn2-1 cfpzyC dgoPHI gPuaBj">
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv brHOWu">
                              <div color="success.light" width="32px" height="32px" class="Box-pcln-design-system__sc-n9h3nv-0 ReviewMessage__CircleDisk-sc-1gp5zn2-0 iYqLfI fTTUvj">
                                <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" fill="currentcolor" color="success.base" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 bnqrEj Smile__SvgSmile-sc-hlaya5-0 dxaJKp">
                                  <path d="M15.65 10.675c.375 0 .694-.131.956-.394.263-.262.394-.581.394-.956s-.131-.694-.394-.956a1.302 1.302 0 00-.956-.394c-.375 0-.694.131-.956.394a1.302 1.302 0 00-.394.956c0 .375.131.694.394.956.262.263.581.394.956.394zm-7.3 0c.375 0 .694-.131.956-.394.263-.262.394-.581.394-.956s-.131-.694-.394-.956a1.302 1.302 0 00-.956-.394c-.375 0-.694.131-.956.394A1.302 1.302 0 007 9.325c0 .375.131.694.394.956.262.263.581.394.956.394zm3.65 6.8c1.1 0 2.113-.296 3.037-.888a5.09 5.09 0 002.013-2.412h-1.3a3.936 3.936 0 01-1.575 1.537 4.497 4.497 0 01-2.163.538c-.774 0-1.5-.175-2.175-.525a3.643 3.643 0 01-1.562-1.55H6.95a5.26 5.26 0 002.025 2.412 5.465 5.465 0 003.025.888zM12 22a9.738 9.738 0 01-3.9-.788 10.099 10.099 0 01-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 012 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 012.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0112 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 013.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0122 12a9.738 9.738 0 01-.788 3.9 10.098 10.098 0 01-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0112 22z"></path>
                                </svg>
                              </div>
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                <div font-size="1" font-weight="500" color="text.light" class="Text-pcln-design-system__sc-1xtb652-0 cInCXq">What did you like most?</div>
                                <div data-testid="See_More_Content_Wrapper" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 SeeMoreContent__SeeMoreContentWrapper-sc-1m2mfs5-1 djhuzv ggTnOm kTfOYx">
                                  <div class="SeeMoreContent__TextWrapper-sc-1m2mfs5-3 cqogvA">
                                    <div font-size="1" color="text.base" data-testid="CONTEXT-TEXT-WRAPPER" class="Text-pcln-design-system__sc-1xtb652-0 SeeMoreContent__ContentText-sc-1m2mfs5-2 dBRFJj fHBNwk">
                                      <span class="Text__Span-pcln-design-system__sc-1xtb652-1 ebyuif">Room were very clean</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Relative-pcln-design-system__sc-us5x91-0 ReviewMessage__ReviewTextBox-sc-1gp5zn2-1 kRClNC dgoPHI gPuaBj">
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv brHOWu">
                              <div color="background.light" width="32px" height="32px" class="Box-pcln-design-system__sc-n9h3nv-0 ReviewMessage__CircleDisk-sc-1gp5zn2-0 jAxqKl fTTUvj">
                                <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" fill="currentcolor" color="text.light" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 gIXjiC Frown__SvgFrown-sc-gl5ir8-0 hXarKA">
                                  <path d="M15.65 10.675c.375 0 .694-.131.956-.394.263-.262.394-.581.394-.956s-.131-.694-.394-.956a1.302 1.302 0 00-.956-.394c-.375 0-.694.131-.956.394a1.302 1.302 0 00-.394.956c0 .375.131.694.394.956.262.263.581.394.956.394zm-7.3 0c.375 0 .694-.131.956-.394.263-.262.394-.581.394-.956s-.131-.694-.394-.956a1.302 1.302 0 00-.956-.394c-.375 0-.694.131-.956.394A1.302 1.302 0 007 9.325c0 .375.131.694.394.956.262.263.581.394.956.394zm3.654 2.9c-1.12 0-2.133.313-3.041.938A5.568 5.568 0 006.95 17h1.325c.367-.7.885-1.242 1.554-1.625a4.327 4.327 0 012.188-.575c.789 0 1.512.196 2.17.587.659.392 1.18.93 1.563 1.613h1.3a5.301 5.301 0 00-1.996-2.5 5.332 5.332 0 00-3.05-.925zM12 22a9.738 9.738 0 01-3.9-.788 10.099 10.099 0 01-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 012 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 012.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0112 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 013.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0122 12a9.738 9.738 0 01-.788 3.9 10.098 10.098 0 01-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0112 22z"></path>
                                </svg>
                              </div>
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                <div font-size="1" font-weight="500" color="text.light" class="Text-pcln-design-system__sc-1xtb652-0 cInCXq">Was there anything you didn't like?</div>
                                <div data-testid="See_More_Content_Wrapper" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 SeeMoreContent__SeeMoreContentWrapper-sc-1m2mfs5-1 djhuzv ggTnOm kTfOYx">
                                  <div class="SeeMoreContent__TextWrapper-sc-1m2mfs5-3 cqogvA">
                                    <div font-size="1" color="text.base" data-testid="CONTEXT-TEXT-WRAPPER" class="Text-pcln-design-system__sc-1xtb652-0 SeeMoreContent__ContentText-sc-1m2mfs5-2 dBRFJj fHBNwk">
                                      <span class="Text__Span-pcln-design-system__sc-1xtb652-1 ebyuif">Shower was not good</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="Box-pcln-design-system__sc-n9h3nv-0 fEdCeI">
                          <span font-size="0" color="text.base" class="Text__Span-pcln-design-system__sc-1xtb652-1 gsltyo">Verified  Guest</span>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 fLhwda KORie">
                <button data-autobot-element-id="HTL_DETAIL_OPEN_REVIEWS_MODAL_BOTTOM" color="primary" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 eoJzYm">
                  <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">Show All Reviews</div>
                </button>
              </div>
            </section>
            <section id='section-5' className='px-4'>
              <hr color="border" class="Divider-pcln-design-system__sc-10weutl-0 kwmkzy"></hr>
              <h2 font-size="4" font-weight="bold" data-testid="MAP_SECTION_HEADER" class="Text-pcln-design-system__sc-1xtb652-0 iQWfjd">Location</h2>
              {/* <div id="map-section" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 dSghoR cIFUSm">
                <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                  <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 bQNZnh brHOWu">
                    <div data-unit-id="BLUE_HOTEL_PIN" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 knXGhI gayMAW">
                      <div class="Box-pcln-design-system__sc-n9h3nv-0 iKucya">
                        <div data-testid="DETAIL_MAP_PIN_CONTAINER" class="Box-pcln-design-system__sc-n9h3nv-0 DetailMapPin__BoxContainer-sc-nwmsxv-0 djhuzv jxfFFn">
                          <div class="DetailMapPin__PinBox-sc-nwmsxv-1 jxwnPg">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="25px" width="25px" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 cccCQY Home__SvgHome-sc-dns7-0 styles__HomeWrapper-sc-33r0uq-4 gYMmdT jziQSv">
                              <path d="M10 20.5v-6h4v6h5v-8h3l-10-9-10 9h3v8h5z"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv ljOuYu">
                      <div font-size="1" font-weight="bold" class="Text-pcln-design-system__sc-1xtb652-0 MapSection__TextWithLineHeight-sc-1im3d1p-1 dBMjnT gKgdHj">Best Western Premier Hotel Gulberg Lahore</div>
                      <div font-size="0" class="Text-pcln-design-system__sc-1xtb652-0 MapSection__TextWithLineHeight-sc-1im3d1p-1 dwCBED gKgdAp">Square One,110b Iii Mm Alam Road Gulberg Iii Lahore, Lahore, Pakistan</div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div class='col-12 col-md-12'>
                <div style={{ width: '100%' }}>
                  <iframe
                    width='100%'
                    height='500'
                    frameborder='0'
                    scrolling='no'
                    marginheight='0'
                    marginwidth='0'
                    src={mapUrl}
                    className='rounded-4'
                  ></iframe>
                </div>
              </div>
            </section>
            <HotelCard4 hotelid={hotelid}/>
            <div class="Box-pcln-design-system__sc-n9h3nv-0 Hide-pcln-design-system__sc-i482tf-0 djhuzv juLRVt">
              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 juJwPl gayMAW">
                <a color="primary" href="/hotels" target="_blank" rel="noopener" class="Link-pcln-design-system__sc-1eidvea-0 styles__BackToListings-sc-33r0uq-1 kXpTRG hRHOFX">Back To All Hotels</a>
              </div>
            </div>
          </div>
        </div>
        </Layout>
      )}
      <div id="mybutton">
      <button  onClick={BookRoom} class="btn feedback  select-styling search-btn1 mt-2 detail-view-btn">Book Now</button>
      </div>
    </>
  )
}

export default HotelDetail
