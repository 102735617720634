import React,{useState,useEffect} from "react";
import Autocomplete from 'react-google-autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGlobe,
  faCalendar
} from '@fortawesome/free-solid-svg-icons';
import { TransferApiToken,ApiEndPoint } from "../GlobalData/GlobalData";
import DatePicker from 'react-datepicker';
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading/Loader";
import { TransferSearchResponse } from "../../Redux/Actions/actions";
import Axios from "axios";

function TransferSearch(){
  const [pickUp, setPickUp] = useState(null)
  const [dropOf, setDropOf] = useState(null)
  const [otherData, setOtherData] = useState({
    pickupDate: null,
    pickupTime: '',
    DropOffDate: null,
    DropOffTime: ''
  })
  const [tripType, setTripType] = useState('One-Way')
  const [Passenger, setPassenger] = useState('1')
  const [Vehicles, setVehicles] = useState('1')
  const [isLoading, setIsLoading] = useState(false)
  const tokenApi = TransferApiToken()
  const endpoint = ApiEndPoint()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleOtherData = event => {
    const { value, name } = event.target
    setOtherData({ ...otherData, [name]: value })
  }
  const handlePickupDate = date => {
    setOtherData({ ...otherData, pickupDate: date, DropOffDate: date })
  }
  const handleDropoffDate = date => {
    setOtherData({ ...otherData, DropOffDate: date })
  }
  const handleSelectTripType = event => {
    setTripType(event.target.value)
  }

  const handlePassenger = event => {
    setPassenger(event.target.value)
  }
  const handleVehicles = event => {
    setVehicles(event.target.value)
  }
  const searchTransfer = async () => {
    if (pickUp === null) {
      toast.info('Please Select Pickup Location.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (Object.keys(pickUp).length === 0) {
      toast.info('Please Select Pickup Location.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (dropOf === null) {
      toast.info('Please Select DropOff Location.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (Object.keys(dropOf).length === 0) {
      toast.info('Please Select DropOff Location.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (otherData.pickupDate === '' || otherData.pickupDate === null) {
      toast.info('Please Select Pickup Date.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (otherData.pickupTime === '') {
      toast.info('Please Select Pickup Time.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (otherData.DropOffDate === '') {
      toast.info('Please Select Dropoff Date.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (otherData.DropOffTime === '') {
      toast.info('Please Select Dropoff Time.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (Passenger === '') {
      toast.info('Please Enter Passenger.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (Vehicles === '') {
      toast.info('Please Enter Vehicle.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    }

    var PickupCountry
    if (
      pickUp.address_components.filter(
        f =>
          JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
      )[0]
    ) {
      PickupCountry = pickUp.address_components.filter(
        f =>
          JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
      )[0]?.long_name
    }

    var PickData = document.getElementsByName('Pickup')
    var PickupLocation = PickData[0].value
    var Picklatitude = pickUp.geometry.location.lat()
    var Picklongitude = pickUp.geometry.location.lng()
    const PickupAddress = pickUp.formatted_address

    var DropOffCountry
    if (
      dropOf.address_components.filter(
        f =>
          JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
      )[0]
    ) {
      DropOffCountry = dropOf.address_components.filter(
        f =>
          JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
      )[0]?.long_name
    }

    var DropData = document.getElementsByName('Dropoff')
    var DropoffLocation = DropData[0].value
    var Dropoflatitude = dropOf.geometry.location.lat()
    var Dropoflongitude = dropOf.geometry.location.lng()
    const DropofAddress = dropOf.formatted_address
    var data = {
      site_URL: 'https://system.alhijaztours.net/public/uploads/package_imgs',
      token: tokenApi,
      name_pickup_location_plc: PickupLocation,
      name_drop_off_location_plc: DropoffLocation,
      pick_up_date: moment(otherData.pickupDate).format('YYYY-MM-DD'),
      trip_type: tripType,
      passenger: String(Passenger),
      no_of_vehicles: String(Vehicles),
      pick_up_location_country: PickupCountry,
      startName: String(Picklatitude + ',' + Picklongitude),
      startplacename: PickupAddress,
      startplacecountrycode: PickupCountry,
      destinationName: String(Dropoflatitude + ',' + Dropoflongitude),
      destinationplacename: DropofAddress,
      destinationplacenamecountrycode: DropOffCountry,
      arrtime: formatTimeWithAMPM(otherData.pickupTime),
      retdate: moment(otherData.DropOffDate).format('YYYY-MM-DD'),
      rettime: formatTimeWithAMPM(otherData.DropOffTime)
    }
    setIsLoading(true)
    try {
      const response = await Axios.post(
        endpoint + '/api/transfers_search_react',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setIsLoading(false)
      if (response.data.message === 'Success') {
        dispatch(TransferSearchResponse(response.data))
        navigate('/transfer-search')
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error:', error)
    }
  }

  function formatTimeWithAMPM (timeString) {
    const time = moment(timeString, 'HH:mm')
    return time.format('hh:mma')
  }
    return (
        <>
        <ToastContainer/>
        {isLoading && (
          <Loading/>
        )}

        <div className='tab-content'>
                <div className='tab-pane fade show active'>
                    <div className='p-3 check-availabilty'>
                        <div className='row'>
                          <div width="1,,0.5,,0.5" class="Box-pcln-design-system__sc-n9h3nv-0 fPtMyk">
                            <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 bXyETw">
                                <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                                  <div class="sc-gEvEer iTwuwh">
                                      <div width="1" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="endLocation-typeahead-downshift-container-label" aria-controls="typeahead-dropdown" class="sc-gEvEer dsJkWl" style={{position: 'relative'}}>
                                        <div width="1" class="sc-gEvEer sc-20db91c2-0 dsJkWl gSCCYN">
                                            <div color="background.lightest" class="sc-gEvEer hILmdS">
                                                <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe">
                                                  <div width="1" class="sc-gEvEer SjeQr">
                                                    <div class="sc-gEvEer PJepm">
                                                        <div class="sc-gEvEer sc-uVWWZ kPRUfN jGcugE">
                                                          <label width="auto" for="endLocation-typeahead-downshift-container-input" id="endLocation-typeahead-downshift-container-label" color="text.light" font-size="10" font-weight="700" letter-spacing="0.2px" class="sc-fXSgeo jcqFHr"
                                                                  style={{height: '20px', transitionProperty: 'opacity', transitionDuration: '0.1s', opacity: '0', pointerEvents: 'none', position: 'relative', width: 'calc(100% - 40px)'}}>Pick-up Location?
                                                          </label>
                                                          <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe">
                                                            <div data-testid="icon-field-prefix-icons" class="sc-gEvEer sc-eqUAAy fQyDwd hIDAJO">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 aLkqs Search__SvgSearch-sc-1osw7jr-0 kHgnEk">
                                                                <path d="M15.5 14h-.8l-.3-.3c1-1.1 1.6-2.6 1.6-4.2C16 5.9 13.1 3 9.5 3S3 5.9 3 9.5 5.9 16 9.5 16c1.6 0 3.1-.6 4.2-1.6l.3.3v.8l5 5 1.5-1.5-5-5zm-6 0C7 14 5 12 5 9.5S7 5 9.5 5 14 7 14 9.5 12 14 9.5 14z"></path>
                                                                </svg>
                                                            </div>
                                                            <Autocomplete id="endLocation-typeahead-downshift-container-input" data-testid="endLocation-typeahead-input" aria-autocomplete="list" 
                                                                aria-labelledby="endLocation-typeahead-downshift-container-label" class="sc-hCPjZK hmtJkv text-start" type='text'
                                                                name='Pickup'
                                                                placeholder="Pick-up Location?" font-size="2,,1" apiKey='AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY' 
                                                                onPlaceSelected={place => {
                                                                  setPickUp(place)
                                                                }}
                                                                options={{
                                                                  types: ['establishment', 'geocode'],
                                                                  componentRestrictions: null
                                                                }}
                                                                style={{height: '56px', transitionProperty: 'paddingTop, padding-bottom' , transitionDuration: '0.1s'}}>
                                                            </Autocomplete>
                                                          </div>
                                                        </div>
                                                    </div>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                            </div>
                          </div>
                          <div width="1,,0.5,,0.5" class="Box-pcln-design-system__sc-n9h3nv-0 fPtMyk">
                            <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 bXyETw">
                                <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                                  <div class="sc-gEvEer iTwuwh">
                                      <div width="1" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="endLocation-typeahead-downshift-container-label" aria-controls="typeahead-dropdown" class="sc-gEvEer dsJkWl" style={{position: 'relative'}}>
                                        <div width="1" class="sc-gEvEer sc-20db91c2-0 dsJkWl gSCCYN">
                                            <div color="background.lightest" class="sc-gEvEer hILmdS">
                                                <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe">
                                                  <div width="1" class="sc-gEvEer SjeQr">
                                                    <div class="sc-gEvEer PJepm">
                                                        <div class="sc-gEvEer sc-uVWWZ kPRUfN jGcugE">
                                                          <label width="auto" for="endLocation-typeahead-downshift-container-input" id="endLocation-typeahead-downshift-container-label" color="text.light" font-size="10" font-weight="700" letter-spacing="0.2px" class="sc-fXSgeo jcqFHr"
                                                                  style={{height: '20px', transitionProperty: 'opacity', transitionDuration: '0.1s', opacity: '0', pointerEvents: 'none', position: 'relative', width: 'calc(100% - 40px)'}}>Drop-off Location?
                                                          </label>
                                                          <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe">
                                                            <div data-testid="icon-field-prefix-icons" class="sc-gEvEer sc-eqUAAy fQyDwd hIDAJO">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 aLkqs Search__SvgSearch-sc-1osw7jr-0 kHgnEk">
                                                                  {/* <span class="Svg-sc-12lgb6u-0 aLkqs Search__SvgSearch-sc-1osw7jr-0 kHgnEk">
                                                                    <FontAwesomeIcon icon={faGlobe} />{' '}
                                                                  </span> */}
                                                                  <path d="M15.5 14h-.8l-.3-.3c1-1.1 1.6-2.6 1.6-4.2C16 5.9 13.1 3 9.5 3S3 5.9 3 9.5 5.9 16 9.5 16c1.6 0 3.1-.6 4.2-1.6l.3.3v.8l5 5 1.5-1.5-5-5zm-6 0C7 14 5 12 5 9.5S7 5 9.5 5 14 7 14 9.5 12 14 9.5 14z"></path>
                                                                </svg>
                                                            </div>
                                                            <Autocomplete id="endLocation-typeahead-downshift-container-input" data-testid="endLocation-typeahead-input" aria-autocomplete="list" 
                                                                aria-labelledby="endLocation-typeahead-downshift-container-label" class="sc-hCPjZK hmtJkv text-start" type='text'
                                                                placeholder="Drop-off Location?" font-size="2,,1"
                                                                name='Dropoff'
                                                                apiKey='AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY' 
                                                                onPlaceSelected={place => {
                                                                  setDropOf(place)
                                                                }}
                                                                options={{
                                                                  types: ['establishment', 'geocode'],
                                                                  componentRestrictions: null
                                                                }}
                                                                style={{height: '56px', transitionProperty: 'paddingTop, padding-bottom' , transitionDuration: '0.1s'}}>
                                                            </Autocomplete>
                                                          </div>
                                                        </div>
                                                    </div>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                            </div>
                          </div>
                          <div width="1,,0.5,,0.3333333333333333" class="Box-pcln-design-system__sc-n9h3nv-0 jXiFwQ">
                              <div width="1" class="sc-gEvEer dsJkWl">
                                <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                                  <div width="1" class="sc-gEvEer sc-20db91c2-0 dsJkWl gSCCYN">
                                      <div color="background.lightest" class="sc-gEvEer hILmdS">
                                        <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe">
                                          <div width="1" class="sc-gEvEer SjeQr">
                                            <div class="sc-gEvEer PJepm">
                                              <div class="sc-gEvEer sc-uVWWZ kPRUfN jGcugE">
                                                <label for="hotelDates" width="auto" color="text.light" font-size="10" font-weight="700" letter-spacing="0.2px" class="sc-fXSgeo cAIQgh" 
                                                      style={{height: '20px', transitionProperty: 'opacity', transitionDuration: '0.1s', opacity: '1', pointerEvents: 'none', position: 'relative', width: 'calc(100% - 40px)'}}>Pick-up Date
                                                </label>
                                                <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe kpaNAY1 transfer">
                                                  <div data-testid="icon-field-prefix-icons" class="sc-gEvEer sc-eqUAAy fQyDwd hIDAJO">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 aLkqs Calendar__SvgCalendar-sc-12aq2xi-0 DXxDd">
                                                      <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"></path>
                                                    </svg>
                                                  </div>
                                                  <DatePicker
                                                    style={{border:'0 !important', marginLeft:'30px', height: '56px', paddingTop: '20px', paddingBottom: '8px', transitionProperty: 'paddingTop', transitionDuration: '0.1s'}} 
                                                    font-size="2,,1" selected={otherData.pickupDate} onChange={handlePickupDate} minDate={new Date()} placeholderText='Select Date' dateFormat='dd/MM/yyyy' type='date'
                                                    data-datepicker="true"  name='pickupDate' id="hotelDates" data-testid="hotel-date-range" aria-controls="hotel-calander-wrapper" aria-expanded="false" 
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                          </div>
                          <div width="1,,0.5,,0.3333333333333333" class="Box-pcln-design-system__sc-n9h3nv-0 jXiFwQ">
                              <div width="1" class="sc-gEvEer dsJkWl">
                                <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                                  <div width="1" class="sc-gEvEer sc-20db91c2-0 dsJkWl gSCCYN">
                                      <div color="background.lightest" class="sc-gEvEer hILmdS">
                                        <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe">
                                          <div width="1" class="sc-gEvEer SjeQr">
                                            <div class="sc-gEvEer PJepm">
                                              <div class="sc-gEvEer sc-uVWWZ kPRUfN jGcugE">
                                                <label for="hotelDates" width="auto" color="text.light" font-size="10" font-weight="700" letter-spacing="0.2px" class="sc-fXSgeo cAIQgh" 
                                                      style={{height: '20px', transitionProperty: 'opacity', transitionDuration: '0.1s', opacity: '1', pointerEvents: 'none', position: 'relative', width: 'calc(100% - 40px)'}}>Pick-up Time
                                                </label>
                                                <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe kpaNAY1 transfer">
                                                  {/* <div data-testid="icon-field-prefix-icons" class="sc-gEvEer sc-eqUAAy fQyDwd hIDAJO">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 aLkqs Calendar__SvgCalendar-sc-12aq2xi-0 DXxDd">
                                                      <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"></path>
                                                    </svg>
                                                  </div> */}
                                                  <input
                                                    type='time'
                                                    class="sc-hCPjZK hmtJkv text-start"
                                                    name='pickupTime'
                                                    value={otherData.pickupTime}
                                                    onChange={handleOtherData}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div width="1,,0.5,,0.3333333333333333" class="Box-pcln-design-system__sc-n9h3nv-0 jXiFwQ">
                              <div width="1" class="sc-gEvEer dsJkWl">
                                <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                                  <div width="1" class="sc-gEvEer sc-20db91c2-0 dsJkWl gSCCYN">
                                      <div color="background.lightest" class="sc-gEvEer hILmdS">
                                        <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe">
                                          <div width="1" class="sc-gEvEer SjeQr">
                                            <div class="sc-gEvEer PJepm">
                                              <div class="sc-gEvEer sc-uVWWZ kPRUfN jGcugE">
                                                <label for="hotelDates" width="auto" color="text.light" font-size="10" font-weight="700" letter-spacing="0.2px" class="sc-fXSgeo cAIQgh" 
                                                      style={{height: '20px', transitionProperty: 'opacity', transitionDuration: '0.1s', opacity: '1', pointerEvents: 'none', position: 'relative', width: 'calc(100% - 40px)'}}>Drop-of Date
                                                </label>
                                                <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe kpaNAY1 transfer">
                                                  <div data-testid="icon-field-prefix-icons" class="sc-gEvEer sc-eqUAAy fQyDwd hIDAJO">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 aLkqs Calendar__SvgCalendar-sc-12aq2xi-0 DXxDd">
                                                      <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"></path>
                                                    </svg>
                                                  </div>
                                                  <DatePicker
                                                    style={{border:'0 !important', marginLeft:'30px', height: '56px', paddingTop: '20px', paddingBottom: '8px', transitionProperty: 'paddingTop', transitionDuration: '0.1s'}} 
                                                    font-size="2,,1" selected={otherData.DropOffDate} onChange={handleDropoffDate} minDate={new Date()} placeholderText='Select Date' dateFormat='dd/MM/yyyy' type='date'
                                                    data-datepicker="true" name='dropoffDate' id="hotelDates" data-testid="hotel-date-range" aria-controls="hotel-calander-wrapper" aria-expanded="false" 
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                          </div>
                          <div width="1,,0.5,,0.3333333333333333" class="Box-pcln-design-system__sc-n9h3nv-0 jXiFwQ">
                              <div width="1" class="sc-gEvEer dsJkWl">
                                <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                                  <div width="1" class="sc-gEvEer sc-20db91c2-0 dsJkWl gSCCYN">
                                      <div color="background.lightest" class="sc-gEvEer hILmdS">
                                        <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe">
                                          <div width="1" class="sc-gEvEer SjeQr">
                                            <div class="sc-gEvEer PJepm">
                                              <div class="sc-gEvEer sc-uVWWZ kPRUfN jGcugE">
                                                <label for="hotelDates" width="auto" color="text.light" font-size="10" font-weight="700" letter-spacing="0.2px" class="sc-fXSgeo cAIQgh" 
                                                      style={{height: '20px', transitionProperty: 'opacity', transitionDuration: '0.1s', opacity: '1', pointerEvents: 'none', position: 'relative', width: 'calc(100% - 40px)'}}>Drop-uf Time
                                                </label>
                                                <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe kpaNAY1 transfer">
                                                  {/* <div data-testid="icon-field-prefix-icons" class="sc-gEvEer sc-eqUAAy fQyDwd hIDAJO">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 aLkqs Calendar__SvgCalendar-sc-12aq2xi-0 DXxDd">
                                                      <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"></path>
                                                    </svg>
                                                  </div> */}
                                                  <input
                                                    type='time'
                                                    class="sc-hCPjZK hmtJkv text-start"
                                                    name='DropOffTime'
                                                    value={otherData.DropOffTime}
                                                    onChange={handleOtherData}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                          </div>
                          <div width="1,,0.5,,0.3333333333333333" class="Box-pcln-design-system__sc-n9h3nv-0 jXiFwQ">
                            <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 bXyETw">
                              <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 FormField__DisableableBox-pcln-design-system__sc-tx1s9e-0 WjkcY"  style={{backgroundColor:'white'}}>
                                  <label for="cabin-class-select" color="text.light" font-size="10" font-weight="700" letter-spacing="0.2px" class="Label-pcln-design-system__sc-7sa88g-0 bhbsQA" 
                                         style={{height: '20px', transitionProperty: 'opacity', transitionDuration: '0.1s', opacity: '1', pointerEvents: 'none', position: 'relative', width: 'calc(100% - 12px)'}}>Trip Type</label>
                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK">
                                    <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 bXyETw llNdfK">
                                      <select id="cabin-class-select"  class="Select__SelectBase-pcln-design-system__sc-m07nxd-1 Ijua sc-856c5fd8-0 hQyEIt" font-size="2,,1" 
                                              value={tripType} onChange={handleSelectTripType}
                                              style={{transitionProperty: 'padding-top, padding-bottom', transitionDuration: '0.1s', paddingTop: '20px', paddingBottom: '8px'}}>
                                              <option selected value='One-Way'>One-Way</option>
                                              <option value='Return'>Return</option>
                                              <option value='All_Round'>All_Round</option>
                                      </select>
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="text.light" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 eAUkiv ChevronDown__SvgChevronDown-sc-1jf6nih-0 Select__ClickableIcon-pcln-design-system__sc-m07nxd-0 hCAiHp bWdZJj">
                                        <path d="M7.4 8l4.6 4.6L16.6 8 18 9.4l-6 6-6-6L7.4 8z"></path>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div width="1,,0.5,,0.3333333333333333" class="Box-pcln-design-system__sc-n9h3nv-0 jXiFwQ">
                            <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 bXyETw">
                              <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 FormField__DisableableBox-pcln-design-system__sc-tx1s9e-0 WjkcY"  style={{backgroundColor:'white'}}>
                                  <label for="cabin-class-select" color="text.light" font-size="10" font-weight="700" letter-spacing="0.2px" class="Label-pcln-design-system__sc-7sa88g-0 bhbsQA" 
                                         style={{height: '20px', transitionProperty: 'opacity', transitionDuration: '0.1s', opacity: '1', pointerEvents: 'none', position: 'relative', width: 'calc(100% - 12px)'}}>Passenger</label>
                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK">
                                    <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 bXyETw llNdfK">
                                      <input id="cabin-class-select"  class="Select__SelectBase-pcln-design-system__sc-m07nxd-1 Ijua sc-856c5fd8-0 hQyEIt" font-size="2,,1" 
                                              type="number" min={1} onChange={handlePassenger} value={Passenger} placeholder="Passenger"
                                              style={{transitionProperty: 'padding-top, padding-bottom', transitionDuration: '0.1s', paddingTop: '20px', paddingBottom: '8px'}}>
                                      </input>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div width="1,,0.5,,0.5" class="Box-pcln-design-system__sc-n9h3nv-0 fPtMyk">
                            <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 bXyETw">
                              <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 FormField__DisableableBox-pcln-design-system__sc-tx1s9e-0 WjkcY"  style={{backgroundColor:'white'}}>
                                  <label for="cabin-class-select" color="text.light" font-size="10" font-weight="700" letter-spacing="0.2px" class="Label-pcln-design-system__sc-7sa88g-0 bhbsQA" 
                                         style={{height: '20px', transitionProperty: 'opacity', transitionDuration: '0.1s', opacity: '1', pointerEvents: 'none', position: 'relative', width: 'calc(100% - 12px)'}}>Vehicles</label>
                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK">
                                    <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 bXyETw llNdfK">
                                      <input id="cabin-class-select"  class="Select__SelectBase-pcln-design-system__sc-m07nxd-1 Ijua sc-856c5fd8-0 hQyEIt" font-size="2,,1" 
                                              type="number" min={0} onChange={handleVehicles} value={Vehicles} placeholder="Vehicles"
                                              style={{transitionProperty: 'padding-top, padding-bottom', transitionDuration: '0.1s', paddingTop: '20px', paddingBottom: '8px'}}>
                                      </input>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div width="1,,1,,0.5" class="Box-pcln-design-system__sc-n9h3nv-0 hnrfup">
                              <button width="1" type="button" scale="1.02" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 hwEbXr sc-266892e-0 kULWIA" color="primary" onClick={searchTransfer}>
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">Find Your Transfer</div>
                              </button>
                              {/* <div font-size="0" class="Text-pcln-design-system__sc-1xtb652-0 jFIluh">Book a flight with free cancellation for flexibility</div> */}
                          </div>
                      </div>
                    </div>
                </div> 
        </div>   
        </>
    );
}

export default TransferSearch;