import React from 'react'
function Googlemap () {
  return (
    <>
      <div className='container'>
        <div className='col-12'>
          <div className='responsive-map'>
            <iframe
              src='https://maps.google.com/maps?q=365%20Flights%20%26%20Tours&t=m&z=17&output=embed&iwloc=near'
              width='600'
              height='450'
              frameborder='0'
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </>
  )
}

export default Googlemap
