import React, { useEffect, useState } from 'react'
import img1 from '../../Images/Carousal/1.jpg'
import '../../Components/Carousal/style.css'
import img2 from '../../Images/Carousal/2.jpg'
import img3 from '../../Images/Carousal/3.jpg'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import img4 from '../../Images/Carousal/4.jpg'
import Carousel from 'react-bootstrap/Carousel'
import SearchBar from '../../Components/SearchBar/Searchbar'
import Packages from '../../Components/Packages/Packages'
import agentpic from '../../Images/Home/agentpic.png'
import adimage from '../../Images/Home/hajjadnew.jpeg'
import OtherComponents from '../../Components/Hotel/OtherComponents'
import MorePackages from '../../Components/MorePackages/MorePackages'
import Layout from '../../Components/Layout/Layout'
import Loader from '../../Components/Loading/Loader'
import Helmet from 'react-helmet'
import wow from 'wowjs'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faEnvelope, faHeadphones, faMoneyBill, faPhone, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
function Home () {
  const [isLoading, setIsLoading] = useState(true)
  const [bottomAdd, setShowbottomAdd] = useState(true)
  // const [modalOpen,setModalOpen]=useState(true);
  const navigate=useNavigate();
  useEffect(() => {
    new wow.WOW().init()
    
    const fetchData = async () => {
      
      await new Promise(resolve => setTimeout(resolve, 2000))
      setIsLoading(false)
    }

    fetchData()
  }, []);

  // const toggleModal=()=>{
  //   setModalOpen(!modalOpen);
  // };

  const hideadd = () => {
    setShowbottomAdd(false)
  };
  const gotoHajjPage=()=>{
    navigate('/hajj');
  }
  return (
    <>
      <Helmet>
        <title>365 Tours - The Best Deals on Hotels, Flights and Rental Cars.</title>
      </Helmet>
      {isLoading && <Loader />}
      <Layout>
        <div className='overlay-container'>
          <div className='sc-jIGnZt jVAcwv'>
             <SearchBar />
          </div>
        </div>
        <OtherComponents />
        {/* <Packages />
        <MorePackages /> */}
      </Layout>
      {bottomAdd && (
        <div class='phone-only'>
          <div class='container position-relative'>
            <div class='phone-only-img'>
              <img
                class='phone-only-agent'
                width='68'
                height='68'
                alt='Call us at0121 777 2522'
                src={agentpic}
              />
            </div>
            <b>
              Looking for last-minute deals? Just give us a call to get
              phone-only deals!
            </b>
            <br />
            Call us at
            <a
              title='0121 777 2522'
              role='button'
              class='phonelink px-1'
              href='tel:0121 777 2522'
            >
              <span class='phone-number'>0121 777 2522</span>
            </a>
            and get assistance.
            <span class='cross-icon' onClick={hideadd}>
              ╳
            </span>
          </div>
        </div>
      )}
   
      {/* <Modal isOpen={modalOpen} className='home_modal' toggle={toggleModal} >
        <ModalHeader toggle={toggleModal}>Al Hijaz Tours</ModalHeader>
        <ModalBody>
        <img
                class='phone-only-agent'
                width='auto'
                height='auto'
                onClick={gotoHajjPage}
                alt='Call us at 0121 777 2522'
                src={adimage}
              />
                <div className='text-center   mt-2 align-self-center'>
                                <p className='phone-only-agent' style={{color:'#0000ffa1'}} onClick={gotoHajjPage}>Click For Detail</p>
                            </div>
                            
        </ModalBody>
      </Modal> */}
    </>
  )
}

export default Home
