import React, { useEffect, useState } from 'react';
import bgimage from '../../Images/Hotels/bg.jpg';
import Axios from 'axios';
import moment from 'moment';
import payment from '../../Images/Logo/payment-img.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDown,
  faArrowRight,
  faClock,
  faClockFour,
  faPlane
} from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { Stripe } from 'stripe';
import { useStripe, useElements,CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import {useNavigate } from 'react-router-dom';
import { AirLineNames } from '../../Components/Data/AirLineNames';
import { ApiEndPoint,FlightSearchToken,StripeClientSecret,Hotelapitoken } from '../../Components/GlobalData/GlobalData';
import { ToastContainer, toast } from 'react-toastify';
import Layout from '../../Components/Layout/Layout';
import HotelSearch from '../../Components/SearchBar/HotelSearch';
var Totalsum=0;
var Currency='';
var ConverterTotalSum=0;
var StripePayment=0;
var StripeCurrency='';
var flighttotal=0;
var hotaltotal=0;
var Gbpexhangeprice=0;
var admingbpprice=0;
var exchangerateprice=0;

function BookRoom (){
  var MarkupSum=0;
  var ConverterCurrency='';
  var Secretkey=StripeClientSecret();
  const stripe = new Stripe(Secretkey);
  const elements = useElements();
  const stripeInstance = useStripe();
  const [error, setError] = useState(null);
  const navigate=useNavigate();
  var endpoint=ApiEndPoint();
  let AdultAmount=0;
  let ChildAmount=0;
  let InfantAmount=0;
  var Airlinenamesdata = AirLineNames;
  const Search_response = useSelector( state => state.hotels.OneWayFlight);
  const homesearch = useSelector(state => state.hotels.hsearch);
  const IndexPageSearchData = useSelector( state => state.hotels.OneWayFlightSearchData);
  const adultCount = homesearch.adult;
  const childCount = homesearch.child;
  var infantCount;
  if(IndexPageSearchData !== undefined){
    infantCount=IndexPageSearchData.infant;
  };
  const [gbpPrices, setGbpPrices] = useState({adult:'',child:'',infant:'',adultqty:'',childqty:'',infantqty:''});
  const [isChecked, setIsChecked] = useState(false);
  const [otherGuestData, setOtherGuestData] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [countryListsimple, setCountryListsimple] = useState([]);
  // const [adminPrices, setAdminPrices] = useState({gbpprice:0,adminPrice:0});
  const [otherDiv, setOtherDiv] = useState({adult:'',child:'',infant:''});
  const [baseCName, setBaseCName] = useState('GBP');
  const [ConversionRates, setConversionRates] = useState({gbpRate:'',exchangeRate:''});
  const [showPrice, setShowPrice] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCountryother, setSelectedCountryother] = useState(null);
  const [exchangemarkup, setExchangemarkup] = useState({admin:0,client:0});
  const [totalperson, setTotalperson] = useState({adult:'',child:'',infant:'',adultprice:'',childprice:'',infantprice:''});
  const [savemarkup, setSavemarkup] = useState({totalroomprice:0,admin:0,client:0,final:0});
  const [selectedNationality, setSelectedNationality] = useState('');
  const [gestinfo, setGestinfo] = useState({
    title:'',
    firstname: '',
    lastname: '',
    dateofbirth: '',
    numbercode:'',
    email: '',
    phonenumber: '',
    gender:'',
    pno:'',
    pexpiry:'',
    country:'',
    nationality:'',
    postalcode:''
  });
  const [cardInfo, setCardInfo] = useState({
    name: '',
    cardnumber: '',
    mm: '',
    yy: '',
    cvv: ''
  });
  const [isBooking, setIsBooking] = useState(false);
  const [guests, setGuests] = useState(Array(adultCount).fill({}));
  const [childs, setChilds] = useState(Array(childCount).fill({}));
  const [infants, setinfants] = useState(Array(infantCount).fill({}));
  const checkoutdetail = useSelector(state => state.hotels.checkoutdetail)
  console.log(checkoutdetail);
  const hotelimg = useSelector(state => state.hotels.hoteldetail.hotel_gallery[0]);
  let Checkin = moment(checkoutdetail?.checkIn);
  let checkout = moment(checkoutdetail?.checkOut);
  let daysBetween = Math.abs(checkout.diff(Checkin, 'days'));
  const childsArray = Array.from({ length: childCount });
  const adultsArray = Array.from({ length: adultCount-1 });
  const infantsArray = Array.from({ length: infantCount });
  const FlightCurrency = useSelector( state => state.hotels.FlightCurrency);
  const CheckoutFlightData = useSelector( state => state.hotels.OneWayFlightcheckout);
  const storedData = JSON.parse(sessionStorage.getItem('FlightCheckOut'));
  var FlightMarkup=JSON.parse( localStorage.getItem('FlightMarkup'));
  useEffect(() => {
    fetchData();
    fetchData2();
    if(storedData !== null){
     Totalsum=calculateSum();
  }
    totalcount();
  }, []);

  const CurrencyRates = useSelector(state => state.hotels.Currency);
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency);
  async function fetchData () {
    Axios.get('https://restcountries.com/v3.1/all?fields=name,flags,cca2,cca3,idd')
          .then(response => {
            const countryOptions = response?.data.map((country) => ({
                value:country.name.common,
                label: country.name.common,
                flag: country.flags.png,
                phoneCode: country.idd.root+country.idd.suffixes[0],
              }));
            setCountryList(countryOptions)
          })
          .catch(error => {
            console.error('Error:', error)
          })
  };
  async function fetchData2 () {
    Axios.get(endpoint+'/api/countries/fetch')
      .then(response => {
        setCountryListsimple(response.data.countries)
      })
      .catch(error => {
        console.error('Error:', error)
      })
  };
  const totalcount=()=>{ 
    
   let allroomsprice=0;
  let markupprice=0;
  let adminmarkupprice=0;
  let clientmarkupprice=0;
  let finalpricemarkup=0;
   allroomsprice= checkoutdetail.rooms_list.reduce((sum,item)=>sum+Number(item.rooms_total_price),0);
   savemarkup.totalroomprice=allroomsprice;
   finalpricemarkup=allroomsprice;
   if(Number(checkoutdetail.admin_markup) !== 0)
   {
    if(checkoutdetail.admin_markup_type === "Percentage")
    {
      markupprice=( allroomsprice * Number(checkoutdetail.admin_markup))/100;
    }else{
      markupprice= Number(checkoutdetail.admin_markup);
     }
      adminmarkupprice=markupprice;
      finalpricemarkup +=markupprice
      savemarkup.admin=adminmarkupprice;
      savemarkup.final=finalpricemarkup;
   }
   if(Number(checkoutdetail.customer_markup) !== 0)
   {
    if(checkoutdetail.customer_markup_type === "Percentage")
    {
      markupprice= (finalpricemarkup * Number(checkoutdetail.customer_markup))/100;
    }else{
      markupprice= Number(checkoutdetail.customer_markup);
     }
     clientmarkupprice=markupprice;
      finalpricemarkup +=markupprice;
      savemarkup.client=clientmarkupprice;
      savemarkup.final=finalpricemarkup;

   }
   else{
    savemarkup.final=allroomsprice;

   }
  };
  // const handleSelectChange = (e, selectedIndex,condition) => {
  //   if(condition===true){
  //     var selectcountry = countryList[selectedIndex - 1]
  //   setSelectedNationality(e.target.value)
  //   setGestinfo(prevdata=>({...prevdata, phonenumber: '+' + selectcountry.phonecode, numbercode:selectcountry.phonecode,nationality:e.target.value }))
  //   }else if(condition===false){
     
  //     var selectcountry = countryList[selectedIndex - 1]
  //     setGestinfo(prevdata => ({
  //       ...prevdata,
  //       country: e.target.value,
  //     }))
  //   }
   
  // };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
     setSelectedNationality(selectedOption.value);
    setGestinfo(prevdata=>({...prevdata, phonenumber:selectedOption.phoneCode, numbercode:selectedOption.phoneCode,nationality:selectedOption.value }));
  };
  const handleCountryChangeother = (selectedOption) => {
    setSelectedCountryother(selectedOption);
    setGestinfo(prevdata => ({
      ...prevdata,
      country: selectedOption.value,
    }))
  };
  const handlePayment = async () => {
    if(cardInfo.name===""){
      setError("Enter Card Holder Name.");
      return { success: false};
    };
    const response = await stripe.paymentIntents.create({
      amount: Number(StripePayment)*100, // Amount in cents
      currency: StripeCurrency,
      description: 'Example payment',
      payment_method_types: ['card'],
    });
    var Client_Secret= response.client_secret;
    
    try {
      // Confirm the payment intent with the payment method
      const { paymentIntent, error } = await stripeInstance.confirmCardPayment(Client_Secret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name:cardInfo.name,
          },
        },
      });

      if (error) {
         setError(error.message);
        console.error(error);
        setIsBooking(!isBooking);
        return { success: false};
      } else {
        return { success: true,data:paymentIntent};
      }
    } catch (error) {
      console.error('Error:', error);
    
    setIsBooking(!isBooking);

      return { success: false};
    }
  };

  const calculateSum = () => {
    const pricedItinerary = storedData.PricedItineraries[0];
    if (pricedItinerary && pricedItinerary.AirItineraryPricingInfo) {
      const fareBreakdowns = pricedItinerary.AirItineraryPricingInfo.PTC_FareBreakdowns;
     Currency=fareBreakdowns[0].PassengerFare.TotalFare.CurrencyCode;
      const sum = fareBreakdowns.reduce((accumulator, item) => {
        var Code=item.PassengerTypeQuantity.Code;
        var x=item.PassengerTypeQuantity.Quantity;
        switch (Code) {
          case 'ADT':
              setTotalperson(prevdata => ({
                  ...prevdata,
                  adult: x,
                 adultprice:item.PassengerFare.TotalFare.Amount
                }))
                setOtherDiv(prevdata => ({
                  ...prevdata,
                  adult: x-1,
                
                }))
            break;
          case 'CHD':
              setTotalperson(prevdata => ({
                  ...prevdata,
                  child: x,
                  childprice:item.PassengerFare.TotalFare.Amount
                }))
                setOtherDiv(prevdata => ({
                  ...prevdata,
                  child: x,
                }))
            break;
          case 'INF':
              setTotalperson(prevdata => ({
                  ...prevdata,
                  infant: x,
                  infantprice:item.PassengerFare.TotalFare.Amount
                }));
                setOtherDiv(prevdata => ({
                  ...prevdata,
                  infant: x,
                }))
            break;
          default:
            break;
        }
       
        return accumulator + (Number(item.PassengerFare.TotalFare.Amount)*item.PassengerTypeQuantity.Quantity);
      }, 0);
      return sum.toFixed(2);
    } else {
      return 0; // Handle the case where the data is missing or empty
    }
  };

  const calculateMarkup = (price) => {
    if(Object.keys(checkoutdetail).length !==0){
    let markupprice=0;
    let adminmarkupprice=0;
    let clientmarkupprice=0;
    let finalpricemarkup=Number(price);
    if(Number(checkoutdetail.admin_markup) !== 0)
   {
    if(checkoutdetail.admin_markup_type === "Percentage")
    {
      markupprice=( price * Number(checkoutdetail.admin_markup))/100;
    }else{
      markupprice= Number(checkoutdetail.admin_markup);
     }
      adminmarkupprice=markupprice;
      finalpricemarkup +=markupprice
      
   }
   if(Number(checkoutdetail.customer_markup) !== 0)
   {
    if(checkoutdetail.customer_markup_type=== "Percentage")
    {
      markupprice= (finalpricemarkup * Number(checkoutdetail.customer_markup))/100;
    }else{
      markupprice= Number(checkoutdetail.customer_markup);
     }
     clientmarkupprice=markupprice;
      finalpricemarkup +=markupprice;
   }
   return finalpricemarkup.toFixed(2);
  }
  };
  

const handlegestchange=(e)=>{
  const {value,name}=e.target;
  setGestinfo(prevgestinfo=>({...prevgestinfo,
    [name]:value,
  }));
};
const confirmbooking=async()=>{
if(gestinfo.title==='')
{
  toast.error('Please Select Lead Guest Title.', {
    position: toast.POSITION.TOP_RIGHT
});
return;
}else if(gestinfo.firstname===''){
  toast.error('Please Enter Lead Guest First Name.', {
    position: toast.POSITION.TOP_RIGHT
});
return;
}else if(gestinfo.lastname===''){
  toast.error('Please Enter Lead Guest Last Name.', {
    position: toast.POSITION.TOP_RIGHT
});
return;
}else if(gestinfo.dateofbirth===''){
  toast.error('Please Enter Lead Guest Date of Birth.', {
    position: toast.POSITION.TOP_RIGHT
});
return;
}else if(gestinfo.email===''){
  toast.error('Please Enter Lead Guest Email.', {
    position: toast.POSITION.TOP_RIGHT
});
return;
}else if(selectedNationality===''){
  toast.error('Please Select Lead Guest Nationality.', {
    position: toast.POSITION.TOP_RIGHT
});
return;
}

 if(isChecked){
  setIsBooking(true);
  if(storedData !==null){
    BothServicesBooking();
    return;
  }
  setIsBooking(true);
   var status=await handlePayment();
      if(status.success===false){
        setIsBooking(false);
        return;
      };
      var title=[];
      var firstname=[];
      var lastname=[];
      var nationality=[];
      if(guests.length!==0){
        guests.forEach(person => {
          if (person.other_title && person.other_first_name) {
          title.push(person.other_title);
          firstname.push(person.other_first_name);
          lastname.push(person.other_last_name);
          nationality.push(person.other_passport_country);
          }
       });
      };
      var Childtitle=[];
      var Childfirstname=[];
      var Childlastname=[];
      var Childnationality=[];
      if(childs.length!==0){
        childs.forEach(children => {
          if (children.title && children.firstName) {
            Childtitle.push(children.title);
            Childfirstname.push(children.firstName);
            Childlastname.push(children.lastName);
            Childnationality.push(children.nationality );
          }
       });
    
      };
  const jsonString = {
    "lead_title":gestinfo.title,
    "lead_first_name": gestinfo.firstname,
    "lead_last_name": gestinfo.lastname,
    "lead_email": gestinfo.email,
    "lead_date_of_birth": gestinfo.dateofbirth,
    "lead_country": selectedNationality,
    "lead_phone": gestinfo.phonenumber,
    "other_title":title,
    "other_first_name":firstname,
    "other_last_name": lastname,
    "other_nationality": nationality,
    "child_title":Childtitle,
    "child_first_name":Childfirstname,
    "child_last_name": Childlastname,
    "child_nationality": Childnationality,
    "slc_pyment_method": "slc_stripe",
    "name": "on",
    "base_exchange_rate": "1",
    "base_currency":'GBP',
    "selected_exchange_rate": '1',
    "exchange_price":StripePayment,
    "admin_markup": checkoutdetail.admin_markup,
    "client_markup": checkoutdetail.customer_markup,
    "exchange_currency": StripeCurrency,
  };
  const apitoken=Hotelapitoken();
  const customersearch={
    "token":apitoken,
    "city_name":checkoutdetail.destinationName,
    "destination": checkoutdetail.destinationName,
    "room_searching":homesearch.room,
    "child_searching": homesearch.child,
    "adult_searching": homesearch.adult,
    "adult_per_room": homesearch.adult,
    "child_per_room": homesearch.children,
    "country_nationality": homesearch.slc_nationality,
    "check_in": homesearch.check_in,
    "check_out": homesearch.check_out,
    "request_all_data":JSON.stringify(homesearch)
  };  

  const phpArray = {
    "token":apitoken,
    "lead_title":gestinfo.title,
    'admin_exchange_currency':'GBP',
    'admin_exchange_rate':Gbpexhangeprice.toFixed(4),
    'admin_exchange_total_markup_price':admingbpprice,
    "lead_first_name": gestinfo.firstname,
    "lead_last_name": gestinfo.lastname,
    "lead_email": gestinfo.email,
    "lead_date_of_birth": gestinfo.dateofbirth,
    "lead_country": selectedNationality,
    "lead_phone": gestinfo.phonenumber,
    "other_title":title,
    "other_first_name":firstname,
    "other_last_name": lastname,
    "other_nationality": nationality,
    "slc_pyment_method": "slc_stripe",
    'payment_details': JSON.stringify(status.data),
   "base_exchange_rate": Gbpexhangeprice,
    "base_currency":'GBP',
    "selected_exchange_rate": exchangerateprice,
    "selected_currency": StripeCurrency,
   "exchange_price":StripePayment,
   "admin_markup": checkoutdetail.admin_markup,
   "client_markup": checkoutdetail.customer_markup,
    "exchange_currency": StripeCurrency,
    "request_data": JSON.stringify(jsonString),
    "creditAmount":StripePayment,
"hotel_checkout_select":JSON.stringify(checkoutdetail),
"customer_search_data":JSON.stringify(customersearch),
  };
  // _Live
  try {
  const response = await Axios.post(endpoint+'/api/hotels/reservation_Live',phpArray , {
    headers: {
      "Access-Control-Allow-Origin": "*",
      // Required for CORS support to work
       "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
      "Access-Control-Allow-Headers":
      "Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale",
      // "Access-Control-Allow-Methods": "POST, OPTIONS"
    } ,
    
  });

  // Handle the API response here
  setIsBooking(false);
  if(response.data.message==='Internal server error')
  {
    toast.error('Please Search Hotel Again.', {
      position: toast.POSITION.TOP_RIGHT
  });
  }else if(response.data.message==='Insufficient allotment'){
    toast.error('Insufficient allotment.', {
      position: toast.POSITION.TOP_RIGHT
  });
  }else if(response.data.status==='error'){
    toast.error(response.data.message, {
      position: toast.POSITION.TOP_RIGHT
  });
  }else if(response.data.status==="success"){
    toast.success('Your Bookinf is Confirmed.', {
      position: toast.POSITION.TOP_RIGHT
  });
  navigate(`/hotel_booking_invoice/${response.data.Invoice_id}`)

  }
  // navigate(`/hotel_detail/${id}`,{state:{index}})
} catch (error) {
  // Handle errors here
  setIsBooking(false);
  console.error('Error:', error);
}
 
}else{
  toast.error('Please Agree with Terms and Conditions.', {
    position: toast.POSITION.TOP_RIGHT
});
}
};
const handlecarddetail=(e)=>{
  const {value,name}=e.target;
  setCardInfo(prevcardinfo=>({...prevcardinfo,
  [name]:value,
  }));
};
const handleCheckboxContinue=()=>{
  setIsChecked(!isChecked);
};
const otherGuestInfo = (e, guestIndex,digit) => {
  
  const selectedValue = e.target.value;
  if(digit===2){
    setChilds((prevChilds) => {
      const updatedChilds = [...prevChilds];
      updatedChilds[guestIndex] = {
        ...updatedChilds[guestIndex],
        title: selectedValue,
      };
      return updatedChilds;
    });
  }else if(digit===1){
    setGuests((prevGuests) => {
      const updatedGuests = [...prevGuests];
      updatedGuests[guestIndex] = {
        ...updatedGuests[guestIndex],
        other_title: selectedValue,
      };
      return updatedGuests;
    });
  }
  else if(digit===3){
    setinfants((prevInfants) => {
      const updatedGuests = [...prevInfants];
      updatedGuests[guestIndex] = {
        ...updatedGuests[guestIndex],
        title: selectedValue,
      };
      return updatedGuests;
    });
  }
    
};
const otherGuestFirstName = (e, guestIndex,digit) => {
  // Handle the first name input and update the state
  const firstName = e.target.value;
  if(digit===2){
    setChilds((prevChilds) => {
      const updatedChilds = [...prevChilds];
      updatedChilds[guestIndex] = {
        ...updatedChilds[guestIndex],
        firstName: firstName,
      };
      return updatedChilds;
    });
  }else if(digit===1){
  setGuests((prevGuests) => {
    const updatedGuests = [...prevGuests];
    updatedGuests[guestIndex] = {
      ...updatedGuests[guestIndex],
      other_first_name: firstName,
    };
    return updatedGuests;
  });
}else if(digit===3){
  setinfants((prevInfant) => {
    const updatedGuests = [...prevInfant];
    updatedGuests[guestIndex] = {
      ...updatedGuests[guestIndex],
      firstName: firstName,
    };
    return updatedGuests;
  });
}
};
const otherGuestLastName = (e, guestIndex,digit) => {
  // Handle the last name input and update the state
  const lastName = e.target.value;
  if(digit===2){
    setChilds((prevChilds) => {
      const updatedChilds = [...prevChilds];
      updatedChilds[guestIndex] = {
        ...updatedChilds[guestIndex],
        lastName: lastName,
      };
      return updatedChilds;
    });
  }else if(digit===1){
    setGuests((prevGuests) => {
      const updatedGuests = [...prevGuests];
      updatedGuests[guestIndex] = {
        ...updatedGuests[guestIndex],
        other_last_name: lastName,
      };
      return updatedGuests;
    });
  }else if(digit===3){
    setinfants((prevInfant) => {
      const updatedGuests = [...prevInfant];
      updatedGuests[guestIndex] = {
        ...updatedGuests[guestIndex],
        lastName: lastName,
      };
      return updatedGuests;
    });
  }
};
const otherGuestdob = (e, guestIndex,digit) => {
  // Handle the last name input and update the state
  const other_dob = e.target.value;
  if(digit===2){
    setChilds((prevChilds) => {
      const updatedChilds = [...prevChilds];
      updatedChilds[guestIndex] = {
        ...updatedChilds[guestIndex],
        dob: other_dob,
      };
      return updatedChilds;
    });
  }else if(digit===1){
    setGuests((prevGuests) => {
      const updatedGuests = [...prevGuests];
      updatedGuests[guestIndex] = {
        ...updatedGuests[guestIndex],
        other_date_of_birth: other_dob,
      };
      return updatedGuests;
    });
  }
  else if(digit===3){
    setinfants((prevInfant) => {
      const updatedGuests = [...prevInfant];
      updatedGuests[guestIndex] = {
        ...updatedGuests[guestIndex],
        dob: other_dob,
      };
      return updatedGuests;
    });
  }
};
const otherGuestGender = (e, guestIndex,digit) => {
  // Handle the last name input and update the state
  const other_g = e.target.value;
  if(digit===2){
    setChilds((prevChilds) => {
      const updatedChilds = [...prevChilds];
      updatedChilds[guestIndex] = {
        ...updatedChilds[guestIndex],
        gender: other_g,
      };
      return updatedChilds;
    });
  }else if(digit===1){
    setGuests((prevGuests) => {
      const updatedGuests = [...prevGuests];
      updatedGuests[guestIndex] = {
        ...updatedGuests[guestIndex],
        other_gender: other_g,
      };
      return updatedGuests;
    });
  }else if(digit===3){
    setinfants((prevInfant) => {
      const updatedGuests = [...prevInfant];
      updatedGuests[guestIndex] = {
        ...updatedGuests[guestIndex],
        gender: other_g,
      };
      return updatedGuests;
    });
  }
};
const otherGuestPNumber = (e, guestIndex,digit) => {
  // Handle the last name input and update the state
  const value= e.target.value;
  if(digit===2){
    setChilds((prevChilds) => {
      const updatedChilds = [...prevChilds];
      updatedChilds[guestIndex] = {
        ...updatedChilds[guestIndex],
        pnumber: value,
      };
      return updatedChilds;
    });
  }else if(digit===1){
    setGuests((prevGuests) => {
      const updatedGuests = [...prevGuests];
      updatedGuests[guestIndex] = {
        ...updatedGuests[guestIndex],
        other_passport_no: value,
      };
      return updatedGuests;
    });
  }else if(digit===3){
    setinfants((prevInfant) => {
      const updatedGuests = [...prevInfant];
      updatedGuests[guestIndex] = {
        ...updatedGuests[guestIndex],
        pnumber: value,
      };
      return updatedGuests;
    });
  }
};

const otherGuestPExpiry = (e, guestIndex,digit) => {
  // Handle the last name input and update the state
  const value= e.target.value;
  if(digit===2){
    setChilds((prevChilds) => {
      const updatedChilds = [...prevChilds];
      updatedChilds[guestIndex] = {
        ...updatedChilds[guestIndex],
        pexpiry: value,
      };
      return updatedChilds;
    });
  }else if(digit===1){
    setGuests((prevGuests) => {
      const updatedGuests = [...prevGuests];
      updatedGuests[guestIndex] = {
        ...updatedGuests[guestIndex],
        other_passport_expiry_date: value,
      };
      return updatedGuests;
    });
  }else if(digit===3){
    setinfants((prevInfants) => {
      const updatedGuests = [...prevInfants];
      updatedGuests[guestIndex] = {
        ...updatedGuests[guestIndex],
        pexpiry: value,
      };
      return updatedGuests;
    });
  }
};
const otherGuestNationality = (e, guestIndex,digit) => {
  // Handle the last name input and update the state
  const newvalue = e.target.value;
  if(digit===2){
    setChilds((prevChilds) => {
      const updatedChilds = [...prevChilds];
      updatedChilds[guestIndex] = {
        ...updatedChilds[guestIndex],
        nationality: newvalue,
      };
      return updatedChilds;
    });
  }else if(digit===1){
  setGuests((prevGuests) => {
    const updatedGuests = [...prevGuests];
    updatedGuests[guestIndex] = {
      ...updatedGuests[guestIndex],
      other_passport_country: newvalue,
    };
    return updatedGuests;
  });
}else if(digit===3){
  setinfants((prevInfants) => {
    const updatedGuests = [...prevInfants];
    updatedGuests[guestIndex] = {
      ...updatedGuests[guestIndex],
      nationality: newvalue,
    };
    return updatedGuests;
  });
}
};
const renderPrice = (price,check) =>{
  const userData = localStorage.getItem('HotelCurrency');
  const Rates = JSON.parse(userData);
  if(userData !==null){
  if(CurrencyRates===undefined){
    const gbpprice = Rates.conversion_rates[baseCName]; // Use square brackets to access the property
    // setConversionRates({gbpRate:gbpprice});
    var baseprice = (Number(gbpprice) * Number(price)).toFixed(0);
    StripeCurrency=baseCName;
    Gbpexhangeprice=gbpprice;
    admingbpprice=baseprice
  }else{
    var select123 = CurrencyRates.selectedcurrency;
    StripeCurrency=select123;
    const gbpprice = Rates.conversion_rates[baseCName];
    var baseprice1 = (Number(gbpprice) * Number(price)).toFixed(0);
    const gbpprice2 = GBPCurrencyRates.conversion_rates[select123]; // Use square brackets to access the property
    var baseprice = (Number(gbpprice2) * Number(baseprice1)).toFixed(0);
    Gbpexhangeprice=gbpprice;
    admingbpprice=baseprice1;
    exchangerateprice=gbpprice2;
    // setConversionRates({gbpRate:gbpprice,exchangeRate:gbpprice2});

  }
  // if(check===1){
  //   StripePayment=baseprice;
  // }
  return baseprice
}else{
  setShowPrice(false);
}
};

const renderPrice2 = (price,qty,check) => {
  
  if(FlightCurrency !==undefined){
  if (CurrencyRates === undefined) {
    const gbpprice = FlightCurrency[baseCName] // Use square brackets to access the property
    var baseprice = (Number(gbpprice) * Number(price))
      
    if(check===2){
      gbpPrices.adult=baseprice;
      gbpPrices.adultqty=qty;
    }else if(check===3){
      gbpPrices.child=baseprice;
      gbpPrices.childqty=qty;
    }else if(check===4){
      gbpPrices.infant=baseprice;
      gbpPrices.infantqty=qty;
    };

    StripeCurrency=baseCName;

  } else {
    var select123 = CurrencyRates.selectedcurrency
    const gbpprice = FlightCurrency[baseCName]
    var baseprice1 = (Number(gbpprice) * Number(price))
    if(check===2){
      gbpPrices.adult=baseprice1;
      gbpPrices.adultqty=qty;
    }else if(check===3){
      gbpPrices.child=baseprice1;
      gbpPrices.childqty=qty;
    }else if(check===4){
      gbpPrices.infant=baseprice1;
      gbpPrices.infantqty=qty;
    };
    const gbpprice2 = GBPCurrencyRates.conversion_rates[select123] // Use square brackets to access the property
    var baseprice = (Number(gbpprice2) * Number(baseprice1))
  }
}else{
  setShowPrice(false);
};
  return baseprice
};

const ShowOtherGuestForm=()=>{
  setOtherGuestData(!otherGuestData);
};
const BothServicesBooking= async()=>{
//   debugger
//   var check=0;
//   if(adultCount-1 !==0){
//     for (let i = 0; i < adultsArray.length; i++) {
//      if (!validateAdultData(adultsArray[i], i)) {
//        // Validation failed for at least one adult, handle accordingly
//        check=1;
//        return;
//      }
//    }
//  }
//    if(check==1){
//      return;
//     }
//    check=0;
//    if(childCount !==''){
//    for (let i = 0; i < childsArray.length; i++) {
//      if (!validateChildData(childsArray[i], i)) {
//        // Validation failed for at least one adult, handle accordingly
//        check=1;
//        return;
//      }
//    }
//  }
//    if(check==1){
//      return;
//     }
var token=FlightSearchToken();
var limit={
  'token':token
}
var limitcheck;
try {
  const response = await Axios.post(endpoint+'/api/flight_credit_limit_Live', limit, {
    headers: {
      "Access-Control-Allow-Origin": "*",
    } ,
    
  });
  limitcheck=response.data;
  // Handle the API response here
 
} catch (error) {
  // Handle errors here
  setIsBooking(false);
  console.error('Error:', error);
}

if(Number(flighttotal) > Number(limitcheck.credit_data.remaining_amount)){
  toast.info('There is a problem in the payment, Please contact Support or try again later on.', {
    position: toast.POSITION.TOP_RIGHT
  });
  setIsBooking(false);
  return;
  

}else{
  var status=await handlePayment();
      if(status.success===false){
        setIsBooking(false);
        return;
      };
      var adminmarkup='';
    var clientmarkup='';

    for(var i=0;i < FlightMarkup.markups.length;i++){
      if(FlightMarkup.markups[i].services_type==='flight'){
        if(FlightMarkup.markups[i].added_markup=='synchtravel'){
          adminmarkup=FlightMarkup.markups[i];
        }else if(FlightMarkup.markups[i].added_markup=='alhijaz'){
          clientmarkup=FlightMarkup.markups[i];
        }
      }
    };
  var leadpassengerdetail= {
    "title":gestinfo.title,
    "first_name": gestinfo.firstname,
    "last_name": gestinfo.lastname,
    "email": gestinfo.email,
    "date_of_birth":gestinfo.dateofbirth,
    "gender": gestinfo.gender,
    "passport_no": gestinfo.pno,
    "passport_expiry_date": gestinfo.pexpiry,
    "passport_country": gestinfo.country,
   "passenger_nationality_id": gestinfo.nationality,
    "passenger_nationality_code": gestinfo.numbercode,
    "passenger_phone_no": gestinfo.phonenumber,
    "postal_code":gestinfo.postalcode
  };
  

  const retrievedNumber = sessionStorage.getItem('15digitnumber');
  var token=FlightSearchToken();
  var farerequest={
    'token_authorization':token,
'ConversationId':retrievedNumber,
'FareSourceCode':CheckoutFlightData.PricedItineraries[0].AirItineraryPricingInfo.FareSourceCode,
  };
  const apitokennew=Hotelapitoken();
  const customersearch={
    "token":apitokennew,
    "city_name":checkoutdetail.destinationName,
    "destination": checkoutdetail.destinationName,
    "room_searching":homesearch.room,
    "child_searching": homesearch.child,
    "adult_searching": homesearch.adult,
    "adult_per_room": homesearch.adults,
    "child_per_room": homesearch.children,
    "country_nationality": homesearch.slc_nationality,
    "check_in": homesearch.check_in,
    "check_out": homesearch.check_out,
    "request_all_data":JSON.stringify(homesearch)
  };
  var adulttotal= Number(gbpPrices.adult)*Number(gbpPrices.adultqty);
  var childtotal=Number(gbpPrices.child)*Number(gbpPrices.childqty);
  var infanttotal=Number(gbpPrices.infant)*Number(gbpPrices.infantqty);
  var total=adulttotal+childtotal+infanttotal;
  var adminmarkupprice=0;
  var clientmarkupprice=0;
  
  FlightMarkup.markups.forEach((markup) => {
    if (markup.services_type === "flight" ) {
      if(markup.added_markup==="alhijaz"){

        if(markup.markup_type==='Percentage'){
          const markupValue = Number(markup.markup_value);
          const markupAmount = (Number(total) * markupValue) / 100;
          clientmarkupprice=markupAmount;
        }else {
          clientmarkupprice =Number(markup.markup_value);
        }

      }else if(markup.added_markup==="synchtravel"){

        if(markup.markup_type==='Percentage'){
          const markupValue = parseFloat(Number(markup.markup_value));
          const markupAmount = (Number(total) * markupValue) / 100;
          adminmarkupprice=markupAmount;
        }else {
          adminmarkupprice= Number(markup.markup_value);
        }

      }
      
    }
  });
  var data={
    'token_authorization':token,
    'ConversationId':retrievedNumber,
     'lead_passenger_details': JSON.stringify(leadpassengerdetail),
     'other_passenger_details': JSON.stringify(guests),
     'child_details': JSON.stringify(childs),
    'infant_details': JSON.stringify(infants),
    'extra_services_details':'',
    'other_extra_services_details':'',
    'child_extra_services_details':'',
    'revalidation_res':JSON.stringify({'Data':CheckoutFlightData}),
    'childs': IndexPageSearchData.child,
    'adults': IndexPageSearchData.adult,
    'infant': IndexPageSearchData.infant,
    'departure_date':IndexPageSearchData.DepartureDate,
    'search_rq':JSON.stringify( IndexPageSearchData),
    'search_rs': JSON.stringify({'Data':Search_response}),
    'farerules_rq': '',
    'farerules_rs':'',
    'revalidation_rq': JSON.stringify(farerequest),
    'revalidation_rs':  JSON.stringify({'Data':CheckoutFlightData}),
    'payment_details': JSON.stringify(status.data),
    'adult_price':Number(totalperson.adult)*AdultAmount,
    'child_price': Number(totalperson.child)*ChildAmount,
    'infant_price': Number(totalperson.infant)*InfantAmount,
    'total_price': ConverterTotalSum,
    'adult_price_markup': CalculateFLightMarkup(Number(totalperson.adult)*AdultAmount),
    'child_price_markup':CalculateFLightMarkup(Number(totalperson.child)*ChildAmount),
    'infant_price_markup': CalculateFLightMarkup(Number(totalperson.infant)*InfantAmount),
    'total_price_markup': MarkupSum,
    'client_commission_amount':exchangemarkup.client,
    'admin_commission_amount': exchangemarkup.admin,
    'currency': ConverterCurrency,
    'client_payable_price':Number(MarkupSum)-Number( exchangemarkup.client),
    'client_markup': clientmarkup.markup_value === undefined ? '' : clientmarkup.markup_value,
        'client_markup_type':clientmarkup.markup_type === undefined ? '' :  clientmarkup.markup_type,
        'client_commision_amount_exchange':clientmarkupprice,
        'client_without_markup_price': total,
        'client_markup_price': clientmarkupprice,
        'client_payable_price_exchange':  Number(total)+Number(clientmarkupprice),
        'client_currency': 'GBP',
        'admin_markup':adminmarkup.markup_value === undefined ? '' : adminmarkup.markup_value,
        'admin_markup_type':adminmarkup.markup_type === undefined ? '' : adminmarkup.markup_type,
        'admin_commision_amount_exchange': adminmarkupprice,
        'admin_without_markup_price': total,
        'admin_markup_price': adminmarkupprice,
        'admin_payable_price_exchange': Number(total)+Number(adminmarkupprice),
        'admin_currency': 'GBP',
    'creditAmount':MarkupSum,
    "hotel_checkout_select":JSON.stringify(checkoutdetail),
    "hotel_customer_search_data":JSON.stringify(customersearch),
  };  
 
      try {
      const response = await Axios.post('https://api.synchtravel.com/api/combine_booking_apis_new',data , {
        headers: {
        // "Access-Control-Allow-Origin": "*",
          // Required for CORS support to work
          //"Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
          // "Access-Control-Allow-Headers":
          // "Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale",
          // "Access-Control-Allow-Methods": "POST"
        } ,
        
      });
      setIsBooking(false);
        if(response.data.message==='success')
        {
            // navigation(`/Flight_invoice/${retrievedNumber}`, {
            //       state: {retrievedNumber}
            //     });
        }else{
          var data=JSON.parse( response.data.error);
          toast.info(data.Message, {
            position: toast.POSITION.TOP_RIGHT
          });
          return;

        }
      // navigate(`/hotel_detail/${id}`,{state:{index}})
    } catch (error) {
      // Handle errors here
      setIsBooking(false);

    console.log(error);
      console.error('Error:', error);
    }
}
};
const CalculateFLightMarkup=(price,check)=>{
  var admin=0;
var client=0;
  if(price !== "NaN"){
  FlightMarkup.markups.forEach((markup) => {
    if (markup.services_type === "flight" ) {
      if(markup.added_markup==="alhijaz"){

        if(markup.markup_type==='Percentage'){
          const markupValue = Number(markup.markup_value);
          const markupAmount = (Number(price) * markupValue) / 100;
          client= markupAmount;
      }else {
        client =Number(markup.markup_value);
      }

      }else if(markup.added_markup==="synchtravel"){

        if(markup.markup_type==='Percentage'){
          const markupValue = parseFloat(Number(markup.markup_value));
          const markupAmount = (Number(price) * markupValue) / 100;
          admin = markupAmount;
        }else {
          admin =Number(markup.markup_value);
        }
      }
      
    }
  });
  if(check===5){
    exchangemarkup.admin=admin;
    exchangemarkup.client=client;
  }
  var total=Number(price)+admin+client;
return total.toFixed(2);
}
};
  return (
    <>
    <Layout>
    <ToastContainer/>
      <div className='container-fluid px-0' style={{marginTop:'1px'}}>
        <div color="text.light" class="sc-aXZVg sc-imWYAI SecureBanner__BannerFlex-sc-e0mxwz-0 yDRhZ ehgxZg fVCbYR">
          <div class="sc-aXZVg sc-hzhJZQ ldwMXy erDZQL">
            <div class="sc-aXZVg sc-imWYAI cIhMIl cnOikk">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="16" width="16" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ePSwcs Verified__SvgVerified-sc-a72ym8-0 eHnFep">
                <path d="M12 2L4 5.6v5.5c0 5 3.4 9.8 8 10.9 4.6-1.1 8-5.9 8-10.9V5.6L12 2zm-1.8 14.5l-3.6-3.6 1.3-1.3 2.3 2.3 5.9-6 1.3 1.3-7.2 7.3z"></path>
              </svg>
              <div font-size="0" class="sc-jXbUNg fMsDNd">SECURE CHECKOUT</div>
            </div>
          </div>
          <div class="sc-aXZVg sc-hzhJZQ ldwMXy kzdltI">
            <div width="620px" class="sc-aXZVg sc-imWYAI bpaeDt fbPhHB">
              <div class="sc-aXZVg sc-imWYAI cIhMIl cnOikk">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="16" width="16" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ePSwcs Verified__SvgVerified-sc-a72ym8-0 eHnFep">
                  <path d="M12 2L4 5.6v5.5c0 5 3.4 9.8 8 10.9 4.6-1.1 8-5.9 8-10.9V5.6L12 2zm-1.8 14.5l-3.6-3.6 1.3-1.3 2.3 2.3 5.9-6 1.3 1.3-7.2 7.3z"></path>
                </svg>
                <div font-size="0" class="sc-jXbUNg fMsDNd">SECURE TRANSACTIONS</div>
              </div>
              <div class="sc-aXZVg sc-imWYAI cIhMIl cnOikk">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="16" width="16" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ePSwcs Clock__SvgClock-sc-nknuv6-0 iIVzwr">
                  <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm.5-7.8l4.5 2.7-.8 1.2L11 13V7h1.5v5.2z"></path>
                </svg>
                <div font-size="0" class="sc-jXbUNg fMsDNd">24-HOUR SERVICE</div>
              </div>
              <div class="sc-aXZVg sc-imWYAI cIhMIl cnOikk">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="16" width="16" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ePSwcs Security__SvgSecurity-sc-167cmoi-0 cgDPaV">
                  <path d="M12 2L4 5.6v5.5c0 5 3.4 9.8 8 10.9 4.6-1.1 8-5.9 8-10.9V5.6L12 2zm0 10h6.2c-.5 3.7-2.9 7.1-6.2 8.1V12H5.8V6.8L12 4v8z"></path>
                </svg>
                <div font-size="0" class="sc-jXbUNg fMsDNd">TRUSTED PAYMENTS</div>
              </div>
            </div>
          </div>
        </div>
        <div class="sc-aXZVg fYuirS">
          <div class="sc-aXZVg eWLJqZ fs-unmask" width="1">
            <div class="sc-aXZVg sc-jxOSlx dVjqBv kDyrQm">
              <div class="sc-aXZVg fFlby">
                <div width="1" class="sc-aXZVg sc-imWYAI Breadcrumbs__FlexContainer-sc-17avwk9-0 gpuPII oipPi eEXOhw">
                  <div class="sc-aXZVg sc-imWYAI aKcUe dNxwPQ">
                    <div aria-selected="false" aria-controls="step-1" font-size="0,0,1" color="text" data-autobot-element-id="TOP_NAVIGATION_Choose_Room" data-testid="breadcrumb-label-0" class="sc-jXbUNg Breadcrumbs__Label-sc-17avwk9-1 lhlKWp klZnzG">
                      <div class="sc-aXZVg sc-imWYAI ldwMXy ejmSJE">
                        <div font-size="1" class="sc-aXZVg sc-imWYAI Breadcrumbs__Circle-sc-17avwk9-2 ldwMXy dUnMKc ejGFTW">
                          <span font-size="0" class="sc-dhKdcB Breadcrumbs__CircleContent-sc-17avwk9-3 giZvOy jaUUmd">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="16px" width="16px" aria-hidden="true" fill="currentcolor" color="white" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 fAXPed Check__SvgCheck-sc-9psb6r-0 jQwtKB">
                              <path d="M8.6 15.6l-4.2-4.2L3 12.8l5.6 5.6 12-12L19.2 5 8.6 15.6z"></path>
                            </svg>
                          </span>
                        </div>
                      </div>
                      <span class="sc-iHmpnF cDaeNv"></span>
                    </div>
                  </div>
                  <hr width="100%" color="primary" class="sc-eeDRCY Breadcrumbs__ProgressLine-sc-17avwk9-4 bBUxUY jpZMXX"/>
                  <div class="sc-aXZVg sc-imWYAI ldwMXy dNxwPQ">
                    <div aria-selected="true" aria-controls="step-2" font-size="0,0,1" color="primary" data-autobot-element-id="TOP_NAVIGATION_Guest_&amp;_Payment_Info" data-testid="breadcrumb-label-1" class="sc-jXbUNg Breadcrumbs__Label-sc-17avwk9-1 AoCyl klZnzG">
                      <div class="sc-aXZVg sc-imWYAI ldwMXy ejmSJE">
                        <div font-size="1" class="sc-aXZVg sc-imWYAI Breadcrumbs__Circle-sc-17avwk9-2 ldwMXy dUnMKc ejGFTW">
                          <span color="white" font-size="0" class="sc-dhKdcB Breadcrumbs__CircleContent-sc-17avwk9-3 jTDlpr jaUUmd">2</span>
                        </div>
                      </div>
                      <span class="sc-iHmpnF cDaeNv">&nbsp;(is currently active)</span>
                    </div>
                  </div>
                  <hr width="100%" color="border" class="sc-eeDRCY Breadcrumbs__ProgressLine-sc-17avwk9-4 euxmHg jpZMXX"/>
                  <div class="sc-aXZVg sc-imWYAI bgyled dNxwPQ">
                    <div aria-selected="false" aria-controls="step-3" font-size="0,0,1" color="text.light" data-autobot-element-id="TOP_NAVIGATION_Booking_Confirmation" data-testid="breadcrumb-label-2" class="sc-jXbUNg Breadcrumbs__Label-sc-17avwk9-1 eTQiGA klZnzG">
                      <div class="sc-aXZVg sc-imWYAI ldwMXy ejmSJE">
                        <div font-size="1" class="sc-aXZVg sc-imWYAI Breadcrumbs__Circle-sc-17avwk9-2 ldwMXy dUnMKc iiUSEP">
                          <span font-size="0" class="sc-dhKdcB Breadcrumbs__CircleContent-sc-17avwk9-3 giZvOy fGTRVB">3</span>
                        </div>
                      </div>
                      <span class="sc-iHmpnF cDaeNv"></span>
                    </div>
                  </div>
                  <hr color="border" class="sc-eeDRCY lbsfXf" />
                </div>
              </div>
              <div class="sc-aXZVg sc-imWYAI fwXifE fffrGg">
                <div id="step-1" color="primary" class="sc-jXbUNg sc-kzqdkY Breadcrumbs__StyledTruncate-sc-17avwk9-5 dTyPIi cxkuqi fnYsDg">Choose Room</div>
                <div id="step-2" color="primary" class="sc-jXbUNg sc-kzqdkY Breadcrumbs__StyledTruncate-sc-17avwk9-5 ftlbro cxkuqi fnYsDg">Guest &amp; Payment Details</div>
                <div id="step-3" color="text.light" class="sc-jXbUNg sc-kzqdkY Breadcrumbs__StyledTruncate-sc-17avwk9-5 bvMvRt cxkuqi fnYsDg">Booking Confirmation</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row mt-2'>
          <div className='col-lg-8 margin-checkout'>
            <div class="sc-aXZVg kkPrOH">
              <div class="sc-aXZVg bffiwg fs-unmask">
                <span font-size="2" font-weight="bold" class="sc-iGgWBj buWeuk fs-mask" color="text.base">Almost done! </span>
                <span font-size="2" color="text.base" class="sc-iGgWBj fQefJM">Enter your details and complete your booking now.</span>
              </div>
            </div>
            <div class="sc-aXZVg sc-bXCLTC hotelProductSummaryusp__ProductSummaryCard-sc-16ljvpv-0 fqslXb klVZFh hmRjcT">
              <div class="sc-aXZVg sc-gEvEer ldwMXy cIUJpZ">
                <div class="sc-aXZVg sc-gEvEer ldwMXy ewVeEI">
                  <div class="sc-aXZVg HotelProductSummary__ResponsiveImageBox-sc-wumvs5-0 hfQmrY hjPUro">
                      <a href='hotel-single.html' class='d-block'>
                        <img alt="Hotel Image" src={hotelimg} width="100%" height="100%" class="sc-hmdomO gkkCmx hero-image" />
                      </a>
                  </div>
                  <div class="sc-aXZVg HotelProductSummary__ItineraryBox-sc-wumvs5-1 cVIExE yeTyp">
                    <div class="sc-aXZVg sc-gEvEer ldwMXy ewVeEI">
                      <div class="sc-aXZVg sc-gEvEer ldwMXy hJyqwh">
                        <div font-weight="bold" font-size="2,,,3" data-autobot-element-id="HOTEL_NAME" translate="no" class="sc-dcJsrY OpuFr">{checkoutdetail?.hotel_name}</div>
                        <div class="sc-aXZVg sc-gEvEer ldwMXy ewVeEI"></div>
                      </div>
                    </div>
                    <div class="sc-aXZVg HotelProductSummary__CoreHotelInfoBoxDisplay-sc-wumvs5-3 ldwMXy iXpLZJ">
                      <div class="sc-aXZVg sc-gEvEer ipZQDb jOxjSs">
                        <div color="gray" font-size="0" font-weight="bold" translate="no" class="sc-dcJsrY bmWfmj">{checkoutdetail?.destinationName}</div>
                        <div class="sc-aXZVg sc-gEvEer wBbuO lgdAME">
                          <div color="alert.base" class="sc-fHjqPf cWxNMU">
                            <span font-weight="bold" data-autobot-element-id="GUEST_RATING" class="sc-iGgWBj iQyTkn">8.3</span>
                          </div>
                          <span font-weight="bold" font-size="0" class="sc-iGgWBj HotelInfo__HotelDescription-sc-17matt1-0 gZlWox kBqT">Very Good</span>
                          <span font-weight="bold" font-size="0" class="sc-iGgWBj loalHW">|</span>
                          <span font-weight="bold" font-size="0" data-autobot-element-id="EXPRESS_TITLE" class="sc-iGgWBj HotelInfo__HotelDescription-sc-17matt1-0 gZlWox kBqT">{checkoutdetail.stars_rating}-STAR HOTEL</span>
                        </div>
                        <div class="sc-aXZVg sc-gEvEer wBbuO lgdAME"></div>
                      </div>
                      {/* <div class="sc-aXZVg sc-gEvEer ipZQDb ewVeEI">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="18" width="18" title="Hotel Fully Refundable Check Icon" aria-labelledby="hotel-Fully Refundable-check-icon hotel-Fully Refundable-check-desc" aria-hidden="true" fill="currentcolor" color="success" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 jkhOWQ Check__SvgCheck-sc-9psb6r-0 jQwtKB">
                          <title id="hotel-Fully Refundable-check-icon">Hotel Fully Refundable Check Icon</title>
                          <desc id="hotel-Fully Refundable-check-desc">Hotel Fully Refundable Cancellation</desc>
                          <path d="M8.6 15.6l-4.2-4.2L3 12.8l5.6 5.6 12-12L19.2 5 8.6 15.6z"></path>
                        </svg>
                        <div color="success" font-size="0" class="sc-dcJsrY fa-DiEf">
                          <span font-weight="bold" class="sc-iGgWBj iQyTkn">Fully Refundable</span> until 11:59PM (property local time) on Feb 21
                        </div>
                      </div> */}
                    </div>
                  </div>
               </div>
              </div>
              <div class="sc-aXZVg sc-dhKdcB ldwMXy innMlq">
                <div class="sc-aXZVg sc-gEvEer ipZQDb jOxjSs">
                  <div color="gray" font-size="0" font-weight="bold" translate="no" class="sc-dcJsrY bmWfmj">{checkoutdetail?.destinationName}</div>
                  <div class="sc-aXZVg sc-gEvEer wBbuO lgdAME">
                    <div color="alert.base" class="sc-fHjqPf cWxNMU">
                      <span font-weight="bold" data-autobot-element-id="GUEST_RATING" class="sc-iGgWBj iQyTkn">8.3</span>
                    </div>
                    <span font-weight="bold" font-size="0" class="sc-iGgWBj HotelInfo__HotelDescription-sc-17matt1-0 gZlWox kBqT">Very Good</span>
                    <span font-weight="bold" font-size="0" class="sc-iGgWBj loalHW">|</span>
                    <span font-weight="bold" font-size="0" data-autobot-element-id="EXPRESS_TITLE" class="sc-iGgWBj HotelInfo__HotelDescription-sc-17matt1-0 gZlWox kBqT">3-STAR HOTEL</span>
                  </div>
                  <div class="sc-aXZVg sc-gEvEer wBbuO lgdAME"></div>
                </div>
                <div class="sc-aXZVg sc-gEvEer ipZQDb ewVeEI">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="18" width="18" title="Hotel Fully Refundable Check Icon" aria-labelledby="hotel-Fully Refundable-check-icon hotel-Fully Refundable-check-desc" aria-hidden="true" fill="currentcolor" color="success" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 jkhOWQ Check__SvgCheck-sc-9psb6r-0 jQwtKB">
                    <title id="hotel-Fully Refundable-check-icon">Hotel Fully Refundable Check Icon</title>
                    <desc id="hotel-Fully Refundable-check-desc">Hotel Fully Refundable Cancellation</desc>
                    <path d="M8.6 15.6l-4.2-4.2L3 12.8l5.6 5.6 12-12L19.2 5 8.6 15.6z"></path>
                  </svg>
                  <div color="success" font-size="0" class="sc-dcJsrY fa-DiEf">
                    <span font-weight="bold" class="sc-iGgWBj iQyTkn">Fully Refundable</span> until 11:59PM (property local time) on Feb 21
                  </div>
                </div>
              </div>
              <div style={{display:'block'}} class="sc-aXZVg sc-bXCLTC HotelItineraryInfo__ItineraryInfoCard-sc-c2j5x0-0 fXKnj cBNmrf cbxzc">
                <div width='auto' style={{justifyContent:'space-evenly',width:'100%'}} class="sc-aXZVg sc-gEvEer fQRFI knA-dlQ">
                  <div class="sc-aXZVg sc-gEvEer gPDLyS jvgMwg">
                    <div font-size="10px,,0" color="gray" font-weight="bold" class="sc-dcJsrY HotelItineraryInfo__ItineraryInfoText-sc-c2j5x0-1 TyBnQ hdtqIg">CHECK-IN</div>
                    <div font-size="0,,2" font-weight="bold" color="text" data-autobot-element-id="CHECKIN_DATE" class="sc-dcJsrY HotelItineraryInfo__ItineraryInfoText-sc-c2j5x0-1 cZKRgL hdtqIg"> {moment(checkoutdetail?.checkIn).format('DD-MM-YYYY')}</div>
                  </div>
                  <hr color="border" class="sc-feUZmu HotelItineraryInfo__VerticalDivider-sc-c2j5x0-2 HotelItineraryInfo__InvisibleVerticalDivider-sc-c2j5x0-3 jUDPJL kHjPeQ ga-dSMk" />
                  <div class="sc-aXZVg sc-gEvEer gPDLyS jvgMwg">
                    <div font-size="10px,,0" color="gray" font-weight="bold" class="sc-dcJsrY HotelItineraryInfo__ItineraryInfoText-sc-c2j5x0-1 TyBnQ hdtqIg">CHECK-OUT</div>
                    <div font-size="0,,2" font-weight="bold" color="text" data-autobot-element-id="CHECKOUT_DATE" class="sc-dcJsrY HotelItineraryInfo__ItineraryInfoText-sc-c2j5x0-1 cZKRgL hdtqIg">{moment(checkoutdetail?.checkOut).format('DD-MM-YYYY')}</div>
                  </div>
                </div>
                <hr color="border" class="sc-feUZmu jUDPJL" />
                {checkoutdetail?.rooms_list.map((item,index)=>(
                   <div key={index} style={{justifyContent:'space-evenly',width:'100%'}} class="sc-aXZVg sc-gEvEer dzSxmp knA-dlQ">
                      <div class="sc-aXZVg sc-gEvEer gPDLyS jvgMwg">
                        <div font-size="10px,,0" color="gray" font-weight="bold" data-autobot-element-id="NUMBER_OF_NIGHTS" class="sc-dcJsrY HotelItineraryInfo__ItineraryInfoText-sc-c2j5x0-1 TyBnQ hdtqIg">Room Type</div>
                        <div font-size="0,,2" font-weight="bold" color="text" class="sc-dcJsrY HotelItineraryInfo__ItineraryInfoText-sc-c2j5x0-1 cZKRgL hdtqIg">{item.board_id}</div>
                      </div>
                      <hr width=",,0" color="border" class="sc-feUZmu HotelItineraryInfo__VerticalDivider-sc-c2j5x0-2 bishko kHjPeQ" />
                      <div class="sc-aXZVg sc-gEvEer gPDLyS jvgMwg">
                        <div font-size="10px,,0" color="gray" font-weight="bold" data-autobot-element-id="NUMBER_OF_ROOMS" class="sc-dcJsrY HotelItineraryInfo__ItineraryInfoText-sc-c2j5x0-1 TyBnQ hdtqIg">Adults</div>
                        <div font-size="0,,2" font-weight="bold" color="text" class="sc-dcJsrY HotelItineraryInfo__ItineraryInfoText-sc-c2j5x0-1 cZKRgL hdtqIg">{item.adults}</div>
                      </div>
                      <hr width=",,0" color="border" class="sc-feUZmu HotelItineraryInfo__VerticalDivider-sc-c2j5x0-2 bishko kHjPeQ" />
                      <div class="sc-aXZVg sc-gEvEer gPDLyS jvgMwg">
                        <div font-size="10px,,0" color="gray" font-weight="bold" data-autobot-element-id="NUMBER_OF_ROOMS" class="sc-dcJsrY HotelItineraryInfo__ItineraryInfoText-sc-c2j5x0-1 TyBnQ hdtqIg">Children</div>
                        <div font-size="0,,2" font-weight="bold" color="text" class="sc-dcJsrY HotelItineraryInfo__ItineraryInfoText-sc-c2j5x0-1 cZKRgL hdtqIg">{item.childs}</div>
                      </div>
                    </div>
                     ))}
              </div>
              <div class="sc-aXZVg sc-gEvEer jAaJVn jOxjSs">
                <div font-weight="bold" font-size="2" class="sc-dcJsrY cRQEIs">Deluxe Room</div>
                <div class="sc-aXZVg sc-gEvEer HotelAmenityInfo__StyledGridFlex-sc-1jx7f56-0 ldwMXy ewVeEI cDnnkr">
                  <div class="sc-aXZVg sc-gEvEer ipZQDb ewVeEI">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="18" width="18" title="Hotel Room Bed Icon" aria-labelledby="hotel-room-bed-icon hotel-room-bed-desc" aria-hidden="true" fill="currentcolor" color="text" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 gTqWVw Bed__SvgBed-sc-1cux7fj-0 heMpRx">
                      <title id="hotel-room-bed-icon">Hotel Room Bed Icon</title>
                      <desc id="hotel-room-bed-desc">Hotel Room Bedding Option</desc>
                      <path d="M7.5 12.3c1.5 0 2.7-1.2 2.7-2.7S9 6.8 7.5 6.8 4.7 8 4.7 9.5s1.2 2.8 2.8 2.8zm10.9-5.5h-7.3v6.4H3.8V5H2v13.6h1.8v-2.7h16.4v2.7H22v-8.1c0-2-1.6-3.7-3.6-3.7z"></path>
                    </svg>
                    <div font-size="0" class="sc-dcJsrY ikGIRk">1 Queen Bed</div>
                  </div>
                  <div class="sc-aXZVg sc-gEvEer ipZQDb ewVeEI">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="18" width="18" title="Hotel Breakfast Icon" aria-labelledby="hotel-amenity-Breakfast-icon hotel-amenity-Breakfast-desc" aria-hidden="false" fill="currentcolor" tabindex="-1" focusable="false" data-testid="Breakfast-svg" color="success" class="Svg-sc-12lgb6u-0 jkhOWQ Breakfast__SvgBreakfast-sc-c158l0-0 Hnkbq Icon-sc-1d5jmd0-0 cFXvmX" role="img">
                      <title id="hotel-amenity-Breakfast-icon">Hotel Breakfast Icon</title>
                      <desc id="hotel-amenity-Breakfast-desc">Hotel Amenity Breakfast</desc>
                      <path d="M19 3H3v10c0 2.2 1.8 4 4 4h6c2.2 0 4-1.8 4-4v-3h2c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 5h-2V5h2v3zM3 19h16v2H3v-2z"></path>
                    </svg>
                    <div color="success" font-size="0" class="sc-dcJsrY khNAfa">Free Breakfast</div>
                  </div>
                  <div class="sc-aXZVg sc-gEvEer ipZQDb ewVeEI">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="18" width="18" title="Hotel Fridge Icon" aria-labelledby="hotel-amenity-Fridge-icon hotel-amenity-Fridge-desc" aria-hidden="false" fill="currentcolor" tabindex="-1" focusable="false" data-testid="Fridge-svg" color="text" class="Svg-sc-12lgb6u-0 gTqWVw Fridge__SvgFridge-sc-12z641i-0 csMANy Icon-sc-1d5jmd0-0 cFXvmX" role="img">
                      <title id="hotel-amenity-Fridge-icon">Hotel Fridge Icon</title>
                      <desc id="hotel-amenity-Fridge-desc">Hotel Amenity Fridge</desc>
                      <path d="M6 21c-.6 0-1-.4-1-1V3c0-.6.4-1 1-1h12c.6 0 1 .4 1 1v17c0 .6-.4 1-1 1v1h-2v-1H8v1H6v-1zM19 9V8H5v1h14zM7 4v2h2V4H7zm0 7v6h2v-6H7z"></path>
                    </svg>
                    <div color="text" font-size="0" class="sc-dcJsrY blvlXi">Fridge</div>
                  </div>
                  <div class="sc-aXZVg sc-gEvEer ipZQDb ewVeEI">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="18" width="18" aria-hidden="true" fill="currentcolor" color="secondary.base" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 jkhOWQ Check__SvgCheck-sc-9psb6r-0 jQwtKB">
                      <path d="M8.6 15.6l-4.2-4.2L3 12.8l5.6 5.6 12-12L19.2 5 8.6 15.6z"></path>
                    </svg>
                    <div color="text" font-size="0" class="sc-dcJsrY blvlXi">Breakfast Included</div>
                  </div>
                  <div class="sc-aXZVg sc-gEvEer ipZQDb ewVeEI">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="18" width="18" aria-hidden="true" fill="currentcolor" color="secondary.base" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 jkhOWQ Check__SvgCheck-sc-9psb6r-0 jQwtKB">
                      <path d="M8.6 15.6l-4.2-4.2L3 12.8l5.6 5.6 12-12L19.2 5 8.6 15.6z"></path>
                    </svg>
                    <div color="text" font-size="0" class="sc-dcJsrY blvlXi">Free Wifi</div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class='row'>
              <div className='hotel-checkout-shadow mt-4 p-3'>
                <div className='d-flex justify-content-between'>
                  <h4>Other Guest Information {storedData !== null ? '' : '(Optional)'}</h4>
                  <span onClick={ShowOtherGuestForm} style={{cursor:'pointer'}} className='mt-auto mb-auto'><FontAwesomeIcon icon={faAngleDown}/></span>
                </div>
                {otherGuestData && (
                  <div>
                  {adultsArray.map((_,index)=>(
                  <div key={index} className='row pt-2'>
                    <h5 className='mb-2'>Guest #{index+2}</h5>
                  <div className='col-md-3 mt-2'>
                  <label className='fw-bold'>Title</label>
                  <select value={guests.title} id={`inputState_${index}`} name='title'  onChange={(e) => otherGuestInfo(e, index,1)}  class='form-control mt-2 form-select select-styling'>
                        <option selected>Select Title</option>
                        <option value='MR'>Mr.</option>
                        <option value='MRS'>Mrs.</option>
                      </select>
                  </div>
                    <div className='col-md-3 mt-2'>
                    <label className='fw-bold'>First Name</label>
                    <input
                        type='text'
                        class='form-control mt-2'
                        value={guests.firstname}
                        name='firstname'
                        placeholder='First Name'
                        onChange={(e) => otherGuestFirstName(e, index,1)}
                      />
                      </div>
                      <div className='col-md-3 mt-2'>
                      <label className='fw-bold'>Last Name</label>
                    <input
                        type='text'
                        class='form-control mt-2'
                        value={guests.lastname}
                        name='lastname'
                        placeholder='Last Name'
                        onChange={(e) => otherGuestLastName(e, index,1)}
                      />
                      </div>
                      <div className='col-md-3 mt-2'>
                      <label className='fw-bold'>Nationality</label>
                      <select
                        value={guests.nationality} // Set the selected value from the state
                        onChange={(e) => otherGuestNationality(e, index,1)}
                        className='form-control form-select mt-2 select-styling'
                        aria-label='Default select example'
                      >
                        <option selected>Nationality</option>
                        {countryListsimple.map((item, index) => (
                          <option key={item.id} value={item.iso2}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      </div>
                      {storedData !== null && (
                        <>
                      <div className='col-md-3 mt-2'>
                    <label className='fw-bold'>Date of Birth</label>
                    <input
                        type='date'
                        class='form-control mt-2'
                        value={guests.dob}
                        name='dob'
                        onChange={(e) => otherGuestdob(e, index,1)}
                      />
                      </div>
                      <div className='col-md-3 mt-2'>
                            <label className='fw-bold'>Gender:</label>
                            <select
                              value={guests.gender}
                              name='gender'
                              onChange={e => otherGuestGender(e, index,1)}
                              class='form-control  mt-2 form-select select-styling'
                            >
                              <option selected>Select Gender</option>
                              <option value='M'>Male</option>
                              <option value='F'>Female</option>
                            </select>
                          </div>
                          <div className='col-md-3 mt-2'>
                    <label className='fw-bold'>Passport Number</label>
                    <input
                        type='text'
                        class='form-control mt-2'
                        value={guests.pnumber}
                        name='pnumber'
                        placeholder='Passport Number'
                        onChange={(e) => otherGuestPNumber(e, index,1)}
                      />
                      </div>
                      <div className='col-md-3 mt-2'>
                    <label className='fw-bold'>Passport Expiry</label>
                    <input
                        type='date'
                        class='form-control mt-2'
                        value={guests.pexpiry}
                        name='pexpiry'
                        onChange={(e) => otherGuestPExpiry(e, index,1)}
                      />
                      </div>
                      </>
                      )}
                      </div>
                      ))}
                      {childsArray.map((_,index)=>(
                  <div key={index} className='row pt-2'>
                    <h5 className='mb-2'>Child #{index+1}</h5>
                  <div className='col-md-3 mt-2'>
                  <select value={guests.title} id={`inputState_${index}`} name='title'  onChange={(e) => otherGuestInfo(e, index,2)}  class='form-control form-select select-styling'>
                        <option selected>Select Title</option>
                        <option value='MSTR'>Mr.</option>
                        <option value='MSTRS'>Mrs.</option>
                      </select>
                  </div>
                    <div className='col-md-3 mt-2'>
                    <input
                        type='text'
                        class='form-control'
                        name='firstname'
                        placeholder='First Name'
                        onChange={(e) => otherGuestFirstName(e, index,2)}

                      />
                      </div>
                      <div className='col-md-3 mt-2'>
                    <input
                        type='text'
                        class='form-control'
                        name='firstname'
                        placeholder='Last Name'
                        onChange={(e) => otherGuestLastName(e, index,2)}
                      />
                      </div>
                      <div className='col-md-3 mt-2'>
                      <select
                        value={childs.nationality} // Set the selected value from the state
                        onChange={(e) => otherGuestNationality(e, index,2)}
                        className='form-control form-select select-styling'
                        aria-label='Default select example'
                      >
                        <option selected>Nationality</option>
                        {countryListsimple.map((item, index) => (
                          <option key={item.id} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      </div>
                      {storedData !== null && (
                        <>
                      <div className='col-md-3 mt-2'>
                    <label className='fw-bold'>Date of Birth</label>
                    <input
                        type='date'
                        class='form-control mt-2'
                        value={guests.dob}
                        name='dob'
                        onChange={(e) => otherGuestdob(e, index,2)}
                      />
                      </div>
                      <div className='col-md-3 mt-2'>
                            <label className='fw-bold'>Gender:</label>
                            <select
                              value={guests.gender}
                              name='gender'
                              onChange={e => otherGuestGender(e, index,2)}
                              class='form-control  mt-2 form-select select-styling'
                            >
                              <option selected>Select Gender</option>
                              <option value='M'>Male</option>
                              <option value='F'>Female</option>
                            </select>
                          </div>
                          <div className='col-md-3 mt-2'>
                    <label className='fw-bold'>Passport Number</label>
                    <input
                        type='text'
                        class='form-control mt-2'
                        value={guests.pnumber}
                        name='pnumber'
                        placeholder='Passport Number'
                        onChange={(e) => otherGuestPNumber(e, index,2)}
                      />
                      </div>
                      <div className='col-md-3 mt-2'>
                        <label className='fw-bold'>Passport Expiry</label>
                        <input
                            type='date'
                            class='form-control mt-2'
                            value={guests.pexpiry}
                            name='pexpiry'
                            onChange={(e) => otherGuestPExpiry(e, index,2)}
                          />
                        </div>
                      </>
                      )}
                      </div>
                      ))}
                        {infantsArray.map((_,index)=>(
                  <div key={index} className='row pt-2'>
                    <h5 className='mb-2'>Infant #{index+1}</h5>
                    <div className='col-md-3 mt-2'>
                    <select value={guests.title} id={`inputState_${index}`} name='title'  onChange={(e) => otherGuestInfo(e, index,3)}  class='form-control form-select select-styling'>
                          <option selected>Select Title</option>
                          <option value='MSTR'>Mr.</option>
                          <option value='MSTRS'>Mrs.</option>
                        </select>
                    </div>
                      <div className='col-md-3 mt-2'>
                      <select
                        value={infants.nationality} // Set the selected value from the state
                        onChange={(e) => otherGuestNationality(e, index,3)}
                        className='form-control form-select select-styling'
                        aria-label='Default select example'
                      >
                        <option selected>Nationality</option>
                        {countryListsimple.map((item, index) => (
                          <option key={item.id} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      </div>
                      {storedData !== null && (
                        <>
                      <div className='col-md-3 mt-2'>
                    <label className='fw-bold'>Date of Birth</label>
                    <input
                        type='date'
                        class='form-control mt-2'
                        value={guests.dob}
                        name='dob'
                        onChange={(e) => otherGuestdob(e, index,3)}
                      />
                      </div>
                      <div className='col-md-3 mt-2'>
                            <label className='fw-bold'>Gender:</label>
                            <select
                              value={guests.gender}
                              name='gender'
                              onChange={e => otherGuestGender(e, index,3)}
                              class='form-control  mt-2 form-select select-styling'
                            >
                              <option selected>Select Gender</option>
                              <option value='M'>Male</option>
                              <option value='F'>Female</option>
                            </select>
                          </div>
                          <div className='col-md-3 mt-2'>
                    <label className='fw-bold'>Passport Number</label>
                    <input
                        type='text'
                        class='form-control mt-2'
                        value={guests.pnumber}
                        name='pnumber'
                        placeholder='Passport Number'
                        onChange={(e) => otherGuestPNumber(e, index,3)}
                      />
                          </div>
                          <div className='col-md-3 mt-2'>
                        <label className='fw-bold'>Passport Expiry</label>
                        <input
                            type='date'
                            class='form-control mt-2'
                            value={guests.pexpiry}
                            name='pexpiry'
                            onChange={(e) => otherGuestPExpiry(e, index,3)}
                          />
                          </div>
                      </>
                      )}
                      </div>
                      ))}
                    </div>
                  )}
              </div>
              </div> */}
            {(adultCount-1 !==0 || childCount !==0 || infantCount !==0) && (
              <div data-testid="hotelCheckIn" class="hotel-check-in">
                <div class="sc-aXZVg sc-bXCLTC HideableCard___HideableCard-sc-2xxkmv-0 kvJOXI klVZFh yOFdB">
                    <div class="hotels">
                      {adultsArray.map((_,index)=>(
                      <div key={index} class="HotelCheckInFormusp__HotelSection-sc-ufpzwx-0 cnzfrU hotel-0">
                        <div class="sc-aXZVg sc-cPiKLX enYEWO cqIzXg InfoPanel__InfoBanner-sc-1tn8rd1-0 gvfTfv info-bar-warning" data-testid="hotelCheckInInfoPanel" color="background.light" tabindex="0">
                        <div class="sc-aXZVg sc-gEvEer ldwMXy bbyLJJ">
                          <div width="1" class="sc-aXZVg gqSRii">
                            <span font-size="1" class="sc-iGgWBj bOZtTM"></span>
                              <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                                <div font-size="0" color="text" class="sc-dcJsrY hcvZAS">The guest checking into each hotel room must be 21 or older, present a valid Photo ID and credit card.</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <h3 font-size="3" font-weight="bold" color="text.base" class="sc-dcJsrY kYOVZP">Guest Name {storedData !== null ? '' : '(Optional)'}</h3>
                          <div class="sc-aXZVg ipzmek hotels[0].rooms[0]">
                            <div class="sc-aXZVg sc-gEvEer ldwMXy fCsXwM">
                              <div width="0.5" class="sc-aXZVg jUZlZF">
                                <div label="First name" type="text" color="background.lightest" class="sc-aXZVg sc-dAlyuH Input__RelativeWithRadius-sc-1qlv0nb-0 ganGCn gRgPQs hDSKSG">
                                  <div class="sc-aXZVg sc-lcIPJg fPsAVt kygVOe">
                                    <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                                    <input name='firstname' type="text" onChange={(e) => otherGuestFirstName(e, index,3)}  placeholder="First name *" aria-describedby="hotel-first-name-0-error"  class="sc-dtBdUo fMPBrN Input__StyledInput-sc-1qlv0nb-1 emfZoy" style={{transitionProperty: 'padding-top, padding-bottom', transitionDuration: '0.1'}} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div width="0.5" class="sc-aXZVg jUZlZF">
                                <div label="Last name" type="text" color="background.lightest" class="sc-aXZVg sc-dAlyuH Input__RelativeWithRadius-sc-1qlv0nb-0 eFkDcU gRgPQs hDSKSG">
                                  <div class="sc-aXZVg sc-lcIPJg fPsAVt kygVOe">
                                    <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                                      <input  name='firstname'  type="text" onChange={(e) => otherGuestLastName(e, index,3)} placeholder="Last name *"  class="sc-dtBdUo fMPBrN Input__StyledInput-sc-1qlv0nb-1 emfZoy" style={{transitionProperty: 'padding-top, padding-bottom', transitionDuration: '0.1'}} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <label color="text.light" font-weight="700" font-size="10px" letter-spacing="0.2px" class="sc-kdBSHD cMtyuI">
                              <div class="sc-aXZVg sc-gEvEer jAaJVn lgdAME">
                                <div color="primary" class="sc-JrDLc jDcnOJ">
                                  <input type="checkbox" id="hotels[0].rooms[0].syncBillingName" name="hotels[0].rooms[0].syncBillingName" size="20" color="primary" role="checkbox" aria-checked="false" data-indeterminate="false" class="sc-fjvvzt kgkxcK" value="false" />
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" data-name="checked" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt BoxChecked__SvgBoxChecked-sc-xar6qz-0 fQHOkP">
                                    <path d="M6 3h12c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3H6c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3zm4 14l9-8.6L17.6 7 10 14.3l-3.6-3.5L5 12.2l5 4.8z"></path>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" data-name="indeterminate" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt BoxMinus__SvgBoxMinus-sc-m2y8sx-0 fWKCvU">
                                    <path d="M6 5c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V6c0-.6-.4-1-1-1H6zm0-2h12c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3H6c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3zm1 8v2h10v-2H7z"></path>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" data-name="empty" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt BoxEmpty__SvgBoxEmpty-sc-tc3bem-0 ehVqsH">
                                    <path d="M6 5c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V6c0-.6-.4-1-1-1H6zm0-2h12c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3H6c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3z"></path>
                                  </svg>
                                </div>
                                <div font-size="1" color="text.base" class="sc-dcJsrY iHzcQK">Guest name and name on card are the same</div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                      ))}
                      {childsArray.map((_,index)=>(
                    <div key={index} class="HotelCheckInFormusp__HotelSection-sc-ufpzwx-0 cnzfrU hotel-0">
                      <div class="sc-aXZVg sc-cPiKLX enYEWO cqIzXg InfoPanel__InfoBanner-sc-1tn8rd1-0 gvfTfv info-bar-warning" data-testid="hotelCheckInInfoPanel" color="background.light" tabindex="0">
                        <div class="sc-aXZVg sc-gEvEer ldwMXy bbyLJJ">
                          <div width="1" class="sc-aXZVg gqSRii">
                            <span font-size="1" class="sc-iGgWBj bOZtTM"></span>
                              <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                                <div font-size="0" color="text" class="sc-dcJsrY hcvZAS">The guest checking into each hotel room must be 21 or older, present a valid Photo ID and credit card.</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <h3 font-size="3" font-weight="bold" color="text.base" class="sc-dcJsrY kYOVZP">Guest Name {storedData !== null ? '' : '(Optional)'}</h3>
                          <div class="sc-aXZVg ipzmek hotels[0].rooms[0]">
                            <div class="sc-aXZVg sc-gEvEer ldwMXy fCsXwM">
                              <div width="0.5" class="sc-aXZVg jUZlZF">
                                <div label="First name" type="text" color="background.lightest" class="sc-aXZVg sc-dAlyuH Input__RelativeWithRadius-sc-1qlv0nb-0 ganGCn gRgPQs hDSKSG">
                                  <div class="sc-aXZVg sc-lcIPJg fPsAVt kygVOe">
                                    <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                                        <input name='firstname' type="text" onChange={(e) => otherGuestFirstName(e, index,3)}  placeholder="First name *" aria-describedby="hotel-first-name-0-error"  class="sc-dtBdUo fMPBrN Input__StyledInput-sc-1qlv0nb-1 emfZoy"  style={{transitionProperty: 'padding-top, padding-bottom', transitionDuration: '0.1'}} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div width="0.5" class="sc-aXZVg jUZlZF">
                                <div label="Last name" type="text" color="background.lightest" class="sc-aXZVg sc-dAlyuH Input__RelativeWithRadius-sc-1qlv0nb-0 eFkDcU gRgPQs hDSKSG">
                                  <div class="sc-aXZVg sc-lcIPJg fPsAVt kygVOe">
                                    <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                                      <input  name='firstname'  type="text" onChange={(e) => otherGuestLastName(e, index,3)} placeholder="Last name *"  class="sc-dtBdUo fMPBrN Input__StyledInput-sc-1qlv0nb-1 emfZoy" style={{transitionProperty: 'padding-top, padding-bottom', transitionDuration: '0.1'}} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <label color="text.light" font-weight="700" font-size="10px" letter-spacing="0.2px" class="sc-kdBSHD cMtyuI">
                              <div class="sc-aXZVg sc-gEvEer jAaJVn lgdAME">
                                <div color="primary" class="sc-JrDLc jDcnOJ">
                                  <input type="checkbox" id="hotels[0].rooms[0].syncBillingName" name="hotels[0].rooms[0].syncBillingName" size="20" color="primary" role="checkbox" aria-checked="false" data-indeterminate="false" class="sc-fjvvzt kgkxcK" value="false" />
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" data-name="checked" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt BoxChecked__SvgBoxChecked-sc-xar6qz-0 fQHOkP">
                                    <path d="M6 3h12c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3H6c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3zm4 14l9-8.6L17.6 7 10 14.3l-3.6-3.5L5 12.2l5 4.8z"></path>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" data-name="indeterminate" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt BoxMinus__SvgBoxMinus-sc-m2y8sx-0 fWKCvU">
                                    <path d="M6 5c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V6c0-.6-.4-1-1-1H6zm0-2h12c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3H6c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3zm1 8v2h10v-2H7z"></path>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" data-name="empty" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt BoxEmpty__SvgBoxEmpty-sc-tc3bem-0 ehVqsH">
                                    <path d="M6 5c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V6c0-.6-.4-1-1-1H6zm0-2h12c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3H6c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3z"></path>
                                  </svg>
                                </div>
                                <div font-size="1" color="text.base" class="sc-dcJsrY iHzcQK">Guest name and name on card are the same</div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                      ))}
                      {infantsArray.map((_,index)=>(
                    <div key={index} class="HotelCheckInFormusp__HotelSection-sc-ufpzwx-0 cnzfrU hotel-0">
                      <div class="sc-aXZVg sc-cPiKLX enYEWO cqIzXg InfoPanel__InfoBanner-sc-1tn8rd1-0 gvfTfv info-bar-warning" data-testid="hotelCheckInInfoPanel" color="background.light" tabindex="0">
                        <div class="sc-aXZVg sc-gEvEer ldwMXy bbyLJJ">
                          <div width="1" class="sc-aXZVg gqSRii">
                            <span font-size="1" class="sc-iGgWBj bOZtTM"></span>
                              <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                                <div font-size="0" color="text" class="sc-dcJsrY hcvZAS">The guest checking into each hotel room must be 21 or older, present a valid Photo ID and credit card.</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <h3 font-size="3" font-weight="bold" color="text.base" class="sc-dcJsrY kYOVZP">Guest Name {storedData !== null ? '' : '(Optional)'}</h3>
                          <div class="sc-aXZVg ipzmek hotels[0].rooms[0]">
                            <div class="sc-aXZVg sc-gEvEer ldwMXy fCsXwM">
                              <div width="0.5" class="sc-aXZVg jUZlZF">
                                <div label="First name" type="text" color="background.lightest" class="sc-aXZVg sc-dAlyuH Input__RelativeWithRadius-sc-1qlv0nb-0 ganGCn gRgPQs hDSKSG">
                                  <div class="sc-aXZVg sc-lcIPJg fPsAVt kygVOe">
                                    <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                                    <input name='firstname' type="text" onChange={(e) => otherGuestFirstName(e, index,3)}  placeholder="First name *" aria-describedby="hotel-first-name-0-error"  class="sc-dtBdUo fMPBrN Input__StyledInput-sc-1qlv0nb-1 emfZoy"  style={{transitionProperty: 'padding-top, padding-bottom', transitionDuration: '0.1'}} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div width="0.5" class="sc-aXZVg jUZlZF">
                                <div label="Last name" type="text" color="background.lightest" class="sc-aXZVg sc-dAlyuH Input__RelativeWithRadius-sc-1qlv0nb-0 eFkDcU gRgPQs hDSKSG">
                                  <div class="sc-aXZVg sc-lcIPJg fPsAVt kygVOe">
                                    <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                                      <input  name='firstname'  type="text" onChange={(e) => otherGuestLastName(e, index,3)} placeholder="Last name *"  class="sc-dtBdUo fMPBrN Input__StyledInput-sc-1qlv0nb-1 emfZoy" style={{transitionProperty: 'padding-top, padding-bottom', transitionDuration: '0.1'}} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <label color="text.light" font-weight="700" font-size="10px" letter-spacing="0.2px" class="sc-kdBSHD cMtyuI">
                              <div class="sc-aXZVg sc-gEvEer jAaJVn lgdAME">
                                <div color="primary" class="sc-JrDLc jDcnOJ">
                                  <input type="checkbox" id="hotels[0].rooms[0].syncBillingName" name="hotels[0].rooms[0].syncBillingName" size="20" color="primary" role="checkbox" aria-checked="false" data-indeterminate="false" class="sc-fjvvzt kgkxcK" value="false" />
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" data-name="checked" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt BoxChecked__SvgBoxChecked-sc-xar6qz-0 fQHOkP">
                                    <path d="M6 3h12c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3H6c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3zm4 14l9-8.6L17.6 7 10 14.3l-3.6-3.5L5 12.2l5 4.8z"></path>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" data-name="indeterminate" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt BoxMinus__SvgBoxMinus-sc-m2y8sx-0 fWKCvU">
                                    <path d="M6 5c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V6c0-.6-.4-1-1-1H6zm0-2h12c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3H6c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3zm1 8v2h10v-2H7z"></path>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" data-name="empty" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ligbEt BoxEmpty__SvgBoxEmpty-sc-tc3bem-0 ehVqsH">
                                    <path d="M6 5c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V6c0-.6-.4-1-1-1H6zm0-2h12c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3H6c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3z"></path>
                                  </svg>
                                </div>
                                <div font-size="1" color="text.base" class="sc-dcJsrY iHzcQK">Guest name and name on card are the same</div>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                      ))}
                    </div>
                </div>
              </div>
            )}
            <div class="sc-aXZVg sc-bXCLTC HideableCard___HideableCard-sc-2xxkmv-0 dgIQti klVZFh yOFdB">
              <form name="billingForm">
                <h3 color="text.base" class="sc-dcJsrY dOWJIt">Contact</h3>
                <div class="sc-aXZVg sc-gEvEer ldwMXy ewVeEI titlel">
                  <div label="Country code" class="sc-aXZVg cEtDdp BillingPhoneCountryCode__PhoneCountryField-sc-qzq4kq-0 hDXoSt1">
                    <div color="background.lightest" class="sc-aXZVg sc-dAlyuH Select__RelativeWithRadius-sc-1irvysi-2 eQjEvg gRgPQs godsfr">
                      <div class="sc-aXZVg sc-lcIPJg fPsAVt kygVOe">
                        <label for="phone-country-code" color="text.light" font-size="10" font-weight="700" letter-spacing="0.2px" class="sc-kdBSHD lnLxis" style={{height: '20px', transitionProperty: 'opacity', transitionDuration: '0.1s', opacity: '1', pointerEvents: 'none', position: 'relative', width: 'calc(100% - 12px)'}}>Title *</label>
                        <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                          <div width="1" class="sc-aXZVg sc-gEvEer feckNt lgdAME nation">
                            <select id='inputState' name='title' value={gestinfo.title} onChange={handlegestchange} class="sc-fXSgeo dEczWB Select__FullWidthSelect-sc-1irvysi-1 gJbZhj" font-size="2,,1" style={{transitionProperty: 'padding-top, padding-bottom', transitionDuration: '0.1s', paddingTop: '20px', paddingBottom: '8px'}}
                              >
                              <option selected>Select Title</option>
                              <option value='MR'>Mr</option>
                              <option value='MRS'>Mrs</option>
                            </select>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="text.light" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ejafuv ChevronDown__SvgChevronDown-sc-1jf6nih-0 sc-esYiGF hCAiHp kYJYVV">
                              <path d="M7.4 8l4.6 4.6L16.6 8 18 9.4l-6 6-6-6L7.4 8z"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="sc-aXZVg dGKWer" style={{width:'50%'}}>
                    <div id="dateofbirth" label="Date Of Birth" type="text" maxlength="64" color="background.lightest" class="sc-aXZVg sc-dAlyuH Input__RelativeWithRadius-sc-1qlv0nb-0 dgkwrF gRgPQs hDSKSG">
                      <div class="sc-aXZVg sc-lcIPJg fPsAVt kygVOe">
                        <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                          <input name='dateofbirth' type="date" maxlength="64" id="dateofbirth" placeholder="Date Of Birth *"  class="sc-dtBdUo fMPBrN Input__StyledInput-sc-1qlv0nb-1 emfZoy" value={gestinfo.dateofbirth} onChange={handlegestchange} style={{transitionProperty: 'padding-top, padding-bottom', transitionDuration: '0.1'}} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='d-flex'>
                  <div class="sc-aXZVg dGKWer" style={{width:'50%'}}>
                    <div id="firstname" label="First Name" type="text" maxlength="64" color="background.lightest" class="sc-aXZVg sc-dAlyuH Input__RelativeWithRadius-sc-1qlv0nb-0 dgkwrF gRgPQs hDSKSG">
                      <div class="sc-aXZVg sc-lcIPJg fPsAVt kygVOe">
                        <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                          <input name='firstname' type="text" maxlength="64" id="firstname" placeholder="First Name *"  class="sc-dtBdUo fMPBrN Input__StyledInput-sc-1qlv0nb-1 emfZoy" value={gestinfo.firstname} onChange={handlegestchange} style={{transitionProperty: 'padding-top, padding-bottom', transitionDuration: '0.1'}} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="sc-aXZVg dGKWer mx-1" style={{width:'50%'}}>
                    <div id="lastname" label="Last Name" type="text" maxlength="64" color="background.lightest" class="sc-aXZVg sc-dAlyuH Input__RelativeWithRadius-sc-1qlv0nb-0 dgkwrF gRgPQs hDSKSG">
                      <div class="sc-aXZVg sc-lcIPJg fPsAVt kygVOe">
                        <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                          <input name='lastname' type="text" maxlength="64" id="lastname" placeholder="Last Name *"  class="sc-dtBdUo fMPBrN Input__StyledInput-sc-1qlv0nb-1 emfZoy" value={gestinfo.lastname} onChange={handlegestchange} style={{transitionProperty: 'padding-top, padding-bottom', transitionDuration: '0.1'}} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sc-aXZVg dGKWer">
                  <div id="email" label="Email address" type="email" maxlength="64" color="background.lightest" class="sc-aXZVg sc-dAlyuH Input__RelativeWithRadius-sc-1qlv0nb-0 dgkwrF gRgPQs hDSKSG">
                    <div class="sc-aXZVg sc-lcIPJg fPsAVt kygVOe">
                      <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                        <input name="email" type="email" maxlength="64" id="email" placeholder="Email address *"  class="sc-dtBdUo fMPBrN Input__StyledInput-sc-1qlv0nb-1 emfZoy" value={gestinfo.email} onChange={handlegestchange} style={{transitionProperty: 'padding-top, padding-bottom', transitionDuration: '0.1'}} />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sc-aXZVg sc-gEvEer ldwMXy ewVeEI">
                  <div label="Country code" class="sc-aXZVg cEtDdp BillingPhoneCountryCode__PhoneCountryField-sc-qzq4kq-0 hDXoSt">
                    <div color="background.lightest" class="sc-aXZVg sc-dAlyuH Select__RelativeWithRadius-sc-1irvysi-2 eQjEvg gRgPQs godsfr">
                      <div class="sc-aXZVg sc-lcIPJg fPsAVt kygVOe">
                      <label for="phone-country-code" color="text.light" font-size="10" font-weight="700" letter-spacing="0.2px" class="sc-kdBSHD lnLxis" style={{height: '20px', transitionProperty: 'opacity', transitionDuration: '0.1s', opacity: '1', pointerEvents: 'none', position: 'relative', width: 'calc(100% - 12px)'}}>Nationality *</label>

                        <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                          <div width="1" class="sc-aXZVg sc-gEvEer feckNt lgdAME nation">

                            <Select options={countryList}  isSearchable={true} value={selectedCountry} onChange={handleCountryChange}  class="sc-fXSgeo dEczWB Select__FullWidthSelect-sc-1irvysi-1 gJbZhj" font-size="2,,1" style={{transitionProperty: 'padding-top, padding-bottom', transitionDuration: '0.1s', paddingTop: '20px', paddingBottom: '8px'}}
                              getOptionLabel={(option) => (
                                <div>
                                  <img
                                      src={option.flag}
                                      alt={option.label}
                                      style={{ width: '20px', marginRight: '8px' }}
                                  />
                                  {option.label} ({option.phoneCode})
                                </div>
                              )}
                              getOptionValue={(option) => option.value} >
                            </Select>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="text.light" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 ejafuv ChevronDown__SvgChevronDown-sc-1jf6nih-0 sc-esYiGF hCAiHp kYJYVV">
                              <path d="M7.4 8l4.6 4.6L16.6 8 18 9.4l-6 6-6-6L7.4 8z"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="sc-aXZVg BillingAddressContent__Input1pxWrap-sc-xgek5t-0 ldwMXy iWoTZL">
                    <div width="1" class="sc-aXZVg sc-gEvEer feckNt jOxjSs">
                      <div label="Mobile phone number" mask="DDD DDD DDDD" class="sc-aXZVg sc-dAlyuH gnRuek gRgPQs">
                        <div class="sc-aXZVg sc-lcIPJg fPsAVt kygVOe">
                          <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                            <input name="phonenumber" type="text" inputmode="text" id="phonenumber" placeholder="Mobile phone number *"  value={gestinfo.phonenumber}  onChange={handlegestchange}  class="sc-dtBdUo fMPBrN InputNumberMasked__StyledInput-sc-15growf-1 gQZEPz" style={{transitionProperty: 'padding-top, padding-bottom', transitionDuration: '0.1s'}} />
                          </div>
                        </div>
                        <div class="sc-aXZVg sc-tagGq InputNumberMasked__CenteredAbsolute-sc-15growf-0 ldwMXy erMnSt jnykfy"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className='row'>
                {storedData !==null && (
                  <>
                <div class='form-group mt-2 col-md-6'>
                <label className='fw-bold'>Gender</label>
                  <select id='inputState' name='gender' value={gestinfo.gender} onChange={handlegestchange} class='form-control form-select mt-2 select-styling'>
                  <option selected>Select Gender</option>
                          <option value='M'>Male</option>
                          <option value='F'>Female</option>
                  </select>
                </div>
                <div class='form-group mt-2 col-md-6'>
                <label className='fw-bold'>Passport Number</label>
                  <input
                    type='text'
                    class='form-control mt-2'
                    value={gestinfo.pno}
                    name='pno'
                    onChange={handlegestchange}
                    placeholder='Passport Number'
                  />
                </div>
                <div class='form-group mt-2 col-md-6'>
                  <label className='fw-bold'>Passport Expiry</label>
                  <input
                    type='date'
                    value={gestinfo.pexpiry}
                    onChange={handlegestchange}
                    class='form-control mt-2'
                    name='pexpiry'
                  />
                </div>
                <div class='form-group mt-2 col-md-6'>
                  <label className='fw-bold'>Country</label>
                  <Select
                                      options={countryList}
                                      isSearchable={true}
                                      className="mt-2"
                                      onChange={handleCountryChangeother}
                                      value={selectedCountryother}
                                      getOptionLabel={(option) => (
                                          <div>
                                          <img
                                              src={option.flag}
                                              alt={option.label}
                                              style={{ width: '20px', marginRight: '8px' }}
                                          />
                                          {option.label} ({option.phoneCode})
                                          </div>
                                      )}
                                      getOptionValue={(option) => option.value}
                                      />
                </div>
                <div class='form-group mt-2 col-md-6'>
                <label className='fw-bold'>Pstal Code</label>
                  <input
                    type='number'
                    class='form-control mt-2'
                    value={gestinfo.postalcode}
                    name='postalcode'
                    onChange={handlegestchange}
                    placeholder='Postal Code'
                  />
                </div>
                </>
                )}
              </div>
            </div>
            <div class="sc-aXZVg sc-bXCLTC HideableCard___HideableCard-sc-2xxkmv-0 KSipl klVZFh yOFdB Payment__EnhancedHideableCard-sc-ayry9i-0 kehola">
              <h3 color="text.base" id="payment-methods-header" class="sc-dcJsrY eWcoQd">Payment</h3>
              <form name="paymentForm">
                <div width="1" display="flex" class="sc-aXZVg cHzsBf" style={{justifyContent: 'space-between'}}>
                  <div data-autobot-element-id="CARD_TYPE" class="sc-aXZVg sc-gEvEer ldwMXy kXWwGH">
                    <div width="36px" alt="Visa Card Image" data-autobot-element-id="CARD_LOGO_VI" class="sc-aXZVg EnhancedCreditCardBox__CreditCardLogo-sc-sonkq4-0 kdUeZQ huaIES">
                      <img src={payment} alt='' />
                    </div>
                  </div>
                </div>
                <div class="sc-aXZVg ipZQDb">
                  <div label="Name on card" maxlength="96" width="1" type="text" color="background.lightest" class="sc-aXZVg sc-dAlyuH Input__RelativeWithRadius-sc-1qlv0nb-0 dgkwrF gRgPQs hDSKSG" htmlFor="cardNumber">
                    <div class="sc-aXZVg sc-lcIPJg fPsAVt kygVOe">
                      <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                        <input name="name" value={cardInfo.name} onChange={handlecarddetail} type="text" maxlength="96" placeholder="Name on card *" class="sc-dtBdUo fMPBrN Input__StyledInput-sc-1qlv0nb-1 emfZoy" style={{transitionProperty: 'padding-top, padding-bottom', transitionDuration: '0.1s'}} />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sc-aXZVg sc-gEvEer ipZQDb ewVeEI">
                  <div width="1" class="sc-aXZVg sc-gEvEer feckNt jOxjSs">
                    <div width="1" class="sc-aXZVg sc-gEvEer feckNt jOxjSs">
                      <div label="Card number" width="1" mask="DDDD DDDD DDDD DDDD" class="sc-aXZVg sc-dAlyuH khcBLK gRgPQs">
                        <div class="sc-aXZVg sc-lcIPJg fPsAVt kygVOe">
                          <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                          <CardNumberElement  className="sc-dtBdUo fMPBrN InputDate__StyledInput-sc-1eygn5f-0 cuSqRA" id="cardNumber" />
                          </div>
                        </div>
                        <div class="sc-aXZVg sc-tagGq InputNumberMasked__CenteredAbsolute-sc-15growf-0 ldwMXy hIzNXN jnykfy">
                          <div data-autobot-element-id="CARD_TYPE" class="sc-aXZVg sc-gEvEer ldwMXy kXWwGH"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sc-aXZVg sc-gEvEer ipZQDb ewVeEI">
                  <div width="1" class="sc-aXZVg feckNt">
                    <div width="1" class="sc-aXZVg sc-gEvEer feckNt jOxjSs">
                      <div label="MM/YYYY" pattern="MM/YYYY" class="sc-aXZVg khcBLK">
                        <div class="sc-aXZVg sc-lcIPJg fPsAVt kygVOe">
                          <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                          <CardExpiryElement className="sc-dtBdUo fMPBrN InputDate__StyledInput-sc-1eygn5f-0 cuSqRA" id="expiryDate" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sc-aXZVg sc-gEvEer ipZQDb ewVeEI">
                  <div width="1" class="sc-aXZVg sc-gEvEer feckNt jOxjSs">
                    <div label="CVV code" required="" width="1" mask="DDDD" data-testid="input-cvv" class="sc-aXZVg sc-dAlyuH dqZlIa gRgPQs">
                      <div class="sc-aXZVg sc-lcIPJg fPsAVt kygVOe">
                        <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                        <CardCvcElement className="sc-dtBdUo fMPBrN InputDate__StyledInput-sc-1eygn5f-0 cuSqRA" id="cvc" />
                        </div>
                      </div>
                      {/* <div class="sc-aXZVg sc-tagGq InputNumberMasked__CenteredAbsolute-sc-15growf-0 ldwMXy hIzNXN jnykfy">
                        <img src="https://assets.pclncdn.com/web/cart-checkout/31291f8144/public/images/cvv.png" width="48" alt="back of credit card" class="sc-hmdomO gGTlCk" />
                      </div> */}
                    </div>
                  </div>
                </div>
                {error && (
                <div style={{color:"red"}}  className="error mt-2" role="alert">
                  {error}
                  </div>
                  )}
              </form>
            </div>
            {/* <div class='row mt-4'>
              <div className='hotel-checkout-shadow p-3'>
                <div className='row'>
                  <div class='form-group mt-2 col-md-6'>
                  <label htmlFor="cardNumber" className="form-label">Card number</label>
                    <CardNumberElement  className="form-control" id="cardNumber" />
                  </div>
                  <div class='form-group mt-2 col-md-6'>
                     <label htmlFor="expiryDate" className="form-label">Expiration date</label>
                     <CardExpiryElement className="form-control" id="expiryDate" />
                  </div>
                  <div class='form-group mt-2 col-md-6'>
                  <label htmlFor="cvc" className="form-label">CVC</label>
                    <CardCvcElement className="form-control" id="cvc" />
                  </div>
                  {error && (
              <div style={{color:"red"}}  className="error mt-2" role="alert">
                {error}
              </div>
            
            )}
                </div>
              </div>
            </div> */}
            <div class='col-12 mt-3'>
              <div class='form-check'>
                <input
                  class='form-check-input'
                  type='checkbox'
                  checked={isChecked} // Set the checked state
                  onChange={handleCheckboxContinue}
                  id='invalidCheck'
                  required
                />
                <label class='form-check-label' for='invalidCheck'>
                  By continuing, you agree to the{' '}
                  <span style={{ color: 'red' }}> Terms and conditions</span>
                </label>
              </div>
              <div class="sc-aXZVg sc-bXCLTC HideableCard___HideableCard-sc-2xxkmv-0 jXuMzM klVZFh yOFdB fs-mask">
                <div class="sc-aXZVg kYgxIw">
                  <div font-weight="bold" font-size="2,2,3" data-testid="importantInfo" color="text.base" class="sc-dcJsrY hIbIIZ">Important Information</div>
                  <ul class="UnorderedList__ImportantInformationUnorderedList-sc-1xbnyt9-1 eznZRo">
                    <li>
                      <div font-size="0" color="text" class="sc-dcJsrY jsPDlj">
                        <span font-weight="bold" class="sc-iGgWBj iQyTkn">Fully Refundable</span> 
                         until 11:59pm (property local time) on 02-20-2024. After that time hotel cancellation and change fees apply as stated in the&nbsp;
                         <a href="#" title="Booking Conditions" data-testid="disclosure-info-booking-conditions" color="primary" class="sc-jXbUNg iiodrr">Booking Conditions
                           <span class="sc-dAbbOL fHRQNh">&nbsp;(this link opens in a modal dialog)</span>
                         </a>.
                      </div>
                    </li>
                    <li>
                      <div font-size="0" color="text" class="sc-dcJsrY jsPDlj">Check-in begins at 2pm and check-out is at 12pm.</div>
                    </li>
                    <li>
                      <div font-size="0" color="text" class="sc-dcJsrY jsPDlj">
                        <div font-weight="bold" class="sc-dcJsrY bkTaLI">
                          <span class="sc-iGgWBj cDZpWC">By selecting Book &amp; Pay Later you agree to the</span>
                          <a href="#" title="Booking Conditions" color="primary.base" data-autobot-element-id="BOOKING_CONDITIONS" data-testid="booking-conditions" target="_blank" font-size="2" rel="noopener" class="sc-jXbUNg heGAxu">
                            <span>Booking Conditions</span>
                            <span class="sc-dAbbOL fHRQNh">&nbsp;(this link opens in a modal dialog)</span>
                          </a>
                          <span class="sc-iGgWBj cDZpWC">,</span>
                          <a title="Terms and Conditions" target="_blank" href="/static-pages/terms_en.html" data-autobot-element-id="TERMS_AND_CONDITIONS" color="primary" rel="noopener" class="sc-jXbUNg iiodrr">Terms &amp; Conditions</a>&nbsp;and&nbsp;
                          <a title="Privacy Policy" target="_blank" href="/static-pages/privacy-policy.html" data-autobot-element-id="PRIVACY_POLICY" data-testid="booking-button-card-privacy-policy-link" color="primary" rel="noopener" class="sc-jXbUNg iiodrr">Privacy Policy</a>.
                       </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div data-testid="sticky-effect" class="sc-aXZVg sc-bXCLTC StickyEffect__StickyCard-sc-wpef65-0 fUFQuA ilAeoW ftzVdE fs-unmask">
                <div color="background.lightest" class="sc-aXZVg sc-bXCLTC HideableCard___HideableCard-sc-lennq0-0 hDLmyD klVZFh kqgbKn">
                  <div class="sc-aXZVg dnXgfb">
                    <div data-testid="hotel-book" class="sc-aXZVg ldwMXy">
                      <button id="confirm-reservation-button" color="secondary" width="1" data-autobot-element-id="BOOK_&amp;_PAY_LATER" class="sc-eqUAAy iogQpj" disabled={isBooking} onClick={confirmbooking}>
                        <div class="sc-aXZVg sc-gEvEer ldwMXy jXzHfI">{isBooking ? 'Please Wait....' : 'Confirm Booking'}</div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <button className='btn fw-bold btn-warning'onClick={showdata}>
                {' '}
                Show
              </button> */}
            </div>
          </div>
          <div className='col-lg-4 order-first order-md-last'>
            <div id="cost-section-component-desktop" class="StickyWrapper-sc-1cqxqgj-0 jWRlYb">
              <div class="sc-aXZVg oSEsZ SummaryOfChargesSlideout__SummaryOfChargesWithCustomBorder-sc-p6yp33-4 hPiIOA">
                <div class="sc-aXZVg sc-bXCLTC hMraCr klVZFh">
                  <div class="sc-aXZVg ldwMXy">
                    {checkoutdetail?.rooms_list.map((item,index)=>(
                      <div key={index} class="sc-aXZVg HVbzn">
                        <div font-size="1" class="sc-aXZVg hIsGgt">
                          {showPrice ?(
                          <div width="1" class="sc-aXZVg sc-gEvEer feckNt iNHyqo">
                            <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                              <span class="sc-iGgWBj gNuJFu receipt-line__item" color="text" font-size="1" style={{color: 'rgb(0, 102, 0)'}}>Room Price</span>
                              <div class="sc-aXZVg ReceiptLine__InlineChildrenContainer-sc-1yfu0gj-1 ldwMXy hWbHIU"></div>
                            </div>
                            <div class="sc-dcJsrY ipzWBx receipt-line__price" color="text" font-size="1" data-autobot-element-id="PRICE_PER_NIGHT" style={{color: 'rgb(0, 102, 0)'}}>{CurrencyRates===undefined ? (baseCName):(CurrencyRates.selectedcurrency)} {hotaltotal=Number(renderPrice(calculateMarkup(item.rooms_total_price))).toFixed(2)}</div>
                          </div>
                          ):(
                          <div width="1" class="sc-aXZVg sc-gEvEer feckNt iNHyqo">
                            <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                              <span class="sc-iGgWBj gNuJFu receipt-line__item" color="text" font-size="1" style={{color: 'rgb(0, 102, 0)'}}>Room Price</span>
                              <div class="sc-aXZVg ReceiptLine__InlineChildrenContainer-sc-1yfu0gj-1 ldwMXy hWbHIU"></div>
                            </div>
                            <div class="sc-dcJsrY ipzWBx receipt-line__price" color="text" font-size="1" data-autobot-element-id="PRICE_PER_NIGHT" style={{color: 'rgb(0, 102, 0)'}}>{checkoutdetail.currency} {hotaltotal=Number(calculateMarkup(item.rooms_total_price)).toFixed(2)}</div>
                          </div>
                          )}
                          <div class="sc-aXZVg kWkErr">
                            <div class="sc-aXZVg ldwMXy">
                              <div font-size="1" class="sc-aXZVg jHufbi">
                                <div width="1" class="sc-aXZVg sc-gEvEer feckNt iNHyqo">
                                  <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                                    <span class="sc-iGgWBj gNuJFu receipt-line__item" color="text" font-size="1">{item.selected_qty} room</span>
                                    <div class="sc-aXZVg ReceiptLine__InlineChildrenContainer-sc-1yfu0gj-1 ldwMXy hWbHIU"></div>
                                  </div>
                                  <div class="sc-dcJsrY ipzWBx receipt-line__price" color="text" font-size="1" data-autobot-element-id="1_ROOM"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div font-size="1" class="sc-aXZVg dGKWer">
                          <div width="1" class="sc-aXZVg sc-gEvEer feckNt iNHyqo">
                            <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                              <span class="sc-iGgWBj gNuJFu receipt-line__item" color="text" font-size="1">Number of nights</span>
                              <div class="sc-aXZVg ReceiptLine__InlineChildrenContainer-sc-1yfu0gj-1 ldwMXy hWbHIU"></div>
                            </div>
                            <div class="sc-dcJsrY ipzWBx receipt-line__price" color="text" font-size="1" data-autobot-element-id="NUMBER_OF_NIGHTS">{daysBetween}</div>
                          </div>
                        </div>
                        {/* <div font-size="1" class="sc-aXZVg dGKWer">
                          <div width="1" class="sc-aXZVg sc-gEvEer feckNt iNHyqo">
                            <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                              <span class="sc-iGgWBj gNuJFu receipt-line__item" color="text" font-size="1">Taxes and fees</span>
                              <div class="sc-aXZVg ReceiptLine__InlineChildrenContainer-sc-1yfu0gj-1 ldwMXy hWbHIU"></div>
                            </div>
                            <div class="sc-dcJsrY ipzWBx receipt-line__price" color="text" font-size="1" data-autobot-element-id="TAXES_AND_FEES">$0</div>
                          </div>
                        </div> */}
                      </div>
                    ))}
                    <hr color="border" class="sc-feUZmu cBOEBA" />
                    <div font-size="2" class="sc-aXZVg fqEUxk">
                      <div width="1" class="sc-aXZVg sc-gEvEer feckNt iNHyqo">
                        <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                          <span class="sc-iGgWBj fQEYCI receipt-line__item" color="success" font-size="2" font-weight="bold">You pay today</span>
                          <div class="sc-aXZVg ReceiptLine__InlineChildrenContainer-sc-1yfu0gj-1 ldwMXy hWbHIU"></div>
                        </div>
                        <div class="sc-dcJsrY fAKedv receipt-line__price" color="success" font-size="2" font-weight="bold" data-autobot-element-id="YOU_PAY_TODAY">$0.00</div>
                      </div>
                    </div>
                  </div>
                  {storedData ===null ? (
                    <>
                     {showPrice ?(
                        <div class="sc-aXZVg ldwMXy">
                          <hr color="border" class="sc-feUZmu cBOEBA" />
                          <div font-size="1" class="sc-aXZVg fqEUxk">
                            <div width="1" class="sc-aXZVg sc-gEvEer feckNt iNHyqo">
                              <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                                <span class="sc-iGgWBj gNuJFu receipt-line__item" color="text" font-size="1">Due later</span>
                                <div class="sc-aXZVg ReceiptLine__InlineChildrenContainer-sc-1yfu0gj-1 ldwMXy hWbHIU">
                                  <div class="sc-aXZVg InfoTooltip__InlineBlockBox-sc-mwujd1-0 dxEKEb gepRhu">
                                    <button title="Toggle tooltip" color="text" data-testid="toggle-tooltip" class="sc-eqUAAy fWRYbD sc-fqkvVR kqrqSr InfoTooltip__StyledIconButton-sc-mwujd1-1 gqYxoE" aria-label="Toggle tooltip">
                                      <div class="sc-aXZVg sc-gEvEer ldwMXy jXzHfI">
                                        <div class="sc-aXZVg sc-gEvEer ldwMXy ewVeEI">
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 bUvHvR InformationOutline__SvgInformationOutline-sc-vo2zk4-0 dOJAoX">
                                            <path d="M11 17h2v-6h-2v6zm1-15C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zM11 9h2V7h-2v2z"></path>
                                          </svg>
                                        </div>
                                      </div>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div class="sc-dcJsrY ipzWBx receipt-line__price" color="text" font-size="1" data-autobot-element-id="DUE_LATER">{CurrencyRates===undefined ? (baseCName):(CurrencyRates.selectedcurrency)} {Number(renderPrice(savemarkup.final))}</div>
                            </div>
                          </div>
                        </div>
                      ):(
                        <div class="sc-aXZVg ldwMXy">
                          <hr color="border" class="sc-feUZmu cBOEBA" />
                          <div font-size="1" class="sc-aXZVg fqEUxk">
                            <div width="1" class="sc-aXZVg sc-gEvEer feckNt iNHyqo">
                              <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                                <span class="sc-iGgWBj gNuJFu receipt-line__item" color="text" font-size="1">Due later</span>
                                <div class="sc-aXZVg ReceiptLine__InlineChildrenContainer-sc-1yfu0gj-1 ldwMXy hWbHIU">
                                  <div class="sc-aXZVg InfoTooltip__InlineBlockBox-sc-mwujd1-0 dxEKEb gepRhu">
                                    <button title="Toggle tooltip" color="text" data-testid="toggle-tooltip" class="sc-eqUAAy fWRYbD sc-fqkvVR kqrqSr InfoTooltip__StyledIconButton-sc-mwujd1-1 gqYxoE" aria-label="Toggle tooltip">
                                      <div class="sc-aXZVg sc-gEvEer ldwMXy jXzHfI">
                                        <div class="sc-aXZVg sc-gEvEer ldwMXy ewVeEI">
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 bUvHvR InformationOutline__SvgInformationOutline-sc-vo2zk4-0 dOJAoX">
                                            <path d="M11 17h2v-6h-2v6zm1-15C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zM11 9h2V7h-2v2z"></path>
                                          </svg>
                                        </div>
                                      </div>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div class="sc-dcJsrY ipzWBx receipt-line__price" color="text" font-size="1" data-autobot-element-id="DUE_LATER">{checkoutdetail.currency} {savemarkup.final.toFixed(2)}</div>
                            </div>
                          </div>
                        </div>
                      )}
                     {showPrice ? (
                        <div class="sc-aXZVg ldwMXy">
                            <hr color="border" class="sc-feUZmu cBOEBA" />
                            <div font-size="2" class="sc-aXZVg fqEUxk">
                              <div width="1" class="sc-aXZVg sc-gEvEer feckNt iNHyqo">
                                <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                                  <span class="sc-iGgWBj buWeuk receipt-line__item" color="text" font-size="2" font-weight="bold">Total Cost</span>
                                  <div class="sc-aXZVg ReceiptLine__InlineChildrenContainer-sc-1yfu0gj-1 ldwMXy hWbHIU"></div>
                                </div>
                                <div class="sc-dcJsrY hmQMEb receipt-line__price" color="text" font-size="2" font-weight="bold" data-autobot-element-id="TOTAL_COST">{CurrencyRates===undefined ? (StripeCurrency=baseCName):(StripeCurrency= CurrencyRates.selectedcurrency)} {StripePayment=(Number(renderPrice(savemarkup.final,1)))}</div>
                              </div>
                            </div>
                            {/* <div font-size="1" class="sc-aXZVg xtwhi">
                              <div width="1" class="sc-aXZVg sc-gEvEer feckNt iNHyqo">
                                <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                                  <span class="sc-iGgWBj gNuJFu receipt-line__item" color="text" font-size="1"></span>
                                  <div class="sc-aXZVg ReceiptLine__InlineChildrenContainer-sc-1yfu0gj-1 ldwMXy hWbHIU"></div>
                                </div>
                                <div class="sc-dcJsrY ipzWBx receipt-line__price" color="text" font-size="1" data-autobot-element-id="">PKR PKR&nbsp;83,519.04</div>
                              </div>
                            </div> */}
                        </div>
                        ):(
                        <div class="sc-aXZVg ldwMXy">
                            <hr color="border" class="sc-feUZmu cBOEBA" />
                            <div font-size="2" class="sc-aXZVg fqEUxk">
                              <div width="1" class="sc-aXZVg sc-gEvEer feckNt iNHyqo">
                                <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                                  <span class="sc-iGgWBj buWeuk receipt-line__item" color="text" font-size="2" font-weight="bold">Total Cost</span>
                                  <div class="sc-aXZVg ReceiptLine__InlineChildrenContainer-sc-1yfu0gj-1 ldwMXy hWbHIU"></div>
                                </div>
                                <div class="sc-dcJsrY hmQMEb receipt-line__price" color="text" font-size="2" font-weight="bold" data-autobot-element-id="TOTAL_COST">{StripeCurrency=checkoutdetail.currency} {StripePayment=savemarkup.final.toFixed(2)}</div>
                              </div>
                            </div>
                            {/* <div font-size="1" class="sc-aXZVg xtwhi">
                              <div width="1" class="sc-aXZVg sc-gEvEer feckNt iNHyqo">
                                <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                                  <span class="sc-iGgWBj gNuJFu receipt-line__item" color="text" font-size="1"></span>
                                  <div class="sc-aXZVg ReceiptLine__InlineChildrenContainer-sc-1yfu0gj-1 ldwMXy hWbHIU"></div>
                                </div>
                                <div class="sc-dcJsrY ipzWBx receipt-line__price" color="text" font-size="1" data-autobot-element-id="">PKR PKR&nbsp;83,519.04</div>
                              </div>
                            </div> */}
                        </div>
                      )}
                    </>
                    ):(
                    <>
                      <div class="sc-aXZVg ldwMXy">
                        <hr color="border" class="sc-feUZmu cBOEBA" />
                        <div font-size="1" class="sc-aXZVg fqEUxk">
                          <div width="1" class="sc-aXZVg sc-gEvEer feckNt iNHyqo">
                            <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                              <span class="sc-iGgWBj gNuJFu receipt-line__item" color="text" font-size="1">Due later</span>
                              <div class="sc-aXZVg ReceiptLine__InlineChildrenContainer-sc-1yfu0gj-1 ldwMXy hWbHIU">
                                <div class="sc-aXZVg InfoTooltip__InlineBlockBox-sc-mwujd1-0 dxEKEb gepRhu">
                                  <button title="Toggle tooltip" color="text" data-testid="toggle-tooltip" class="sc-eqUAAy fWRYbD sc-fqkvVR kqrqSr InfoTooltip__StyledIconButton-sc-mwujd1-1 gqYxoE" aria-label="Toggle tooltip">
                                    <div class="sc-aXZVg sc-gEvEer ldwMXy jXzHfI">
                                      <div class="sc-aXZVg sc-gEvEer ldwMXy ewVeEI">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20" aria-hidden="true" fill="currentcolor" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 bUvHvR InformationOutline__SvgInformationOutline-sc-vo2zk4-0 dOJAoX">
                                          <path d="M11 17h2v-6h-2v6zm1-15C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zM11 9h2V7h-2v2z"></path>
                                        </svg>
                                      </div>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="sc-dcJsrY ipzWBx receipt-line__price" color="text" font-size="1" data-autobot-element-id="DUE_LATER">{ConverterCurrency} {Number(hotaltotal)+Number(flighttotal)}</div>
                          </div>
                        </div>
                      </div>
                      <div class="sc-aXZVg ldwMXy">
                        <hr color="border" class="sc-feUZmu cBOEBA" />
                          <div font-size="2" class="sc-aXZVg fqEUxk">
                            <div width="1" class="sc-aXZVg sc-gEvEer feckNt iNHyqo">
                              <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                                <span class="sc-iGgWBj buWeuk receipt-line__item" color="text" font-size="2" font-weight="bold">Total Cost</span>
                                <div class="sc-aXZVg ReceiptLine__InlineChildrenContainer-sc-1yfu0gj-1 ldwMXy hWbHIU"></div>
                              </div>
                              <div class="sc-dcJsrY hmQMEb receipt-line__price" color="text" font-size="2" font-weight="bold" data-autobot-element-id="TOTAL_COST">{StripeCurrency=ConverterCurrency} {StripePayment=Number(hotaltotal)+Number(flighttotal)}</div>
                            </div>
                          </div>
                          <div font-size="1" class="sc-aXZVg xtwhi">
                            <div width="1" class="sc-aXZVg sc-gEvEer feckNt iNHyqo">
                              <div class="sc-aXZVg sc-gEvEer ldwMXy lgdAME">
                                <span class="sc-iGgWBj gNuJFu receipt-line__item" color="text" font-size="1"></span>
                              <div class="sc-aXZVg ReceiptLine__InlineChildrenContainer-sc-1yfu0gj-1 ldwMXy hWbHIU"></div>
                            </div>
                            <div class="sc-dcJsrY ipzWBx receipt-line__price" color="text" font-size="1" data-autobot-element-id="">PKR PKR&nbsp;83,519.04</div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div class="sc-aXZVg gQhiCX">
                  <div font-size="0" color="text.light" class="sc-dcJsrY euPQdY">
                    <div class="sc-aXZVg PromoIneligibleMessage__PromoBox-sc-18asjf0-0 jYMkmA bffjmu">
                      <div class="sc-aXZVg sc-gEvEer dUQmGt bzumZI">
                        <div font-size="0" class="sc-dcJsrY ePgjVd">Priceline coupons can not be redeemed for this booking.</div>
                      </div>
                    </div>
                    <div class="sc-dcJsrY bAhxyC">The property will charge you in PKR. The price in PKR has been converted to show you the approximate charge in USD based on the current exchange rate, which may vary at the time of travel.</div>  
                    <span class="sc-iGgWBj cDZpWC">Information about</span>
                    <span color="primary.base" aria-label="Taxes and Fees Disclaimer Info Popover" type="button" class="sc-iGgWBj qyedh" style={{cursor: 'pointer'}}>Taxes and Fees.</span>
                  </div>
                </div>
                <div className='sc-aXZVg gQhiCX'>
                  <div className='card-body'>
                    {storedData !==null && (
                      <>
                    {storedData.PricedItineraries.map((item, index) => (
                  <div key={index}>
                    <div className='flight-checkout-logo'>
                      
                </div>
                    <h4 className='text-center' style={{color:'cadetblue'}}>Flight Detail</h4>
                    <div className='mt-2 text-center'><h5>{item.DirectionInd} Flight</h5></div>
                    {item.OriginDestinationOptions.map((item3,index)=>(
                      <div key={index}>
                        {item3.FlightSegments.map(
                          (flight, index) => (
                            <div key={index}>
                              <div className='border-line mt-2'></div>
                              <div class='container3 mt-2'>
                                <h5 class='text3'>
                                  {flight.DepartureAirportLocationCode}
                                </h5>
                                <h5>
                                  <FontAwesomeIcon icon={faArrowRight} />
                                </h5>
                                <h5 class='text2'>
                                  {flight.ArrivalAirportLocationCode}
                                </h5>
                              </div>
                              <div className='border-line mt-2'></div>
                              <div className='tour_booking_amount_area'>
                                <div className='Hotel-img'>
                                  <div className='card-body  '>
                                    <ul class='list-items  list-items-2 mt-2 py-2'>
                                      <li className='mt-2'>
                                        <span>Airline :</span>
                                        {
                                          Airlinenamesdata[
                                            storedData.PricedItineraries[0]
                                              .ValidatingAirlineCode
                                          ].AirLineName
                                        }
                                      </li>

                                      <li className='mt-2'>
                                        <span>Flight Type :</span>
                                        {
                                          flight.CabinClassType
                                        }
                                      </li>

                                      <li className='mt-2'>
                                        <span>Flight Number :</span>
                                        {flight.FlightNumber}
                                      </li>
                                      <li className='mt-2'>
                                        <span>Stop:</span>
                                        {flight.StopQuantity === 0 ? "Non-stop" : flight.StopQuantity+"Stop"}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div
                                class='fl-detail-left ms-0'
                                style={{ padding: '0' }}
                              >
                                <div class='fl-detail-left-container'>
                                  <div class='fl-flight-schedual'>
                                    <div
                                      class='fl-flight-route'
                                      style={{ paddingBottom: '0' }}
                                    >
                                      <div class='fl-route-detail'>
                                        <h4 class='left h4-line'>
                                          {' '}
                                          {moment(flight.DepartureDateTime).format(
                                            'LT'
                                          )}
                                          <br></br>(
                                          {flight.DepartureAirportLocationCode})
                                        </h4>
                                        <h4 class='center fl-width  h4-line text-center'>
                                          <FontAwesomeIcon
                                            color='gray'
                                            icon={faClockFour}
                                          />{' '}
                                        
                                          {Math.floor(flight.JourneyDuration / 60)}h{' '}
                                          {flight.JourneyDuration % 60}m
                                        </h4>
                                        <h4 class='right  h4-line fl-width text-end'>
                                          {moment(flight.ArrivalDateTime).format(
                                            'LT'
                                          )}
                                          <span>
                                            {' '}
                                            ({flight.ArrivalAirportLocationCode})
                                          </span>
                                        </h4>
                                      </div>
                                      <div class='fl-route-direction'>
                                        <div class='fl-route-bar'></div>
                                        <div class='fl-icon'>
                                          <FontAwesomeIcon icon={faPlane} />
                                        </div>
                                      </div>
                                      <div class='fl-route-detail'>
                                        <p
                                          class='left'
                                          style={{ fontSize: '.9em' }}
                                        >
                                          {' '}
                                          <br />{' '}
                                          {moment(flight.DepartureDateTime).format(
                                            'll'
                                          )}
                                        </p>
                                        <p
                                          class='center text-center'
                                          style={{ fontSize: '.9em' }}
                                        >
                                        </p>
                                        <p
                                          class='right text-end'
                                          style={{ fontSize: '.9em' }}
                                        >
                                          <br />{' '}
                                          {moment(flight.ArrivalDateTime).format(
                                            'll'
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    ))}
                    <div className='border-line mt-2'></div>
                    <div className='tour_booking_amount_area'>
                      <div className='Hotel-img'>
                        <div class='card-img mt-2 pb-4'></div>
                        <div className='card-body  '>
                          <div className='mt-2'>
                            <h4 class='card-title'>Baggage Info:</h4>
                          </div>
                          <div >
                          <ul class='list-items mt-2 list-items-2 py-2'>
                          {item.AirItineraryPricingInfo.PTC_FareBreakdowns.map((item1, index) => (
                        
                          <li key={index} className='mt-2'>
                            {item1.PassengerTypeQuantity.Code==='ADT' && (
                                <div style={{display:'contents'}}>
                              <span>Adult Baggage :</span>  <span>{item1.BaggageInfo[0]}/ {item1.CabinBaggageInfo[0]}</span>
                              </div>
                            
                              )}
                              {item1.PassengerTypeQuantity.Code==='CHD' && (
                                <div style={{display:'contents'}}>
                              <span>Child Baggage :</span>  <span>{item1.BaggageInfo[0]}/ {item1.CabinBaggageInfo[0]}</span>
                              </div>
                            
                              )}
                              {item1.PassengerTypeQuantity.Code==='INF' && (
                                <div style={{display:'contents'}}>
                              <span>Infant Baggage :</span>  <span>{item1.BaggageInfo[0]}/ {item1.CabinBaggageInfo[0]}</span>
                              </div>
                            
                              )}
                            </li>
                            ))}
                          </ul>
                          </div>
                        
                          <div className='border-line'></div>
                          <div className='mt-2'>
                            <h4 class='card-title'>Payment Info:</h4>
                          </div>
                          <div>
                            {showPrice ?(
                              <ul class='list-items mt-2 list-items-2 py-2'>
                              {item.AirItineraryPricingInfo.PTC_FareBreakdowns.map((item1, index) => (
                            
                            <li key={index} className='mt-2'>
                            {item1.PassengerTypeQuantity.Code==='ADT' && (
                                <div style={{display:'contents'}}>
                              <span>Adult Price :</span>  <span>{item1.PassengerTypeQuantity.Quantity} * {CurrencyRates===undefined ? (baseCName):(CurrencyRates.selectedcurrency)} {CalculateFLightMarkup(AdultAmount =renderPrice2(item1.PassengerFare.TotalFare.Amount,item1.PassengerTypeQuantity.Quantity,2))}</span>
                              </div>
                            
                              )}
                              {item1.PassengerTypeQuantity.Code==='CHD' && (
                                <div style={{display:'contents'}}>
                              <span>Child Price :</span>  <span>{item1.PassengerTypeQuantity.Quantity} * {CurrencyRates===undefined ? (baseCName):(CurrencyRates.selectedcurrency)} {CalculateFLightMarkup(ChildAmount=renderPrice2(item1.PassengerFare.TotalFare.Amount,item1.PassengerTypeQuantity.Quantity,3))}</span>
                              </div>
                            
                              )}
                              {item1.PassengerTypeQuantity.Code==='INF' && (
                                <div style={{display:'contents'}}>
                              <span>Infant Price :</span>  <span>{item1.PassengerTypeQuantity.Quantity} * {CurrencyRates===undefined ? (baseCName):(CurrencyRates.selectedcurrency)} {CalculateFLightMarkup(InfantAmount=renderPrice2(item1.PassengerFare.TotalFare.Amount,item1.PassengerTypeQuantity.Quantity,4))}</span>
                              </div>
                              )}
                            </li>
                                ))}
                              </ul>
                              ):(
                                <ul class='list-items mt-2 list-items-2 py-2'>
                                {item.AirItineraryPricingInfo.PTC_FareBreakdowns.map((item1, index) => (
                              
                                <li key={index} className='mt-2'>
                                  {item1.PassengerTypeQuantity.Code==='ADT' && (
                                      <div style={{display:'contents'}}>
                                      <span>Adult Price :</span>  <span>{item1.PassengerTypeQuantity.Quantity} * {item1.PassengerFare.TotalFare.CurrencyCode}  {CalculateFLightMarkup(AdultAmount =item1.PassengerFare.TotalFare.Amount)}</span> </div>
                                  
                                    )}
                                    {item1.PassengerTypeQuantity.Code==='CHD' && (
                                      <div style={{display:'contents'}}>
                                      <span>Child Price :</span>  <span>{item1.PassengerTypeQuantity.Quantity} * {item1.PassengerFare.TotalFare.CurrencyCode}  {CalculateFLightMarkup(ChildAmount=item1.PassengerFare.TotalFare.Amount)}</span></div>
                                  
                                    )}
                                    {item1.PassengerTypeQuantity.Code==='INF' && (
                                      <div style={{display:'contents'}}>
                                    <span>Infant Price :</span>  <span>{item1.PassengerTypeQuantity.Quantity} * {item1.PassengerFare.TotalFare.CurrencyCode}  {CalculateFLightMarkup(InfantAmount=item1.PassengerFare.TotalFare.Amount)}</span>
                                    </div>
                                  
                                    )}
                                  
                                  </li>
                            
                                  ))}
                                </ul>
                          )}
                          </div>
                          <div >
                          <ul class='list-items mt-2 list-items-2 py-2'>
                          {showPrice ?(
                            <>
                            <div className='border-line'></div>
                            <li  className='mt-2 '>
                            <span>Flight Total : </span>{CurrencyRates===undefined ? (ConverterCurrency=baseCName):(ConverterCurrency=CurrencyRates.selectedcurrency)} {flighttotal=MarkupSum=CalculateFLightMarkup(ConverterTotalSum=renderPrice2(Totalsum,1,1),5)}
                            </li><li  className='mt-2 '>
                            <span>Hotel Total : </span> {CurrencyRates===undefined ? (baseCName):(CurrencyRates.selectedcurrency)} {hotaltotal}
                            </li>
                            </>
                            ):(
                              <>
                              <div className='border-line'></div>
                              <li  className='mt-2 '>
                              <span>Flight Total : </span> {ConverterCurrency=Currency} {flighttotal=MarkupSum=CalculateFLightMarkup(ConverterTotalSum=Totalsum)}
                            </li>
                            <li  className='mt-2 '>
                            <span>Hotel Total : </span> {checkoutdetail.currency} {hotaltotal=savemarkup.final.toFixed(2)}
                            </li>
                            </>
                            )}
                          </ul>
                          </div>
                          <div className='border-line'></div>
                          <div className='text-center'>
                            {/* <button class='btn btn-primary btn-block select-styling search-btn1'>
                          Book Package
                        </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Layout>
    </>
  )
};

export default BookRoom
